// import VerbyoAppBanner from "../assets/images/verbyo-app/banner.png";
// import MobileApp from "../assets/images/home/mobile-app.png";

// import React from "react";
// import Footer from "./home/Footer";
// import { useHistory } from "react-router-dom";

// export default function VerbyoApp() {
//   const { push } = useHistory();

//   return (
//     <>
//       <section className="max-w-[1366px] relative mt-32 md:mt-64 px-8 md:px-20 pb-20  w-full bg-white mx-auto">
//         <div className="mx-auto w-full flex justify-center -translate-y-1/2 max-w-5xl">
//           <img src={VerbyoAppBanner} className="w-full" alt="banner" />
//           <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-center text-white text-xl lg:text-4xl">
//             VERBYO APP
//           </span>
//         </div>
//         <p className="text-base text-center text-black font-normal tracking-widest">
//           PEOPLE USE
//         </p>
//         <h1 className="text-black text-3xl text-center mt-2 font-bold">
//           VERBYO APP
//         </h1>
//         <div className="px-4 lg:px-0 grid md:grid-cols-2 lg:grid-cols-[1fr_3fr] justify-center items-center gap-16 my-8 max-w-5xl mx-auto">
//           <img
//             src={MobileApp}
//             alt="Mobile App"
//             className="w-full h-72 object-cover object-top md:h-auto"
//           />
//           <div>
//             <p className="text-[#444444] xl:text-left text-center xl:text-xl mt-0 md:mt-7">
//               Used by people around the world to create content on their social
//               media account to engage their friends. The first app in the world
//               that rewards people for each post that they create, giving them
//               the opportunity to monetize their time spent of social media
//               networks.
//               <br /> <br />
//               People can post brands, services, products, social causes, music
//               videos and many other interesting things, to improve the quality
//               of their feed content.
//             </p>
//             <a
//               href="https://download.verbyo.com/"
//               rel="noreferrer"
//               target="_blank"
//             >
//               <button
//                 onClick={() => push("/people")}
//                 className="text-[#5042A8] text-xl font-medium mt-12 gap-3 flex justify-center md:justify-start items-center w-full"
//               >
//                 Download the App
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="8.385"
//                   height="14.667"
//                   viewBox="0 0 8.385 14.667"
//                   className="hidden md:block"
//                 >
//                   <path
//                     id="Icon_ionic-ios-arrow-back"
//                     data-name="Icon ionic-ios-arrow-back"
//                     d="M13.778,13.525,19.328,7.98A1.048,1.048,0,0,0,17.843,6.5l-6.287,6.283a1.046,1.046,0,0,0-.031,1.445l6.314,6.327a1.048,1.048,0,1,0,1.485-1.48Z"
//                     transform="translate(19.635 20.86) rotate(180)"
//                     fill="#0b429e"
//                   />
//                 </svg>
//               </button>
//             </a>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }



import VerbyoCommon from "../components/common/VerbyoCommon";
import Advertising from "../components/common/Advertising";
import Footer from "../components/common/Footer";

export const metadata = {
  title: "Verbyo App",
};
const OtherComponent = () => {
  return (
    <div className="max-w-[909px] w-full mx-auto px-[18px]">
      <img src={"/verbyoapp/mobiles.png"} className="sm:block hidden" width={909} height={545} />
      <img src={"/verbyoapp/small-mobiles.png"} className="sm:hidden block" width={322} height={323} />
    </div>
  );
};
function VerbyoApp() {
  return (
    <>
      <div className="max-w-[1280px] w-full mx-auto  2xl:px-0 px-[17px]">
        {/* Verbyo App */}
        <VerbyoCommon
          handleClick={() => { window.open("https://download.verbyo.com/", "_blank"); }}
          title={"Verbyo App"}
          paragraph={
            "This groundbreaking app, embraced by users globally, enables individuals to craft content for their social media profiles and interact with their friends. It stands as the world's first platform to reward users for every post they make, presenting them with a unique chance to earn from the time they invest in social media networks."
          }
          trustedbussiness
          stylesForDiv={"sm:min-h-[555px] sm:max-h-[555px] min-h-[741px] max-h-[741px]"}
          btnText={"Get the App"}
          videoBtnText={"Watch Video"}
          otherComponent={<OtherComponent />}
        />
        <Advertising
          extraClasses={"max-w-[1195px] sm:mt-[451px] mt-[222px]"}
          extraClassespara={"normal-case max-w-[548px]  	"}
          title={"Meet the people who use Verbyo App"}
          verbyo
          Authenticity
          para={
            "The people posting your campaign on their social media accounts are everyday individuals, akin to a friend you'd meet for coffee, a family member, or even someone you look up to. "
          }
          maindata={[
            {
              id: 0,
              title: "Monetize their time spent online ",

              imgUrl: "/advertise/auth.svg",
            },
            {
              id: 1,
              title: "Improve content quality on their feeds ",
              imgUrl: "/advertise/trust.svg",
            },
            {
              id: 2,
              title: "Drive positive change by sharing social causes ",
              imgUrl: "/advertise/viral.svg",
            },
            {
              id: 3,
              title: "Share innovative ideas with their audience ",
              imgUrl: "/advertise/enhanced.svg",
            },
          ]}
          rightImage={"/verbyoapp/girl.png"}
        />
      </div>
      <Footer marginTop={"sm:mt-[269px] mt-[189px]"} />
    </>
  );
}

export default VerbyoApp;
