import React from "react";
import { useEffect } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Tooltip from "../../Tooltip-v2";
import IconCircle from "../../../assets/images/icons/icon-circle-blue.svg";

Chart.register(ChartDataLabels);

const GenderCard = ({ genderData, tooltip }) => {
  useEffect(() => {
    const ctx = document.getElementById("myChart2");
    const data = {
      labels: ["Men", "Women", "Others"],
      datasets: [
        {
          label: "Users",
          data: [
            ...((genderData?.male == 0 || !genderData?.male) &&
              (genderData?.females == 0 || !genderData?.male) &&
              (genderData?.others == 0 || !genderData?.others)
              ? [25, 25, 25]
              : [genderData?.male, genderData?.females, genderData?.others]),
          ],
          backgroundColor: ["#0D2174", "#C351E5", "#EC6666"],
          borderWidth: 2,
          fill: true,
          borderRadius: 6,
          tension: 0.3,
        },
      ],
    };
    const myChart = new Chart(ctx, {
      type: "doughnut",
      data: data,
      options: {
        layout: {
          padding: {
            top: 30,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled:
              (genderData?.male == 0 &&
                genderData?.females == 0 &&
                genderData?.others == 0) !== true,
            titleColor: "#000000",
            bodyColor: "#000000",
            backgroundColor: "#fff",
            titleFont: {
              family: "Poppins",
              size: 16,
            },
            bodyFont: {
              family: "Poppins",
              size: 14,
            },
            borderWidth: 0.5,
            borderColor: "#94a3b8",
            displayColors: false,
            callbacks: {
              title: (context) => {
                console.log(genderData, context, "chart")
                if (genderData) {
                  return (
                    Math.round(
                      (context[0].dataset.data[context[0].dataIndex] /
                        (genderData?.others +
                          genderData?.male +
                          genderData?.females)) *
                      100
                    ) + "%"
                  );
                } else {
                  return "0%"
                }
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              usePointStyle: true,
              padding: 30
            },
          },
          datalabels: {
            backgroundColor: "#fff",
            borderColor: "#A1A1AA",
            borderWidth: 0.5,
            borderRadius: 500,
            padding: {
              top: 18,
              bottom: 18,
              left: 15,
              right: 15,
            },
            anchor: "end",
            formatter: (value) => {
              if (
                (genderData?.male == 0 || !genderData?.male) &&
                (genderData?.females == 0 || !genderData?.females) &&
                (genderData?.others == 0 || !genderData?.others)
              ) {
                return "0%";
              }
              return (
                Math.round(
                  (value /
                    (genderData?.others +
                      genderData?.male +
                      genderData?.females)) *
                  100
                ) + "%"
              );
            },
            color: "#000000",
            font: {
              weight: "bold",
              size: 14,
            },
          },
        },
      },
    });
    return () => {
      myChart.destroy();
    };
  }, []);
  return (
    <div className="max-w-[313px] min-h-[300px] max-h-[300px] w-full bg-white p-[15px] rounded-[10px]">
      <div className="relative w-fit flex items-center gap-2">
        <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width={30} height={30} rx={5} fill="#F4F1F1" />
          <path d="M22.8525 15.5263C23.6801 14.6995 24.1951 13.5486 24.1946 12.286C24.1951 11.0234 23.6801 9.87249 22.8525 9.04589C22.0259 8.21797 20.8753 7.70305 19.6122 7.70384C18.3496 7.70305 17.1984 8.21801 16.3723 9.04589C15.5442 9.87249 15.0293 11.0234 15.0298 12.286C15.0293 13.5486 15.5442 14.6995 16.3723 15.5263C17.057 16.2121 17.9647 16.6819 18.9739 16.823V18.7055H16.8956V19.9828H18.9739V22.0001H20.251V19.9828H22.3293V18.7055H20.251V16.823C21.2602 16.6819 22.1678 16.2121 22.8525 15.5263ZM19.6122 15.208C19.2066 15.2077 18.8242 15.1262 18.4749 14.9786C17.9517 14.7575 17.5038 14.3857 17.1886 13.919C16.8739 13.4519 16.6907 12.894 16.6907 12.286C16.6907 11.8802 16.7722 11.4981 16.9198 11.1491C17.1406 10.6256 17.5121 10.178 17.9791 9.86272C18.4466 9.54773 19.0043 9.36454 19.6122 9.36401C20.0177 9.36429 20.4001 9.44581 20.7494 9.59364C21.2726 9.81446 21.7205 10.1862 22.0357 10.6529C22.3505 11.1204 22.5336 11.6782 22.5341 12.286C22.5336 12.6918 22.4521 13.0739 22.3045 13.423C22.0837 13.9464 21.7117 14.3943 21.2452 14.7093C20.7777 15.0245 20.2205 15.2075 19.6122 15.208Z" fill="black" />
          <path d="M11.2207 12.5919V9.4452L12.4889 10.7132L13.3924 9.81026L10.5824 7L7.77186 9.81026L8.67483 10.7132L9.94361 9.44495V12.5919C8.93435 12.7333 8.02676 13.2031 7.34205 13.889C6.51392 14.7157 5.9995 15.8663 6 17.1289C5.9995 18.3921 6.51392 19.5427 7.34205 20.3692C8.16866 21.1974 9.31928 21.7121 10.5824 21.7116C11.845 21.7121 12.9957 21.1974 13.8222 20.3692C14.6504 19.5426 15.1648 18.392 15.1648 17.1289C15.1648 15.8663 14.6504 14.7157 13.8222 13.8891C13.1375 13.2031 12.2299 12.7333 11.2207 12.5919ZM13.2748 18.2662C13.0534 18.7896 12.6819 19.2372 12.2155 19.5525C11.748 19.8678 11.1902 20.051 10.5824 20.051C10.1763 20.051 9.7945 19.9694 9.44518 19.8218C8.92198 19.6005 8.47409 19.229 8.15885 18.7623C7.84357 18.2948 7.66042 17.7373 7.66042 17.129C7.66042 16.7234 7.74194 16.3413 7.88949 15.9923C8.11084 15.4691 8.48237 15.0212 8.94931 14.7059C9.41625 14.391 9.97403 14.2081 10.5824 14.2075C10.9879 14.2075 11.3697 14.289 11.7196 14.4369C12.2423 14.658 12.6902 15.0295 13.0054 15.4962C13.3207 15.9637 13.5039 16.5212 13.5039 17.129C13.5039 17.535 13.4223 17.9173 13.2748 18.2662Z" fill="black" />
        </svg>
        <div className="font-bold text-sm flex items-center gap-2">
          By Gender{" "}
        </div>
        <Tooltip
          style={{ width: "14px", height: "14px" }} small={true} content={[tooltip]} />
      </div>

      <div className="flex justify-between items-end h-[230px] w-full ">
        <canvas id="myChart2" width="350" height="260"></canvas>
      </div>
      <div className="flex justify-center gap-14 text-xs relative bottom-2">
        <p className="text-xs font-bold">{genderData?.male}</p>
        <p className="text-xs font-bold">{genderData?.females}</p>
        <p className="text-xs font-bold">{genderData?.others}</p>
      </div>
    </div>
  );
};

export default GenderCard;
