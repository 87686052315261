import React from "react";

const Camera2 = () => {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 21.4444V8.66667C1 7.25527 2.16406 6.11111 3.6 6.11111H4.25C5.06836 6.11111 5.83898 5.73239 6.33 5.08889L9.216 1.30667C9.3633 1.11362 9.59449 1 9.84 1H18.16C18.4056 1 18.6367 1.11362 18.784 1.30667L21.67 5.08889C22.161 5.73239 22.9316 6.11111 23.75 6.11111H24.4C25.836 6.11111 27 7.25527 27 8.66667V21.4444C27 22.8559 25.836 24 24.4 24H3.6C2.16406 24 1 22.8559 1 21.4444Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0002 18.8888C16.872 18.8888 19.2002 16.6004 19.2002 13.7777C19.2002 10.955 16.872 8.6666 14.0002 8.6666C11.1283 8.6666 8.80017 10.955 8.80017 13.7777C8.80017 16.6004 11.1283 18.8888 14.0002 18.8888Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Camera2;
