import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import VerbyoLogo from "../../assets/images/Hexagon Verbyo abastru cu alb (2).png";
import React from "react";

const AdvertisersLogo = ({ row, width }) => {
  const history = useHistory();
  return (
    <div
      onClick={() => {
        history.push("/");
      }}
      className={`flex cursor-pointer ${
        row ? "flex-row max-w-[300px] w-full" : "flex-col"
      } justify-center items-center`}
    >
      <img width={width} src={VerbyoLogo} alt="Logo" />
      <p
        className={`${
          row ? "md:text-[16px]" : "md:text-[28px]"
        } text-2xl text-[#262F61]  font-bold`}
      >
        Verbyo <span className="font-normal ">for</span> Advertisers
      </p>
    </div>
  );
};

export default AdvertisersLogo;
