import React from "react";
import Loading from "../Loading/Loading";

const SkeletonLoader = () => {
  return (
    <>
      {" "}
      <div className="flex gap-[20px]">
        <div
          className={`p-8 max-w-[400px]  bg-white animate-pulse justify-center h-[600px] w-full border-t-[3px]   flex items-center`}
        >
          <Loading />
        </div>{" "}
        <div className="w-full">
          <div className="flex gap-[23px] items-center">
            <div className="max-w-[180px] w-full rounded-[40px] min-h-[40px] bg-white border-[3px] animate-pulse"></div>
            <div className="max-w-[180px] w-full rounded-[40px] min-h-[40px] bg-white border-[3px] animate-pulse"></div>
            <div className="max-w-[180px] w-full rounded-[40px] min-h-[40px] bg-white border-[3px] animate-pulse"></div>

          </div>
          <div className="flex mt-5 items-center gap-5">
            <div
              className={`p-8 bg-white flex justify-center animate-pulse h-[300px] w-full max-w-[480px] border-t-[3px]   flex items-center`}
            >
              <Loading />
            </div>{" "}
            <div
              className={`p-8 bg-white animate-pulse justify-center h-[300px] w-full border-t-[3px]   flex items-center`}
            >
              <Loading />
            </div>{" "}
          </div>
          <div className="flex items-center gap-5 mt-5 justify-between">
            <div
              className={`p-8 bg-white animate-pulse justify-center h-[265px] w-full border-t-[3px]   flex items-center`}
            >
              <Loading />
            </div>{" "}
            {/* <div
              className={`p-8 bg-white animate-pulse justify-center h-[250px] w-full border-t-[3px]   flex items-center`}
            ></div>{" "}
            <div
              className={`p-8 bg-white animate-pulse justify-center h-[250px] w-full border-t-[3px]   flex items-center`}
            >
              <Loading />
            </div>{" "} */}
          </div>
          <div className="flex items-center gap-5 mt-5 justify-between">
            <div
              className={`p-8 bg-white animate-pulse justify-center h-[300px] w-1/2 border-t-[3px]   flex items-center`}
            >
              <Loading />
            </div>{" "}
            <div
              className={`p-8 bg-white animate-pulse justify-center h-[300px] w-1/2 border-t-[3px]   flex items-center`}
            >
              <Loading />
            </div>{" "}
            <div
              className={`p-8 bg-white animate-pulse justify-center h-[300px] w-1/2 border-t-[3px]  flex items-center`}
            >
              <Loading />
            </div>{" "}
          </div>
          <div className="flex gap-[20px] item-center mt-5">
            <div
              className={`p-8 bg-white animate-pulse justify-center max-w-[313px] h-[300px] w-1/3 border-t-[3px]  flex items-center`}
            >
              <Loading />
            </div>
            <div
              className={`p-8 bg-white animate-pulse justify-center max-w-[313px] h-[300px] w-1/3 border-t-[3px]  flex items-center`}
            >
              <Loading />
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default SkeletonLoader;
