import React from "react";
import { getDaysLeft } from "../../admin/TableRaffle/Table";

const DetailsTab = ({ data, color }) => {
  const winnerIs = () => {
    const { winner } = data;
    if (!!winner) {
      return (
        <>
          <div className="mt-3">
            <div>
              <p className="text-black text-base font-semibold">Name</p>
              <p className="text-black text-sm font-regular mt-2">
                {winner.name}
              </p>
            </div>
            <div className="mt-4">
              <p className="text-black text-base font-semibold">Whatsapp</p>
              <p className="text-black text-sm font-regular mt-2">
                {winner.whatsappNo ? winner.whatsappNo : "N/A"}
              </p>
            </div>
            <div className="mt-4">
              <p className="text-black text-base font-semibold">Email</p>
              <p className="text-black text-sm font-regular mt-2">
                {winner.email ? winner.email : "N/A"}
              </p>
            </div>
            <div className="mt-4">
              <p className="text-black text-base font-semibold">
                Deleiver Prize to:
              </p>
              {data.productType == "digital" ? (
                <p className="text-black text-sm font-regular mt-2">
                  {winner.email ? winner.email : "N/A"}
                </p>
              ) : (
                <>
                  <p className="text-black text-sm font-regular mt-2">
                    {winner.shippingAddress
                      ? `${winner.shippingAddress.country}, ${winner.shippingAddress.state}, ${winner.shippingAddress.city} `
                      : "Shipping Address not provided, Please contact through email or whatsapp."}
                  </p>
                  {winner.shippingAddress && (
                    <p className="text-black text-sm font-regular ">
                      {winner.shippingAddress.street}
                      {winner.shippingAddress.zipCode}
                      {winner.shippingAddress.aptOrFloor}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <p className="text-red-500 text-sm font-medium mt-3">No Winner yet!</p>
      );
    }
  };
  return (
    <div>
      <div
        tabIndex={4}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`flex justify-start items-start flex-col     h-full  `}
      >
        <div className="w-full max-w-[1090px]">
          <div className="mt-7  gap-5 w-full flex">
            {/* 1st column */}
            <div className="flex flex-col min-w-[350px] gap-6">
              {/* 3rd box */}
              <div
                style={{
                  borderTop: `3px solid ${color}`,
                }}
                className="rounded-sm col-start-1 row-span-3 w-full max-w-[350px] bg-white px-1 "
              >
                <div className={` w-full h-1 ${color}`} />
                <div className="py-[22px] pl-[23px]  pr-[10px]">
                  <p className="break-words font-semibold text-lg leading-6">
                    {data.status === "rejected"
                      ? "Rejection Reason"
                      : "Winner Of the Raffle"}
                  </p>

                  {data.status === "rejected" ? (
                    <p className="text-sm text-black font-regular mt-3">
                      {data.rejectionReason}
                    </p>
                  ) : (
                    winnerIs()
                  )}
                </div>
                {data.status === "rejected" && (
                  <div className="w-full flex justify-end pb-3 pr-3">
                    <button className="text-xs text-[#198CD9] font-semibold hover:opacity-90">
                      CONTACT SUPPORT
                    </button>
                  </div>
                )}
              </div>
              {/* 2nd box */}
              {/* 1st box */}
              <div className="rounded-sm col-start-1 row-span-3 w-full max-w-[350px] bg-white px-1 ">
                <div
                  style={{
                    borderTop: `3px solid ${color}`,
                  }}
                  className={` w-full border-t `}
                />
                <div className="py-[22px] pl-[23px]  pr-[10px]">
                  <p className="break-words font-semibold text-lg leading-6">
                    Short Stats
                  </p>
                  <p className="text-black font-normal mt-2 text-sm">
                    Time remaining:{" "}
                    <span
                      className={`font-medium ${
                        getDaysLeft(data?.endDate) < 0 && "text-red-500"
                      }`}
                    >
                      {getDaysLeft(data?.endDate) > 0
                        ? getDaysLeft(data?.endDate)
                        : "Raffle ended"}
                    </span>
                  </p>
                  <p className="text-black font-normal mt-2 text-sm">
                    People signed up:{" "}
                    <span className="font-medium">
                      {data.usersJoined ? data.usersJoined : 0}
                    </span>
                  </p>
                  <p className="text-black font-normal mt-2 text-sm">
                    Ticket Price:{" "}
                    <span className="font-medium">
                      {data?.points} Social Points
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {/* 2nd column */}
            <div className="flex flex-col min-w-[350px] gap-6">
              {/* 1st box */}
              <div
                style={{
                  borderTop: `3px solid ${color}`,
                }}
                className="rounded-sm col-start-1 row-span-3 w-full max-w-[350px] bg-white px-1 "
              >
                <div className={` w-full h-1 ${color}`} />
                <div className="py-[22px] pl-[23px]  pr-[10px]">
                  <p className="break-words font-semibold text-lg leading-6">
                    Raffle description
                  </p>

                  <p className="text-sm font-normal text-black leading-[18px] mt-3">
                    {data?.description}
                  </p>
                </div>
              </div>
              {/* 2nd box */}
              <div className="rounded-sm col-start-1 row-span-3 w-full max-w-[350px] bg-white px-1 ">
                <div
                  style={{
                    borderTop: `3px solid ${color}`,
                  }}
                  className={` w-full h-1 ${color}`}
                />
                <div className="py-[22px] pl-[23px]  pr-[10px]">
                  <p className="break-words font-semibold text-lg leading-6">
                    Raffle details
                  </p>
                  <div className="h-[200px] w-[280px] mt-3 rounded-[10px] overflow-hidden flex items-center  ">
                    <img
                      src={data?.media}
                      alt={data?.name}
                      className=" object-cover w-full"
                    />
                  </div>
                  <div className="mt-3">
                    <div className="mt-4">
                      <p className="text-black text-base font-semibold">
                        Raffle Name
                      </p>
                      <p className="text-black text-sm font-regular mt-2">
                        {data.name ? data.name : "N/A"}
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="text-black text-base font-semibold">
                        Brand
                      </p>
                      <p className="text-black text-sm font-regular mt-2">
                        {data.brandName ? data.brandName : "N/A"}
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="text-black text-base font-semibold">
                        Type of campaign
                      </p>
                      <p className="text-black text-sm font-regular mt-2">
                        Raffle
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 3rd column */}
            <div className="flex flex-col min-w-[350px] gap-6">
              <div
                style={{
                  borderTop: `3px solid ${color}`,
                }}
                className="rounded-sm col-start-1 row-span-3 w-full max-w-[350px] bg-white px-1 "
              >
                <div className={` w-full h-1 ${color}`} />
                <div className="py-[22px] pl-[23px]  pr-[10px]">
                  <p className="break-words font-semibold text-lg leading-6">
                    Audience
                  </p>
                  <div className="mt-3">
                    <div className="mt-4">
                      <p className="text-black text-base font-semibold">
                        Country
                      </p>
                      <p className="text-black text-sm font-regular mt-2">
                        {data.country ? data.country : "N/A"}
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="text-black text-base font-semibold">
                        County
                      </p>
                      <p className="text-black capitalize text-sm font-regular mt-2">
                        {data.state ? data.state : "N/A"}
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="text-black text-base font-semibold">City</p>
                      <p className="text-black capitalize text-sm font-regular mt-2">
                        {data.city ? data.city : "N/A"}
                      </p>
                    </div>
                  </div>
                  <p className="break-words font-semibold text-lg leading-6 mt-[18px]">
                    Demographics
                  </p>
                  <div className="mt-4">
                    <p className="text-black text-base font-semibold">Age</p>
                    <p className="text-black capitalize text-sm font-regular mt-2">
                      {data?.ageFrom} - {data?.ageTo}
                    </p>
                  </div>
                  <div className="mt-4">
                    <p className="text-black text-base font-semibold">Gender</p>
                    <p className="text-black capitalize text-sm font-regular mt-2">
                      {data?.gender}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsTab;
