import { API } from "../globals";
import { buildHeaders } from "./utils";

export const RaffleApi = {
  createOrUpdate: (data) => {
    return fetch(`${API}/raffles/create-or-update`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ ...data }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  cancelPending: (id) => {
    return fetch(`${API}/raffles/cancel-pending`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ id }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  deleteRaffle: (id) => {
    return fetch(`${API}/raffles/delete-raffle`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ id }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  saveToDraft: (data) => {
    return fetch(`${API}/raffles/create-or-update`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ ...data, status: "draft" }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  launch: (id) => {
    return fetch(`${API}/raffles/launch-raffle`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ raffleId: id }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  uploadCover: (data) => {
    return fetch(`${API}/raffles/upload-media`, {
      method: "post",
      headers: buildHeaders(true),
      body: data,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  deleteCover: (data) => {
    return fetch(`${API}/raffles/delete-media`, {
      method: "post",
      headers: buildHeaders(true),
      body: data,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  uploadCreative: (data) => {
    return fetch(`${API}/raffles/uploadcreative`, {
      method: "post",
      headers: buildHeaders(true),
      body: data,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  deleteCreative: (data) => {
    return fetch(`${API}/raffles/deletecreative`, {
      method: "post",
      headers: buildHeaders(true),
      body: data,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getRaffles: () => {
    return fetch(`${API}/raffles/get-all`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getRaffle: (id) => {
    return fetch(`${API}/raffles/get-raffle/${id}`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  approveRaffle: (id) => {
    return fetch(`${API}/admin/migrate-raffle`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ raffleId: id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  adminRaffleDetails: (id) => {
    return fetch(`${API}/admin/get-raffle-details`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ raffleId: id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  notifyRaffleUsers: (id) => {
    return fetch(`${API}/admin/notify-raffle-users`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ raffle: id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
};
