import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const RaffleCampaignToggle = ({ campaignsLength, rafflesLength }) => {
  const history = useHistory();
  const pathname = useLocation().pathname;
  return (
    <div className="flex lg:gap-8 gap-4 2xl:px-8 md:px-5 px-3 w-full">
      <p
        onClick={() => history.push("/dashboard/my-campaigns")}
        className={`${
          pathname.includes("campaigns") && "font-bold"
        }  cursor-pointer text-[#293663] tracking-widest flex items-center gap-1 text-sm lg:text-lg font-medium hover:opacity-90  border-purple-900 pb-1`}
      >
        Campaigns
        <div className="px-2 py-[1px] bg-[#E6E6E6] rounded-md text-sm font-bold">
          {campaignsLength || 0}
        </div>
      </p>
      <p
        onClick={() => history.push("/dashboard/my-raffles")}
        className={`${
          pathname.includes("raffles") && "font-bold"
        }  cursor-pointer text-[#293663] tracking-widest flex items-center gap-1 text-sm lg:text-lg font-medium hover:opacity-90  border-purple-900 pb-1`}
      >
        Raffles
        <div className="px-2 py-[1px] bg-[#E6E6E6] rounded-md text-sm font-bold">
          {rafflesLength || 0}
        </div>
      </p>
    </div>
  );
};

export default RaffleCampaignToggle;
