import {
  LOAD_RAFFLES,
  START,
  SUCCESS,
  ERROR,
  DELETE_USER,
  LOGOUT,
} from "../actions/types";

const defaultsState = {
  loading: false,
  loaded: false,
  error: false,
  entities: null,
  editData: null,
};

const status = {
  live: 0,
  pending: 1,
  rejected: 2,
  finished: 3,
  draft: 4,
};
// eslint-disable-next-line
const correctRaffles = (entities) =>
  entities.map((c) => {
    return {
      id: c.id,
      raffle: c.name,
      platform: c.platform,
      image: c.coverImg,
      product: c.productName,
      country: c.country,
      state: c.county,
      city: c.city,
      gender: c.gender,
      price: c.budget || "price",
      ageFrom: `${c.ageFrom}`,
      ageTo: `${c.ageTo}`,
      status: status[c.status],
      reason: c.rejectionReason,
      notified: c.notified,
      demographics1: "632",
      demographics2: "230",
      averageAge: "25",
      duration: "4",
      offers: "1,200",
      postsNo: c.numberOfPosts,
      budget: c.budget,
      total: "124",
      clicks: {
        total: "3,400",
        unique: 24,
        countries: ["Romania"],
      },
      customAd: c.customAd,
    };
  });

export default function raffles(state = defaultsState, action) {
  switch (action.type) {
    case LOAD_RAFFLES + START:
      return {
        loading: true,
        loaded: false,
        error: false,
      };
    case LOAD_RAFFLES + SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: false,
        entities: action.payload,
      };
    case LOAD_RAFFLES + ERROR:
      return {
        loading: false,
        loaded: false,
        error: true,
      };
    // case EDIT_RAFFLE + SUCCESS:
    //   return {
    //     loaded: true,
    //     editData: action.payload ? action.payload : false
    //   };
    // case EDIT_RAFFLE + ERROR:
    //   return {
    //     error: true
    //   };
    case LOGOUT:
    case DELETE_USER:
      return {
        loading: false,
        loaded: false,
        error: false,
        entities: null,
      };
    default:
      return state;
  }
}
