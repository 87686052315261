import PropTypes from "prop-types";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";
import io from "socket.io-client";

import { LastLocationProvider } from "react-router-last-location";
import { Sticky, StickyContainer } from "react-sticky";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import * as actions from "./actions";
import Header from "./components/Header/Header";
import globals, { API, TOKEN_COOKIE_NAME } from "./globals";
import history from "./history";

import { getToken } from "./api/utils";
import "./app.scss";
import Loading from "./components/Loading/LoadingDashboard";
import NotificationsHeader from "./components/dashboard/NotificationsHeader-v2/NotificationsHeader";
import {
  authSelectorLoading,
  authUserType,
  campaignsSelector,
  campaignsSelectorLoaded,
  campaignsSelectorLoading,
} from "./selectors";
import styles from "./styles/main.module.scss";
import { debounce } from "./utils/debounce";
import { isDashboardPage, isFormPage } from "./utils/routes";

import PopUp from "./components/PopUp/PopUp";
import RaffleDetails from "./pages/RaffleDetails";
import ActivateAccountPage from "./pages/activateAccount";
import ActivateEmail from "./pages/activateEmail";
import NewPassword from "./pages/newPassword";
import { setCookie } from "./utils/cookie";
// import RaffleDetails from "./pages/RaffleDetails";
import VerbyoApp from "./pages/verbyo-app";
import { getIntercomHmac, signUpWithGoogle } from "./services/userAuthService";
import CookieBanner from "./components/CookieBanner";
import intializeSocket, { setupSocketEvent } from "./utils/socket.js";

const About = lazy(() => import("./pages/about"));
const FoundationCampaign = lazy(() =>
  import("./pages/campaigns/FoundationCampagn")
);
const ContactUs = lazy(() => import("./pages/contactUs"));
const IgVerify = lazy(() => import("./pages/admin/igverification.js"));
const TermControls = lazy(() => import("./pages/admin/TermControls.js"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const ChooseAccount = lazy(() =>
  import("./pages/chooseAccount-v2/ChooseAccount")
);
const organicDistributionCampaign = lazy(() =>
  import("./pages/campaigns/organicDistribution")
);
const CreateSocialMediaContentCampaign = lazy(() =>
  import("./pages/campaigns/createSocialMediaContent")
);
const ContentCreationDistribution = lazy(() =>
  import("./pages/campaigns/contentCreationDistribution")
);
const FreeHumanitarianCampaign = lazy(() =>
  import("./pages/campaigns/freeHumanitarian-v2")
);
const CreateRaffle = lazy(() => import("./pages/campaigns/createRaffle"));
const InstagramCampaign = lazy(() =>
  import("./pages/campaigns/instagramCampaign")
);
const YoutubeCampaign = lazy(() => import("./pages/campaigns/youtubeCampaign"));
const BlogCampaign = lazy(() => import("./pages/campaigns/blogCampaign"));
const GamingCampaign = lazy(() => import("./pages/campaigns/gamingCampaign"));
const MyCampaigns = lazy(() => import("./pages/dashboard/my-campaigns-v2"));
const MyRaffles = lazy(() => import("./pages/dashboard/my-raffles"));
const StartCampaign = lazy(() => import("./pages/dashboard/start-campaign"));
const FAQ = lazy(() => import("./pages/faq"));
const Footer = lazy(() => import("./pages/home/Footer.js"));
const Home = lazy(() => import("./pages/home"));
const LandMark = lazy(() => import("./pages/landmark"));
const GDPR = lazy(() => import("./pages/gdpr"));
const Login = lazy(() => import("./pages/login"));
const OrganicDistributionPage = lazy(() =>
  import("./pages/organicDistribution")
);

const Page404 = lazy(() => import("./pages/page404"));
const Pricing = lazy(() => import("./pages/pricing"));
const PasswordRecovery = lazy(() => import("./pages/passwordRecovery"));
const PrivacyPolicy = lazy(() => import("./pages/privacyPolicy"));
const SignUp = lazy(() => import("./pages/signUp"));
const Settings = lazy(() => import("./pages/dashboard/settings"));
const Notifications = lazy(() => import("./pages/dashboard/notifications"));
const VideoResources = lazy(() => import("./pages/dashboard/videoResouces-v2"));
const ContentCreation = lazy(() => import("./pages/contentCreation"));
const Terms = lazy(() => import("./pages/terms"));
const WeCare = lazy(() => import("./pages/weCare"));
const UseDesktop = lazy(() => import("./pages/useDesktop"));
const NewHeader = lazy(() => import("./components/common/Header.js"));
//admin pages
const Admin = lazy(() => import("./pages/admin/admin"));
const Users = lazy(() => import("./pages/admin/users"));
const UsersLastFiveDays = lazy(() => import("./pages/admin/usersocials"));
const Clients = lazy(() => import("./pages/admin/clients"));
const Campaigns = lazy(() => import("./pages/admin/campaigns-v2"));
const Companies = lazy(() => import("./pages/admin/companies"));
const Offers = lazy(() => import("./pages/admin/offers"));
const Financials = lazy(() => import("./pages/admin/financials"));
const UserPosts = lazy(() => import("./pages/admin/UserPosts"));
const People = lazy(() => import("./pages/people"));
const CampaignManager = lazy(() => import("./pages/verbyo-campaign-manager"));
const Careers = lazy(() => import("./pages/careers.js"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions.js"));
const AutoLogoutPopup = lazy(() =>
  import("./components/AutoLogoutPopup/AutoLogoutPopup")
);
const InterestStatistics = lazy(() =>
  import("./pages/admin/interests-stats.js")
);
const UserSocialVerification = lazy(() =>
  import("./pages/admin/user-social-verification.js")
);
const FoundationVerification = lazy(() => import("./pages/verification/index"));
const CampaignDetailsPage = lazy(() => import("./pages/CamapignDetails"));
const UserDetails = lazy(() => import("./pages/admin/UserDetails"));
const Foundations = lazy(() => import("./pages/admin/foundations"));
const FoundationDetails = lazy(() => import("./pages/admin/FoundationDetails"));
const PlatformSettings = lazy(() => import("./pages/admin/settings.js"));
const Blocked = lazy(() => import("./pages/Blocked"));
const Warned = lazy(() => import("./pages/Warned"));
const Suspended = lazy(() => import("./pages/Suspended"));
const Announements = lazy(() => import("./pages/admin/announement.js"));
const PwlUser = lazy(() => import("./pages/pwlUser.js"));

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

function _MainContainer(props) {
  const { desktop, checkUser, campaigns, getToken, logoutUser } = props;
  const location = useLocation();
  const path = location.pathname;
  const headerLeft = isDashboardPage(path);

  let history = useHistory();

  useEffect(() => {
    if (getToken() === null) {
      logoutUser();
      if (
        !path.includes("reset-password") && //! Issues with reset password page
        !path.includes("activate-new-user") &&
        !path.includes("/login") &&
        !path.includes("/sign-up") &&
        !path.includes("/social-media-campaigns") &&
        !path.includes("/about") &&
        !path.includes("/people") &&
        !path.includes("/verbyo-campaign-manager") &&
        !path.includes("/verbyo-careers") &&
        !path.includes("/contact-us") &&
        !path.includes("/verbyo-terms") &&
        !path.includes("/verbyo-app") &&
        !path.includes("/pwl-user")
      ) {
        history.push("/");
      }
    }
  }, [getToken()]);

  return (
    <>
      {headerLeft ? (
        <StickyContainer className={styles.rowContainer}>
          {props.children}
        </StickyContainer>
      ) : (
        <StickyContainer
          className={
            path == globals.CHOOSE_ACCOUNT_PATH
              ? styles.stickyContainer_v2
              : styles.stickyContainer
          }
        >
          {props.children}
        </StickyContainer>
      )}
    </>
  );
}

const MainContainer = withRouter(_MainContainer);

function _HeaderContainer(props) {
  const { desktop, campaigns } = props;
  const location = useLocation();
  const path = location.pathname;
  const headerLeft =
    isDashboardPage(path) ||
    path == globals.PRIVACY_POLICY_PATH ||
    path == globals.TERMS_PATH;

  const hideHeaderFormPage = isFormPage(path);
  return (
    <>
      <>
        {desktop ? (
          <>
            {headerLeft ? (
              //  TODO TODO:POST Side panel */
              // <LeftPanel />
              <></>
            ) : (
              <Sticky>
                {({ style, distanceFromTop, calculatedHeight }) => {
                  return (
                    <div style={{ ...style, zIndex: 20 }}>
                      <Header isSticky={-distanceFromTop > calculatedHeight} />
                    </div>
                  );
                }}
              </Sticky>
            )}
          </>
        ) : (
          <Sticky>
            {({ style, distanceFromTop, calculatedHeight }) => {
              return (
                <div style={{ ...style, zIndex: 20 }}>
                  <Header
                    mobile={true}
                    isSticky={-distanceFromTop > calculatedHeight}
                  />
                </div>
              );
            }}
          </Sticky>
        )}
      </>
    </>
  );
}

const HeaderContainer = withRouter(_HeaderContainer);

function App({
  userLoading,
  fetchUser,
  logoutUser,
  campaigns,
  userType,
  userId,
  updateCampaign,
  user,
}) {
  const [checkUser, setCheckUser] = useState(false);

  const desktopWidth = 1152;
  const mobileWidth = 640;
  const isDesktop = () => window.innerWidth >= desktopWidth;
  const isMobile = () => window.innerWidth <= mobileWidth;
  const [desktop, setDesktop] = useState(isDesktop());
  const [mobile, setMobile] = useState(isMobile());
  const [autoLogout, setAutoLogout] = useState(false);
  !checkUser && getToken() && fetchUser().then(setCheckUser(true));

  const bootIntercom = async (APP_ID) => {
    const hmac = await getIntercomHmac({
      email: user.accountEmail,
    });

    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: APP_ID,
      email: user.accountEmail, // the email for your user
      user_hash: hmac.hmac, // an Identity Verification user hash for your user
      custom_attributes: {
        user_id: userId,
        type: user.type,
      },
    });
  };

  useEffect(() => {
    var APP_ID = "w579ozlq";

    if (userId) {
      if (!user.wallet) return;
      bootIntercom(APP_ID);
    } else {
      window.Intercom("boot", {
        app_id: APP_ID,
      });
    }
  }, [userId]);

  useEffect(() => {
    if (!getToken()) return;
    let timer;
    const mouseEvent = (e) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (!getToken()) return;
        const options = {
          "max-age": 3600,
        };
        setCookie(TOKEN_COOKIE_NAME, getToken(), options);
        window.cookieStore &&
          window.cookieStore.get(TOKEN_COOKIE_NAME).then((cookie) => {
            if (cookie) {
              localStorage.setItem(TOKEN_COOKIE_NAME, JSON.stringify(cookie));
            }
          });
      }, 300);
    };
    window.addEventListener("mousemove", mouseEvent);
    return () => {
      window.removeEventListener("mousemove", mouseEvent);
    };
  }, [getToken()]);
  console.log(getToken(), "token");
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!getToken()) return;
      const options = {
        "max-age": 3600,
      };
      setCookie(TOKEN_COOKIE_NAME, getToken(), options);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDesktop(isDesktop());
      setMobile(isMobile());
    }, 500);

    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  //  Gets cookie value
  const getLocalCookie = () => {
    const cookie = localStorage.getItem(TOKEN_COOKIE_NAME);
    return cookie ? JSON.parse(cookie) : null;
  };

  // Checks if the cookie is expired or not.
  useEffect(() => {
    const interval = setInterval(() => {
      const cookie = getLocalCookie();
      if (cookie && cookie.value) {
        const expiry = cookie.expires;
        const currentTime = Date.now();
        if (currentTime >= expiry - 2000) {
          setAutoLogout(true);
          localStorage.removeItem(TOKEN_COOKIE_NAME);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const socket = intializeSocket(userId);

    // campaignChangeEvent
    if (socket)
      setupSocketEvent(socket, "onCampaignChange", (data) => {
        updateCampaign(data.res);
      });
  }, [userId]);
  function AdminCheck({ userType }) {
    const isAdmin = userType === "ADMIN";
    const history = useHistory();
    useEffect(() => {
      if (!isAdmin && window.location.pathname.includes("/admin")) {
        history.push("/");
      }
    }, [isAdmin]);

    return null;
  }

  if (getToken() || !userType) {
    <Loading />;
  }
  return (
    <GoogleOAuthProvider clientId="215839256699-8c29i8ppn8d7dn5r4lum335363p3rnd8.apps.googleusercontent.com">
      <Router history={history}>
        {userType && <AdminCheck userType={userType} />}
        <LastLocationProvider>
          <Suspense fallback={<Loading />}>
            <PopUp
              open={autoLogout}
              onClose={() => {
                setAutoLogout(false);
              }}
              fullWidth
              lightCloseButton={false}
              hideCloseButton={true}
            >
              <AutoLogoutPopup />
            </PopUp>
            <ScrollToTop>
              <MainContainer
                desktop={desktop}
                checkUser={checkUser}
                getToken={getToken}
                logoutUser={logoutUser}
                campaigns={campaigns}
              >
                {/* <HeaderContainer desktop={desktop} /> */}
                <NewHeader />
                <main className={styles.main}>
                  {/*  */}
                  {userLoading ? (
                    <Loading />
                  ) : (
                    <Suspense fallback={<Loading />}>
                      <NotificationsHeader />
                      <Switch>
                        <Route path="/" exact component={Home} />
                        <Route
                          path="/pwl-user/:token"
                          exact
                          component={PwlUser}
                        />
                        <Route
                          path="/social-media-campaigns"
                          exact
                          component={LandMark}
                        />
                        <Route
                          path={globals.ORGANIC_DISTRIBUTION_PATH}
                          exact
                          component={OrganicDistributionPage}
                        />
                        <Route
                          path={globals.CAMPAIGN_DETAILS_PATH + "/:id"}
                          exact
                          component={CampaignDetailsPage}
                        />
                        <Route
                          path={globals.RAFFLE_DETAILS_PATH + "/:id"}
                          exact
                          component={RaffleDetails}
                        />
                        <Route
                          path={globals.CONTENT_CREATION_PATH}
                          exact
                          component={ContentCreation}
                        />
                        <Route
                          path={globals.PRICING_PATH}
                          exact
                          component={Pricing}
                        />
                        <Route path={globals.FAQ_PATH} exact component={FAQ} />

                        <Route
                          path={globals.ABOUT_PATH}
                          exact
                          component={About}
                        />
                        <Route path={"/people"} exact component={People} />
                        <Route
                          path={"/foundation-verification"}
                          exact
                          component={FoundationVerification}
                        />
                        <Route
                          path={"/verbyo-campaign-manager"}
                          exact
                          component={CampaignManager}
                        />
                        <Route
                          exact
                          path={globals.VERBYO_APP_PATH}
                          component={VerbyoApp}
                        />
                        <Route
                          path={"/verbyo-careers"}
                          exact
                          component={Careers}
                        />
                        <Route
                          path={"/verbyo-terms"}
                          exact
                          component={TermsAndConditions}
                        />
                        <Route
                          path={globals.PRIVACY_POLICY_PATH}
                          exact
                          component={PrivacyPolicy}
                        />
                        <Route
                          path={globals.TERMS_PATH}
                          exact
                          component={Terms}
                        />
                        <Route
                          path={globals.GDPR_PATH}
                          exact
                          component={GDPR}
                        />
                        <Route
                          path={globals.SIGN_UP_PATH}
                          exact
                          component={SignUp}
                        />
                        <Route
                          path={globals.CONTACT_US_PATH}
                          exact
                          component={ContactUs}
                        />
                        <Route
                          path={globals.WE_CARE_PATH}
                          exact
                          component={WeCare}
                        />

                        <Route
                          path={`${globals.ACTIVATE_ACCOUNT_PATH}/:token`}
                          exact
                          // component={!mobile ? ActivateAccountPage : UseDesktop}
                          component={ActivateAccountPage}
                        />
                        <Route
                          path={globals.PASSWORD_RECOVERY_PATH}
                          exact
                          component={PasswordRecovery}
                        />
                        <Route
                          path={`${globals.NEW_PASSWORD_PATH}/:token`}
                          component={!mobile ? NewPassword : UseDesktop}
                        />
                        <Route path={globals.LOGIN_PATH} component={Login} />
                        <Route
                          path={globals.DASHBOARD_PATH}
                          exact
                          component={!mobile ? Dashboard : UseDesktop}
                          // component={Dashboard}
                        />
                        <Route
                          path={globals.START_CAMPAIGN}
                          exact
                          component={StartCampaign}
                        />
                        <Route
                          path={globals.CHOOSE_ACCOUNT_PATH}
                          exact
                          component={ChooseAccount}
                        />
                        <Route
                          path={[
                            `${globals.DASHBOARD_CAMPAIGN_1}/:id`,
                            globals.DASHBOARD_CAMPAIGN_1,
                          ]}
                          component={
                            !mobile ? organicDistributionCampaign : UseDesktop
                          }
                        />
                        <Route
                          path={[
                            `${globals.DASHBOARD_CAMPAIGN_2}/:id`,
                            globals.DASHBOARD_CAMPAIGN_2,
                          ]}
                          component={
                            !mobile ? ContentCreationDistribution : UseDesktop
                          }
                        />
                        <Route
                          path={[
                            `${globals.DASHBOARD_CAMPAIGN_3}/:id`,
                            globals.DASHBOARD_CAMPAIGN_3,
                          ]}
                          component={
                            !mobile
                              ? CreateSocialMediaContentCampaign
                              : UseDesktop
                          }
                        />
                        <Route
                          path={[
                            `${globals.DASHBOARD_CAMPAIGN_4}/:id`,
                            globals.DASHBOARD_CAMPAIGN_4,
                          ]}
                          component={FreeHumanitarianCampaign}
                        />
                        <Route
                          path={[
                            `${globals.DASHBOARD_CAMPAIGN_5}/:id`,
                            globals.DASHBOARD_CAMPAIGN_5,
                          ]}
                          component={!mobile ? CreateRaffle : UseDesktop}
                        />

                        <Route
                          path={[
                            `${globals.DASHBOARD_CAMPAIGN_IG}/:id`,
                            globals.DASHBOARD_CAMPAIGN_IG,
                          ]}
                          component={InstagramCampaign}
                        />

                        <Route
                          path={"/dashboard/foundation-campaign"}
                          exact
                          component={FoundationCampaign}
                        />
                        <Route
                          path={[
                            `${globals.DASHBOARD_CAMPAIGN_YT}/:id`,
                            globals.DASHBOARD_CAMPAIGN_YT,
                          ]}
                          component={!mobile ? YoutubeCampaign : UseDesktop}
                        />
                        <Route
                          path={[
                            `${globals.DASHBOARD_CAMPAIGN_BL}/:id`,
                            globals.DASHBOARD_CAMPAIGN_BL,
                          ]}
                          component={!mobile ? BlogCampaign : UseDesktop}
                        />
                        {/* <Route
                        path={[
                          `${globals.DASHBOARD_CAMPAIGN_GM}/:id`,
                          globals.DASHBOARD_CAMPAIGN_GM,
                        ]}
                        component={!mobile ? GamingCampaign : UseDesktop}
                      /> */}

                        <Route
                          path={globals.DASHBOARD_CAMPAIGNS_PATH}
                          exact
                          render={() => <MyCampaigns offsetTop={63} />}
                        />
                        <Route
                          path={globals.DASHBOARD_RAFFLES_PATH}
                          exact
                          render={() => <MyRaffles />}
                        />
                        <Route
                          path={"/account/blocked"}
                          exact
                          render={() => <Blocked />}
                        />
                        <Route
                          path={"/account/warned"}
                          exact
                          render={() => <Warned />}
                        />
                        <Route
                          path={"/account/suspended"}
                          exact
                          render={() => <Suspended />}
                        />
                        <Route
                          path={[
                            `${globals.DASHBOARD_SETTINGS_PATH}/:tab`,
                            globals.DASHBOARD_SETTINGS_PATH,
                          ]}
                          component={Settings}
                        />

                        <Route
                          path={globals.DASHBOARD_NOTIFICATIONS_PATH}
                          exact
                          component={Notifications}
                        />
                        <Route
                          path={globals.DASHBOARD_VIDEO_RESOURCES_PATH}
                          exact
                          component={!mobile ? VideoResources : UseDesktop}
                        />

                        <Route
                          path={globals.ADMIN_PATH}
                          exact
                          component={!mobile ? Admin : UseDesktop}
                        />
                        <Route
                          path={"/admin/platform-settings"}
                          exact
                          component={!mobile ? PlatformSettings : UseDesktop}
                        />
                        <Route
                          path={"/admin/insta-verification"}
                          exact
                          component={!mobile ? IgVerify : UseDesktop}
                        />
                        <Route
                          path={"/admin/terms-controls"}
                          exact
                          component={!mobile ? TermControls : UseDesktop}
                        />
                        <Route
                          path={"/admin/announcement"}
                          exact
                          component={!mobile ? Announements : UseDesktop}
                        />
                        <Route
                          path={"/admin/foundation"}
                          exact
                          component={!mobile ? Foundations : UseDesktop}
                        />
                        <Route
                          path={"/admin/foundations-details/:id"}
                          exact
                          component={!mobile ? FoundationDetails : UseDesktop}
                        />
                        <Route
                          path={globals.ADMIN_USERS_PATH}
                          exact
                          component={!mobile ? Users : UseDesktop}
                        />
                        <Route
                          path={globals.ADMIN_USERSOCIALS_PATH}
                          exact
                          component={!mobile ? UsersLastFiveDays : UseDesktop}
                        />
                        <Route
                          path={globals.ADMIN_CLIENTS_PATH}
                          exact
                          component={!mobile ? Clients : UseDesktop}
                        />
                        <Route
                          path={globals.ADMIN_CAMPAIGNS_PATH}
                          exact
                          component={!mobile ? Campaigns : UseDesktop}
                        />
                        <Route
                          path={globals.ADMIN_OFFERS_PATH}
                          exact
                          component={!mobile ? Offers : UseDesktop}
                        />
                        <Route
                          path={globals.ADMIN_FINANCIALS_PATH}
                          exact
                          component={!mobile ? Financials : UseDesktop}
                        />
                        <Route
                          path={globals.ADMIN_COMPANIES_PATH}
                          exact
                          component={!mobile ? Companies : UseDesktop}
                        />

                        <Route
                          path={`${globals.ACTIVATE_EMAIL_PATH}/:token`}
                          exact
                          component={ActivateEmail}
                        />
                        <Route
                          path={`${globals.ADMIN_STATS_PATH}`}
                          exact
                          component={InterestStatistics}
                        />
                        <Route
                          path={`${globals.ADMIN_USER_VERIFICATION_PATH}`}
                          exact
                          component={UserSocialVerification}
                        />
                        <Route
                          path={`${globals.ADMIN_USER_DETAILS_PATH}:id`}
                          exact
                          component={UserDetails}
                        />
                        <Route
                          path={`${globals.ADMIN_USER_POSTS}`}
                          exact
                          component={UserPosts}
                        />
                        <Route component={Page404} />
                      </Switch>
                    </Suspense>
                  )}
                  <Footer />
                </main>
              </MainContainer>
            </ScrollToTop>
          </Suspense>
        </LastLocationProvider>
        <CookieBanner />
      </Router>
    </GoogleOAuthProvider>
  );
}

App.propTypes = {
  userLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => {
  return {
    userLoading: authSelectorLoading(store),
    campaigns: campaignsSelector(store),
    loading: campaignsSelectorLoading(store),
    loaded: campaignsSelectorLoaded(store),
    userId: store.auth.id,
    userType: authUserType(store),
    user: store.auth,
  };
};

export default connect(mapStateToProps, actions)(App);
