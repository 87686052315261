import gsap from "gsap";
import React, { useRef, useState } from "react";

const Tooltip = ({
  content,
  content2,
  style,
  small,
  tooltipPosition,
  black,
}) => {
  const [hover, setHover] = useState(false);

  const [position, setPosition] = useState({
    left: 0,
    top: 0,
    right: 0,
  });

  const tooltipRef = useRef(null);
  const iconRef = useRef(null);

  const tl = gsap.timeline();
  const onMouseEnter = () => {
    const { left, top, right } = iconRef.current.getBoundingClientRect();
    setPosition({ left, top, right });
    tl.to(tooltipRef.current, {
      opacity: 1,
      duration: 0.2,
      ease: "power3.inOut",
      scaleY: 1,
    });
  };
  const onMouseLeave = () => {
    tl.to(tooltipRef.current, {
      opacity: -1,
      duration: 0.4,
      ease: "power3.inOut",
      scaleY: -1,
    });
  };
  const left = tooltipPosition === "left";
  return (
    <div className="" style={style ? style : {}}>
      <button
        ref={iconRef}
        onMouseOver={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {black ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={small ? "10" : "15"}
            height={small ? "10" : "15"}
            viewBox="0 0 15 15"
          >
            <path
              id="Icon_ionic-md-help-circle"
              data-name="Icon ionic-md-help-circle"
              d="M10.875,3.375a7.5,7.5,0,1,0,7.5,7.5A7.5,7.5,0,0,0,10.875,3.375Zm.757,12.007H10.118V13.868h1.514Zm-.007-2.272h-1.5c0-2.416,2.25-2.243,2.25-3.743a1.507,1.507,0,0,0-1.5-1.5,1.522,1.522,0,0,0-1.5,1.5h-1.5a3,3,0,0,1,6,0C13.875,11.239,11.625,11.452,11.625,13.111Z"
              transform="translate(-3.375 -3.375)"
            />
          </svg>
        ) : (
          <svg
            className="hover:opacity-80 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            width={small ? "14" : "20.509"}
            height={small ? "14" : "20.509"}
            viewBox="0 0 20.509 20.509"
          >
            <g
              id="Icon_ionic-ios-help-circle-outline"
              data-name="Icon ionic-ios-help-circle-outline"
              transform="translate(-3.375 -3.375)"
            >
              <path
                id="Path_1781"
                data-name="Path 1781"
                d="M13.629,4.755a8.871,8.871,0,1,1-6.276,2.6,8.815,8.815,0,0,1,6.276-2.6m0-1.38A10.254,10.254,0,1,0,23.884,13.629,10.253,10.253,0,0,0,13.629,3.375Z"
                fill="#bfbaba"
              />
              <path
                id="Path_1782"
                data-name="Path 1782"
                d="M16.69,11.25c1.849,0,3.121,1.025,3.121,2.5a2.411,2.411,0,0,1-1.385,2.189c-.858.5-1.149.863-1.149,1.494v.389H15.566l-.015-.424a2.052,2.052,0,0,1,1.163-2.169c.833-.5,1.183-.813,1.183-1.425a1.2,1.2,0,0,0-1.326-1.06,1.247,1.247,0,0,0-1.321,1.213H13.5C13.535,12.369,14.708,11.25,16.69,11.25Zm-1.3,8.45a1.058,1.058,0,1,1,1.055,1.016A1.033,1.033,0,0,1,15.393,19.7Z"
                transform="translate(-3.026 -2.353)"
                fill="#bfbaba"
              />
            </g>
          </svg>
        )}
      </button>

      <div
        ref={tooltipRef}
        style={{
          left: left ? position.left - 300 : position.left + 16,
          top: position.top + 16,
          right: position.right,
        }}
        className={`w-[303px] 
        fixed  text-sm z-50 text-black origin-top
        scale-y-0 pointer-events-none opacity-0   p-4 pb- px-3 tooltip-shadow bg-white rounded-md`}
      >
        {content &&
          content.map((text, index) => (
            <>
              {index >= 1 && <br />}
              {index >= 1 && <br />}
              <span
                className="font-normal text-xs"
                style={{
                  textAlign: "justify",
                }}
                dangerouslySetInnerHTML={{ __html: text }}
              ></span>
            </>
          ))}
        {content2 && <br />}
        {content2 && (
          <span className="text-xs tooltip-text__sub text-gray-400 italic  text-right">
            {content2}
          </span>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
