import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";

import { isAdminPage, isDashboardPage } from "../../utils/routes";
import { authSelectorIsUserLogin } from "../../selectors";
import globals from "../../globals";
import Logo from "../../assets/images/Hexagon Verbyo abastru cu alb (2).png";
import BusinessLogo from "../Logos/Business";

const ServiceMenu = ({ setHover, serviceMenu }) => {
  const history = useHistory();
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`absolute py-7 ${
        serviceMenu ? "scale-y-100 " : " scale-y-0  pointer-events-none"
      } top-[49px] h-[240px] flex flex-col gap-2 transition-all duration-200 origin-top justify-between right-[240px] px-6 shadow-md  bg-white`}
    >
      <div
        onClick={() => history.push("/social-media-campaigns#organic-campaign")}
        className="text-lg cursor-pointer hover:opacity-90 text-black"
      >
        for advertisers
      </div>
      <div
        onClick={() =>
          history.push("/social-media-campaigns#advertise-instagram-account")
        }
        className="text-lg cursor-pointer hover:opacity-90 text-black"
      >
        for content creators
      </div>
      <div
        onClick={() => history.push("/social-media-campaigns#find-volunteers")}
        className="text-lg cursor-pointer hover:opacity-90 text-black"
      >
        for ngos and foundations
      </div>
      <div
        onClick={() =>
          history.push("/social-media-campaigns#promote-landmarks")
        }
        className="text-lg cursor-pointer hover:opacity-90 text-black"
      >
        for government
      </div>
      <div
        onClick={() => history.push("/verbyo-campaign-manager")}
        className="text-lg cursor-pointer hover:opacity-90 text-black"
      >
        ad campaign manager
      </div>
    </div>
  );
};

const Header = ({ location, isSticky, mobile }) => {
  const [menuShown, setMenuShown] = useState(false);
  const [serviceMenu, setServiceMenu] = useState(false);
  const [serviceMenu2, setServiceMenu2] = useState(false);
  const [hover, setHover] = useState(false);
  const path = location.pathname;
  const centerLogo = !isAdminPage(path) && !isDashboardPage(path);
  const homeUrl = isDashboardPage(path) ? globals.DASHBOARD_PATH : "/";

  const history = useHistory();

  const showMenu = () => {
    setMenuShown(true);
  };

  const closeMenu = () => {
    setMenuShown(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hover) {
        setServiceMenu(true);
      } else {
        setServiceMenu(false);
      }
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      {(path == "/" ||
        path.includes("/social-media-campaigns") ||
        path == "/about" ||
        path == "/people" ||
        path == "/verbyo-campaign-manager" ||
        path == "/verbyo-careers" ||
        path == "/contact-us" ||
        path == "/verbyo-terms" ||
        path == "/verbyo-app") && (
        <header
          className={`fixed transition-all delay-200 duration-500 z-50 ${
            !isSticky && path == "/"
              ? " -translate-y-full opacity-0"
              : "translate-y-0"
          } flex items-center justify-center top-0 left-0 w-full ${
            mobile ? "h-[60px]" : "h-[70px]"
          }  bg-white`}
        >
          {mobile ? (
            <div className="w-full flex justify-between items-center px-4">
              <BusinessLogo row width={40} />
              <button
                onClick={() => {
                  setMenuShown(!menuShown);
                }}
                className="hover:opacity-90"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23.097"
                  height="16.064"
                  viewBox="0 0 23.097 16.064"
                >
                  <g
                    id="Icon_feather-menu"
                    data-name="Icon feather-menu"
                    transform="translate(-3.5 -8)"
                  >
                    <path
                      id="Path_6743"
                      data-name="Path 6743"
                      d="M4.5,18H25.6"
                      transform="translate(0 -1.968)"
                      fill="none"
                      stroke="#aaa0a0"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Path_6744"
                      data-name="Path 6744"
                      d="M4.5,9H25.6"
                      fill="none"
                      stroke="#aaa0a0"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Path_6745"
                      data-name="Path 6745"
                      d="M4.5,27H25.6"
                      transform="translate(0 -3.936)"
                      fill="none"
                      stroke="#aaa0a0"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
            </div>
          ) : (
            <div className="max-w-[1380px] w-full flex items-center justify-between">
              <BusinessLogo row width={60} />
              <div className="relative">
                <ul className="flex items-center gap-10">
                  <li
                    onClick={() => {
                      history.push("/");
                    }}
                    className="text-lg text-black cursor-pointer hover:opacity-90"
                  >
                    home
                  </li>
                  <li
                    onClick={() => {
                      history.push("/about");
                    }}
                    className="text-lg text-black cursor-pointer hover:opacity-90"
                  >
                    about us
                  </li>
                  <li
                    onClick={() => {
                      history.push("/verbyo-app");
                    }}
                    className="text-lg text-black cursor-pointer hover:opacity-90"
                  >
                    the app
                  </li>
                  <li
                    onClick={() => {
                      history.push("/people");
                    }}
                    className="text-lg text-black cursor-pointer hover:opacity-90"
                  >
                    the people
                  </li>
                  <li
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className="text-lg flex items-center gap-2 text-black cursor-pointer hover:opacity-90"
                  >
                    marketing campaigns
                    <p
                      className={` ${
                        serviceMenu ? "" : "rotate-180"
                      } transition-all duration-200 text-3xl`}
                    >
                      +
                    </p>
                  </li>
                  <div className="h-10 w-[1px] border-r border-r-[#1f8efa]"></div>
                  <li
                    onClick={() => {
                      history.push("/sign-up");
                    }}
                    className="text-lg flex  items-center gap-2 text-black cursor-pointer hover:opacity-90"
                  >
                    create an account
                  </li>
                  <li
                    onClick={() => {
                      history.push("/login");
                    }}
                    className="text-lg flex  items-center gap-2 text-black cursor-pointer hover:opacity-90"
                  >
                    sign in
                  </li>
                </ul>
                {<ServiceMenu serviceMenu={serviceMenu} setHover={setHover} />}
              </div>
            </div>
          )}
        </header>
      )}
      {mobile && menuShown && (
        <div className="h-screen w-screen fixed  left-0 z-[5000] bg-white flex items-center justify-start flex-col">
          <svg
            onClick={() => {
              setMenuShown(false);
            }}
            className="absolute top-10"
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
          >
            <path
              id="Icon_material-close"
              data-name="Icon material-close"
              d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z"
              transform="translate(-7.5 -7.5)"
              fill="#817d7d"
            />
          </svg>
          <div className=" mt-24">
            <BusinessLogo row width={60} />
            <ul className="flex  flex-col justify-center items-center font-medium text-black text-[18px] gap-7">
              <div
                onClick={() => {
                  setMenuShown(false);
                }}
              >
                <Link to="/">home</Link>
              </div>
              <div
                onClick={() => {
                  setMenuShown(false);
                }}
              >
                <Link to="/about">about us</Link>
              </div>
              <div
                onClick={() => {
                  setMenuShown(false);
                }}
              >
                <Link to="/verbyo-app">the app</Link>
              </div>
              <div
                onClick={() => {
                  setMenuShown(false);
                }}
              >
                <Link to="/people">the people</Link>
              </div>
              <div
                onClick={() => {
                  setServiceMenu2(!serviceMenu2);
                }}
              >
                {/* <Link to="/social-media-campaigns#organic-campaign"> */}
                <div className="flex items-center gap-2">
                  marketing campaigns
                  <svg
                    className={`transition-all duration-200 text-3xl ${
                      serviceMenu2 ? "rotate-180" : ""
                    }`}
                    fill="#333333"
                    height="14px"
                    width="14px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 330 330"
                    xmlSpace="preserve"
                    stroke="#333333"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        id="XMLID_225_"
                        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                      />{" "}
                    </g>
                  </svg>
                </div>
                {/* </Link> */}
                <div
                  className={`service menu
                ${
                  serviceMenu2
                    ? "scale-y-100 h-full "
                    : " scale-y-0 h-0 absolute  pointer-events-none"
                }
                flex pl-5 mt-2 transition-all duration-300 origin-top flex-col gap-1`}
                >
                  <div
                    onClick={() => {
                      history.push("/social-media-campaigns#organic-campaign");
                      setServiceMenu2(false);
                      setMenuShown(false);
                    }}
                    className="text-base font-semibold cursor-pointer hover:opacity-90 text-black"
                  >
                    for advertisers
                  </div>
                  <div
                    onClick={() => {
                      setServiceMenu2(false);
                      setMenuShown(false);

                      history.push(
                        "/social-media-campaigns#advertise-instagram-account"
                      );
                    }}
                    className="text-base font-semibold cursor-pointer hover:opacity-90 text-black"
                  >
                    for content creators
                  </div>
                  <div
                    onClick={() => {
                      history.push("/social-media-campaigns#find-volunteers");
                      setServiceMenu2(false);
                      setMenuShown(false);
                    }}
                    className="text-base font-semibold cursor-pointer hover:opacity-90 text-black"
                  >
                    for ngos and foundations
                  </div>
                  <div
                    onClick={() => {
                      setServiceMenu2(false);
                      setMenuShown(false);

                      history.push("/social-media-campaigns#promote-landmarks");
                    }}
                    className="text-base font-semibold cursor-pointer hover:opacity-90 text-black"
                  >
                    for government
                  </div>
                  <div
                    onClick={() => {
                      setServiceMenu2(false);
                      setMenuShown(false);
                      history.push("/verbyo-campaign-manager");
                    }}
                    className="text-base font-semibold cursor-pointer hover:opacity-90 text-black"
                  >
                    ad campaign manager
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  setMenuShown(false);
                }}
              >
                <Link to="/verbyo-campaign-manager">campaign manager</Link>
              </div>
              <div
                onClick={() => {
                  setMenuShown(false);
                }}
              >
                <Link to="/sign-up">sign up</Link>
              </div>
              <div
                onClick={() => {
                  setMenuShown(false);
                }}
              >
                <Link to="/login">log in</Link>
              </div>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

Header.defaultProps = {
  isLogin: false,
  isSticky: false,
};

Header.propTypes = {
  isLogin: PropTypes.bool,
  isSticky: PropTypes.bool,
};

const mapStateToProps = (store) => ({
  isLogin: authSelectorIsUserLogin(store),
});

export default connect(mapStateToProps, null)(withRouter(Header));
