import React from 'react'

const NewButton = ({ text, styles, handleClick }) => {

    return (
        <button
            onClick={handleClick}
            className={`${styles}  ${!styles?.includes("text-[#057DF3]") && "text-[#fff]"} ${!styles?.includes("hover") && "hover:bg-[#0554f3]"}  transition-all duration-300 ease-in bg-[#057DF3]  rounded-[6px]   font-semibold   w-full justify-center items-center  flex min-h-[44px]`}
        >  {text}
        </button>
    );
};

export default NewButton;

