import React from "react";

const Verbyo = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6254 8.48864V7.06161C13.1934 5.21816 12.2336 3.03445 11.8782 0.70347C11.7716 0.0178875 10.9134 -0.251267 10.4665 0.281964C9.39506 1.54648 8.55212 3.02937 7.98339 4.67477C9.72005 6.61979 12.0458 7.95541 14.6254 8.48864ZM20.3127 3.24774C18.0682 3.24774 16.2503 5.07088 16.2503 7.31553V10.3321C10.9489 10.0172 6.44985 6.75183 4.41866 2.12034C4.13937 1.48554 3.24057 1.44999 2.94605 2.07464C2.10311 3.85715 1.62578 5.84788 1.62578 7.95033C1.62578 11.5458 3.35737 14.9026 5.94713 17.3911C6.61742 18.036 7.27248 18.5692 7.92245 19.0568L0.615267 20.885C0.0719254 21.0221 -0.171817 21.6518 0.132861 22.1241C1.01643 23.4902 3.19995 25.8059 7.9123 25.9989C8.31854 26.0142 8.72477 25.8669 9.03453 25.5977L12.3454 22.7488H16.2503C20.7392 22.7488 24.3751 19.1177 24.3751 14.6284V6.49791L26 3.24774H20.3127ZM20.3127 8.12807C19.8658 8.12807 19.5002 7.76243 19.5002 7.31553C19.5002 6.86863 19.8658 6.50299 20.3127 6.50299C20.7595 6.50299 21.1252 6.86863 21.1252 7.31553C21.1252 7.76243 20.7595 8.12807 20.3127 8.12807Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Verbyo;
