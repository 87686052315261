import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./button.module.scss";

function Link(props) {
  const {
    to,
    text,
    outline,
    white,
    size,
    fontWeight,
    stylesAttr,
    transparent,
    color,
    className,
  } = props;

  return (
    <RouterLink
      to={to}
      style={{ ...stylesAttr }}
      className={cx(
        styles.button,
        outline && styles.outline,
        white && styles.white,
        transparent && styles.transparent,
        color && styles[color],
        size && styles[size],
        styles[fontWeight],
        className
      )}
    >
      {text}
    </RouterLink>
  );
}

Link.defaultProps = {
  fontWeight: "regular",
  stylesAttr: {},
  outline: false,
  white: false,
  size: "m",
  color: null,
};

Link.propTypes = {
  fontWeight: PropTypes.oneOf(["bold", "regular"]),
  stylesAttr: PropTypes.object,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  outline: PropTypes.bool,
  white: PropTypes.bool,
  white_shadow: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "purple",
    "green",
    "cyan",
    "pink",
    "light-green",
    "white-bordered",
    "white-shadow",
  ]),
  size: PropTypes.oneOf(["l", "m", "s", "xs", "h", "sm", "lg", "md"]),
};

export default Link;
