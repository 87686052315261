import { getToken } from "../api/utils";
import { ACCOUNT_TYPE_COOKIE, TOKEN_COOKIE_NAME } from "../globals";
import { getCampaign, getCampaigns } from "../services/campaignService";
import { getRaffles } from "../services/raffleService";
import {
  getDetailedCampaignStatistics,
  getDetailedPostsStatistics,
  getDetailedRaffleStatistics,
  getStatistics,
} from "../services/statisticsService";
import { getNotifications, getUser } from "../services/userAuthService";
import { getVideos } from "../services/videoService";
import { getWallet } from "../services/walletService";
import { deleteCookie, setCookie } from "../utils/cookie";
import {
  ADD_ERROR,
  DELETE_ERROR,
  DELETE_USER,
  ERROR,
  FETCH_USER,
  FIRST_LOGIN,
  GET_CAMPAIGN,
  GET_DETAILED_CAMPAIGN_STATS,
  GET_DETAILED_POSTS_STATS,
  GET_DETAILED_RAFFLES_STATS,
  LOAD_CAMPAIGNS,
  LOAD_NOTIFICATIONS,
  LOAD_RAFFLES,
  LOAD_STATISTICS,
  LOAD_TRANSACTIONS,
  LOAD_VIDEOS,
  LOGOUT,
  SET_ACCOUNT_TYPE,
  SET_USER_DATA,
  START,
  SUCCESS,
  UPDATE_CAMPAIGN,
} from "./types";

export const fetchUser = () => async (dispatch) => {
  const token = getToken();
  if (!token) return;

  dispatch({ type: FETCH_USER + START });
  dispatch({ type: LOAD_CAMPAIGNS + START });
  getUser()
    .then((data) => {
      if (!data || data.error) {
        dispatch({ type: FETCH_USER + ERROR });
      } else {
        const correct = {
          ...data.UserDetail,
          verified: data.verified,
          rejectionReason: data.rejectionReason,
          accountStatus: data.accountStatus,
          warningText: data.warningText,
        };
        if (data.UserDetail) {
          dispatch({
            type: FETCH_USER + SUCCESS,
            payload: {
              id: data.UserDetail.userId,
              status: data.status,
              loginEmail: data.email,
              wallet: data.Wallet,
              data: correct,
              type: data.type,
              budgetMinimals: data.budgetMinimal,
            },
          });
        } else {
          dispatch({
            type: FETCH_USER + SUCCESS,
            payload: {
              id: data.id,
              status: data.status,
              loginEmail: data.email,
              wallet: data.Wallet,
              type: data.type,
              verified: data.verified,
              rejectionReason: data.rejectionReason,
            },
          });
        }

        if (data.Campaigns) {
          dispatch({ type: LOAD_CAMPAIGNS + SUCCESS, payload: data.Campaigns });
        } else {
          dispatch({ type: LOAD_CAMPAIGNS + ERROR });
        }
      }
    })
    .catch((e) => {
      dispatch({ type: FETCH_USER + ERROR });
      dispatch({ type: LOAD_CAMPAIGNS + ERROR });
    });
};

export const setLoginUserData = (data, rememberMe) => async (dispatch) => {
  const { token, id, type, status } = data;

  const options = {
    "max-age": rememberMe ? 2147483647 : 3600,
  };

  setCookie(TOKEN_COOKIE_NAME, token, options);
  setCookie(ACCOUNT_TYPE_COOKIE, type, {
    "max-age": 2147483647,
  });
  dispatch({ type: FETCH_USER + SUCCESS, payload: { id, type, status } });
};

export const logoutUser = () => async (dispatch) => {
  localStorage.removeItem(TOKEN_COOKIE_NAME);
  dispatch({ type: LOGOUT });
  deleteCookie(ACCOUNT_TYPE_COOKIE);
  deleteCookie(TOKEN_COOKIE_NAME);
};

export const fetchNotifications = () => async (dispatch) => {
  dispatch({ type: LOAD_NOTIFICATIONS + START });
  getNotifications()
    .then((data) => {
      if (!data || data.error) {
        dispatch({ type: LOAD_NOTIFICATIONS + ERROR });
      } else {
        dispatch({ type: LOAD_NOTIFICATIONS + SUCCESS, payload: data });
      }
    })
    .catch((e) => {
      dispatch({ type: LOAD_NOTIFICATIONS + ERROR });
    });
};

export const fetchCampaigns = () => async (dispatch) => {
  dispatch({ type: LOAD_CAMPAIGNS + START });

  getCampaigns()
    .then((data) => {
      if (!data || data.error) {
        dispatch({ type: LOAD_CAMPAIGNS + ERROR });
      } else {
        dispatch({ type: LOAD_CAMPAIGNS + SUCCESS, payload: data });
      }
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: LOAD_CAMPAIGNS + ERROR });
    });
};

export const updateCampaign = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_CAMPAIGN, payload: data });
};

export const getCampaignEditData = (id) => async (dispatch) => {
  getCampaign(id)
    .then((data) => {
      if (!data || data.error) {
        dispatch({ type: GET_CAMPAIGN + ERROR });
      } else {
        dispatch({ type: GET_CAMPAIGN + SUCCESS, payload: data });
      }
    })
    .catch((e) => {
      dispatch({ type: GET_CAMPAIGN + ERROR });
    });
};

export const fetchRaffles = () => async (dispatch) => {
  dispatch({ type: LOAD_RAFFLES + START });

  getRaffles()
    .then((data) => {
      if (!data || data.error) {
        dispatch({ type: LOAD_RAFFLES + ERROR });
      } else {
        dispatch({ type: LOAD_RAFFLES + SUCCESS, payload: data });
      }
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: LOAD_RAFFLES + ERROR });
    });
};

export const setUserDeleted = () => async (dispatch) => {
  dispatch({ type: DELETE_USER });

  deleteCookie(TOKEN_COOKIE_NAME);
};

export const fetchTransactions = () => async (dispatch) => {
  dispatch({ type: LOAD_TRANSACTIONS + START });

  getWallet()
    .then((data) => {
      if (!data || data.error) {
        dispatch({ type: LOAD_STATISTICS + ERROR });
      } else {
        dispatch({
          type: LOAD_TRANSACTIONS + SUCCESS,
          payload: data.wallet.WalletTransactions,
        });
      }
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: LOAD_TRANSACTIONS + ERROR });
    });

  //dispatch({type: LOAD_TRANSACTIONS + SUCCESS, payload: transactions});
};

export const fetchStatistics = () => async (dispatch) => {
  dispatch({ type: LOAD_STATISTICS + START });

  getStatistics()
    .then((data) => {
      if (!data || data.error) {
        dispatch({ type: LOAD_STATISTICS + ERROR });
      } else {
        dispatch({ type: LOAD_STATISTICS + SUCCESS, payload: data });
      }
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: LOAD_STATISTICS + ERROR });
    });
};

export const getDashboard = () => async (dispatch) => {
  const token = getToken();

  if (!token) return;

  dispatch({ type: FETCH_USER + START });
};

export const setAccountTypeAction = (accountType) => async (dispatch) => {
  setCookie(ACCOUNT_TYPE_COOKIE, accountType);

  dispatch({
    type: SET_ACCOUNT_TYPE,
    payload: accountType,
  });
};

export const setUserData = (data) => async (dispatch) => {
  dispatch({
    type: SET_USER_DATA,
    payload: data,
  });
};

export const loadNotifications = () => async (dispatch) => {};

export const addError = (data) => async (dispatch) => {
  dispatch({
    type: ADD_ERROR,
    payload: data,
  });
};

export const deleteError = () => async (dispatch) => {
  dispatch({
    type: DELETE_ERROR,
  });
};

export const fetchVideos = () => async (dispatch) => {
  dispatch({ type: LOAD_VIDEOS + START });

  getVideos()
    .then((data) => {
      if (!data || data.error) {
        dispatch({ type: LOAD_VIDEOS + ERROR });
      } else {
        dispatch({ type: LOAD_VIDEOS + SUCCESS, payload: data });
      }
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: LOAD_VIDEOS + ERROR });
    });
};

export const getDetailedCampaignStats = (id) => async (dispatch) => {
  dispatch({ type: GET_DETAILED_CAMPAIGN_STATS + START });
  getDetailedCampaignStatistics(id)
    .then((data) => {
      if (!data || data.error) {
        dispatch({ type: GET_DETAILED_CAMPAIGN_STATS + ERROR });
      } else {
        dispatch({
          type: GET_DETAILED_CAMPAIGN_STATS + SUCCESS,
          payload: data,
        });
      }
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: GET_DETAILED_CAMPAIGN_STATS + ERROR });
    });
};

export const getDetailedRaffleStats = (id) => async (dispatch) => {
  dispatch({ type: GET_DETAILED_RAFFLES_STATS + START });
  getDetailedRaffleStatistics(id)
    .then((data) => {
      if (!data || data.error) {
        dispatch({ type: GET_DETAILED_RAFFLES_STATS + ERROR });
      } else {
        dispatch({
          type: GET_DETAILED_RAFFLES_STATS + SUCCESS,
          payload: data,
        });
      }
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: GET_DETAILED_RAFFLES_STATS + ERROR });
    });
};

export const getDetailedPostStats = (id) => async (dispatch) => {
  dispatch({ type: GET_DETAILED_POSTS_STATS + START });
  getDetailedPostsStatistics(id)
    .then((data) => {
      if (!data || data.error) {
        dispatch({ type: GET_DETAILED_POSTS_STATS + ERROR });
      } else {
        dispatch({
          type: GET_DETAILED_POSTS_STATS + SUCCESS,
          payload: data,
        });
      }
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: GET_DETAILED_POSTS_STATS + ERROR });
    });
};

export const changeFirstLogin = () => {
  return {
    type: FIRST_LOGIN,
    payload: {
      state: true,
    },
  };
};
