import React from "react";
import { useState } from "react";
import { setCookie } from "../../utils/cookie";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const CookieBanner = () => {
  const [show, setShow] = useState(false);
  const [accpeted, setAccepted] = useState(false);

  const history = useHistory();

  const handleAccept = (type) => {
    setAccepted(true);
    setCookie("cookie_consent", type, {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
    });
  };

  useEffect(() => {
    if (document.cookie.indexOf("cookie_consent") === -1) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  return (
    <div
      className={`
    ${show
          ? "translate-y-0 opacity-100 delay-[3000ms]"
          : "translate-y-full opacity-50"
        }
    fixed bottom-0 transition-all z-[50]  shadow duration-500 ease-out left-0 bg-white w-full`}
    >
      <div className="w-full gap-5 flex-col md:flex-row justify-between flex items-center max-w-[900px] mx-auto   px-5 py-4 ">
        <div className="bg-[#FAFAFA] p-2 rounded-lg shrink-0 w-fit">
          <img src="./cookies.svg" alt="cookies" />
        </div>

        {accpeted ? (
          <>
            <p
              className={`  ${accpeted ? "opacity-100" : "opacity-0"
                } text-black delay-1000 transition-all duration-700 leading-5 text-xs`}
            >
              Your cookie preferences have been saved. To change your
              preferences at any time, see our .{" "}
              <span
                onClick={() => {
                  history.push("/verbyo-terms?active=cookie-policy");
                }}
                className="text-[#0D2174] font-semibold hover:underline cursor-pointer"
              >
                cookies policies
              </span>{" "}
              Thank you.
            </p>
            <button
              onClick={() => {
                setShow(false);
              }}
              className="font-semibold whitespace-nowrap text-white text-sm px-6 py-3 bg-[#0D2174] outline-none hover:opacity-90"
            >
              Close
            </button>
          </>
        ) : (
          <>
            <p className=" text-black  leading-5 text-xs">
              Cookies store information about how a user interacts with a
              website. All your data is anonymised and cannot be used to
              identify you.{" "}
              <span
                onClick={() => {
                  history.push("/verbyo-terms?active=cookie-policy");
                }}
                className="text-[#0D2174] font-semibold hover:underline cursor-pointer"
              >
                Read cookies policies.
              </span>
            </p>
            <div className="flex w-full md:w-fit items-center gap-5">
              <button
                onClick={() => {
                  handleAccept("essential");
                }}
                className="font-semibold whitespace-nowrap w-full md:w-fit text-white text-sm px-6 md:py-3 py-2 bg-[#0D2174] outline-none hover:opacity-90"
              >
                Only essentials
              </button>
              <button
                onClick={() => {
                  handleAccept("all");
                }}
                className="font-semibold w-full md:w-fit whitespace-nowrap text-white text-sm px-6 md:py-3 py-2 bg-[#0D2174] outline-none hover:opacity-90"
              >
                Accept
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CookieBanner;
