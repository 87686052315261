import React from "react";
import Button from "./Button";
import validator from "validator";

const CompanyDocs = ({ children, onSubmit, form }) => {
  // One Social Present
  const oneSocialPresent =
    form.facebookSocial || form.instaSocial || form.twitterSocial;
  return (
    <div className="max-w-[600px] w-full pt-[34px] pb-[31px]  pl-[33px] pr-[42px] bg-[#fff] ">
      <p className="text-[18px] text-[#4E588D] font-semibold">
        Upload company documents
      </p>
      <p className="text-[14px] leading-[22px] mt-[10px]">
        Based on your country, specific documents will be requested.
      </p>
      <hr className="my-6" />
      <form
        onSubmit={onSubmit}
        className=" flex mt-10 flex-col gap-[25px] pb-[43px] border-b border-[#E8E8E8]"
      >
        {children}
        <div className="mt-[27px]">
          <Button
            disabled={
              !form.ein ||
              !form.aoi ||
              !form.website ||
              !form.description ||
              !form.logo ||
              !form.foundationName ||
              (form.website && !validator.isURL(form.website)) ||
              !oneSocialPresent
            }
            type={"submit"}
            btnText={"Send"}
          />
        </div>
      </form>
    </div>
  );
};

export default CompanyDocs;
