import { countries } from "./countries.json";
import { states } from "./states.json";
import { cities } from "./cities.json";

export const COUNTRY_OPTIONS = countries;
export const PRODUCT_TYPE_OPTIONS = [
  {
    value: "digital",
    label: "Digital Goods",
  },
  {
    value: "physical",
    label: "Physical Goods",
  },
];
export const PRODUCT_SHIPPING_OPTIONS = [
  {
    value: "self",
    label: "You Ship",
  },
  {
    value: "verbyo",
    label: "Verbyo Ships",
  },
];
export const COUNTY_OPTIONS = states;
export const CITY_OPTIONS = cities;
export const GENDER_OPTIONS = [
  { value: "all", label: "All" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

export const LOCATION_OPTIONS = [
  { value: "user", label: "User Location" },
  { value: "geo", label: "GeoLocation" },
];

export const DETAILS_OPTIONS = [
  { value: "adtext", label: "Enter your captions" },
  { value: "keywords", label: "Enter keywords" },
];

export const LANGUAGE_OPTIONS = [
  { value: "english", label: "English" },
  { value: "spanish", label: "Spanish" },
  { value: "romanian", label: "Romanian" },
];
export const LEVEL_OPTIONS = [
  { value: "PAID", label: "Paid" },
  { value: "NOT_PAID", label: "Not Paid" },
  { value: "All", label: "All" },
];
