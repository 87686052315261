import Link from "react-router-dom/Link";
import globals from "../globals";

const NoCampaigns = ({ raffle, dashboard }) => {
  return (
    <>
      <div
        className={`${
          !dashboard && "pt-32"
        } absolute  m-auto sm:block hidden inset-0 w-fit h-fit  `}
      >
        <div className="  w-full">
          <div className=" flex items-center justify-center   ">
            <div className="max-w-[521px] flex space-x-[29px]  w-full">
              <div className="h-[197px] w-[14px] bg-[#0D2174] rounded-[12px] sm:block hidden" />
              <div className="py-4">
                <h4 className="text-[22px] font-medium text-[#0D2174]">
                  You have no campaigns
                </h4>
                <p className="max-w-[478px] whitespace-normal text-sm leading-[22px] mt-2">
                  Once you have launched a campaign, your results will appear
                  here. You can launch a campaign from your Dashboard or by
                  clicking the button below.
                </p>
                <Link to={globals.START_CAMPAIGN}>
                  <button className="flex bg-[#0D2174] items-center gap-[6px] whitespace-nowrap text-white  px-5 py-[11px] mt-6 rounded-md hover:opacity-80 text-xs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.932"
                      height="13.932"
                      viewBox="0 0 13.932 13.932"
                    >
                      <g
                        id="Icon_ionic-ios-add-circle-outline"
                        data-name="Icon ionic-ios-add-circle-outline"
                        transform="translate(-3.375 -3.375)"
                      >
                        <path
                          id="Path_1760"
                          data-name="Path 1760"
                          d="M17.109,13.543H14.614V11.048a.536.536,0,1,0-1.072,0v2.495H11.048a.513.513,0,0,0-.536.536.519.519,0,0,0,.536.536h2.495v2.495a.519.519,0,0,0,.536.536.533.533,0,0,0,.536-.536V14.614h2.495a.536.536,0,1,0,0-1.072Z"
                          transform="translate(-3.738 -3.738)"
                          fill="#fff"
                        />
                        <path
                          id="Path_1761"
                          data-name="Path 1761"
                          d="M10.341,4.313A6.026,6.026,0,1,1,6.078,6.078a5.988,5.988,0,0,1,4.263-1.765m0-.938a6.966,6.966,0,1,0,6.966,6.966,6.965,6.965,0,0,0-6.966-6.966Z"
                          transform="translate(0 0)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    New Campaign
                  </button>
                </Link>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      <div className="sm:hidden min-w-[280px] mx-auto pb-32 pt-16 flex flex-col items-center justify-center text-center">
        <svg
          width="31"
          height="24"
          viewBox="0 0 31 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.6288 10.309L13.5741 0.641003C13.3621 0.437312 13.1104 0.275843 12.8335 0.165847C12.5565 0.0558523 12.2597 -0.000505635 11.96 3.41793e-06H2.2776C1.67354 3.41793e-06 1.09423 0.230735 0.667094 0.64144C0.239961 1.05214 1.80667e-07 1.60918 1.80667e-07 2.19V11.5C-0.000118964 11.7876 0.0586925 12.0725 0.173076 12.3383C0.287459 12.604 0.455173 12.8456 0.66664 13.049L10.7214 22.717C10.9329 22.9208 11.1842 23.0825 11.4608 23.1928C11.7374 23.3031 12.0339 23.3599 12.3334 23.3599C12.6328 23.3599 12.9293 23.3031 13.2059 23.1928C13.4825 23.0825 13.7338 22.9208 13.9454 22.717L23.6309 13.404C23.8428 13.2006 24.011 12.959 24.1257 12.693C24.2404 12.4271 24.2995 12.1419 24.2995 11.854C24.2995 11.5661 24.2404 11.281 24.1257 11.015C24.011 10.749 23.8428 10.5074 23.6309 10.304L23.6288 10.309ZM5.3144 7.3C4.86393 7.3 4.42358 7.17156 4.04903 6.93092C3.67448 6.69028 3.38256 6.34825 3.21017 5.94808C3.03779 5.54791 2.99268 5.10757 3.08056 4.68276C3.16845 4.25794 3.38537 3.86772 3.70389 3.56144C4.02242 3.25516 4.42825 3.04659 4.87006 2.96208C5.31187 2.87758 5.76982 2.92095 6.186 3.08671C6.60218 3.25246 6.95789 3.53316 7.20816 3.8933C7.45842 4.25345 7.592 4.67686 7.592 5.11C7.592 5.69083 7.35204 6.24786 6.92491 6.65857C6.49777 7.06927 5.91846 7.3 5.3144 7.3ZM29.7024 13.406L20.0169 22.72C19.8053 22.9238 19.5541 23.0855 19.2775 23.1958C19.0009 23.3061 18.7043 23.3629 18.4049 23.3629C18.1054 23.3629 17.8089 23.3061 17.5323 23.1958C17.2557 23.0855 17.0044 22.9238 16.7929 22.72L16.7762 22.704L25.0359 14.762C25.8366 13.9918 26.2863 12.9475 26.2863 11.8585C26.2863 10.7695 25.8366 9.72517 25.0359 8.955L15.7258 3.41793e-06H18.0378C18.3369 -0.000111144 18.6331 0.0564384 18.9096 0.166422C19.186 0.276406 19.4371 0.43767 19.6487 0.641003L29.7034 10.309C29.9154 10.5124 30.0835 10.754 30.1983 11.02C30.313 11.286 30.372 11.5711 30.372 11.859C30.372 12.1469 30.313 12.4321 30.1983 12.698C30.0835 12.964 29.9154 13.2056 29.7034 13.409L29.7024 13.406Z"
            fill="#162E7D"
          />
        </svg>
        <p className="text-lg font-bold mt-7 text-[#070707] leading-5">
          {!raffle ? "No campaigns yet !" : "No Raffles !"}
        </p>
        <p className="leading-5 mt-3 text-black text-sm max-w-[240px] ">
          {!raffle
            ? "Each time you launch a campaign it will be shown here."
            : "Create awareness for your brand by launching a raffle. Raffles are the way we encourage people to post social causes on their media accounts."}{" "}
        </p>
        <Link
          to={!raffle ? globals.START_CAMPAIGN : globals.DASHBOARD_CAMPAIGN_5}
        >
          <button className="flex w-[280px] items-center mt-16 max-w-[280px] bg-[#162E7D] hover:opacity-90 font-medium text-sm leading-4 text-white py-3 px-4 gap-2 justify-center rounded-md">
            {!raffle ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.6296 9.90892H9.90894V13.6301H8.0911V9.90892H4.37048V8.09107H8.09171V4.37046H9.90894V8.09169H13.6302L13.6296 9.90892Z"
                  fill="white"
                />
                <path
                  d="M9 1.81723C10.6609 1.81799 12.2702 2.39459 13.5537 3.44879C14.8372 4.50299 15.7155 5.96958 16.0389 7.5987C16.3624 9.22783 16.111 10.9187 15.3276 12.3833C14.5442 13.8478 13.2773 14.9955 11.7426 15.6308C10.208 16.266 8.50058 16.3495 6.91126 15.8671C5.32195 15.3846 3.94906 14.3661 3.02648 12.985C2.1039 11.6038 1.68871 9.94555 1.85163 8.29264C2.01456 6.63972 2.74553 5.09442 3.92 3.92C4.5862 3.25174 5.37803 2.72191 6.24989 2.36102C7.12175 2.00013 8.0564 1.81532 9 1.81723ZM9 8.41859e-08C7.21997 8.41859e-08 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.0038995 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C18.0002 7.81806 17.7675 6.64766 17.3152 5.55566C16.863 4.46365 16.2001 3.47144 15.3643 2.63568C14.5286 1.79992 13.5363 1.13699 12.4443 0.684755C11.3523 0.23252 10.1819 -0.000161582 9 8.41859e-08Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.5502 10V18.46C18.5502 18.7583 18.2883 19 17.9652 19H3.5352C3.21211 19 2.9502 18.7583 2.9502 18.46V10"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.915 5.5H1.585C1.26191 5.5 1 5.74177 1 6.04V9.46C1 9.75826 1.26191 10 1.585 10H19.915C20.2381 10 20.5 9.75826 20.5 9.46V6.04C20.5 5.74177 20.2381 5.5 19.915 5.5Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.75 19V5.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.75 5.5H6.36255C5.71608 5.5 5.09609 5.26295 4.63897 4.84099C4.18185 4.41904 3.92505 3.84674 3.92505 3.25C3.92505 2.65326 4.18185 2.08096 4.63897 1.65901C5.09609 1.23705 5.71608 1 6.36255 1C9.77505 1 10.75 5.5 10.75 5.5Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.75 5.5H15.1375C15.7839 5.5 16.4039 5.26295 16.8611 4.84099C17.3182 4.41904 17.575 3.84674 17.575 3.25C17.575 2.65326 17.3182 2.08096 16.8611 1.65901C16.4039 1.23705 15.7839 1 15.1375 1C11.725 1 10.75 5.5 10.75 5.5Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}

            {!raffle ? "Start Campaign" : "Start Raffle"}
          </button>
        </Link>
      </div>
    </>
  );
};

export default NoCampaigns;
