
import {
    LOAD_INTERESTS_STATS_ADMIN,
    START,
    SUCCESS,
    ERROR,
    LOGOUT,
  } from "../actions/types";


  const defaultsState = {
    loading: false,
    loaded: false,
    error: false,
    entities: null,
    editData: null,
  };


export default function notifications(state = defaultsState, action) {
    switch (action.type) {
        case LOAD_INTERESTS_STATS_ADMIN + START:
          return {
            loading: true,
            loaded: false,
            error: false,
          };
        case LOAD_INTERESTS_STATS_ADMIN + SUCCESS:
          return {
            loading: false,
            loaded: true,
            error: false,
            entities: action.payload
          };
        case LOAD_INTERESTS_STATS_ADMIN + ERROR:
          return {
            loading: false,
            loaded: false,
            error: true,
          };
          case LOGOUT:
        default:
          return state;
      }
}