import React, { useEffect, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";

import { useDispatch } from "react-redux";
import { fetchUser, setLoginUserData } from "../actions";
import Notice from "../components/Notice/Notice";
import globals from "../globals";
import { activateAccount } from "../services/userAuthService";

function ActivateAccountPage() {
  let { token } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState("");
  const successText = "Your account is now active! You can now login.";
  const errorText = "This link has expired or it has already been activated.";

  useEffect(() => {
    if (!token) return;
    const activate = async () => {
      if (loading) return;
      console.log("calling");
      setLoading(true);
      await activateAccount(token)
        .then((data) => {
          if (!data || data.error) {
            setMessage(errorText);
          } else {
            setMessage(successText);
            dispatch(setLoginUserData({ token: data.token, ...data.userData }));
            dispatch(fetchUser());
            history.push(globals.CHOOSE_ACCOUNT_PATH);
          }
        })
        .catch((err) => console.log(err));
    };
    const activateTimeout = setTimeout(activate);
    return () => {
      clearTimeout(activateTimeout);
    };
  }, []);

  return (
    <div className="container !mt-20">
      <div className="wrapper mx-auto text-center">
        <Notice text={message} />
      </div>
    </div>
  );
}

export default withRouter(ActivateAccountPage);
