import { ADD_ERROR, DELETE_ERROR } from "../actions/types";

const defaultsState = [
  //   {
  //     type: "error",
  //     title: "Password do not match!",
  //     message:
  //       "Your passwords has to match. Please try to enter again your passwords.",
  //   },
];

// function nextErrorId(erros) {
//   const maxId = erros.reduce((maxId, error) => Math.max(error.id, maxId), -1);
//   return maxId + 1;
// }

export default function erros(state = defaultsState, action) {
  switch (action.type) {
    case ADD_ERROR:
      return [action.payload];
    case DELETE_ERROR: {
      return [];
    }
    default:
      return state;
  }
}
