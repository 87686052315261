
import {
    LOAD_NOTIFICATIONS,
    START,
    SUCCESS,
    ERROR,
    LOGOUT,
    GET_DETAILED_CAMPAIGN_STATS,
  } from "../actions/types";


  const defaultsState = {
    loading: false,
    loaded: false,
    error: false,
    entities: null,
    editData: null,
  };


export default function notifications(state = defaultsState, action) {
    switch (action.type) {
        case GET_DETAILED_CAMPAIGN_STATS + START:
          return {
            loading: true,
            loaded: false,
            error: false,
          };
        case GET_DETAILED_CAMPAIGN_STATS + SUCCESS:
          return {
            loading: false,
            loaded: true,
            error: false,
            entities: action.payload
          };
        case GET_DETAILED_CAMPAIGN_STATS + ERROR:
          return {
            loading: false,
            loaded: false,
            error: true,
          };
          case LOGOUT:
        default:
          return state;
      }
}