import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "../Checkbox-v2/checkbox.module.scss";

function CheckBoxV2({
    id,
    label,
    v2,
    v3,
    v4,
    value,
    onChange,
    round,
    color,
    disabled,
    mb0,
}) {
    const attrs = {};

    if (value !== null && onChange) {
        attrs["value"] = value;
        attrs["checked"] = value;
        attrs["onChange"] = onChange;
    }

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className={`flex items-center gap-[11px]`}
        >
            <div className="flex items-center bg-[#EFF0FC] border-[#323232] rounded-[2px] border-[2px] justify-center overflow-hidden bg-[url(/auth/check-bg.svg)] bg-cover bg-no-repeat bg-center cursor-pointer w-[15px] h-[15px]">

                <input className="accent-[#EFF0FC]  w-[13px] h-[13px]" type="checkbox" id={id} disabled={disabled} {...attrs} />
            </div>
            <label htmlFor={id} className={`text-[12px] leading-[14px] font-medium text-[#000]`} >
                {label}
            </label>
        </div>
    );
}

CheckBoxV2.defaultProps = {
    v2: false,
    v3: false,
    v4: false,
    value: null,
    onChange: null,
    round: false,
    color: "violent",
    mb0: false,
};

CheckBoxV2.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    v2: PropTypes.bool,
    v3: PropTypes.bool,
    v4: PropTypes.bool,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    round: PropTypes.bool,
    color: PropTypes.oneOf(["violent", "blue", "darkblue", "grey"]),
    mb0: PropTypes.bool,
};

export default CheckBoxV2;
