import React from 'react';
import PropTypes from 'prop-types';

import styles from './notice.module.scss';

function Notice({text}) {
  return (
    <div className={styles.text}>
      {text}
    </div>
  );
}

Notice.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Notice;
