import globals from "../globals";

const correctPath = (path) => {
  if (path.slice(path.length - 1) === "/") {
    path = path.slice(0, path.length - 1);
  }

  return path;
};

export function isDashboardPage(path) {
  path = correctPath(path);

  return (
    path === globals.DASHBOARD_PATH ||
    path === globals.DASHBOARD_CAMPAIGNS_PATH ||
    path === globals.DASHBOARD_RAFFLES_PATH ||
    path === globals.START_CAMPAIGN ||
    path.startsWith(globals.DASHBOARD_CAMPAIGN_1) ||
    path.startsWith(globals.DASHBOARD_CAMPAIGN_2) ||
    path.startsWith(globals.DASHBOARD_CAMPAIGN_3) ||
    path.startsWith(globals.DASHBOARD_CAMPAIGN_4) ||
    path.startsWith(globals.DASHBOARD_CAMPAIGN_5) ||
    path.startsWith(globals.DASHBOARD_CAMPAIGN_IG) ||
    path.startsWith(globals.DASHBOARD_CAMPAIGN_YT) ||
    path.startsWith(globals.DASHBOARD_CAMPAIGN_BL) ||
    path.startsWith(globals.DASHBOARD_CAMPAIGN_GM) ||
    path === globals.EDIT_CAMPAIGN_ROUTE ||
    path === globals.DASHBOARD_NOTIFICATIONS_PATH ||
    path === globals.DASHBOARD_VIDEO_RESOURCES_PATH ||
    path === globals.DASHBOARD_SETTINGS_PATH ||
    path.indexOf(globals.DASHBOARD_SETTINGS_PATH) === 0
  );
}

export function isAdminPage(path) {
  path = correctPath(path);

  return (
    path === globals.ADMIN_COMPANIES_PATH ||
    path === globals.ADMIN_CAMPAIGNS_PATH ||
    path === globals.ADMIN_FINANCIALS_PATH ||
    path === globals.ADMIN_USERS_PATH ||
    path === globals.ADMIN_PATH
  );
}

export function isFormPage(path) {
  path = correctPath(path);

  return (
    path.includes(globals.SIGN_UP_PATH) ||
    path.includes(globals.LOGIN_PATH) ||
    path.includes(globals.NEW_PASSWORD_PATH) ||
    path.includes(globals.CONTACT_US_PATH) ||
    path.includes(globals.PASSWORD_RECOVERY_PATH)
  );
}

export function isChooseAccoutPage(path) {
  path = correctPath(path);

  return path === globals.CHOOSE_ACCOUNT_PATH;
}

export function isVideoResources(path) {
  path = correctPath(path);

  return path === globals.DASHBOARD_VIDEO_RESOURCES_PATH;
}
