import React from "react";
import PropTypes from "prop-types";

import styles from "./base.module.scss";

function Base({ children, style }) {
  return (
    <div className={styles.item} style={style}>
      {children}
    </div>
  );
}

Base.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default Base;
