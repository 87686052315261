import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./checkbox.module.scss";

function Checkbox({
  id,
  label,
  v2,
  v3,
  v4,
  value,
  onChange,
  round,
  color,
  disabled,
  mb0,
}) {
  const attrs = {};

  if (value !== null && onChange) {
    attrs["value"] = value;
    attrs["checked"] = value;
    attrs["onChange"] = onChange;
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={cx(
        styles.checkbox,
        v2 && styles.checkboxV2,
        v3 && styles.checkboxV3,
        v4 && styles.checkboxV4,
        mb0 && styles.checkbox__mb0,
        styles[color]
      )}
    >
      <input type="checkbox" id={id} disabled={disabled} {...attrs} />
      <label htmlFor={id}>
        <span className={cx(styles.main, round && styles.round)} />
        {label}
      </label>
    </div>
  );
}

Checkbox.defaultProps = {
  v2: false,
  v3: false,
  v4: false,
  value: null,
  onChange: null,
  round: false,
  color: "violent",
  mb0: false,
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  v2: PropTypes.bool,
  v3: PropTypes.bool,
  v4: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  round: PropTypes.bool,
  color: PropTypes.oneOf(["violent", "blue", "darkblue", "grey"]),
  mb0: PropTypes.bool,
};

export default Checkbox;
