import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import styles from "./errorsList.module.scss";
import ErrorItem from "../ErrorItem/ErrorItem";
import { errors } from "../../../selectors";
import { deleteError } from "../../../actions";
import classNames from "classnames";

function ErrorsList({ errors, deleteError, location }) {
  let path = location.pathname;

  useEffect(() => {
    deleteError();
  }, [path]);
  useEffect(() => {
    if (errors.length > 0) {
      document.body.addEventListener("click", deleteError);
    }

    return () => {
      document.body.removeEventListener("click", deleteError);
    };
  }, [errors?.length]);

  return (
    <div
      className={classNames(
        styles.errorsList,
        "fixed",
        "sm:static",
        "top-0",
        "left-0"
      )}
    >
      {errors &&
        errors.map((item, i) => (
          <ErrorItem
            key={i}
            type={item.type}
            title={item.title}
            message={item.message}
            items={item.items}
            link={item.link}
            position={item.position}
          />
        ))}
    </div>
  );
}

ErrorsList.propTypes = {};

const mapStateToProps = (store) => ({
  errors: errors(store),
});

const mapDispatchToProps = {
  deleteError: deleteError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ErrorsList));
