import { AdminApi } from "../api/admin";
import { adminDash } from "../api/adminDash";

export function approveCampaign({
  campaignId,
  searchTag,
  premiumOffer,
  verifiedOffer,
}) {
  return AdminApi.approveCampaign({
    campaignId,
    searchTag,
    premiumOffer,
    verifiedOffer,
  });
}

export function approveNgoCampaign({
  campaignId,
  searchTag,
  points,
  premiumOffer,
  verifiedOffer,
}) {
  return AdminApi.approveNgoCampaign({
    campaignId,
    searchTag,
    points,
    premiumOffer,
    verifiedOffer,
  });
}

export function rejectCampaign({ campaignId, rejectionReason }) {
  return AdminApi.rejectCampaign({ campaignId, rejectionReason });
}

export function notifyUsers(id) {
  return AdminApi.notifyUsers(id);
}

export function notifyWhatsapp(id) {
  return AdminApi.notifyWhatsapp(id);
}

//Admin Dashboard

export function getAdminDashboard() {
  return adminDash.getAdminDashStatsAndGoals();
}
export function getAdminUsers() {
  return AdminApi.getAdminUsers();
}
export function getAdminNgos() {
  return AdminApi.getAdminNgos();
}
export function getFiveDayUsers() {
  return AdminApi.getFiveDayUsers();
}
export function searchAdminUsers(search) {
  return AdminApi.searchAdminUsers(search);
}
export function getAdminAllCampaigns() {
  return AdminApi.getAdminAllCampaigns();
}

export function getAdminInterestStats() {
  return AdminApi.fetchInterestsStats();
}
export function getAdminUserPosts(page, limit, ranges, search) {
  return AdminApi.fetchAdminPosts(page, limit, ranges, search);
}
export function getPendingSocialRequests() {
  return AdminApi.getPendingSocialRequests();
}
export function approveOrDeclineUserVerification(id, status, socialMedia) {
  return AdminApi.socialVerificationAction(id, status, socialMedia);
}

export function getVerificationDocs(id) {
  return AdminApi.getVerificationDoc(id);
}

export function changeVerifiedStatus(id, status, rejectionReason) {
  return AdminApi.changeVerifiedStatus(id, status, rejectionReason);
}
export function updateUserDetails(body) {
  return AdminApi.updateUserDetails(body);
}
export function updateLogo(body) {
  return AdminApi.updateLogo(body);
}
export function updateUserStatus(body) {
  return AdminApi.updateUserStatus(body);
}
export function getPlatformControls() {
  return AdminApi.getPlatformControls();
}
export function updatePlatformControls(body) {
  return AdminApi.updatePlatformControls(body);
}

export function getBudgetMinimals() {
  return AdminApi.getBudgetMinimals();
}

export function updateBudgetMinimals(data) {
  return AdminApi.updateBudgetMinimals(data);
}

export function getCompanyStats(id) {
  return AdminApi.getCompanyStats(id);
}
export function updateWallet(body) {
  return AdminApi.updateWallet(body);
}

export function getMinimumPayouts() {
  return AdminApi.getMinimumPayouts();
}
export function updateMinimumPayouts(body) {
  return AdminApi.updateMinimumPayouts(body);
}
export function changeMemberVerification(body) {
  return AdminApi.changeMemberVerification(body);
}
export function createUserAnnouncement(body) {
  return AdminApi.createUserAnnouncement(body);
}
export function getUserAnnouncement(body) {
  return AdminApi.getUserAnnouncement(body);
}
export function getInstaRequest(body) {
  return AdminApi.getInstaRequest(body);
}
export function updateInstaRequest(body) {
  return AdminApi.updateInstaRequest(body);
}
export function updateRaffleStatus(body) {
  return AdminApi.updateRaffleStatus(body);
}
export function getMinimumRequirements(body) {
  return AdminApi.getMinimumRequirements(body);
}
export function updateMinimumRequirements(body) {
  return AdminApi.updateMinimumRequirements(body);
}
export function getTermsAndConditions(body) {
  return AdminApi.getTermsAndConditions(body);
}
export function editTermsAndConditions(body) {
  return AdminApi.editTermsAndConditions(body);
}
export function getTerms(query) {
  return AdminApi.getTerms(query);
}
