import { LOAD_CAMPAIGNS_ADMIN, START, SUCCESS, ERROR } from '../actions/types';

const defaultsState = {
  loading: false,
  loaded: false,
  error: false,
  entities: null
};
export default function transactions(state = defaultsState, action) {
  switch (action.type) {
    case LOAD_CAMPAIGNS_ADMIN + START:
      return {
        loading: true,
        loaded: false,
        error: false
      };
    case LOAD_CAMPAIGNS_ADMIN + SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: false,
        entities: action.payload || false
      };
    case LOAD_CAMPAIGNS_ADMIN + ERROR:
      return {
        loading: false,
        loaded: false,
        error: true
      };
    default:
      return state;
  }
}

