import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Base from "../Base/Base";
import IconSort from "../../../assets/images/icons/icon-sort-amount-up.svg";
import styles from "./table.module.scss";

import { connect } from "react-redux";
import { adminSearch, adminFilter } from "../../../selectors";
import moment from "moment/moment";
import Label from "../../dashboard/Label/Label-v2";
import { DASHBOARD_CAMPAIGN_5, STATUSES } from "../../../globals";
import SkeletonLoader from "../../dashboard/CampaignsList-v2/SkeletonLoader";
import RaffleCampaignToggle from "../../dashboard/CampaignsList-v2/RaffleCampaignToggle";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../Loading/Loading";
import MobileSelect from "../../MobileSelect";
import BellBlackIcon from "../../BellBlack";
import NoCampaigns from "../../NoCampaigns";
import FilterMenu from "../../dashboard/CampaignsList-v2/FilterMenu";
import Checkbox from "../../Checkbox-v2/Checkbox";
import ReactSelect from "react-select";

const statuss = [
  "active",
  "pending",
  "rejected",
  "finished",
  "draft",
  "paused",
  "stopped",
  "deleted",
];

const icons = {
  sort: IconSort,
};

const GiftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.264"
      height="36.106"
      viewBox="0 0 41.264 36.106"
    >
      <path
        id="Icon_awesome-gift"
        data-name="Icon awesome-gift"
        d="M2.579,35.777a2.576,2.576,0,0,0,2.579,2.579h12.9v-12.9H2.579Zm20.632,2.579h12.9a2.576,2.576,0,0,0,2.579-2.579V25.461H23.211Zm15.474-25.79H35.292a7.027,7.027,0,0,0,.814-3.224A7.1,7.1,0,0,0,29.014,2.25c-3.353,0-5.521,1.717-8.3,5.5-2.78-3.788-4.948-5.5-8.3-5.5A7.1,7.1,0,0,0,5.319,9.342a6.929,6.929,0,0,0,.814,3.224H2.579A2.576,2.576,0,0,0,0,15.145v6.448a1.293,1.293,0,0,0,1.29,1.29H39.975a1.293,1.293,0,0,0,1.29-1.29V15.145A2.576,2.576,0,0,0,38.685,12.566Zm-26.282,0a3.224,3.224,0,0,1,0-6.448c1.6,0,2.789.266,6.939,6.448H12.4Zm16.61,0H22.075c4.143-6.165,5.3-6.448,6.939-6.448a3.224,3.224,0,0,1,0,6.448Z"
        transform="translate(0 -2.25)"
        fill="#0d2174"
      />
    </svg>
  );
};

export const getDaysLeft = (date) => {
  const today = moment();
  const endDate = moment(date);
  const daysLeft = endDate.diff(today, "days");
  return daysLeft;
};

function Table({
  head,
  data: _data,
  filter,
  search,
  length,
  headClass,
  usersTable,
  renderButtons,
  loading,
  campaignsLength,
}) {
  const [selected, setSelected] = useState([]);
  const [filterMenu, setFilterMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = React.useState({
    status: [],
    platform: [],
  });
  const [data, setData] = useState(_data);

  useEffect(() => {
    if (searchTerm && _data) {
      const filteredData = _data?.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchTerm) ||
          item.brandName.toLowerCase().includes(searchTerm)
        );
      });
      setData(filteredData);
    } else {
      setData(_data);
    }
  }, [searchTerm, _data.length, _data]);

  let searchKeys;
  if (usersTable) {
    searchKeys = ["name", "email", "memberStatus"];
  } else {
    searchKeys = ["name", "platform", "status", "country", "brandName"];
  }
  const history = useHistory();

  const getRows = (info) => {
    return (
      <Row
        info={info}
        selected={selected.includes(info.id)}
        setSelected={(e) => {
          if (e.target.checked) {
            setSelected([...selected, info.id]);
            return;
          }
          setSelected(selected.filter((el) => el !== info.id));
        }}
        key={info.id}
        renderButtons={renderButtons}
      />
    );
  };

  return (
    <>
      <div
        className={`  min-h-[78vh] sm:block hidden relative bg-white mx-auto max-w-[1500px] `}
      >
        <div
          className={`pt-10 pb-7 ${
            !loading && length !== 0 && "border-b"
          } flex items-center justify-between`}
        >
          <RaffleCampaignToggle
            rafflesLength={length}
            campaignsLength={campaignsLength}
          />
          <div className="relative z-40 flex items-center justify-center gap-5 pr-6">
            {selected.some((item) => item) > 0 && (
              <ReactSelect
                onChange={() => {}}
                defaultValue={{
                  value: "Action",
                  label: "Action",
                }}
                options={[
                  {
                    value: "delete",
                    label: "Delete",
                  },
                  {
                    value: "stop",
                    label: "Stop",
                  },
                  {
                    value: "pause",
                    label: "Pause",
                  },
                ]}
                className="w-[200px]"
              ></ReactSelect>
            )}
            <div className="relative">
              <input
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                value={searchTerm}
                className="border py-2 px-2 placeholder-gray-600 placeholder:font-medium outline-none rounded-md"
                placeholder="Search Raffles"
              />
              {/* <img
                src={SearchIcon}
                className="absolute top-[10px] right-3 w-4 h-4"
              /> */}
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              className="cursor-pointer hover:opacity-90 scale-110"
              onClick={(e) => {
                e.stopPropagation();
                setFilterMenu(!filterMenu);
              }}
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                id="Icon_awesome-filter"
                data-name="Icon awesome-filter"
                d="M17.155,0H.845a.844.844,0,0,0-.6,1.44l6.5,6.5v7.244a.844.844,0,0,0,.36.691l2.812,1.968a.844.844,0,0,0,1.328-.691V7.943l6.5-6.5A.844.844,0,0,0,17.155,0Z"
                fill={filterMenu ? "#6B46C1" : "#9CA3AF"}
              />
            </svg>
            {filterMenu && (
              <FilterMenu
                filters={filters}
                setFilters={setFilters}
                setFilterMenu={setFilterMenu}
                // resetFilters={resetFilters}
                // applyFilters={applyFilters}
              />
            )}
          </div>
        </div>

        {!loading && length == 0 ? (
          <div className=" flex items-center justify-center min-h-[70vh]  ">
            <div className="max-w-[521px] flex space-x-[29px]  w-full">
              <div className="h-[197px] w-[14px] bg-[#0D2174] rounded-[12px]" />
              <div className="py-4">
                <h4 className="text-[22px] font-medium text-[#0D2174]">
                  You have no raffles
                </h4>
                <p className="max-w-[478px] text-sm leading-[22px] mt-2">
                  Once you have launched a raffle, your results will appear
                  here. You can launch a raffle from your Dashboard or by
                  clicking the button below.
                </p>
                <Link to={"/dashboard/raffle"}>
                  <button className="flex bg-[#0D2174] items-center gap-[6px] whitespace-nowrap text-white  px-5 py-[11px] mt-6 rounded-md hover:opacity-80 text-xs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.932"
                      height="13.932"
                      viewBox="0 0 13.932 13.932"
                    >
                      <g
                        id="Icon_ionic-ios-add-circle-outline"
                        data-name="Icon ionic-ios-add-circle-outline"
                        transform="translate(-3.375 -3.375)"
                      >
                        <path
                          id="Path_1760"
                          data-name="Path 1760"
                          d="M17.109,13.543H14.614V11.048a.536.536,0,1,0-1.072,0v2.495H11.048a.513.513,0,0,0-.536.536.519.519,0,0,0,.536.536h2.495v2.495a.519.519,0,0,0,.536.536.533.533,0,0,0,.536-.536V14.614h2.495a.536.536,0,1,0,0-1.072Z"
                          transform="translate(-3.738 -3.738)"
                          fill="#fff"
                        />
                        <path
                          id="Path_1761"
                          data-name="Path 1761"
                          d="M10.341,4.313A6.026,6.026,0,1,1,6.078,6.078a5.988,5.988,0,0,1,4.263-1.765m0-.938a6.966,6.966,0,1,0,6.966,6.966,6.965,6.965,0,0,0-6.966-6.966Z"
                          transform="translate(0 0)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    New Campaign
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="  max-w-[96vw] pr-4 overflow-auto">
            <div className=" ray-800   pt-4  whitespace-nowrap ">
              <table className="w-full ">
                <thead>
                  <tr
                    tabindex="0"
                    className="focus:outline-none h-16 w-full text-sm leading-none text-gray-800 -white "
                  >
                    <th align="left" className="py-6 pl-8   font-normal">
                      {console.log(data, "asdsadasasd", selected)}
                      <Checkbox
                        id={"raffle_header"}
                        value={data.every((item) => selected.includes(item.id))}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelected(data.map((item) => item.id));
                            return;
                          }
                          setSelected([]);
                        }}
                      />
                    </th>
                    <th align="left" className="py-6 pl-8   font-normal">
                      Start / End Date
                    </th>
                    <th align="left" className="py-5 pl-6  font-normal">
                      Days left
                    </th>
                    <th align="left" className="py-5 pl-6  font-normal">
                      Details
                    </th>
                    <th align="left" className="py-5 pl-6  font-normal">
                      Audience
                    </th>
                    <th align="left" className="py-5 pl-6  font-normal">
                      User Level
                    </th>
                    <th align="left" className="py-5 pl-6  font-normal">
                      Points
                    </th>
                    <th align="left" className="py-5 pl-6  font-normal">
                      Joined
                    </th>
                    <th align="left" className="py-5 pl-6  font-normal">
                      Status
                    </th>
                    <th align="left" className="py-5 pl-6  font-normal">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full ">
                  {loading &&
                    Array.from(Array(5)).map(() => <SkeletonLoader />)}
                  {data && data.map((item) => getRows(item))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="block sm:hidden pb-40">
        <div className="px-6 bg-white py-4">
          <div className="flex items-center justify-between">
            <p className="font-bold text-lg text-[#070707]">My Campaigns</p>
            <BellBlackIcon />
          </div>
          <div className=" mt-10 flex items-center gap-5">
            <MobileSelect
              value={"raffles"}
              onChange={(e) => {
                if (e.value === "campaigns") {
                  history.push("/dashboard/my-campaigns");
                }
              }}
            />
          </div>
        </div>
        <div className="py-10  px-5 w-full bg-white mt-5">
          {!loading && length == 0 ? (
            <NoCampaigns raffle={true} />
          ) : (
            data && data.map((item) => getRows(item))
          )}
        </div>
      </div>
    </>
  );
}

Table.defaultProps = {
  filter: [],
  search: "",
  searchKeys: [],
  length: null,
};

Table.propTypes = {
  head: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      position: PropTypes.oneOf(["center", "right"]),
      column: PropTypes.string,
      icon: PropTypes.oneOf(["sort"]),
    })
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.shape({
        //value: PropTypes.number || PropTypes.number,
        component: PropTypes.element,
        position: PropTypes.oneOf(["center", "right"]),
        //column: PropTypes.string || PropTypes.number,
      })
    )
  ).isRequired,
  filter: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  search: PropTypes.string,
  searchKeys: PropTypes.arrayOf(PropTypes.string),
  length: PropTypes.number,
};

const mapStateToProps = (store) => ({
  search: adminSearch(store),
  filter: adminFilter(store),
});

const Row = ({ info, renderButtons, setSelected, selected }) => {
  const [statusLoading, setStatusLoading] = useState(false);

  const [menu, setMenu] = useState(false);

  const menuRef = useRef(null);
  const positionRef = useRef(null);

  useEffect(() => {
    menuRef?.current?.focus();
  }, [menu]);
  const isDisabled = !renderButtons(info);
  const colors = {
    active: "#15803d",
    live: "#15803d",
    pending: "#a16207",
    rejected: "#dc2626",
    finished: "#1B52D7",
    draft: "#6d28d9",
    stopped: "#ee5700",
    paused: "#6d28d9",
    deleted: "#6d28d9",
    scheduled: "#1e2420",
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      setMenu(false);
    });
  }, []);

  return (
    <React.Fragment key={info.id}>
      <tr
        onClick={() => {
          window.open(
            `${window.location.origin}/raffle-details/${info.id}`,
            "_blank"
          );
        }}
        className="border-[0.5px] sm:table-row hidden border-x-0 hover:bg-gray-100 cursor-pointer"
      >
        <td className="py-4 pl-8">
          <Checkbox
            id={"raffle_table_" + info.id}
            value={selected}
            onChange={setSelected}
          />
        </td>
        <td className="py-4 pl-8 text-sm  text-black">
          <div className="flex items-center gap-3">
            <div className="w-12 h-12 rounded-md overflow-hidden relative">
              <img
                alt="Work"
                src={info.media}
                className="object-cover w-full h-full"
              />
            </div>
            <div>
              <div className="text-sm font-medium">{info?.name}</div>
              {info?.brandName}
            </div>
          </div>
        </td>
        <td className="py-4 pl-2 text-sm font-normal text-black">
          <div className="text-sm font-medium">
            {info && moment(info?.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="text-sm text-red-500">
            {info && moment(info?.endDate).format("DD MMM YYYY")}
          </div>
        </td>
        <td className="py-4 pl-6 text-sm font-normal text-black">
          {getDaysLeft(info?.endDate) > 0 ? getDaysLeft(info?.endDate) : 0}
        </td>
        <td className="py-4 pl-6 text-sm  text-black">
          <div className="text-sm font-medium">{info?.country}</div>
          <span className="capitalize">{info?.state}</span> -{" "}
          <span className="capitalize"> {info?.city}</span>
        </td>
        <td className="py-4 pl-6 text-sm font-medium text-black">
          <div className="font-normal capitalize">
            {info?.userLevel || "N/A"}
          </div>
        </td>
        <td className="py-4 pl-6 text-sm font-medium text-black">
          {info?.points}
        </td>
        <td className="py-4 pl-6 text-sm font-medium text-black">
          {info?.peopleJoined}
        </td>
        <td className="py-4 pl-6 text-sm font-normal text-black">
          {statusLoading ? (
            <Loading size={"s"} />
          ) : (
            info?.status !== null &&
            (getDaysLeft(info?.endDate) > 0 ? (
              <Label
                type={
                  STATUSES[
                    info?.status === "live"
                      ? 0
                      : statuss.findIndex((item) => item == info?.status)
                  ]
                }
              />
            ) : (
              <Label
                type={
                  STATUSES[
                    info?.status === "live"
                      ? 3
                      : statuss.findIndex((item) => item == info?.status)
                  ]
                }
              />
            ))
          )}
        </td>
        <td
          onClick={(e) => e.stopPropagation()}
          className="py-4 pl-6 text-sm font-normal text-black relative"
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (!isDisabled) {
                positionRef.current = e.target.getBoundingClientRect();
                setMenu(!menu);
              }
            }}
            className={`${
              isDisabled && " cursor-not-allowed "
            } hover:scale-110 px-[10px] py-1 rounded-full`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M4.16667 10.8334C4.62691 10.8334 5 10.4603 5 10.0001C5 9.53984 4.62691 9.16675 4.16667 9.16675C3.70643 9.16675 3.33334 9.53984 3.33334 10.0001C3.33334 10.4603 3.70643 10.8334 4.16667 10.8334Z"
                stroke="#A1A1AA"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10.0001C10.8333 9.53984 10.4602 9.16675 10 9.16675C9.53976 9.16675 9.16666 9.53984 9.16666 10.0001C9.16666 10.4603 9.53976 10.8334 10 10.8334Z"
                stroke="#A1A1AA"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.8333 10.8334C16.2936 10.8334 16.6667 10.4603 16.6667 10.0001C16.6667 9.53984 16.2936 9.16675 15.8333 9.16675C15.3731 9.16675 15 9.53984 15 10.0001C15 10.4603 15.3731 10.8334 15.8333 10.8334Z"
                stroke="#A1A1AA"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {menu && (
            <div
              ref={menuRef}
              onBlur={() => {
                setMenu(false);
              }}
              tabIndex={0}
              style={{
                top: positionRef.current?.top + 20,
                left: positionRef.current?.left - 150,
              }}
              className={`
              
             w-[150px] bg-white fixed outline-none shadow-md py-1   z-10 right-16 top-1/3 `}
            >
              {renderButtons(info, setMenu, setStatusLoading)}
            </div>
          )}
        </td>
      </tr>
      <div
        onClick={() => {
          window.open(
            `${window.location.origin}/raffle-details/${info.id}`,
            "_blank"
          );
        }}
        className="sm:hidden mb-5"
      >
        <div
          style={{
            boxShadow: "0px 1px 3px 0px #00000040",
          }}
          className={`px-3
        py-[10px] rounded-md sm:hidden hover:bg-gray-50
        cursor-pointer`}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-[7px]">
              <svg
                width="5"
                height="5"
                viewBox="0 0 5 5"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  color: colors[info?.status.toLowerCase()],
                }}
              >
                <path
                  d="M2.5 5C3.88071 5 5 3.88071 5 2.5C5 1.11929 3.88071 0 2.5 0C1.11929 0 0 1.11929 0 2.5C0 3.88071 1.11929 5 2.5 5Z"
                  fill="currentColor"
                />
              </svg>
              <span className="capitalize text-xs leading-4 text-black font-bold">
                {info?.status}
              </span>
            </div>
            <div className="relative">
              <button
                className="hover:scale-110"
                onClick={(e) => {
                  e.stopPropagation();
                  !isDisabled && setMenu(!menu);
                }}
              >
                <svg
                  width="6"
                  height="18"
                  viewBox="0 0 6 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.8741 8.90507C3.8741 9.0805 3.82208 9.25199 3.72461 9.39786C3.62715 9.54372 3.48862 9.65741 3.32654 9.72455C3.16446 9.79168 2.98611 9.80925 2.81405 9.77502C2.64199 9.7408 2.48394 9.65632 2.35989 9.53227C2.23584 9.40822 2.15137 9.25017 2.11714 9.07811C2.08292 8.90605 2.10048 8.7277 2.16762 8.56563C2.23475 8.40355 2.34844 8.26502 2.49431 8.16755C2.64017 8.07009 2.81166 8.01807 2.9871 8.01807C3.10358 8.01807 3.21892 8.04101 3.32654 8.08558C3.43415 8.13016 3.53194 8.1955 3.6143 8.27786C3.69667 8.36023 3.762 8.45801 3.80658 8.56563C3.85116 8.67324 3.8741 8.78858 3.8741 8.90507Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.8741 2.69706C3.8741 2.87249 3.82208 3.04398 3.72461 3.18985C3.62715 3.33572 3.48862 3.4494 3.32654 3.51654C3.16446 3.58367 2.98611 3.60124 2.81405 3.56702C2.64199 3.53279 2.48394 3.44831 2.35989 3.32426C2.23584 3.20021 2.15137 3.04216 2.11714 2.8701C2.08292 2.69804 2.10048 2.5197 2.16762 2.35762C2.23475 2.19554 2.34844 2.05701 2.49431 1.95955C2.64017 1.86208 2.81166 1.81006 2.9871 1.81006C3.10358 1.81006 3.21892 1.833 3.32654 1.87758C3.43415 1.92215 3.53194 1.98749 3.6143 2.06985C3.69667 2.15222 3.762 2.25 3.80658 2.35762C3.85116 2.46523 3.8741 2.58058 3.8741 2.69706Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.8741 15.1131C3.8741 15.2885 3.82208 15.46 3.72461 15.6059C3.62715 15.7517 3.48862 15.8654 3.32654 15.9326C3.16446 15.9997 2.98611 16.0173 2.81405 15.983C2.64199 15.9488 2.48394 15.8643 2.35989 15.7403C2.23584 15.6162 2.15137 15.4582 2.11714 15.2861C2.08292 15.1141 2.10048 14.9357 2.16762 14.7736C2.23475 14.6116 2.34844 14.473 2.49431 14.3756C2.64017 14.2781 2.81166 14.2261 2.9871 14.2261C3.10358 14.2261 3.21892 14.249 3.32654 14.2936C3.43415 14.3382 3.53194 14.4035 3.6143 14.4859C3.69667 14.5682 3.762 14.666 3.80658 14.7736C3.85116 14.8812 3.8741 14.9966 3.8741 15.1131Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              {menu && (
                <div
                  ref={menuRef}
                  onBlur={() => {
                    setMenu(false);
                  }}
                  tabIndex={0}
                  className={`
              
             w-[150px] bg-white outline-none shadow-md py-1  absolute z-10 right-3 top-1/3 `}
                >
                  {renderButtons(info, setMenu, setStatusLoading)}
                </div>
              )}
            </div>
          </div>
          <div className="mt-4 flex items-center gap-3">
            <div className=" w-10 h-10 rounded-md overflow-hidden relative">
              <img
                className="w-full h-full object-cover object-center"
                alt="campaign"
                src={
                  info.media ||
                  "https://w7.pngwing.com/pngs/653/502/png-transparent-gray-draft-text-on-black-background-postage-stamps-draft-miscellaneous-angle-white.png"
                }
              />
            </div>
            <div>
              <p className="text-base font-bold leading-5 text-black">
                {info?.name || "N/A"}
              </p>
              <div className="capitalize text-sm text-black leading-4">
                {info?.userLevel || "N/A"} - {info?.country || "N/A"}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between mt-6">
            <div>
              <h3 className="text-base font-bold text-black leading-5">230</h3>
              <p className="text-xs text-black leading-4">posts</p>
            </div>
            <div>
              <h3 className="text-base font-bold text-black leading-5">$1</h3>
              <p className="text-xs text-black leading-4">pPp</p>
            </div>
            <div>
              <h3 className="text-base font-bold text-black leading-5">$230</h3>
              <p className="text-xs text-black leading-4">spent</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(connect(mapStateToProps, null)(Table));
