import React from "react";
import validator from "validator";

const SideBar = ({ step, setStep, form }) => {
  const sideBarData = [
    {
      src: "/images/info.svg",
      link: "ABOUT VERIFICATION",
      step: 1,
    },
    {
      src: "/images/profile.svg",
      link: "PUBLIC PROFILE",
      step: 2,
    },
    {
      src: "/images/docs.svg",
      link: "COMPANY DOCUMENTS",
      step: 3,
    },
  ];
  // ein: null,
  // aoi: null,
  // website: "",
  // description: "",
  // instaSocial: "",
  // facebookSocial: "",
  // twitterSocial: "",
  // logo: "",
  // foundationName: "",
  const step2 =
    form.foundationName &&
    form.description &&
    form.website &&
    form.logo &&
    (form.facebookSocial || form.instaSocial || form.twitterSocial);

  return (
    <div className="flex flex-col gap-[18px] max-w-[300px] w-full">
      {sideBarData.map((item, index) => (
        <div
          key={index + "sidebar"}
          onClick={() => {
            if (!step2) {
              return;
            }
            setStep(item.step);
          }}
          className="cursor-pointer hover:bg-opacity-[0.4] duration-300 transition-all ease-in-out py-[25px] pl-[18px] pr-[9px] bg-[#fff] flex justify-between items-center gap-[15px]"
        >
          <div
            className={`flex ${index == 0
                ? "gap-[12px]"
                : index == 1
                  ? "gap-[17px]"
                  : "gap-[18px]"
              } items-center `}
          >
            <img src={item.src} alt="" />
            <p className="text-[14px] font-semibold">{item.link}</p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
          >
            <path
              d="M13.75 23.375L20.625 16.5L13.75 9.625"
              stroke="#000"
              strokewidth="2"
              strokelinecap="round"
              strokelinejoin="round"
            />
          </svg>
        </div>
      ))}
    </div>
  );
};

export default SideBar;
