import React from "react";

const ProgressBar = ({
  trackColor,
  barColor,
  progress,
  label,
  showProgress,
}) => {
  return (
    <div className=" w-full">
      <div className="flex justify-between items-center">
        <div className="text-xs font-semibold text-black">{label}</div>
        <div className="text-xs font-semibold text-black">
          {showProgress && progress}
        </div>
      </div>
      <div
        style={{ backgroundColor: "#D9D9D9" }}
        className=" mt-[10px] w-full rounded-md h-[8px] overflow-hidden"
      >
        <div
          style={{ width: progress, background: barColor }}
          className="h-full rounded-md"
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
