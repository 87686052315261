import { API } from "../globals";
import { buildHeaders } from "./utils";

export const AdminApi = {
  approveCampaign: (data) => {
    return fetch(`${API}/admin/approve-campaign`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  approveNgoCampaign: (data) => {
    return fetch(`${API}/admin/approve-ngo-campaign`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  rejectCampaign: (data) => {
    return fetch(`${API}/admin/reject-campaign`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  notifyUsers: (data) => {
    return fetch(`${API}/campaigns/notify-users`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ campaign: data }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  notifyWhatsapp: (data) => {
    return fetch(`${API}/campaigns/notify-user-whatsapp`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ campaign: data }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getAdminUsers: () => {
    return fetch(`${API}/admin/get-vrby-users`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getAdminNgos: () => {
    return fetch(`${API}/admin/get-ngos`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getFiveDayUsers: () => {
    return fetch(`${API}/admin/five-day-users`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  searchAdminUsers: (searchFor) => {
    return fetch(`${API}/admin/search-vrby-users`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ search: searchFor }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  updateAdminUser: (id, data) => {
    return fetch(`${API}/admin/update-user-data`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ id: id, data: data }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  updatePaymentData: (id, data) => {
    return fetch(`${API}/admin/update-payment-data`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ id: id, data: data }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  updateAdminShipping: (id, data) => {
    return fetch(`${API}/admin/update-shipping-data`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ id: id, data: data }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  deleteUserSocial: (platform, id) => {
    return fetch(`${API}/admin/delete-user-social`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ platform: platform, id: id }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  sendResetPasswordLink: (email) => {
    return fetch(`https://verbyo.com/api/user/forgot`, {
      method: "post",
      //headers: buildHeaders(),
      body: JSON.stringify({ email: email }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  suspendUser: (id) => {
    return fetch(`${API}/admin/suspend-user-byid`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  unSuspendUser: (id) => {
    return fetch(`${API}/admin/un-suspend-user-byid`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  deleteUser: (id) => {
    return fetch(`${API}/admin/delete-user-byid`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  blockUser: (id) => {
    return fetch(`${API}/admin/block-user-byid`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getAdminAllCampaigns: () => {
    return fetch(`${API}/admin/get-all-campaigns`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  fetchInterestsStats: () => {
    return fetch(`${API}/admin/get-interests-stats`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  fetchAdminPosts: (page, limit, ranges, search) => {
    return fetch(
      `${API}/admin/user-posts?page=${page}&limit=${limit}${
        search ? `&search=${search}` : ""
      }${ranges ? `&range=${ranges}` : ""}`,
      {
        method: "get",
        headers: buildHeaders(),
      }
    )
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getCompletedOffers: (id) => {
    return fetch(`${API}/admin/posts-by-user/${id}`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getCompletedRaffles: (id) => {
    return fetch(`${API}/admin/raffles-by-user/${id}`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  removeFakePost: (id) => {
    return fetch(`${API}/admin/remove-fake-post`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getPendingSocialRequests: () => {
    return fetch(`${API}/admin/get-social-verifications`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  socialVerificationAction: (id, status, socialMedia) => {
    return fetch(`${API}/admin/social-verification-action`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ userId: id, status, socialMedia }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getVerificationDoc: (id) => {
    return fetch(`${API}/foundation/doc?id=${id}`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  changeVerifiedStatus: (id, status, rejectionReason) => {
    return fetch(`${API}/foundation/verify`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ userId: id, status, rejectionReason }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error:", error);
      });
  },
  updateUserDetails: (body) => {
    return fetch(`${API}/admin/update-user`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error:", error);
      });
  },
  updateLogo: (body) => {
    return fetch(`${API}/admin/update-logo`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error:", error);
      });
  },
  updateUserStatus: (body) => {
    return fetch(`${API}/admin/update-user-status`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error:", error);
      });
  },
  getPlatformControls: () => {
    return fetch(`${API}/admin/platform-control`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error:", error);
      });
  },
  updatePlatformControls: (body) => {
    return fetch(`${API}/admin/platform-control`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error:", error);
      });
  },
  getBudgetMinimals: () => {
    return fetch(`${API}/admin/budget-minimals`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error:", error);
      });
  },
  updateBudgetMinimals: (body) => {
    return fetch(`${API}/admin/budget-minimals`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error:", error);
      });
  },
  getCompanyStats: (id) => {
    return fetch(`${API}/admin/get-company-stats?id=${id}`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  updateWallet: (body) => {
    return fetch(`${API}/admin/update-user-wallet`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  getMinimumPayouts: () => {
    return fetch(`${API}/admin/minimum-payouts`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  updateMinimumPayouts: (body) => {
    return fetch(`${API}/admin/minimum-payouts`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  changeMemberVerification: (body) => {
    return fetch(`${API}/admin/change-member-verification`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  createUserAnnouncement: (body) => {
    return fetch(`${API}/admin/create-user-announcement`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  getUserAnnouncement: () => {
    return fetch(`${API}/admin/get-user-announcement`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  getInstaRequest: () => {
    return fetch(`${API}/admin/insta-requests`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  updateInstaRequest: (body) => {
    return fetch(`${API}/admin/insta-requests`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  updateRaffleStatus: (body) => {
    return fetch(`${API}/admin/change-raffle-status`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  getMinimumRequirements: () => {
    return fetch(`${API}/admin/min-requirements`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  updateMinimumRequirements: (body) => {
    return fetch(`${API}/admin/min-requirements`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  editTermsAndConditions: (body) => {
    return fetch(`${API}/admin/edit-terms`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  getTermsAndConditions: () => {
    return fetch(`${API}/admin/get-terms`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
  getTerms: (query) => {
    return fetch(`${API}/terms?type=${query.type}&country=${query.country}`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data);
  },
};
