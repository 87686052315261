import React from "react";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  authSelectorIsUserLogin,
  authSelectorLoading,
  notificationsSelector,
  userData,
} from "../../../selectors";

import { fetchNotifications } from "../../../actions/";
import { useEffect } from "react";
import { useState } from "react";
import {
  DASHBOARD_SETTINGS_DETAILS_PATH,
  DASHBOARD_NOTIFICATIONS_PATH,
} from "../../../globals";
import moment from "moment/moment";
import Loading from "../../Loading/Loading";
import { getCurrentAccountType } from "../../../api/utils";

const NotificationItem = ({ data }) => {
  const history = useHistory();
  return (
    <div
      onClick={() => {
        if (!data.link && data.date) {
          window.open("/campaign-details/" + data.date, "_blank");
        } else {
          history.push(data.link);
        }
      }}
      className="flex items-center cursor-pointer hover:bg-gray-50  gap-3"
    >
      <div
        className={`min-h-[58px] flex-grow h-full rounded-full min-w-[5px] max-w-[5px] ${
          data.type === "success"
            ? "bg-[#617801]"
            : data.type === "pending"
            ? "bg-[#EAD838]"
            : "bg-[#D52D2D]"
        } `}
      ></div>
      <div className="flex flex-col gap-2">
        <div
          className="text-xs font-regular text-black"
          dangerouslySetInnerHTML={{ __html: data?.message }}
        ></div>
        <div className="text-[10px] font-regular text-[#988E8E]">
          {data.createdAt
            ? moment(data.createdAt).format("MMMM Do YYYY, h:mm a")
            : "!important"}
        </div>
      </div>
    </div>
  );
};

const NotificationBox = ({
  data,
  loading,
  fetchNotifications,
  notifications,
  panel,
  notificationsLoading,
  isLogin,
}) => {
  const [notificationsList, setNotificationsList] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (!notifications && isLogin) {
      fetchNotifications();
    }
  }, [panel]);
  useEffect(() => {
    if (notifications) {
      const profileIncomplete = () => {
        if (
          !data.firstName ||
          !data.lastName ||
          !data.address ||
          !data.country ||
          !data.state ||
          !data.city ||
          !data.email ||
          !data.contactName ||
          data.firstName == "" ||
          data.lastName == "" ||
          data.address == "" ||
          data.country == "" ||
          data.state == "" ||
          data.city == "" ||
          data.email == "" ||
          data.contactName == ""
        ) {
          return true;
        } else {
          return false;
        }
      };

      if (
        profileIncomplete() &&
        notifications
        // &&
        // getCurrentAccountType() != "ngo"
      ) {
        setNotificationsList([
          ...notificationsList,
          {
            type: "warning",
            title: "Finish your profile ",
            message:
              "By filling up your Contact Details in case we our team needs to get in contact with you",
            link: DASHBOARD_SETTINGS_DETAILS_PATH,
          },
          ...notifications,
        ]);
      } else {
        setNotificationsList([...notifications]);
      }
    }
  }, [loading, notifications]);

  return (
    <div className="w-[250px]  h-fit  sm:top-16 -top-[350px] pb-2 bg-white shadow-md  absolute right-0">
      <div className="flex items-center justify-start gap-2 pl-4 border-b font-bold text-sm text-[#4E588D] border-[#E1E1E1] py-4">
        <svg
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.39648 19.7734C6.34271 20.9763 7.8292 21.7514 9.50072 21.7514C11.1722 21.7514 12.6587 20.9763 13.6049 19.7734C10.8804 20.1427 8.12102 20.1427 5.39648 19.7734Z"
            fill="#1C274C"
          />
          <path
            d="M17.7177 7.875V8.66711C17.7177 9.61774 17.9891 10.5471 18.4975 11.338L19.7433 13.2764C20.8814 15.0468 20.0126 17.4531 18.0333 18.0131C12.8557 19.4776 7.39433 19.4776 2.21666 18.0131C0.237432 17.4531 -0.631327 15.0468 0.506656 13.2764L1.75253 11.338C2.26094 10.5471 2.53223 9.61774 2.53223 8.66711V7.875C2.53223 3.52576 5.93163 0 10.125 0C14.3183 0 17.7177 3.52576 17.7177 7.875Z"
            fill="#1C274C"
          />
        </svg>
        Notifications
      </div>
      <div className="flex flex-col px-4 gap-3 pt-3 w-full">
        {notificationsLoading && (
          <div className="w-full flex items-center pt-10 justify-center">
            <Loading />
          </div>
        )}
        {notificationsList && notificationsList.length > 0 ? (
          <>
            {notificationsList.map((item, index) => {
              if (index < 3) {
                return <NotificationItem data={item} key={index} />;
              }
            })}
          </>
        ) : (
          !notificationsLoading && (
            <div className="text-xs font-regular text-black">
              No Notifications!
            </div>
          )
        )}
      </div>
      <div className="flex items-center justify-center  py-4">
        <button
          onClick={() => {
            history.push(DASHBOARD_NOTIFICATIONS_PATH);
          }}
          className="text-[#198CD9] flex items-center justify-center text-xs  bottom-3 font-semibold hover:underline "
        >
          VIEW ALL
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  data: userData(store),
  loading: authSelectorLoading(store),
  notifications: notificationsSelector(store),
  notificationsLoading: store.notifications.loading,
  isLogin: authSelectorIsUserLogin(store),
});

const mapDispatchToProps = {
  fetchNotifications,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationBox));
