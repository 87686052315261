import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import ImageRetina from "../ImageRetina";
import Logo1x from "../../assets/images/icons/hex-logo.svg";
import styles from "./formLogo.module.scss";

function FormLogo({ color }) {
  return (
    <div className={styles.logo}>
      <img src={Logo1x} alt="" />
      <h3 className={cx(styles.text, color ? styles[color] : "")}>
        <div className={styles.title}>Verbyo</div>
        <div className={styles.subtitle}>BUSINESS</div>
      </h3>
    </div>
  );
}

FormLogo.defaultProps = {
  color: "white",
};

FormLogo.propTypes = {
  color: PropTypes.oneOf(["white", "black", "purple"]),
};

export default FormLogo;
