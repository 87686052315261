import { getCookie } from "../utils/cookie";
import { TOKEN_COOKIE_NAME, ACCOUNT_TYPE_COOKIE } from "../globals";

export function getToken() {
  const token = getCookie(TOKEN_COOKIE_NAME);

  if (token) return token;

  return null;
}

export function getCurrentAccountType() {
  const type = getCookie(ACCOUNT_TYPE_COOKIE);

  if (type) return type;

  return null;
}

export function checkIfVideo(item) {
  return item.match(/\.(mp4|mov|flv|mpeg-4|mkv|webm)$/i);
}

export function buildHeaders(withoutType, token) {
  token = token || getToken();

  const headers = {};
  const oldTokens = localStorage.getItem("old-token");

  if (!withoutType) headers["Content-Type"] = "application/json";

  if (token) {
    headers["x-token"] = token;
    headers["Authorization"] = token;
  }

  if (oldTokens) headers["x-old-tokens"] = oldTokens;

  return headers;
}

export const checkNonZero = (value) => {
  return !value ? 0 : value;
};
export const caluculatePercentage = (value, total) => {
  if (!checkNonZero(total)) return "0%";
  if (!checkNonZero(value)) return "0%";
  return Math.round((value / total) * 100) + "%";
};
