import React from "react";
import RaffleDetailsComponent from "../components/RaffleDetailsPage";

const RaffleDetails = () => {
  return (
    <div className="max-w-[1300px] w-full mx-auto p-10 mt-20">
      <RaffleDetailsComponent />
    </div>
  );
};

export default RaffleDetails;
