export const FETCH_USER = "fetch_user";
export const FIRST_LOGIN = "first_login";
export const DELETE_USER = "delete_user";
export const LOAD_CAMPAIGNS = "load_campaigns";
export const UPDATE_CAMPAIGN = "update_campaign";
export const EDIT_CAMPAIGN = "edit_campaign";
export const GET_CAMPAIGN = "get_campaign";
export const LOAD_TRANSACTIONS = "load_transactions";
export const LOAD_STATISTICS = "load_statistics";
export const GET_DETAILED_CAMPAIGN_STATS = "get_detailed_campaign_stats";
export const GET_DETAILED_RAFFLES_STATS = "get_detailed_raffles_stats";
export const GET_DETAILED_POSTS_STATS = "get_detailed_posts_stats";
export const LOAD_USERS = "load_users";
export const LOAD_NGOS = "load_ngos";
export const LOAD_OFFERS = "load_offers";
export const LOAD_CLIENTS = "load_clients";
export const LOAD_CAMPAIGNS_ADMIN = "load_campaigns_admin";
export const START = "_start";
export const SUCCESS = "_success";
export const ERROR = "_error";
export const LOGOUT = "logout";
export const SET_ACCOUNT_TYPE = "set_account_type";
export const LOAD_RAFFLES = "LOAD_RAFFLES";
export const LOAD_ADMIN_STATS = "LOAD_ADMIN_STATS";
export const UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE";
export const UPDATE_FILTER_VALUE = "UPDATE_FILTER_VALUE";
export const SET_USER_DATA = "SET_USER_DATA";
export const LOAD_NOTIFICATIONS = "load_notifications";
export const ADD_ERROR = "add_error";
export const DELETE_ERROR = "delete_error";
export const LOAD_VIDEOS = "load_videos";
export const LOAD_INTERESTS_STATS_ADMIN = "load_interests_stats_admin";
export const LOAD_IG_REQUESTS = "load_ig_requests";
