import {
  START,
  SUCCESS,
  ERROR,
  GET_DETAILED_POSTS_STATS,
} from "../actions/types";

const defaultsState = {
  loading: false,
  loaded: false,
  error: false,
  entities: null,
};
export default function transactions(state = defaultsState, action) {
  switch (action.type) {
    case GET_DETAILED_POSTS_STATS + START:
      return {
        loading: true,
        loaded: false,
        error: false,
      };
    case GET_DETAILED_POSTS_STATS + SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: false,
        entities: action.payload || false,
      };
    case GET_DETAILED_POSTS_STATS + ERROR:
      return {
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
}
