import React, { useEffect } from "react";
import Button from "./Button";
import { useState } from "react";
import validator from "validator";
import { DeleteDoc, uploadDoc } from "../../services/userAuthService";
import { userData } from "../../selectors";
import { connect } from "react-redux";
const inputs = [
  {
    type: "text",
    placeholder: "Website",
    name: "website",
    maxLength: 30,
    pattern:
      "^(https?://)?(www.)?([a-zA-Z0-9]+).[a-zA-Z0-9]*.[a-z]{3}.?([a-z]+)?$",
    validatorFunc: (value) => {
      return validator.isURL(value);
    },
  },
  {
    type: "text",
    placeholder: "Facebook",
    name: "facebookSocial",
    maxLength: 100,
    pattern: "^(https?://)?(www.)?facebook.com/.*$",
    validatorFunc: (value) => {
      const match = value.match(/^(https?:\/\/)?(www\.)?facebook\.com\/.*$/); // Removed quotes around the regex
      return !!match; // Return a boolean indicating whether the value matches the pattern
    },
  },
  {
    type: "text",
    placeholder: "Instagram",
    name: "instaSocial",
    maxLength: 100,
    pattern: "^(https?://)?(www.)?instagram.com/.*$",
    validatorFunc: (value) => {
      const match = value.match(/^(https?:\/\/)?(www\.)?instagram\.com\/.*$/); // Removed quotes around the regex
      return !!match; // Return a boolean indicating whether the value matches the pattern
    },
  },
  {
    type: "text",
    placeholder: "X",
    name: "twitterSocial",
    maxLength: 100,
    pattern: "^(https?://)?(www.)?twitter.com/.*$",
    validatorFunc: (value) => {
      const match = value.match(/^(https?:\/\/)?(www\.)?twitter\.com\/.*$/); // Removed quotes around the regex
      return !!match; // Return a boolean indicating whether the value matches the pattern
    },
  },
];

const ProfileForm = ({ logo, setLogo, form, setForm, setStep, user }) => {
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState({
    foundationName: false,
    description: false,
    website: false,
    facebookSocial: false,
    instaSocial: false,
    twitterSocial: false,
  });
  const handleChange = (e) => {
    setForm((previousData) => {
      return {
        ...previousData,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    if (user && user.companyName) {
      setForm((previousData) => {
        return {
          ...previousData,
          foundationName: user.companyName,
        };
      });
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // check if one social link is present
    if (!form.facebookSocial && !form.instaSocial && !form.twitterSocial) {
      setTouched((previousData) => {
        return {
          ...previousData,
          facebookSocial: true,
          instaSocial: true,
          twitterSocial: true,
        };
      });
      return;
    }
    setStep(3);
  };

  const handleUpload = (e) => {
    const form2 = new FormData();
    setLoading(true);
    form2.append("media", e.target.files[0]);
    setLogo(e.target.files[0]);

    uploadDoc(form2).then((res) => {
      setForm({
        ...form,
        logo: res.location,
      });

      setLoading(false);
    });
  };
  return (
    <form onSubmit={handleSubmit} className="w-full max-w-[254px]">
      <p className="text-[14px] text-[#4E588D] font-semibold">Upload logo</p>
      {logo ? (
        loading ? (
          <p className="text-xs font-medium h-[50px] pt-4"> Loading...</p>
        ) : (
          <Logo
            form={form}
            onRemove={() => {
              DeleteDoc({ file: form.logo });
              setForm({
                ...form,
                logo: null,
              });
            }}
            setLogo={setLogo}
            logo={logo}
          />
        )
      ) : (
        <div className="bg-[#EEE] cursor-pointer hover:opacity-90 flex items-center relative  justify-center rounded-full h-[50px] w-[50px] mt-[13px]">
          <svg
            className="absolute inset-0 m-auto "
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={31}
            viewBox="0 0 30 31"
            fill="none"
          >
            <path
              d="M7.5 15.5H22.5M15 7.75V23.25"
              stroke="#676767"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            onChange={handleUpload}
            className="z-[2] appearance-none placeholder:text-xs placeholder:text-[#565656] opacity-0 bg-transparent cursor-pointer custom-file-input w-full h-full rounded-full"
            type="file"
            name="img"
            required
            accept="image/*"
          />
        </div>
      )}
      <p className="mt-[13px] text-[14px] text-[#4E588D] font-semibold">
        Enter details
      </p>
      <input
        onChange={handleChange}
        name="foundationName"
        type="text"
        disabled={user && user.companyName}
        placeholder="Foundation name"
        required
        minLength={5}
        maxLength={50}
        value={form.foundationName}
        onFocus={() => {
          setTouched((previousData) => {
            return {
              ...previousData,
              foundationName: true,
            };
          });
        }}
        onBlur={() => {
          setTouched((previousData) => {
            return {
              ...previousData,
              foundationName: false,
            };
          });
        }}
        className={`py-[11px]
        ${
          touched["foundationName"] &&
          form.foundationName.length < 5 &&
          "invalid:border-red-500"
        }
        outline-none placeholder:text-xs placeholder:text-[#565656] pl-[15px] border border-[#E1E1E1] mt-[17px] rounded-[5px] w-full text-[14px]`}
      />
      <div className="relative">
        <textarea
          required
          onChange={handleChange}
          value={form.description}
          maxLength={200}
          onFocus={() => {
            setTouched((previousData) => {
              return {
                ...previousData,
                description: true,
              };
            });
          }}
          onBlur={() => {
            setTouched((previousData) => {
              return {
                ...previousData,
                description: false,
              };
            });
          }}
          minLength={100}
          name="description"
          placeholder="Enter a public description of your foundation. Mission, social causes, etc."
          className={`min-h-[135px]
        ${
          touched["description"] &&
          form.description.length < 100 &&
          "invalid:border-red-500"
        }
         outline-none max-h-[240px] placeholder:text-xs placeholder:text-[#565656] py-[11px] px-[14px] border border-[#E1E1E1] mt-[10px] rounded-[5px] w-full text-[14px]`}
        />
        <span
          className={`absolute
        ${
          form.description.length < 100 && touched["description"]
            ? "text-red-500"
            : "text-[#4E588D]"
        }
        -bottom-3 right-1 text-[10px] `}
        >
          {form.description.length} / 200
        </span>
      </div>
      {form.description.length < 100 && touched["description"] && (
        <span className="text-red-500 text-xs mt-1  left-0 w-full">
          Enter at least 100 characters.
        </span>
      )}
      <div className="mt-[17px] flex flex-col gap-[10px]">
        {inputs.map((item, index) => (
          <>
            <input
              onChange={handleChange}
              name={item.name}
              key={index + "inputs"}
              type={item.type}
              placeholder={item.placeholder}
              value={form[item.name]}
              maxLength={item.maxLength}
              pattern={item.pattern}
              onFocus={() => {
                setTouched((previousData) => {
                  return {
                    ...previousData,
                    [item.name]: true,
                  };
                });
              }}
              onBlur={() => {
                setTouched((previousData) => {
                  return {
                    ...previousData,
                    [item.name]: false,
                  };
                });
              }}
              className={`
            ${
              (touched[item.name] && !validator.isURL(form[item.name])) ||
              (!item.validatorFunc(form[item.name]) && touched[item.name])
                ? "border-red-500"
                : "border-[#E1E1E1] "
            }
            py-[11px]  pl-[15px] placeholder:text-xs placeholder:text-[#565656] border rounded-[5px] w-full outline-none text-[14px]`}
            />
            {(touched[item.name] && !validator.isURL(form[item.name])) ||
            (!item.validatorFunc(form[item.name]) && touched[item.name]) ? (
              <span className="text-red-500 text-xs  left-0 w-full">
                Please enter a valid URL.
              </span>
            ) : null}
          </>
        ))}
      </div>

      <div className="mt-[33px]">
        <Button
          disabled={
            !form.foundationName ||
            !form.description ||
            !form.website ||
            !form.logo ||
            (!form.facebookSocial &&
              !form.instaSocial &&
              !form.twitterSocial) ||
            (form.website && !validator.isURL(form.website)) ||
            (form.facebookSocial &&
              !validator.isURL(form.facebookSocial) &&
              form.instaSocial &&
              !validator.isURL(form.instaSocial) &&
              form.twitterSocial &&
              !validator.isURL(form.twitterSocial))
          }
          btnText={"Next"}
          type={"submit"}
        />
      </div>
    </form>
  );
};

const Logo = ({ logo, setLogo, onRemove, form }) => {
  return (
    <div className="relative w-[54px] mt-[13px] h-[54px]">
      <button
        onClick={() => {
          setLogo(null);
          onRemove();
        }}
        className="absolute z-[10] bg-white rounded-full -top-1 -right-1"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5625 21.125C4.72875 21.125 0 16.3962 0 10.5625C0 4.72875 4.72875 0 10.5625 0C16.3962 0 21.125 4.72875 21.125 10.5625C21.125 16.3962 16.3962 21.125 10.5625 21.125ZM15.4058 7.48231C15.6041 7.28406 15.6041 6.9615 15.4058 6.76325L14.3268 5.68506C14.1286 5.48681 13.806 5.48681 13.6077 5.68506L10.5519 8.74088L7.49612 5.68506C7.29787 5.48681 6.97531 5.48681 6.77706 5.68506L5.69806 6.76325C5.49981 6.9615 5.49981 7.28406 5.69806 7.48231L8.75469 10.5389L5.69806 13.5939C5.49981 13.7922 5.49981 14.1147 5.69806 14.313L6.77706 15.392C6.97531 15.5903 7.29787 15.5903 7.49612 15.392L10.5519 12.3354L13.6077 15.392C13.806 15.5903 14.1286 15.5903 14.3268 15.392L15.4058 14.313C15.6041 14.1147 15.6041 13.7922 15.4058 13.5939L12.3492 10.5389L15.4058 7.48231Z"
            fill="#B81919"
          />
        </svg>
      </button>
      <div className="w-[50px] rounded-full overflow-hidden  h-[50px] relative ">
        <img
          src={form.logo}
          alt="logo"
          className="w-full h-full object-cover object-center"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: userData(state),
});

export default connect(mapStateToProps)(ProfileForm);
