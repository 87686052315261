import React from "react";
import ArticleBlog from "../../../assets/images/icons/article-blog-icon.svg";
import ArticleBrand from "../../../assets/images/icons/article-brand-icon.svg";
import ArticleCamera from "../../../assets/images/icons/article-camera-icon.svg";
import ArticleGaming from "../../../assets/images/icons/article-gaming-icon.svg";
import ArticleInsta from "../../../assets/images/icons/article-insta-icon.svg";
import ArticleRaffle from "../../../assets/images/icons/article-raffle-icon.svg";
import ArticleSiren from "../../../assets/images/icons/article-siren-icon.svg";
import ArticleSpotify from "../../../assets/images/icons/article-spotify-icon.svg";
import ArticleTiktok from "../../../assets/images/icons/article-tiktok-icon.svg";
import ArticleVerbyo from "../../../assets/images/icons/article-verbyo2-icon.svg";
import ArticleVolunteer from "../../../assets/images/icons/article-volunteer-icon.svg";
import ArticleYoutube from "../../../assets/images/icons/article-youtube-icon.svg";

const icons = {
  sirenIcon: ArticleSiren,
  cameraIcon: ArticleCamera,
  raffleIcon: ArticleRaffle,
  instaIcon: ArticleInsta,
  tiktokIcon: ArticleTiktok,
  youtubeIcon: ArticleYoutube,
  blogIcon: ArticleBlog,
  gamingIcon: ArticleGaming,
  spotifyIcon: ArticleSpotify,
  volunteerIcon: ArticleVolunteer,
  brandIcon: ArticleBrand,
  verbyoIcon: ArticleVerbyo,
};

const Article = ({ title, description, icon, tools, advantages }) => {
  return (
    <article className="py-[30px] pb-[30px] px-11 max-w-[500px] w-full border-[#E6E6E6] border min-h-[600px]">
      <div className=" gap-5">
        <img src={icons[icon]} />
        <br />
        <h3 className="font-bold text-lg">{title}</h3>
      </div>
      <br />
      <p
        className="text-sm text=[#373535] leading-6"
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
      {tools && (
        <>
          <br />
          <br />
          <p className="text-sm font-medium text-[#373535]">Tools Available</p>
          <div className="flex items-center gap-2 flex-wrap mt-2">
            {tools.map((tool, index) => (
              <div className="flex items-center gap-2 rounded-full text-xs text-black px-2 py-1 bg-[#F6F2F2] whitespace-nowrap w-min">
                {tool}
              </div>
            ))}
          </div>
        </>
      )}
      <br />
      <br />
      {advantages && (
        <>
          <p className="text-sm font-medium text-[#373535]">Good For</p>
          <div className="flex items-center gap-2 flex-wrap mt-2">
            {advantages.map((advantage, index) => (
              <div className="flex items-center gap-2 rounded-full text-xs text-black px-2 py-1 bg-[#F6F2F2] whitespace-nowrap w-min">
                {advantage}
              </div>
            ))}
          </div>
        </>
      )}
    </article>
  );
};

export default Article;
