import { API } from "../globals";
import { buildHeaders } from "./utils";

export const NotificationApi = {
    getNotifications: () => {
        return fetch(`${API}/notifications`, {
          method: "get",
          headers: buildHeaders(),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            } else {
              return response.json();
            }
          })
          .then((data) => {
            return data;
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      },
}