import React from "react";

const Tiktok = () => {
  return (
    <svg
      width="29"
      height="33"
      viewBox="0 0 29 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0047 5.7633C20.8906 4.48606 20.2209 2.82066 20.2209 1H18.8249M22.0047 5.7633C23.02 6.92773 24.3947 7.77457 25.9601 8.10617C26.4466 8.21203 26.9542 8.26854 27.483 8.26854V13.4904C24.7756 13.4904 22.2653 12.6226 20.2206 11.1547V21.7751C20.2206 27.0819 15.9055 31.3937 10.6104 31.3937C7.83932 31.3937 5.33627 30.208 3.58061 28.3239C1.98007 26.6021 1 24.3015 1 21.7751C1 16.5461 5.18822 12.2838 10.3777 12.1709M22.0047 5.7633C21.9777 5.74561 21.9506 5.72775 21.9237 5.70971M7.05679 24.3297C6.53504 23.61 6.2248 22.728 6.2248 21.7682C6.2248 19.3478 8.19198 17.3789 10.6105 17.3789C11.0617 17.3789 11.4988 17.4564 11.9078 17.5834V12.2486C11.4847 12.1921 11.0547 12.1567 10.6105 12.1567C10.5329 12.1567 9.90813 12.1983 9.83057 12.1983M18.8175 1H14.9959L14.9889 21.9375C14.9043 24.2803 12.9723 26.1646 10.6104 26.1646C9.14375 26.1646 7.85343 25.4377 7.04964 24.3369"
        stroke="currentColor"
        stroke-width="1.51968"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Tiktok;
