import { WalletApi } from "../api/wallet";

export function topUp(amount) {
  return WalletApi.topUp(amount);
}

export function getWallet() {
  return WalletApi.wallet();
}

export function createIntent(amount, currency) {
  return WalletApi.createIntent(amount, currency);
}
export function getTopupOptions() {
  return WalletApi.getTopupOptions();
}

export function addFunds(paymentIntent) {
  return WalletApi.addFunds(paymentIntent);
}
