import { combineReducers } from "redux";
import authReducer from "./auth";
import campaignsReducer from "./campaigns";
import rafflesReducer from "./raffles";
import statisticsReducer from "./statistics";
import transactionsReducer from "./transactions";
import usersReducer from "./users";
import offersReducer from "./offers";
import clientsReducer from "./clients";
import campaignsAdminReducer from "./campaigns-admin";
import adminStats from "./adminDash";
import searchReducer from "./searchReducer";
import errorsReducer from "./errors";
import videosReducer from "./video";
import notificationsReducer from "./notifications";
import interestStatsReducer from "./interests";
import campaignStatsReducer from "./campaign-stats";
import raffleStatsReducer from "./raffle-stats";
import postStatsReducer from "./posts";
import firstloginReducer from "./firstLogin";
import ngosReducer from "./ngos";
import igrequests from "./igrequests";

const appReducer = combineReducers({
  auth: authReducer,
  campaigns: campaignsReducer,
  raffles: rafflesReducer,
  statistics: statisticsReducer,
  transactions: transactionsReducer,
  users: usersReducer,
  offers: offersReducer,
  clients: clientsReducer,
  campaignsAdmin: campaignsAdminReducer,
  adminStats: adminStats,
  search: searchReducer,
  errors: errorsReducer,
  videos: videosReducer,
  notifications: notificationsReducer,
  interestStats: interestStatsReducer,
  campaignStats: campaignStatsReducer,
  raffleStats: raffleStatsReducer,
  postStats: postStatsReducer,
  firstlogin: firstloginReducer,
  ngos: ngosReducer,
  igrequests: igrequests,
});

const rootReducer = (state, action) => {
  if (action.type === "logout") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
