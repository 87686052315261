import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FreeAdvertising from "../../pages/home/FreeAdvertising";

function Footer({ marginTop, advertising }) {
  const history = useHistory();
  const maindata = [
    {
      id: 0,
      title: "Products",
      links: [
        {
          name: "Business",
          target: "_blank",
          link: "https://business.verbyo.com/",
        },
        { name: "Verbyo App", target: "_blank", link: "https://verbyo.com/" },
        {
          name: "Verbyo Foundation",
          target: "_blank",
          link: "https://verbyofoundation.org/",
        },
      ],
    },
    {
      id: 1,
      title: "Support",
      links: [
        {
          name: "Help center",
          target: "_blank",
          link: " https://help.verbyo.com/business",
        },
        {
          name: "Contact us",
          link: "/contact-us",
        },
        { name: "DMCA", link: "/verbyo-terms?active=dmca-policy" },
      ],
    },
    {
      id: 2,
      title: "Resources",
      links: [
        {
          name: "for Foundations",
          target: "_blank",
          link: "https://foundations.verbyo.com",
        },
        {
          name: "for Advertisers",
          target: "_blank",
          link: "https://advertisers.verbyo.com/",
        },
        {
          name: "for Content Creators",
          target: "_blank",
          link: "https://content-creators.verbyo.com/",
        },
      ],
    },
    {
      id: 3,
      title: "Company",
      links: [
        { name: "About us", link: "/about" },
        { name: "Careers", link: "/verbyo-careers" },
      ],
    },
  ];

  return (
    <div
      className={`w-full flex justify-between bg-[#262F61] ${marginTop}  px-[25px] relative`}
    >
      <img
        src="footer/ornument-circle.svg"
        alt=""
        className={`${!advertising ? "block" : "xl:hidden block"
          } absolute inset-x-0 mx-auto z-[-1] sm:top-[-193px] top-[-130px] xl:max-w-[468px] lg:max-w-[400px] sm:max-w-[350px] max-w-[266px] w-full`}
      />
      {advertising && (
        <div className="absolute inset-x-0 mx-auto z-[2] top-[-363px] w-full max-w-[1286px]">
          <FreeAdvertising />
        </div>
      )}
      <div
        className={`max-w-[1290px] bg-[#262F61]  w-full mx-auto ${advertising ? "min-h-[581px]" : ""
          } flex justify-end  sm:pt-[57px] sm:pb-[31px] py-[20px] flex-col gap-[82px] `}
      >
        <div className="w-full mx-auto flex justify-between  flex-col sm:gap-[82px] gap-[30px] ">
          <div className="max-w-[1290px] w-full flex sm:items-center justify-between   sm:gap-[40px] gap-[11px] xl:flex-row flex-col">
            <div>
              <div className="xl:max-w-[285px] max-w-[335px] w-full flex flex-col justify-center sm:gap-[14px] gap-[5px] xl:items-center">
                <button
                  onClick={() => {
                    history.push("/");
                  }}
                  className="max-w-[285px] w-full flex justify-center items-center sm:gap-[10px] gap-[5px]"
                >
                  <img
                    src="/landing/footer-logo.svg"
                    alt=""
                    className="sm:h-[38px] sm:w-[38px] h-[25px] w-[25px]"
                  />
                  <div className="w-full font-normal sm:text-[20px] text-[16px] leading-[25px] text-[#fff] ">
                    <span className="font-bold">Verbyo</span> for{" "}
                    <span className="font-bold">Business</span>
                  </div>
                </button>
                <div
                  className={` w-full text-[#D8DBFB] font-normal sm:text-[16px] text-[12px] sm:leading-[30px] leading-[20px]`}
                >
                  An organic marketing advertising campaigns platform.
                </div>
              </div>
            </div>
            <div className="sm:max-w-[781px] sm:w-full w-fit flex gap-x-[60px] gap-y-[15px] md:justify-between justify-start flex-wrap text-[#fff]  ">
              {maindata.map((items, index) => {
                return (
                  <div
                    key={index + "main"}
                    className={`max-w-[165px] w-fit flex flex-col gap-[12px] `}
                  >
                    <div className="sm:text-[20px] text-[16px] leading-[30px] font-semibold text-[#D8DBFB]">
                      {items.title}
                    </div>
                    <div className="flex flex-col gap-[2px] ">
                      <div className="w-full flex flex-col gap-[2px] justify-center ">
                        {items.links.map((item, index) => (
                          <button
                            key={index + "links"}
                            onClick={() => {
                              if (item.target === "_blank") {
                                const win = window.open(item.link, "_blank");
                                win.focus();
                              } else {
                                history.push(item.link);
                              }
                            }}
                            className="sm:text-[16px] text-start text-[14px] leading-[30px]"
                          >
                            {item.name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="ma-w-[1290px] w-full flex flex-col sm:gap-[23px] gap-[30px] ">
            <div className="w-full border-t-[0.6px] bg-[#FFFFFF] "></div>
            <div className="w-full flex justify-between flex-wrap gap-[11px]">
              <button
                onClick={() => {
                  history.push("/");
                }}
                className={` text-[#FFFFFF] sm:text-[16px] text-[14px] sm:leading-[24px] leading-[17.07px]`}
              >
                © 2019 Verbyo LLC. All rights reserved.
              </button>
              <div className="max-w-[241px] w-full flex justify-between items-center ">
                <button
                  onClick={() => {
                    history.push("/verbyo-terms?active=terms");
                  }}
                  className={` text-[#FFFFFF] sm:text-[16px] text-[14px] sm:leading-[24px] leading-[17.07px]`}
                >
                  Terms & Conditions
                </button>
                <button
                  onClick={() => {
                    history.push("/verbyo-terms?active=privacy-policy");
                  }}
                  className={` text-[#FFFFFF] sm:text-[16px] text-[14px] sm:leading-[24px] leading-[17.07px]`}
                >
                  Privacy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
