import ArticleVerbyo from "../../../assets/images/icons/article-verbyo-icon.svg";

const NotSelected = ({ type }) => {
  return (
    <article className="py-[30px] pb-[60px] px-11 max-w-[500px] w-full border-[#E6E6E6] border min-h-[600px]">
      <div className="flex items-center gap-5">
        <img src={ArticleVerbyo} />
        <h3 className="font-bold text-lg">
          {type == "advertiser"
            ? "ADVERTISER CAMPAIGNS"
            : "FOUNDATIONS & NGOs ORGANIC CAMPAIGNS"}
        </h3>
      </div>
      <p className="text-sm text=[#373535] leading-6">
        <br />
        <br />
        {type == "advertiser"
          ? `You can ask people to post your content on their social accounts
          and pay for each post individually.`
          : "We have simplified the way you launch your campaigns over social media networks. Each campaign is designed to help you to achieve your goals faster."}
        <br />
        <br />
        {type == "advertiser"
          ? `Each type of campaign is tailored to help you to achieve your
            goals for promoting your brand.`
          : "Each time a user posts a social cause on his social account, he is rewarded with Social Points. He can use these points to join Raffles and win prizes offered by advertisers."}
        {type !== "advertiser" && (
          <>
            <br />
            <br />
            Verbyo is the only platform in the world that helps foundations to
            promote their social causes for free over social media networks.
          </>
        )}
      </p>
      <br />
      <br />
      <p className="text-sm">
        <span className="font-bold">Hover </span>
        to see the details of each type of campaign and how it can help you.
      </p>

      <p className="text-sm mt-5">
        <span className="font-bold">Select </span>
        the desired type of campaign and hit Start.
      </p>
    </article>
  );
};

export default NotSelected;
