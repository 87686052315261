import React from "react";
import Checkbox from "../../Checkbox-v2/Checkbox";

const FilterMenu = ({
  filters,
  setFilters,
  setFilterMenu,
  applyFilters,
  resetFilters,
  style,
}) => {
  const [genState, setGenState] = React.useState({
    status: true,
    platform: false,
  });

  const status = [
    {
      id: "status-0",
      label: "Active",
      value: 0,
    },
    {
      id: "status-1",
      label: "Paused",
      value: 5,
    },
    {
      id: "status-2",
      label: "Finished",
      value: 3,
    },
    {
      id: "status-3",
      label: "Stopped",
      value: 6,
    },
    {
      id: "status-4",
      label: "Rejected",
      value: 2,
    },
    {
      id: "status-5",
      label: "Pending",
      value: 1,
    },
    {
      id: "status-9",
      label: "Draft",
      value: 4,
    },
  ];

  const platforms = [
    {
      id: "platform-0",
      label: "Facebook",
      value: "facebook",
    },
    {
      id: "platform-1",
      label: "Instagram",
      value: "instagram",
    },
    {
      id: "platform-2",
      label: "Twitter",
      value: "twitter",
    },
    {
      id: "platform-3",
      label: "Tiktok",
      value: "tiktok",
    },
  ];
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={style}
      className="w-[250px]  bg-white absolute overflow-auto top-12 right-5 shadow-md"
    >
      <div className="px-4 py-4 border-b flex justify-between w-full">
        <div className=" flex items-center gap-1 text-sm font-semibold">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.77"
            height="10.77"
            viewBox="0 0 10.77 10.77"
          >
            <path
              id="Icon_awesome-filter"
              data-name="Icon awesome-filter"
              d="M10.265,0H.505A.505.505,0,0,0,.148.862l3.89,3.891V9.087a.5.5,0,0,0,.215.414l1.683,1.178a.505.505,0,0,0,.794-.414V4.753L10.622.862A.505.505,0,0,0,10.265,0Z"
            />
          </svg>
          Filters
        </div>
        <svg
          className="cursor-pointer"
          onClick={() => {
            setFilterMenu(false);
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M17.5,8.507,16.493,7.5,12.5,11.493,8.507,7.5,7.5,8.507,11.493,12.5,7.5,16.493,8.507,17.5,12.5,13.507,16.493,17.5,17.5,16.493,13.507,12.5Z"
            transform="translate(-7.5 -7.5)"
          />
        </svg>
      </div>
      <div className="border-b">
        <div
          onClick={() => {
            setGenState((prev) => {
              return {
                ...prev,
                status: !prev.status,
              };
            });
          }}
          className="flex items-center hover:bg-gray-100 cursor-pointer justify-between p-5 text-sm font-semibold"
        >
          Status{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`transform transition-transform duration-300 ${
              genState.status ? "rotate-180" : ""
            }`}
            width="11.031"
            height="6.307"
            viewBox="0 0 11.031 6.307"
          >
            <path
              id="Icon_ionic-ios-arrow-down"
              data-name="Icon ionic-ios-arrow-down"
              d="M11.705,15.652l4.171-4.174a.785.785,0,0,1,1.113,0,.8.8,0,0,1,0,1.117l-4.726,4.729a.787.787,0,0,1-1.087.023L6.417,12.6a.788.788,0,0,1,1.113-1.117Z"
              transform="translate(-6.188 -11.246)"
              fill="#978e8e"
            />
          </svg>
        </div>
        {genState.status && (
          <div className="p-5 pt-0 flex flex-col gap-1">
            {status.map((item, index) => {
              return (
                <Checkbox
                  id={item.id}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    if (e.target.checked) {
                      setFilters((prev) => {
                        return {
                          ...prev,
                          status: [...prev.status, item.value],
                        };
                      });
                    } else {
                      setFilters((prev) => {
                        return {
                          ...prev,
                          status: prev.status.filter(
                            (item2) => item2 !== item.value
                          ),
                        };
                      });
                    }
                  }}
                  key={index}
                  label={item.label}
                  value={filters.status.includes(item.value)}
                  name="status"
                  v3={true}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className="border-b">
        <div
          onClick={() => {
            setGenState((prev) => {
              return {
                ...prev,
                platform: !prev.platform,
              };
            });
          }}
          className="flex items-center hover:bg-gray-100 cursor-pointer justify-between p-5 text-sm font-semibold"
        >
          Social Network{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`transform transition-transform duration-300 ${
              genState.platform ? "rotate-180" : ""
            }`}
            width="11.031"
            height="6.307"
            viewBox="0 0 11.031 6.307"
          >
            <path
              id="Icon_ionic-ios-arrow-down"
              data-name="Icon ionic-ios-arrow-down"
              d="M11.705,15.652l4.171-4.174a.785.785,0,0,1,1.113,0,.8.8,0,0,1,0,1.117l-4.726,4.729a.787.787,0,0,1-1.087.023L6.417,12.6a.788.788,0,0,1,1.113-1.117Z"
              transform="translate(-6.188 -11.246)"
              fill="#978e8e"
            />
          </svg>
        </div>
        {genState.platform && (
          <div className="p-5 pt-0 flex flex-col gap-1">
            {platforms.map((item, index) => {
              return (
                <Checkbox
                  id={item.id}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    if (e.target.checked) {
                      setFilters((prev) => {
                        return {
                          ...prev,
                          platform: [...prev.platform, item.value],
                        };
                      });
                    } else {
                      setFilters((prev) => {
                        return {
                          ...prev,
                          platform: prev.platform.filter(
                            (item2) => item2 !== item.value
                          ),
                        };
                      });
                    }
                  }}
                  key={index}
                  label={item.label}
                  value={filters.platform.includes(item.value)}
                  name="status"
                  v3={true}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className="p-5">
        <button
          onClick={applyFilters}
          className="bg-[#0D2184] hover:opacity-90 font-semibold text-sm text-white w-full py-2"
        >
          Apply
        </button>
        <button
          onClick={resetFilters}
          className="border-[#0D2184] border mt-2 hover:opacity-90 font-semibold text-[#0D2184]  w-full py-2"
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default FilterMenu;
