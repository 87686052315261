import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./loadings.module.scss";

function Loading({ size, color }) {
  return (
    <div className={cx(styles.loading, styles[size], styles[color])}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

Loading.defaultProps = {
  size: "m",
  color: "violent",
};

Loading.propTypes = {
  color: PropTypes.oneOf(["white", "violent"]),
  size: PropTypes.oneOf(["xs", "s", "m"]),
};

export default Loading;
