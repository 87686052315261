import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./cardHeader.module.scss";
import { campaignColors } from "../../../data/campaigns";
import CampaignTip from "../CampaignTip/CampaignTip";
import { Popup } from "reactjs-popup";
import TipPopUp from "../../PopUp/TipPopUp";

function CardHeader({
  title,
  subtitle,
  color,
  checked,
  disabled,
  center,
  number,
  tip,
  type,
  twoCols,
  white,
}) {
  const lightBackgrounds = [
    campaignColors[5],
    "white",
    "transparent",
    "#F9FAFF",
  ];
  const [modalShown, setModalShown] = useState(false);

  const show = () => {
    setModalShown(true);
  };

  const close = () => {
    setModalShown(false);
  };

  return (
    <div
      className={cx(
        styles.header,
        disabled && styles.disabled,
        center && styles.center,
        color && lightBackgrounds.includes(color) && styles.lightHeader,
        twoCols && styles.twoCols
      )}
      style={{ backgroundColor: color && color }}
    >
      {number && <div className={styles.number}>{number}</div>}
      <div className={styles.titles}>
        {twoCols ? (
          <div className={styles.twoCols__title}>
            {title}{" "}
            {tip && (
              <>
                <div className={styles.iconContainer} onClick={show}>
                  <span className={styles.tipIcon} />
                </div>
                <TipPopUp onClose={close} open={modalShown}>
                  <CampaignTip {...tip} close={close} />
                </TipPopUp>
              </>
            )}
            {checked && <div className={styles.checked} />}
          </div>
        ) : (
          <span
            className={`text-[#06519A] ${
              !white ? "sm:text-black" : "sm:text-white"
            } text-[24px] sm:text-[20px] text-center sm:text-left mx-auto`}
          >
            {title}
          </span>
        )}
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
      {!twoCols && checked && <div className={styles.checked} />}
      {!twoCols && tip && (
        <>
          <div className={styles.iconContainer} onClick={show}>
            <span className={styles.tipIcon} />
          </div>
          <TipPopUp onClose={close} open={modalShown}>
            <CampaignTip {...tip} close={close} />
          </TipPopUp>
        </>
      )}
    </div>
  );
}

CardHeader.defaultProps = {
  color: campaignColors[1],
  subtitle: null,
  checked: false,
  disabled: false,
  center: false,
  twoCols: false,
};

CardHeader.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  center: PropTypes.bool,
  twoCols: PropTypes.bool,
};

export default CardHeader;
