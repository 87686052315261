import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./button.module.scss";
import Loading from "../Loading/Loading";
import ArrowIcon from "../../assets/images/icons/arrow-white.svg";

function Button(props) {
  const {
    onClick,
    text,
    outline,
    size,
    color,
    disabled,
    fullWidth,
    isSubmit,
    loading,
    shadow,
    fontWeight,
    stylesAttr,
    className,
    loadingColor,
    border,
    borderColor,
    icon,
  } = props;

  return (
    <button
      style={{ ...stylesAttr }}
      className={cx(
        styles.button,
        outline && styles.outline,
        color && styles[color],
        size && styles[size],
        fullWidth && styles.fullWidth,
        shadow && styles.shadow,
        styles[fontWeight],
        className,
        border,
        "disabled:!bg-gray-300 disabled:!border-gray-300"
      )}
      onClick={onClick}
      disabled={disabled}
      type={isSubmit ? "submit" : "button"}
    >
      <div className="flex items-center justify-center gap-3">
        {loading ? <Loading size="xs" color={loadingColor || "white"} /> : text}
        {icon && <img src={ArrowIcon} alt="icon" className={styles.icon} />}
      </div>
    </button>
  );
}

Button.defaultProps = {
  onClick: null,
  outline: false,
  color: null,
  size: "m",
  disabled: false,
  fullWidth: false,
  isSubmit: false,
  loading: false,
  shadow: false,
  fontWeight: "bold",
  stylesAttr: {},
};

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  outline: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "purple",
    "green",
    "cyan",
    "pink",
    "light-green",
    "white-bordered",
    "white-shadow",
    "blue-shadow",
    "white-shadow-small",
    "blue",
  ]),
  size: PropTypes.oneOf(["l", "m", "s", "xs", "h", "sm", "md"]),
  fontWeight: PropTypes.oneOf(["bold", "regular"]),
  stylesAttr: PropTypes.object,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isSubmit: PropTypes.bool,
  loading: PropTypes.bool,
  shadow: PropTypes.bool,
};

export default Button;
