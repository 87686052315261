import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BackMobile = () => {
  const history = useHistory();
  return (
    <button
      onClick={() => {
        history.push("?settings=true");
      }}
    >
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.605 4.75999L9.99831 12.3667C9.09998 13.265 9.09998 14.735 9.99831 15.6333L17.605 23.24"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};

export default BackMobile;
