import {
  StatisticsApi,
  getDetailedCampaignStats,
  getDetailedRaffleStats,
  getDetailedPostsStats,
} from "../api/statistics";

export function getStatistics() {
  return StatisticsApi.get();
}

export function getDetailedCampaignStatistics(id) {
  return getDetailedCampaignStats.get(id);
}
export function getDetailedRaffleStatistics(id) {
  return getDetailedRaffleStats.get(id);
}
export function getDetailedPostsStatistics(id) {
  return getDetailedPostsStats.get(id);
}
