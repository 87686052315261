import React from "react";

const Brand = () => {
  return (
    <svg
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.925 20.3H5.075C4.67625 20.3 4.35 20.6263 4.35 21.025V22.475C4.35 22.8738 4.67625 23.2 5.075 23.2H23.925C24.3238 23.2 24.65 22.8738 24.65 22.475V21.025C24.65 20.6263 24.3238 20.3 23.925 20.3ZM26.825 5.8C25.6242 5.8 24.65 6.77422 24.65 7.975C24.65 8.29672 24.7225 8.59578 24.8494 8.87219L21.5688 10.8387C20.8709 11.2556 19.9692 11.02 19.5659 10.3131L15.873 3.85156C16.3578 3.45281 16.675 2.85469 16.675 2.175C16.675 0.974219 15.7008 0 14.5 0C13.2992 0 12.325 0.974219 12.325 2.175C12.325 2.85469 12.6422 3.45281 13.127 3.85156L9.43406 10.3131C9.03078 11.02 8.12453 11.2556 7.43125 10.8387L4.15516 8.87219C4.2775 8.60031 4.35453 8.29672 4.35453 7.975C4.35453 6.77422 3.38031 5.8 2.17953 5.8C0.97875 5.8 0 6.77422 0 7.975C0 9.17578 0.974219 10.15 2.175 10.15C2.29281 10.15 2.41063 10.1319 2.52391 10.1138L5.8 18.85H23.2L26.4761 10.1138C26.5894 10.1319 26.7072 10.15 26.825 10.15C28.0258 10.15 29 9.17578 29 7.975C29 6.77422 28.0258 5.8 26.825 5.8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Brand;
