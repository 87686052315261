import React from "react";
import PropTypes from "prop-types";
import { Popup as ReactPopUp } from "reactjs-popup";
import "./popup.scss";
import CloseButton from "../CloseButton/CloseButton";

function PopUp({
  open,
  onClose,
  children,
  hideCloseButton,
  lightCloseButton,
  offsetY,
  fullWidth,
  adminPopup,
  mediumWidth,
}) {
  const body = document.body;

  const onOpenHandler = () => {
    body.classList.add("popup-open");
    const popupOverlay = document.querySelector(".popup-overlay");
    popupOverlay.scrollTo(0, 0);
  };

  const onCloseHandler = () => {
    body.classList.remove("popup-open");

    onClose();
  };

  if (!open) {
    body.classList.remove("popup-open");
  }

  return (
    <ReactPopUp
      closeOnDocumentClick
      onClose={onCloseHandler}
      onOpen={onOpenHandler}
      open={open}
      lockScroll={true}
    >
      <div
        className={`popup-wrapper ${fullWidth ? "popup-wrapper__full" : ""} ${
          adminPopup ? "popup-wrapper__admin " : ""
        }`}
        style={{ transform: `translateY(${offsetY}px)` }}
      >
        {!hideCloseButton && (
          <CloseButton onClick={onCloseHandler} light={lightCloseButton} />
        )}
        {children}
      </div>
    </ReactPopUp>
  );
}

PopUp.defaultProps = {
  hideCloseButton: false,
  lightCloseButton: true,
  offsetY: null,
  fullWidth: false,
};

PopUp.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  hideCloseButton: PropTypes.bool,
  lightCloseButton: PropTypes.bool,
  offsetY: PropTypes.number,
  fullWidth: PropTypes.bool,
};
export default PopUp;
