import React from "react";

const Raffle = () => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4002 13V24.28C24.4002 24.6777 24.051 25 23.6202 25H4.38019C3.94941 25 3.60019 24.6777 3.60019 24.28V13"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.22 7H1.78C1.34922 7 1 7.32236 1 7.72V12.28C1 12.6777 1.34922 13 1.78 13H26.22C26.6508 13 27 12.6777 27 12.28V7.72C27 7.32236 26.6508 7 26.22 7Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 25V7"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0003 7H8.15027C7.28832 7 6.46166 6.68393 5.85217 6.12132C5.24268 5.55872 4.90027 4.79565 4.90027 4C4.90027 3.20435 5.24268 2.44128 5.85217 1.87868C6.46166 1.31607 7.28832 1 8.15027 1C12.7003 1 14.0003 7 14.0003 7Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 7H19.85C20.7119 7 21.5386 6.68393 22.1481 6.12132C22.7576 5.55872 23.1 4.79565 23.1 4C23.1 3.20435 22.7576 2.44128 22.1481 1.87868C21.5386 1.31607 20.7119 1 19.85 1C15.3 1 14 7 14 7Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Raffle;
