import React from "react";
import Button from "./Button";
import ProfileForm from "./ProfileForm";
import ProfileInfo from "./ProfileInfo";

const ProfileCreation = ({ form, setForm, setStep, setLogo, logo }) => {
  return (
    <div className="max-w-[600px] w-full pt-[34px] pb-[32px]  pl-[33px] pr-[36px] bg-[#fff] ">
      <p className="text-[18px] text-[#4E588D] font-semibold">
        Create your public profile
      </p>
      <p className="text-[14px] leading-[22px] mt-[10px]">
        Your foundation's profile on Verbyo App will be public. Users can review
        it before sharing your cause, fostering trust and awareness. let's do
        them on the fly brother
      </p>
      <hr className="my-6" />
      <div className="mt-10 flex gap-[36px]">
        <ProfileForm
          logo={logo}
          setLogo={setLogo}
          form={form}
          setForm={setForm}
          setStep={setStep}
        />
        <ProfileInfo logo={logo} form={form} />
      </div>
    </div>
  );
};

export default ProfileCreation;
