import { UPDATE_SEARCH_VALUE, UPDATE_FILTER_VALUE } from '../actions/types';

const defaultsState = {
  search: null,
  filter: [],
};

export default function searchReducer(state = defaultsState, action) {
  switch (action.type) {
    case UPDATE_SEARCH_VALUE:
      return {
        search: action.payload
      }
    case UPDATE_FILTER_VALUE:
      return {
        filter: action.payload
      }    
    default:
      return state;
  }
}