import {
  CITY_OPTIONS,
  COUNTRY_OPTIONS,
  COUNTY_OPTIONS,
} from "../data/locationDropdowns";

export const getSelectValue = (value, arr) => {
  if (!arr) return { value: value, label: value };
  if (!value) return null;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i].value === value) return arr[i];
  }
  return value;
};

export const getSelectedCSC = (value, arr) => {
  if (!arr) return { value: value, label: value };
  if (!value) return null;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i].label === value) return arr[i];
  }
  return value;
};

export const getCountryLabelById = (countryId) => {
  return COUNTRY_OPTIONS.find((item) => countryId === item.value).label;
};
export const getStateLabelById = (stateId) => {
  return COUNTY_OPTIONS.find((item) => stateId === item.value).label;
};
export const getCityLabelById = (cityId) => {
  return CITY_OPTIONS.find((item) => cityId === item.value).label;
};

export const getStatesData = (countryId) => {
  let counties = [...COUNTY_OPTIONS];
  return counties.filter((item) => countryId === item.country_id);
};

export const getStateDataByName = (countryName) => {
  const country = COUNTRY_OPTIONS.find(
    (country) => country.label === countryName
  );

  return getStatesData(country.value);
};

export const getCityDataByName = (stateName) => {
  if (stateName === "all") {
    return [
      {
        label: "all",
        value: "all",
      },
    ];
  }
  const state = COUNTY_OPTIONS.find((county) => county.label === stateName);

  return getCityData(state.value);
};

export const getCityData = (stateId, cId) => {
  const cities = [...CITY_OPTIONS];
  return cities.filter(
    (item) => stateId === item.state_id || (cId == 238 && item.state_id == cId)
  );
};

export const getOption = (options, value) => {
  const option = options.find(
    (item) =>
      item.value.toLowerCase() === value.toLowerCase() ||
      item.label.toLowerCase() === value.toLowerCase()
  );
  if (option) {
    return option;
  }
  return null;
};

export const getFileType = (url) => {
  if (url.match(/\.(jpg|jpeg|gif|png|tiff|bmp|eps|raw)$/) != null) {
    return "image";
  }
  if (
    url.match(
      /\.(mp4|video|3gp|ogg|webm|mpg|mp2|mpeg|mpe|mpv|m4p|m4v|avi|wmv|mov|qt|flv|swf)$/
    ) != null
  ) {
    return "video";
  }
  if (url.match(/\.(yt)$/) != null) {
    return "id";
  }
  if (url.match(/\.(link)$/) != null) {
    return "link";
  }
  return "";
};
