
import NewButton from "../Button/NewButton";
import Titleandparagraph from "./CommonTitlePara";
import VideoButton from "../Button/VideoButton";

function VerbyoCommon({
  btnText,
  handleClick,
  videoBtnHref,
  videoBtnText,
  title,
  paragraph,
  stylesForDiv,
  otherComponent,
  trustedbussiness
}) {
  const trustedBuisnessData = [
    "Trusted by 112,000 people",
    "Over 1,302 offers to post"
  ]
  return (
    <div
      className={`max-w-[1280px] bg-[#EFF0FC] w-full mx-auto rounded-[16px]  sm:pt-[50px] pt-[40px] lg:mt-[60px] mt-[100px] ${stylesForDiv} `}
    >
      <div className="flex flex-col gap-[35px]">
        <div className="2xl:px-0 px-[20px]">

          <Titleandparagraph
            title={title}
            para={paragraph}
            stylesForPara={"sm:mt-[22px] mt-[25px] sm:text-[18px] sm:leading-[30px] leading-[24px]"}
            stylesForMain={
              title == "Verbyo App" ? "max-w-[1036px]" : "max-w-[935px]"
            }
          />
        </div>
        <div className={`flex gap-[16px] flex-wrap items-center justify-center sm:px-0 px-[80.5px] ${!trustedbussiness ? "sm:pb-[50px] pb-[41px]" : "sm:pb-[19px] pb-[22.5px]"}`}>
          <NewButton handleClick={handleClick} text={btnText} styles={"max-w-[184px] "} />
          <VideoButton
            text={videoBtnText}
            href={videoBtnHref}
            styles={"max-w-[184px] "}
          />
        </div>
      </div>

      {trustedbussiness && (
        <div className="pb-[50px] flex sm:gap-[20px] gap-[10px] items-center justify-center ">
          {trustedBuisnessData.map((item, index) => (
            <div key={index + "trusted"} className="flex gap-[5px] items-center">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.3 8.3L8.825 4.775L8.125 4.075L5.3 6.9L3.875 5.475L3.175 6.175L5.3 8.3ZM6 11C5.30833 11 4.65833 10.8687 4.05 10.606C3.44167 10.3433 2.9125 9.98717 2.4625 9.5375C2.0125 9.0875 1.65633 8.55833 1.394 7.95C1.13167 7.34167 1.00033 6.69167 1 6C1 5.30833 1.13133 4.65833 1.394 4.05C1.65667 3.44167 2.01283 2.9125 2.4625 2.4625C2.9125 2.0125 3.44167 1.65633 4.05 1.394C4.65833 1.13167 5.30833 1.00033 6 1C6.69167 1 7.34167 1.13133 7.95 1.394C8.55833 1.65667 9.0875 2.01283 9.5375 2.4625C9.9875 2.9125 10.3438 3.44167 10.6065 4.05C10.8692 4.65833 11.0003 5.30833 11 6C11 6.69167 10.8687 7.34167 10.606 7.95C10.3433 8.55833 9.98717 9.0875 9.5375 9.5375C9.0875 9.9875 8.55833 10.3438 7.95 10.6065C7.34167 10.8692 6.69167 11.0003 6 11Z"
                  fill="#057DF3"
                />
              </svg>
              <p className={`whitespace-nowrap text-[12px] leading-[18px]`}>{item}</p>
            </div>
          ))}
        </div>
      )}
      {otherComponent &&
        <div className={`w-fit mx-auto`}>
          {otherComponent}
        </div>}
    </div>
  );
}

export default VerbyoCommon;
