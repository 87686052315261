import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import parse from "html-react-parser";
import "./tipPopup.scss";

import styles from "./campaignTip.module.scss";
import Logo from "../../Logo/Logo";
import IconQuestion from "../../../assets/images/icons/icon-question-mark.svg";
import { Popup } from "reactjs-popup";

function CampaignTip({ content, title, close }) {
  return (
    <div className={cx(styles.tip)}>
      <div className={styles.content}>
        <div className={styles.headerIcon}>
          <img src={IconQuestion} alt="tip" />
        </div>
        <button className={styles.popupCloseButton} onClick={close}>
          <span>&#215;</span>
        </button>

        <h3 className="center">{parse(title)}</h3>
        {content && content}
      </div>
    </div>
  );
}

CampaignTip.defaultProps = {
  icon: "help",
  content: null,
  headerVariant: "v1",
  title: "Tips & Tricks",
};

CampaignTip.propTypes = {
  content: PropTypes.element,
  title: PropTypes.string,
  close: PropTypes.func,
};

export default CampaignTip;
