import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useHistory } from "react-router-dom";
import cx from "classnames";

import Button from "../Button/Button";
import Checkbox from "../Checkbox-v2/Checkbox";
import FooterMenu from "../FooterMenu/FooterMenu";
import Logo from "../Logo/Logo";
import Link from "../Button/Link";
import globals, { LOGIN_PATH, SIGN_UP_PATH } from "../../globals";
import styles from "./form.module.scss";
import VerbyoLogo from "../../assets/images/Hexagon Verbyo abastru cu alb (2).png";
import classNames from "classnames";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import {
  signInWithGoogle,
  signUpWithGoogle,
} from "../../services/userAuthService";

import BusinessLogo from "../Logos/Business";
import CheckBoxV2 from "../common/CheckBoxV2";

export const validateEmailUtil = (email) => {
  const re =
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  return re.test(email);
};

function Form({
  inputs,
  checkboxes,
  link,
  message,
  submit,
  remember,
  title,
  subtitle,
  onSubmit,
  error,
  popupMessage,
  linkIcon,
  recover,
  contact,
  sent,
  signUp,
  login,
  popup,
  mobile,
  registration,
  agree,
  aftertitle,
  googleSignUp,
  googleSignIn,
}) {
  const [answers, setAnswers] = useState({});
  const [validates, setValidates] = useState({});
  const [hidePopup, setHidePopup] = useState(false);

  const required = [];
  const history = useHistory();

  const validateEmail = (email) => {
    const isEmailValid = validateEmailUtil(email);
    setValidates({ ...validates, email: isEmailValid });
  };

  const validatePassword = (value) => {
    const isRePasswordValid =
      answers.rePassword && answers.rePassword === value;
    setValidates({ ...validates, rePassword: isRePasswordValid });
  };

  const validateRePassword = (value) => {
    const isRePasswordValid = answers.password && answers.password === value;
    setValidates({ ...validates, rePassword: isRePasswordValid });
  };

  const validateNewPassword = (value) => {
    const isRePasswordValid =
      answers.confirmPassword && answers.confirmPassword === value;
    setValidates({ ...validates, confirmPassword: isRePasswordValid });
  };
  const [rerender, setRerender] = useState(false);

  const validateConfirmPassword = (value) => {
    const isRePasswordValid =
      answers.newPassword && answers.newPassword === value;
    setValidates({ ...validates, confirmPassword: isRePasswordValid });
  };

  const getInput = (input, i) => {
    let type = "";
    let placeholder = "";
    let classStyleName = "";
    let validation = null;
    let validationStyles = false;
    let errorMessage = "";

    switch (input) {
      case "email":
        type = "email";
        placeholder = "Enter your email";
        classStyleName = "email";
        validation = validateEmail;
        validationStyles = true;
        errorMessage = "Invalid email address";
        break;
      case "password":
        type = "password";
        placeholder = "Enter your password";
        classStyleName = "password";
        validation = inputs.includes("rePassword") && validatePassword;
        break;
      case "rePassword":
        type = "password";
        placeholder = "Retype The Password";
        classStyleName = "password";
        validation = inputs.includes("rePassword") && validateRePassword;
        validationStyles = true;
        errorMessage = "Passwords do not match";
        break;
      case "newPassword":
        type = "password";
        placeholder = "Enter your new password";
        classStyleName = "password";
        validation = validateNewPassword;
        break;
      case "confirmPassword":
        type = "password";
        placeholder = "Enter your password (again)";
        classStyleName = "password";
        validation = validateConfirmPassword;
        validationStyles = true;
        errorMessage = "Passwords do not match";
        break;
      case "name":
        type = "text";
        placeholder = "Enter your name";
        classStyleName = "email";
        break;
      default:
        type = "text";
        break;
    }
    required.push(input);

    const isInvalid =
      validation && validationStyles && answers[input]
        ? !validates[input]
        : false;

    return (
      <div className=" pb-8 relative " key={i}>
        <div className="relative">
          <input
            className="relative  w-full max-h-[50px] outline-none  border border-[#D8DBFB] bg-white py-[16.5px] pl-[18px] rounded-[6px] text-[14px] leading-[17.7px] font-medium"
            style={
              {
                // borderRadius:"6px",
                // border: "1px solid #D8DBFB",
                // borderBottom:
                //   window.innerWidth > 456
                //     ? "2px solid #E9E9F0"
                //     : "2px solid #E9E9F0",
              }
            }
            type={type}
            placeholder={placeholder}
            id={input}
            value={answers[input] || ""}
            onChange={(e) => {
              validation && validation(e.target.value);

              setAnswers({
                ...answers,
                [input]: e.target.value,
              });
            }}
            required
          />
          {type === "password" && (
            <button
              type="button"
              onClick={() => {
                setRerender(!rerender);
                document.getElementById(input).type =
                  document.getElementById(input).type === "password"
                    ? "text"
                    : "password";
              }}
              className="absolute right-4 top-[18px]  hover:opacity-50 opacity-80 text-black"
            >
              {document.getElementById(input)?.type === "password" ? (
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.426 1L15.5737 15M6.80549 6.37728C6.3947 6.79724 6.1419 7.36938 6.1419 8C6.1419 9.2887 7.19761 10.3333 8.49983 10.3333C9.1463 10.3333 9.73202 10.0759 10.1579 9.659M4.17694 3.83667C2.68412 4.81138 1.54706 6.2764 1 8C2.00154 11.1555 4.98066 13.4444 8.49999 13.4444C10.0632 13.4444 11.5199 12.9929 12.744 12.2143M7.71385 2.59397C7.97243 2.56857 8.23472 2.55556 8.49999 2.55556C12.0194 2.55556 14.9985 4.84449 16 8C15.7794 8.69533 15.4626 9.34851 15.0653 9.94444"
                    stroke="#C4C4C4"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.426 1L15.5737 15M6.80549 6.37728C6.3947 6.79724 6.1419 7.36938 6.1419 8C6.1419 9.2887 7.19761 10.3333 8.49983 10.3333C9.1463 10.3333 9.73202 10.0759 10.1579 9.659M4.17694 3.83667C2.68412 4.81138 1.54706 6.2764 1 8C2.00154 11.1555 4.98066 13.4444 8.49999 13.4444C10.0632 13.4444 11.5199 12.9929 12.744 12.2143M7.71385 2.59397C7.97243 2.56857 8.23472 2.55556 8.49999 2.55556C12.0194 2.55556 14.9985 4.84449 16 8C15.7794 8.69533 15.4626 9.34851 15.0653 9.94444"
                    stroke="#C4C4C4"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </button>
          )}
        </div>
        {isInvalid && errorMessage && (
          <span className={cx(styles.error, "absolute", "-bottom-3", "left-0")}>
            {errorMessage}
          </span>
        )}
      </div>
    );
  };

  const getCheckboxes = (checkbox, i) => {
    if (checkbox === "agree") {
      required.push(checkbox);
      return (
        <CheckBoxV2
          key={i}
          id="terms"
          v4={true}
          value={answers[checkbox] || false}
          onChange={() =>
            setAnswers({ ...answers, [checkbox]: !answers[checkbox] })
          }
          label="I agree with terms and conditions"
        />
      );
    } else if (checkbox === "newsletter") {
      return (
        <Checkbox
          key={i}
          id="newsletter"
          v4={true}
          color="grey"
          value={answers[checkbox] || false}
          onChange={() =>
            setAnswers({ ...answers, [checkbox]: !answers[checkbox] })
          }
          label="I agree with terms and conditions"
        />
      );
    }
  };

  const isFormCompleted = () => {
    const isInvalidField = Object.values(validates).some((value) => !value);

    return required.some((field) => !answers[field] || isInvalidField);
  };

  const handlePopUp = () => {
    setAnswers({});
    setHidePopup(true);
    history.push(LOGIN_PATH);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    onSubmit && onSubmit(answers);
  };

  const resetPage = window.location.pathname.includes("reset-password");

  const loginGoogle = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      signInWithGoogle({
        token: credentialResponse.access_token,
      }).then((res) => {
        googleSignIn(res);
      });
    },
  });

  const registerGoogle = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      signUpWithGoogle({
        token: credentialResponse.access_token,
      }).then((res) => {
        googleSignUp(res);
      });
    },
  });

  useEffect(() => {
    setHidePopup(!popupMessage);
  }, [popupMessage]);

  return (
    <div className="flex flex-col inset-0 m-auto px-4  absolute max-w-[600px] max-h-[661px] sm:gap-[50px] gap-[30px] items-center w-full ">
      <button
        onClick={() => {
          history.push("/");
        }}
        className="max-w-[250px] w-full flex justify-center items-center gap-[10px]"
      >
        <img width={40} height={40} src={"/landing/logo.svg"} />
        {/* Name */}
        <div className="font-normal leading-[24.5px]">
          {" "}
          <span className="font-bold sm:text-[20px] text-[18px]">
            Verbyo
          </span>{" "}
          <span>for</span>{" "}
          <span className="font-bold sm:text-[20px] text-[18px]">Business</span>{" "}
        </div>
      </button>
      <div className="bg-[#EFF0FC] sm:px-[85px] px-[30px]  sm:pt-[54px] pt-[35px] sm:pb-[38px] pb-[26px]  h-fit  rounded-[16px] max-w-[600px] w-full ">
        <p className="text-[#1C1934] sm:text-[28px] text-[24px] leading-[38.13px] text-center font-bold ">
          {title}
        </p>
        <div className={classNames(styles.inner, "w-full")}>
          {popupMessage && !hidePopup && (
            <div className={cx(styles.popup)}>
              <div className="flex flex-col relative bottom-[70px] items-center">
                <BusinessLogo />
              </div>
              <div
                className={cx(styles.popupText, "max-w-[650px]", "mx-auto")}
                dangerouslySetInnerHTML={{ __html: popupMessage }}
              />
              <div className="mx-auto">
                <Button
                  color="blue"
                  size="md"
                  text="OK"
                  onClick={handlePopUp}
                />
              </div>
            </div>
          )}
          <form
            className={cx(
              // styles.form,
              contact ? styles.form__contact : "",
              "w-full"
            )}
          >
            {error && (
              <div
                className={cx(
                  "warning",
                  "warning--icon-small",
                  styles.errorMessage
                )}
              >
                {error}
              </div>
            )}
            {
              <div
                className={`flex flex-col items-center justify-center gap-2 md:pb-7 lg:pb-10 pb-4 `}
              >
                <p className=" text-sm font-normal text-center text-[#4B4B4B] mt-[14px]">
                  {submit == "Log In"
                    ? ""
                    : submit == "Create Account"
                      ? ""
                      : resetPage
                        ? "Please enter a new password for your account."
                        : "Enter the email address you used to sign up with us and we will send you a reset link."}
                </p>
              </div>
            }

            {subtitle && (
              <div
                className={cx(
                  styles.subtitle,
                  (recover || sent) && styles.subtitle_rec
                )}
              >
                {subtitle}
              </div>
            )}
            {sent && <div className={styles.subtitle}>You message sent</div>}
            <div className={`${error ? "mt-[-45px]" : "mt-[15px]"}`}>
              {!recover &&
                !sent &&
                inputs.map((input, i) => getInput(input, i))}
            </div>
            {!sent && message && (
              <textarea
                name="message"
                id="message"
                placeholder="Your message"
                cols="30"
                rows="10"
                value={answers["message"] || ""}
                onChange={(e) =>
                  setAnswers({ ...answers, message: e.target.value })
                }
              />
            )}
            {remember && (
              <div className="flex gap-[15px] justify-center flex-wrap sm:justify-between items-center pb-[33px]">
                <CheckBoxV2
                  id="rememberMe"
                  label="Keep me logged in"
                  v4={true}
                  color="#4B4B4B"
                  value={answers["rememberMe"] || false}
                  onChange={() =>
                    setAnswers({
                      ...answers,
                      ["rememberMe"]: !answers["rememberMe"],
                    })
                  }
                  mb0={true}
                />
                <button
                  type="button"
                  className="text-[#057DF3] font-medium text-[14px]"
                  onClick={() => {
                    history.push(globals.PASSWORD_RECOVERY_PATH);
                  }}
                >
                  Forgot password
                </button>
              </div>
            )}

            {checkboxes && (
              <div className={styles.checkboxes}>
                {checkboxes.map((checkbox, i) => getCheckboxes(checkbox, i))}
              </div>
            )}
            {aftertitle && (
              <div className={styles.aftertitle}>{aftertitle}</div>
            )}
            {recover || sent ? (
              // <Link
              //   color="blue"
              //   size="md"
              //   text="Go Back"
              //   to={globals.LOGIN_PATH}
              //   className={styles.back}
              // />
              ""
            ) : (
              <div className="w-full md:gap-2 flex flex-col items-center justify-center">
                <button
                  onClick={submitHandler}
                  disabled={isFormCompleted()}
                  className=" transition-all duration-300 ease-in bg-[#057DF3] hover:bg-[#0554f3]  rounded-[6px]   font-semibold   w-full justify-center items-center  flex min-h-[44px] disabled:bg-gray-300 text-white mx-auto outline-none text-base"
                >
                  {submit === "Create Account"
                    ? submit
                    : submit == "Log In"
                      ? submit
                      : resetPage
                        ? submit
                        : "Send Email"}
                </button>
                {(submit == "Log In" || submit == "Create Account") && (
                  <button
                    onClick={() => {
                      if (submit == "Create Account") {
                        registerGoogle();
                      } else {
                        loginGoogle();
                      }
                    }}
                    type="button"
                    className="w-full rounded-[12px]  text-[16px] hover:bg-[#ddd] transition-all duration-300 ease-in-out justify-center gap-[6px] bg-white items-center  flex min-h-[44px] mt-[15px]  font-semibold    mx-auto outline-none   text-base"
                  >
                    <img src="/google-icon.png" alt="google" />
                    Continue with Google
                  </button>
                )}
                {/* {submit == "Log In" && !(window.innerWidth < 456) && (
                <Button
                  color="white"
                  size={window.innerWidth < 456 ? "lg" : "md"}
                  text={submit == "Log In" ? "Sign up" : "Log In"}
                  isSubmit={true}
                  onClick={() => {
                    if (submit == "Log In") {
                      history.push(SIGN_UP_PATH);
                    } else {
                      history.push(LOGIN_PATH);
                    }
                  }}
                  className={styles.submit}
                  stylesAttr={{
                    border: "1px solid #4D4F5C",
                    color: "#4D4F5C",
                    selfAlign: "center",
                  }}
                />
              )} */}

                {/* {submit == "Create Account" && (
                <div>
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      signUpWithGoogle({
                        token: credentialResponse.credential,
                      }).then((res) => {
                        googleSignUp(res);
                      });
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>
              )} */}
                {/* {submit == "Log In" && (
                <div>
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      signInWithGoogle({
                        token: credentialResponse.credential,
                      }).then((res) => {
                        googleSignIn(res);
                      });
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>
              )} */}
              </div>
            )}
            {/* {signUp && (
            <Link
              fontWeight="bold"
              color="white-shadow"
              size="l"
              text="Sign Up"
              to={globals.SIGN_UP_PATH}
            />
          )}
          {login && (
            <Link
              fontWeight="bold"
              color="white-shadow"
              size="l"
              text="Log In"
              to={globals.LOGIN_PATH}
            />
          )} */}
          </form>
          <div className="flex justify-center">
            <button
              onClick={() => {
                history.push(
                  submit === "Create Account"
                    ? globals.LOGIN_PATH
                    : submit == "Log In"
                      ? globals.SIGN_UP_PATH
                      : globals.LOGIN_PATH
                );
              }}
              className="w-fit  text-center mt-[29px] font-medium text-[14px] leading-[17px] text-[#057DF3]"
            >
              {submit === "Create Account"
                ? "Login"
                : submit == "Log In"
                  ? "Create your account"
                  : "back to Login"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Form.defaultProps = {
  title: null,
  subtitle: null,
  message: false,
  link: null,
  linkIcon: null,
  remember: false,
  checkboxes: null,
  onSubmit: null,
  error: null,
  popupMessage: null,
  recover: false,
  contact: false,
  sent: false,
  signUp: false,
  login: false,
  popup: false,
  registration: false,
  agree: false,
  aftertitle: null,
};

Form.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  inputs: PropTypes.arrayOf(
    PropTypes.oneOf([
      "email",
      "password",
      "rePassword",
      "newPassword",
      "confirmPassword",
      "name",
    ])
  ).isRequired,
  checkboxes: PropTypes.arrayOf(PropTypes.oneOf(["agree", "newsletter"])),
  message: PropTypes.bool,
  submit: PropTypes.string.isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  linkIcon: PropTypes.shape({
    icon: PropTypes.oneOf(["close"]),
    to: PropTypes.string.isRequired,
  }),
  remember: PropTypes.bool,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  popupMessage: PropTypes.string,
  recover: PropTypes.bool,
  contact: PropTypes.bool,
  sent: PropTypes.bool,
  signUp: PropTypes.bool,
  login: PropTypes.bool,
  popup: PropTypes.bool,
  mobile: PropTypes.bool,
  registration: PropTypes.bool,
  agree: PropTypes.bool,
  aftertitle: PropTypes.string,
};
export default Form;
