const SMP_PATH = "/social-media-platforms";
const MC_PATH = "/marketing-campaigns";
const ORGANIC_DISTRIBUTION_PATH = "/organic-distribution-marketing-campaign";
const CONTENT_CREATION_PATH = "/content-creation";
const FAQ_PATH = "/faq";
const ABOUT_PATH = "/about";
const VERBYO_APP_PATH = "/verbyo-app";
// const TERMS_PATH = "/terms-and-conditions";
// const PRIVACY_POLICY_PATH = "/privacy-policy";
//
const TERMS_PATH = "/terms";
const PRIVACY_POLICY_PATH = "/privacy";
const GDPR_PATH = "/gdpr";
const LOGIN_PATH = "/login";
const SIGN_UP_PATH = "/sign-up";
const ACTIVATE_ACCOUNT_PATH = "/activate-new-user";
const PASSWORD_RECOVERY_PATH = "/password-recovery";
const NEW_PASSWORD_PATH = "/reset-password";
const ACTIVATE_EMAIL_PATH = "/activate-new-email";
const CONTACT_US_PATH = "/contact-us";
const CAMPAIGN_DETAILS_PATH = "/campaign-details";
const RAFFLE_DETAILS_PATH = "/raffle-details";
const WE_CARE_PATH = "/we-care";
const PRICING_PATH = "/pricing";
const DASHBOARD_PATH = "/dashboard";
const CHOOSE_ACCOUNT_PATH = "/choose-account";
const DASHBOARD_CAMPAIGNS_PATH = "/dashboard/my-campaigns";
const START_CAMPAIGN = "/dashboard/start-campaign";
const DASHBOARD_RAFFLES_PATH = "/dashboard/my-raffles";
const DASHBOARD_SETTINGS_PATH = "/dashboard/settings";
const DASHBOARD_SETTINGS_DETAILS_PATH = "/dashboard/settings/details";
const DASHBOARD_SETTINGS_ACTIVITY_PATH = "/dashboard/settings/history";
const DASHBOARD_SETTINGS_WALLET_PATH = "/dashboard/settings/wallet";
const DASHBOARD_SETTINGS_TRANSACTIONS_PATH = "/dashboard/settings/transactions";
const DASHBOARD_SETTINGS_SECURITY_PATH = "/dashboard/settings/password";
const DASHBOARD_SETTINGS_SUPPORT_PATH = "/dashboard/settings/support";
const DASHBOARD_NOTIFICATIONS_PATH = "/dashboard/notifications";
const DASHBOARD_VIDEO_RESOURCES_PATH = "/dashboard/video-recources";
const RAFFLE_DETAIL_PATH = "/raffles/:id";
const DASHBOARD_CAMPAIGN_1 = "/dashboard/organic-distribution-campaign";
const DASHBOARD_CAMPAIGN_2 =
  "/dashboard/content-creation-distribution-campaign";
const DASHBOARD_CAMPAIGN_3 = "/dashboard/create-social-media-content-campaign";
const DASHBOARD_CAMPAIGN_4 = "/dashboard/free-humanitarian-campaign";
const DASHBOARD_CAMPAIGN_5 = "/dashboard/raffle";
const DASHBOARD_CAMPAIGN_IG = "/dashboard/instagram-campaign";
const DASHBOARD_CAMPAIGN_YT = "/dashboard/youtube-campaign";
const DASHBOARD_CAMPAIGN_BL = "/dashboard/blog-campaign";
const DASHBOARD_CAMPAIGN_GM = "/dashboard/gaming-campaign";
const ADMIN_PATH = "/admin";
const ADMIN_USERS_PATH = "/admin/users";
const ADMIN_USERSOCIALS_PATH = "/admin/usersocials";
const ADMIN_CLIENTS_PATH = "/admin/clients";
const ADMIN_CAMPAIGNS_PATH = "/admin/campaigns";
const ADMIN_OFFERS_PATH = "/admin/offers";
const ADMIN_FINANCIALS_PATH = "/admin/financials";
const ADMIN_COMPANIES_PATH = "/admin/companies";
const ADMIN_USER_VERIFICATION_PATH = "/admin/pending-user-verification";
const ADMIN_USER_POSTS = "/admin/user-posts";
const ADMIN_STATS_PATH = "/admin/interest-statistics";
const ADMIN_USER_DETAILS_PATH = "/admin/user-details/";
const TWITTER_LINK = "https://twitter.com/verbyoapp";
const INSTAGRAM_LINK = "https://www.instagram.com/verbyo";
const FACEBOOK_LINK = "https://m.me/verbyo";
const MAIL_PATH = "business@verbyo.com";
const DASHBOARD_REDIRECT = LOGIN_PATH;

const CAMPAIGN_CREATE_IDS = {
  choosePlatform: "choose-platform",
  campaignDetails: "campaign-details",
  defineAudience: "define-audience",
  createScript: "create-script",
  adRequirements: "ad-requirements",
  budgetPayment: "budget-payment",
  designAdd: "design-add",
  contentDetails: "content-details",
};
const PLATFORM_OPTIONS = [
  { value: "facebook", label: "Facebook" },
  { value: "instagram", label: "Instagram" },
  // { value: "twitter", label: "Twitter" },
  { value: "tiktok", label: "Tiktok" },
];

const VIDEO_OPTIONS = [
  { value: "30sec", label: "30 sec" },
  { value: "1min", label: "1 min" },
  { value: "3min", label: "3 mins" },
  // {value: '5min', label: '5 minutes'}
];

const TIKTOK_OPTIONS = [
  { value: "normal", label: "Normal" },
  // { value: "duet", label: "Duet" },
];

const PLATFORMS = [
  "instagram",
  "facebook",
  "twitter",
  "tiktok",
  "facebook-story",
  "instagram-story",
  "twitter-story",
  "tiktok-story",
];

const STATUSES = [
  "active",
  "pending",
  "rejected",
  "finished",
  "draft",
  "paused",
  "stopped",
  "deleted",
  "scheduled",
];

// const API = "https://business.verbyo.com/api";
// const API = process.env.REACT_APP_API || "http://localhost:3456/api";
// const API = process.env.REACT_APP_API || "https://api-business.verbyo.com/api";
const API =
  process.env.REACT_APP_API || "https://staging-api-business.verbyo.com/api";
// const API = "http://3.226.158.163/api";
// const API = "https://verbyo-mock.herokuapp.com/api";
// const API = "https://staging-business-api.verbyo.com/api";
// const API = "http://46.101.247.148:3459/api"

const TOKEN_COOKIE_NAME = "jwt_token";
const ACCOUNT_TYPE_COOKIE = "ACCOUNT_TYPE_COOKIE";
const CAMPAIGN_LAYOUT = {
  accordion: "accordion",
  block: "block",
  columns: "columns",
};

const SLIDE_OPTIONS = {
  contentCreator: "content-creator",
  ngo: "ngo",
  advertiser: "advertiser",
  how: "how",
  people: "people",
  organic: "organic",
  scripted: "scripted",
  analytics: "analitics",
  privacyPolicy: "privacy-policy",
  termsConditions: "terms-conditions",
  createAnAccount: "create-an-account",
  writeUs: "write-us",
};

const AC_TYPE = {
  advertiser: "Advertiser",
  content_creator: "Content Creator",
  ngo: "NGO",
};

module.exports = {
  ADMIN_USER_POSTS,
  RAFFLE_DETAILS_PATH,
  SMP_PATH,
  MC_PATH,
  START_CAMPAIGN,
  ORGANIC_DISTRIBUTION_PATH,
  CONTENT_CREATION_PATH,
  FAQ_PATH,
  ABOUT_PATH,
  VERBYO_APP_PATH,
  TERMS_PATH,
  PRIVACY_POLICY_PATH,
  GDPR_PATH,
  LOGIN_PATH,
  SIGN_UP_PATH,
  ACTIVATE_ACCOUNT_PATH,
  PASSWORD_RECOVERY_PATH,
  ACTIVATE_EMAIL_PATH,
  NEW_PASSWORD_PATH,
  CONTACT_US_PATH,
  WE_CARE_PATH,
  DASHBOARD_PATH,
  CHOOSE_ACCOUNT_PATH,
  DASHBOARD_CAMPAIGNS_PATH,
  DASHBOARD_RAFFLES_PATH,
  RAFFLE_DETAIL_PATH,
  DASHBOARD_SETTINGS_PATH,
  DASHBOARD_SETTINGS_DETAILS_PATH,
  DASHBOARD_SETTINGS_ACTIVITY_PATH,
  DASHBOARD_SETTINGS_WALLET_PATH,
  DASHBOARD_SETTINGS_TRANSACTIONS_PATH,
  DASHBOARD_SETTINGS_SECURITY_PATH,
  DASHBOARD_SETTINGS_SUPPORT_PATH,
  DASHBOARD_NOTIFICATIONS_PATH,
  DASHBOARD_VIDEO_RESOURCES_PATH,
  DASHBOARD_CAMPAIGN_1,
  DASHBOARD_CAMPAIGN_2,
  DASHBOARD_CAMPAIGN_3,
  DASHBOARD_CAMPAIGN_4,
  DASHBOARD_CAMPAIGN_5,
  DASHBOARD_CAMPAIGN_IG,
  DASHBOARD_CAMPAIGN_YT,
  DASHBOARD_CAMPAIGN_BL,
  CAMPAIGN_DETAILS_PATH,
  DASHBOARD_CAMPAIGN_GM,
  DASHBOARD_REDIRECT,
  ADMIN_PATH,
  ADMIN_USERS_PATH,
  ADMIN_USERSOCIALS_PATH,
  ADMIN_CLIENTS_PATH,
  ADMIN_CAMPAIGNS_PATH,
  ADMIN_OFFERS_PATH,
  ADMIN_FINANCIALS_PATH,
  ADMIN_COMPANIES_PATH,
  TWITTER_LINK,
  INSTAGRAM_LINK,
  FACEBOOK_LINK,
  MAIL_PATH,
  CAMPAIGN_CREATE_IDS,
  PLATFORMS,
  API,
  TOKEN_COOKIE_NAME,
  STATUSES,
  PRICING_PATH,
  ACCOUNT_TYPE_COOKIE,
  CAMPAIGN_LAYOUT,
  PLATFORM_OPTIONS,
  VIDEO_OPTIONS,
  SLIDE_OPTIONS,
  AC_TYPE,
  TIKTOK_OPTIONS,
  ADMIN_STATS_PATH,
  ADMIN_USER_DETAILS_PATH,
  ADMIN_USER_VERIFICATION_PATH,
};

const classes = [
  "w-[1%]",
  "w-[2%]",
  "w-[3%]",
  "w-[4%]",
  "w-[5%]",
  "w-[6%]",
  "w-[7%]",
  "w-[8%]",
  "w-[9%]",
  "w-[10%]",
  "w-[11%]",
  "w-[12%]",
  "w-[13%]",
  "w-[14%]",
  "w-[15%]",
  "w-[16%]",
  "w-[17%]",
  "w-[18%]",
  "w-[19%]",
  "w-[20%]",
  "w-[21%]",
  "w-[22%]",
  "w-[23%]",
  "w-[24%]",
  "w-[25%]",
  "w-[26%]",
  "w-[27%]",
  "w-[28%]",
  "w-[29%]",
  "w-[30%]",
  "w-[31%]",
  "w-[32%]",
  "w-[33%]",
  "w-[34%]",
  "w-[35%]",
  "w-[36%]",
  "w-[37%]",
  "w-[38%]",
  "w-[39%]",
  "w-[40%]",
  "w-[41%]",
  "w-[42%]",
  "w-[43%]",
  "w-[44%]",
  "w-[45%]",
  "w-[46%]",
  "w-[47%]",
  "w-[48%]",
  "w-[49%]",
  "w-[50%]",
  "w-[51%]",
  "w-[52%]",
  "w-[53%]",
  "w-[54%]",
  "w-[55%]",
  "w-[56%]",
  "w-[57%]",
  "w-[58%]",
  "w-[59%]",
  "w-[60%]",
  "w-[61%]",
  "w-[62%]",
  "w-[63%]",
  "w-[64%]",
  "w-[65%]",
  "w-[66%]",
  "w-[67%]",
  "w-[68%]",
  "w-[69%]",
  "w-[70%]",
  "w-[71%]",
  "w-[72%]",
  "w-[73%]",
  "w-[74%]",
  "w-[75%]",
  "w-[76%]",
  "w-[77%]",
  "w-[78%]",
  "w-[79%]",
  "w-[80%]",
  "w-[81%]",
  "w-[82%]",
  "w-[83%]",
  "w-[84%]",
  "w-[85%]",
  "w-[86%]",
  "w-[87%]",
  "w-[88%]",
  "w-[89%]",
  "w-[90%]",
  "w-[91%]",
  "w-[92%]",
  "w-[93%]",
  "w-[94%]",
  "w-[95%]",
  "w-[96%]",
  "w-[97%]",
  "w-[98%]",
  "w-[99%]",
  "w-[100%]",
];
