import React, { useState } from "react";
import Link from "../components/Button/Link";
import ImageRetina from "../components/ImageRetina";
import SlidePopUp from "../components/PopUp/SlidePopUp";
import Video from "../components/Video/Video";
import {
  CONTACT_US_PATH,
  FACEBOOK_LINK,
  SIGN_UP_PATH,
  SLIDE_OPTIONS,
} from "../globals";

import styles from "../components/SectionCard/sectionCard.module.scss";

import iconBlog from "../assets/images/icons/icon-slide-blogger.svg";
import iconInstagram from "../assets/images/icons/icon-slide-instagram.svg";
import iconTiktok from "../assets/images/icons/icon-slide-tiktok.svg";
import iconYoutube from "../assets/images/icons/icon-slide-youtube.svg";

import logo from "../assets/images/logo-hex.png";
import logo_2x from "../assets/images/logo-hex@2x.png";

import logo4 from "../assets/images/logo-asus.png";
import logo4_2x from "../assets/images/logo-asus@2x.png";
import logo3 from "../assets/images/logo-lyft.png";
import logo3_2x from "../assets/images/logo-lyft@2x.png";
import logo1 from "../assets/images/logo-north-face.png";
import logo1_2x from "../assets/images/logo-north-face@2x.png";
import logo2 from "../assets/images/logo-under-armour.png";
import logo2_2x from "../assets/images/logo-under-armour@2x.png";

import dashbordImg from "../assets/images/dashboard.png";
import dashbordImg_2x from "../assets/images/dashboard@2x.png";
import peopleImg from "../assets/images/people.png";
import peopleImg_2x from "../assets/images/people@2x.png";

import ngoLogo2 from "../assets/images/The_Ocean_Cleanup_logo.png";
import ngoLogo2_2x from "../assets/images/The_Ocean_Cleanup_logo@2x.png";
import ngoLogo4 from "../assets/images/WWF.png";
import ngoLogo4_2x from "../assets/images/WWF@2x.png";
import ngoLogo1 from "../assets/images/greenpeace.png";
import ngoLogo1_2x from "../assets/images/greenpeace@2x.png";
import ngoLogo5 from "../assets/images/sea-sheperd.png";
import ngoLogo5_2x from "../assets/images/sea-sheperd@2x.png";
import ngoLogo3 from "../assets/images/unicef-logo.png";
import ngoLogo3_2x from "../assets/images/unicef-logo@2x.png";

import iconHand from "../assets/images/icons/icon-hand-up.svg";
import iconHands from "../assets/images/icons/icon-hands-helping.svg";
import iconPerson from "../assets/images/icons/icon-ios-person.svg";
import iconYoutube2 from "../assets/images/icons/icon-youtube.svg";

import iconSocials2 from "../assets/images/icons/icon-facebook-black.svg";
import iconSocials1 from "../assets/images/icons/icon-instagram-black.svg";
import iconSocials3 from "../assets/images/icons/icon-twitter-black.svg";
import LinkOutside from "../components/Button/LinkOutside";
import Form from "../components/Form/Form";
import { registerNewUser } from "../services/userAuthService";

const SlideContent = (selector) => {
  const desktopWidth = 1152;
  const isDesktop = () => window.innerWidth >= desktopWidth;
  const mobileWidth = 640;
  const isMobile = () => window.innerWidth <= mobileWidth;

  const [stateType, setStateType] = useState("right");

  const [secondSelector, setSecondSelector] = useState("default");
  const [modalShownSecond, setModalShownSecond] = useState(false);
  const showSecond = (id) => {
    if (!isDesktop()) {
      setStateType("bottom");
      setSecondSelector(id);
      setModalShownSecond(true);
    } else {
      setStateType(stateType);
      setSecondSelector(id);
      setModalShownSecond(true);
    }
  };
  const closeSecond = () => {
    setModalShownSecond(false);
  };

  const getSlideContent = () => {
    const selector = secondSelector || "default";
    return <>{SlideContent(selector)}</>;
  };

  const loginPath = SIGN_UP_PATH;

  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const messageText = `
          One more step.<br/><br/>
          Please verify your email that you used to register with us, click on the link to activate your account.
          `;

  const submit = (answers) => {
    registerNewUser(answers)
      .then((data) => {
        if (data.error) {
          setError(data.error);
          setMessage("");
        } else {
          setError(null);
          setMessage(messageText);
        }
      })
      .catch();
  };

  switch (selector) {
    case SLIDE_OPTIONS.contentCreator:
      return (
        <>
          <div className="slidePopUp__pretitle">LOVED BY</div>
          <h3 className="slidePopUp__header">CONTENT CREATORS</h3>
          <p className="slidePopUp__text">
            You create amazing content so it's time for the world to see, what
            you are made of. Get more visibility for your content, using our
            organic social campaigns.
          </p>
          <div className="slidePopUp__video">
            <Video
              id="iYX22mMf5BM.yt"
              url="https://www.youtube.com/embed/iYX22mMf5BM"
              showPlayIcon={true}
              height={true}
              width={true}
              controls={false}
            />
          </div>
          <div className="slidePopUp__icon">
            <img src={iconInstagram} alt="Instagram" />
          </div>
          <h4 className="slidePopUp__title slidePopUp__title-small">
            Instagram Campaigns
          </h4>
          <p className="slidePopUp__text">
            Get more visibility for the content that you are creating on your
            Instagram account. Create an organic social campaign and let people
            around the world post your content on their IG profiles and tag you.
            This way their friends will see your content and follow you. Get
            those people who really love your content and create a trusty
            database of followers.
          </p>
          <div className="slidePopUp__icon">
            <img src={iconYoutube} alt="YouTube" />
          </div>
          <h4 className="slidePopUp__title slidePopUp__title-small">
            YouTube Creators
          </h4>
          <p className="slidePopUp__text">
            It's time to get more subscribers for your channel and views for
            your videos. Distribute your videos using our YouTube Organic
            Campaign, over social media networks. You upload the video that you
            want to be featured and people will post it on their social media
            profiles.
          </p>
          <div className="slidePopUp__icon">
            <img src={iconBlog} alt="Blog" />
          </div>
          <h4 className="slidePopUp__title slidePopUp__title-small">
            Bloggers & News Agencies
          </h4>
          <p className="slidePopUp__text">
            If you are a blogger or a news agency, we are the right solution to
            make your content known over social media networks.
          </p>
          <div className="slidePopUp__icon">
            <img src={iconTiktok} alt="TikTok" />
          </div>
          <h4 className="slidePopUp__title slidePopUp__title-small">
            TikTokers
          </h4>
          <p className="slidePopUp__text">
            You can boost your TikTok profile followers with our TikTok Social
            Campaigns. This feature will be avalaible starting 1st of December
            2021.
          </p>
          <div className="slidePopUp__finish">
            <Link
              to={loginPath}
              text="START A Campaign"
              white={true}
              size="lg"
              color="white-shadow"
              fontWeight="bold"
              stylesAttr={{ marginTop: "40px" }}
            />
          </div>
        </>
      );
    case SLIDE_OPTIONS.ngo:
      return (
        <>
          <div className="slidePopUp__pretitle">LOVED BY</div>
          <h3 className="slidePopUp__header">NGOs</h3>
          <p className="slidePopUp__text">
            We are the first marketing platform that help NGOs to raise
            awareness for their causes, <span>for FREE </span>, through millions
            of authentic voices using our organic distribution campaigns.
          </p>
          <div className="slidePopUp__video">
            <Video
              id="iYX22mMf5BM.yt"
              url="https://www.youtube.com/embed/iYX22mMf5BM"
              showPlayIcon={true}
              height={true}
              width={true}
              controls={false}
            />
          </div>
          <h4 className="slidePopUp__title mt75">HOW DOES IT WORK?</h4>
          <p className="slidePopUp__text mt30">
            Imagine that 5,000 people that you choose will post your message on
            their social media wall or story. So their friends will find out
            about the cause that you are fighting for.
          </p>
          <p className="slidePopUp__text mt50">
            1. You create a FREE NGO business account with Verbyo. <br />
            2. You create a Social Campaign. <br />
            3. You enter the video / photo and text that you want to be
            distributed.
            <br />
            4. You choose who is going to post your message on their social
            profiles. <br />
            5. Launch the campaign and analyze the results.
          </p>
          <h4 className="slidePopUp__title mt75">
            WHO ARE THE PEOPLE THAT WILL POST?
          </h4>
          <p className="slidePopUp__text mt30">
            They are not influencers with millions of followers and not even
            micro-influencers. So then, who are Verbyo users?
            <br />
            <span>
              They are ordinary people like you and me, with an average of 500
              to 1000 friends on their friend list.
            </span>
          </p>
          <div
            className="slidePopUp__btn mt20"
            onClick={() => showSecond(SLIDE_OPTIONS.people)}
          >
            read more about them
          </div>
          <h4 className="slidePopUp__title mt75">
            BENEFITS OF WORKING WITH US
          </h4>
          <p className="slidePopUp__text mt20">
            1. You create awareness for your NGO and the cause you are fighting
            for.
            <br />
            2. Your message is posted organically on social media networks, and
            people will find out about you. <br />
            3. Your fundraising campaigns will become more successful. <br />
            4. You get international exposure for your NGO work.
          </p>
          <h4 className="slidePopUp__title mt75">CAUSES WE ARE SUPPORTING</h4>
          <p className="slidePopUp__text mt20 mb75">
            Verbyo is supporting causes related to:
            <span>
              education, ocean conservation, the fight against global warming,
              green energy, wild life protection, fight against cancer,
              humanitarian causes and animal rescue.
            </span>
          </p>
          <h4 className="slidePopUp__title slidePopUp__title-small">
            .our mission
          </h4>
          <p className="slidePopUp__text">
            We, at Verbyo, believe in empathy. We believe in solving the
            problems by coming together, united as one, under one voice. We are
            more than just a simple marketing platform, we are a community that
            aims to make a difference in the world.
          </p>
          <p className="slidePopUp__text mt30">
            We are the harbingers, we carry out the message in the world about
            brands, products or services, but we also give a voice to those in
            need.
          </p>
          <p className="slidePopUp__text mt30">
            We create awareness for things that really matter for our community,
            for our environment, for me and you.
          </p>

          <div className="slidePopUp__images">
            <ImageRetina img1x={ngoLogo1} img2x={ngoLogo1_2x} alt="" />
            <ImageRetina img1x={ngoLogo2} img2x={ngoLogo2_2x} alt="" />
            <ImageRetina img1x={ngoLogo3} img2x={ngoLogo3_2x} alt="" />
            <ImageRetina img1x={ngoLogo4} img2x={ngoLogo4_2x} alt="" />
            <ImageRetina img1x={ngoLogo5} img2x={ngoLogo5_2x} alt="" />
          </div>

          <div className="slidePopUp__finish">
            <Link
              to={loginPath}
              text="START A Campaign"
              white={true}
              size="lg"
              color="white-shadow"
              fontWeight="bold"
              stylesAttr={{ marginTop: "100px" }}
            />
          </div>

          <SlidePopUp
            onClose={closeSecond}
            open={modalShownSecond}
            type={stateType}
          >
            <div className={styles.slidePopUp}>
              {getSlideContent(secondSelector)}
            </div>
          </SlidePopUp>
        </>
      );
    case SLIDE_OPTIONS.advertiser:
      return (
        <>
          <div className="slidePopUp__pretitle">PERFECT FOR</div>
          <h3 className="slidePopUp__header">BRANDS & ADVERTISERS</h3>
          <p className="slidePopUp__text">
            Create awareness for your product or service using our Social
            Organic Distribution Methods.
          </p>

          <div className="slidePopUp__video">
            <Video
              id="iYX22mMf5BM.yt"
              url="https://www.youtube.com/embed/iYX22mMf5BM"
              showPlayIcon={true}
              height={true}
              width={true}
              controls={false}
            />
          </div>

          <h4 className="slidePopUp__title mt75">BENEFITS FOR BRANDS</h4>
          <p className="slidePopUp__text mt30">
            1. Increase awareness for your services or products using regular
            social media users.
            <br />
            <span className="slidePopUp__blue">
              * regular people represent 80% of the social networks active
              users.
            </span>
          </p>
          <p className="slidePopUp__text mt30">
            2. Build trust with your customers.
          </p>
          <p className="slidePopUp__text mt30">
            3. Select the placement of your ad on users profiles. Social Wall or
            Stories.
          </p>

          <h4 className="slidePopUp__title mt75">WHO ARE THE USERS?</h4>
          <p className="slidePopUp__text mt30">
            They are not influencers with millions of followers and not even
            micro-influencers. So then, who are Verbyo users?
            <br />
            <span>
              They are ordinary people like you and me, with an average of 500
              to 1000 friends on their friend list.
            </span>
          </p>
          <div
            className="slidePopUp__btn mt20"
            onClick={() => showSecond(SLIDE_OPTIONS.people)}
          >
            read more about Verbyo People
          </div>

          <h4 className="slidePopUp__title mt75">
            5 REASONS WHY YOU SHOULD ADVERTISE WITH REAL PEOPLE
          </h4>
          <p className="slidePopUp__text mt20">
            1. Friend's recommendation is the best sale tool. The most efficient
            in the world.
          </p>
          <p className="slidePopUp__text mt20">
            2. Social media network algorithms prioritize the content that we
            most like and comment on.
          </p>
          <p className="slidePopUp__text mt20">
            3. They form a total of 90% of the social media users.
          </p>
          <p className="slidePopUp__text mt20">
            4. They create original and creative content, unlike an influencer
            which might lose his creative potential over time.
          </p>
          <p className="slidePopUp__text mt20">
            5. Because they generate trust and genuine feedback.
          </p>

          <h4 className="slidePopUp__title mt75">TYPE OF CAMPAIGNS</h4>
          <p className="slidePopUp__text mt20">
            We have created 2 types of campaigns dedicated for brands, who want
            to promote their products.
          </p>

          <h4 className="slidePopUp__text slidePopUp__text-bold mt30">
            Organic Distribution
          </h4>
          <p className="slidePopUp__text mt30">
            You give the creative and text of the ad, and people will post on
            their social media profiles. This way, their friends will find out
            about your product.
          </p>
          <div
            className="slidePopUp__btn mt30"
            onClick={() => showSecond(SLIDE_OPTIONS.organic)}
          >
            read more about Organic Distribution
          </div>

          <h4 className="slidePopUp__text slidePopUp__text-bold mt30">
            Scripted Campaigns & Distribution
          </h4>
          <p className="slidePopUp__text mt30">
            You give the script and users will create a video based on it and
            post it on their social media profiles.
          </p>
          <div
            className="slidePopUp__btn mt30"
            onClick={() => showSecond(SLIDE_OPTIONS.scripted)}
          >
            read more about Scripted Distribution
          </div>

          <h4 className="slidePopUp__title mt75">DATA & ANALYTICS</h4>
          <p className="slidePopUp__text mt30">
            We offer you important metrics to track your organic social media
            campaigns.
          </p>
          <p className="slidePopUp__text mt30">
            Once you launched a campaign, you will be able to see who posted
            your ad, the sentiment of the ad, interactions with your ad (not
            avalaible for all social networks yet).
          </p>
          <p className="slidePopUp__text mt30">
            We support link tracking. Every time you create an ad you can insert
            a link and track its performance.
          </p>
          <p className="slidePopUp__text mt30">
            Demographics are a very useful tool when advertising. You will know
            the age of people who posted / view your ad, country and other
            metrics.
          </p>
          <div
            className="slidePopUp__btn mt20"
            onClick={() => showSecond(SLIDE_OPTIONS.analytics)}
          >
            read more about Data & Analytics
          </div>

          <div className="slidePopUp__finish">
            <Link
              to={loginPath}
              text="START A Campaign"
              white={true}
              size="lg"
              color="white-shadow"
              fontWeight="bold"
              stylesAttr={{ marginTop: "40px" }}
            />
          </div>

          <SlidePopUp
            onClose={closeSecond}
            open={modalShownSecond}
            type={stateType}
          >
            <div className={styles.slidePopUp}>
              {getSlideContent(secondSelector)}
            </div>
          </SlidePopUp>
        </>
      );
    case SLIDE_OPTIONS.how:
      return (
        <>
          <h3 className="slidePopUp__header">HOW DOES IT WORK?</h3>
          <p className="slidePopUp__text">
            Imagine that 10,000 people you choose, post organically on their
            social wall or story your brand's message. How much awareness your
            brand will get?
          </p>
          <p className="slidePopUp__text mt30">
            This is what Verbyo is doing. Creating awareness for brands, content
            creators and NGOs, increase conversions and building trust on social
            media networks.
          </p>
          <div className="slidePopUp__video">
            <Video
              id="iYX22mMf5BM.yt"
              url="https://www.youtube.com/embed/iYX22mMf5BM"
              showPlayIcon={true}
              height={true}
              width={true}
              controls={false}
            />
          </div>

          <h4 className="slidePopUp__title mt75">THE PROCESS</h4>
          <p className="slidePopUp__text mt30">
            Very simple. You have to create an ad and your ad for your product
            or service. This will be posted by the people you choose on their
            social wall or stories.
          </p>
          <p className="slidePopUp__text mt30">
            You can create two types of ads.{" "}
            <span className="slidePopUp__aqua">
              You give the creative (photo or video), text and instructions and
              people post.
            </span>
          </p>
          <p className="slidePopUp__text mt30">
            Or, you{" "}
            <span className="slidePopUp__aqua">
              can give them a script, and they will create a video based on it
              and then post this on their social media
            </span>{" "}
            accounts.
          </p>
          <p className="slidePopUp__text mt30">
            You decide how many people will post and how much you are willing to
            pay for each post that they make.
          </p>
          <p className="slidePopUp__text mt30">
            Once the campaign starts, you can see who posted your ad and the
            performance of each ad.
          </p>

          <h4 className="slidePopUp__title mt30">WHO ARE THE USERS?</h4>
          <p className="slidePopUp__text mt30">
            They are not influencers with millions of followers and not even
            micro-influencers. So then, who are Verbyo users?
            <br />
            <span>
              They are ordinary people like you and me, with an average of 500
              to 1000 friends on their friend list.
            </span>
          </p>
          <div
            className="slidePopUp__btn mt20"
            onClick={() => showSecond(SLIDE_OPTIONS.people)}
          >
            read more about Verbyo People
          </div>

          <h4 className="slidePopUp__title mt50">PAY FOR EACH POST</h4>
          <p className="slidePopUp__text mt20">
            <span>You pay directly users that post your ad.</span>You decide how
            much you want to pay for each post and how many posts you want to
            have.
          </p>
          <p className="slidePopUp__text mt30">
            Each post represents a person. So, if you decide to have 1,000 posts
            that means, that 1,000 people will post your message on their social
            wall or stories.
          </p>

          <h4 className="slidePopUp__title mt75">POST VERIFICATION</h4>
          <p className="slidePopUp__text mt30">
            We've developed a complex AI system to verify each post that users
            make on their social wall or stories. The user gets paid when he
            posts and verifies the post with Verbyo. Also the user has to follow
            the requirements for each post.
          </p>
          <p className="slidePopUp__text mt30">
            However, we are not perfect and sometimes we might miss things, that
            is why you are able to see each post that users make and verify it.
          </p>

          <h4 className="slidePopUp__title mt50">
            SOCIAL NETWORKS & AD PLACEMENT
          </h4>
          <p className="slidePopUp__text mt20">
            You can advertise your brand on all major social networks:{" "}
            <span>Facebook, Instagram, TikTok & twitter</span> and ask users to
            post on their <span>Personal Wall and Stories</span>.
          </p>

          <h4 className="slidePopUp__title mt75">TYPE OF CAMPAIGNS</h4>
          <p className="slidePopUp__text mt20">
            We offer you two types of campaigns suitable for any type of account
            that you open with us.
          </p>
          <div className="slidePopUp__items">
            <div className="slidePopUp__item mt75">
              <img src={iconHand} alt="" />
              <h5 className="slidePopUp__title mt20">ORGANIC CAMPAIGN</h5>
              <p className="slidePopUp__text mt30">
                you give the creative (photo or video) and text. People will
                post on their social wall or stories your message.
              </p>
              <div
                className="slidePopUp__btn mt20"
                onClick={() => showSecond(SLIDE_OPTIONS.organic)}
              >
                read more
              </div>
            </div>
            <div className="slidePopUp__item mt75">
              <img src={iconHand} alt="" />
              <h5 className="slidePopUp__title mt20">SCRIPTED CAMPAIGN</h5>
              <p className="slidePopUp__text mt30">
                you create the script and people will record a video based on it
                and post it on their social wall or stories.
              </p>
              <div
                className="slidePopUp__btn mt20"
                onClick={() => showSecond(SLIDE_OPTIONS.scripted)}
              >
                read more
              </div>
            </div>
          </div>

          <h4 className="slidePopUp__title mt75">TYPE OF ACCOUNTS</h4>
          <div className="slidePopUp__items">
            <div className="slidePopUp__item mt50">
              <img src={iconPerson} alt="" />
              <h5 className="slidePopUp__title mt20">BUSINESS ACCOUNTS</h5>
              <p className="slidePopUp__text mt20">
                You can create a <span>company account</span> or a{" "}
                <span>personal account</span> to promote with us.
              </p>
            </div>
            <div className="slidePopUp__item mt50">
              <img src={iconYoutube2} alt="" />
              <h5 className="slidePopUp__title mt20">ADVERTISERS</h5>
              <p className="slidePopUp__text mt20">
                specially created for brands that want to promote products or
                services (restaurants, hotels, e-commerce, banks, mobile apps,
                etc.)
              </p>
            </div>
            <div className="slidePopUp__item mt30">
              <img src={iconYoutube2} alt="" />
              <h5 className="slidePopUp__title mt20">CONTENT CREATORS</h5>
              <p className="slidePopUp__text mt20">
                created for people that want to promote their content on social
                media networks{" "}
                <span>
                  (Instagram influencers, TikTok creators, YouTube channels,
                  music artists, tattoo artists, bloggers,)
                </span>{" "}
                and many more.
              </p>
            </div>
            <div className="slidePopUp__item mt30">
              <img src={iconHands} alt="" />
              <h5 className="slidePopUp__title mt20">NGOs</h5>
              <p className="slidePopUp__text mt20">
                dedicated for NGOs that want to raise awareness for the causes
                they are fighting for.
              </p>
            </div>
          </div>

          <h4 className="slidePopUp__title mt75">
            ACCEPTED MARKETING CAMPAIGNS
          </h4>
          <p className="slidePopUp__text mt30">
            We strive to keep a clean marketing environment. We are responsible
            for the content that users share on their profiles post using Verbyo
            app.
          </p>
          <p className="slidePopUp__text mt20">
            That is why we verify and approve manually all campaigns. Once the
            campaign is approved the users are notified to post.
          </p>
          <p className="slidePopUp__text mt20">
            We DO NOT under any circumstances accept campaigns that:
          </p>
          <p className="slidePopUp__text mt20">
            a. Promote racism, nudity, fake news, violence, MLM, "get rich
            quickly" schemes, weight loss products, pills, medicines, Covid19
            vaccine related campaigns, etc.
          </p>
          <p className="slidePopUp__text mt20">
            b. Promote religious or political causes.
          </p>
          <p className="slidePopUp__text mt20">
            c. All other campaigns that goes against Social Network Platforms
            Policies.
          </p>
          <p className="slidePopUp__text mt20">
            For more information related to this, please visit our{" "}
            <span
              className="slidePopUp__btn"
              onClick={() => showSecond(SLIDE_OPTIONS.privacyPolicy)}
            >
              Terms & Conditions.
            </span>
          </p>

          <div className="slidePopUp__finish">
            <Link
              to={loginPath}
              text="START A Campaign"
              white={true}
              size="lg"
              color="white-shadow"
              fontWeight="bold"
              stylesAttr={{ marginTop: "40px" }}
            />
          </div>

          <SlidePopUp
            onClose={closeSecond}
            open={modalShownSecond}
            type={stateType}
          >
            <div className={styles.slidePopUp}>
              {getSlideContent(secondSelector)}
            </div>
          </SlidePopUp>
        </>
      );
    case SLIDE_OPTIONS.people:
      return (
        <>
          <h3 className="slidePopUp__header">VERBYO PEOPLE</h3>

          <div className="slidePopUp__image">
            <ImageRetina img1x={peopleImg} img2x={peopleImg_2x} alt="" />
          </div>

          <p className="slidePopUp__text mt40">
            They are not influencers with millions of followers and not even
            micro-influencers. So then, who are Verbyo users? They are ordinary
            people like you and me, with an average of 500 to 1000 friends on
            their friend list.
          </p>

          <h4 className="slidePopUp__title mt50">
            5 REASONS WHY YOU SHOULD ADVERTISE WITH REAL PEOPLE
          </h4>
          <p className="slidePopUp__text mt40">
            1. Friend's recommendation is the best sale tool. The most efficient
            in the world.
          </p>
          <p className="slidePopUp__text mt20">
            2. Social media network algorithms prioritize the content that we
            most like and comment on.
          </p>
          <p className="slidePopUp__text mt20">
            3. They form a total of 90% of the social media users.
          </p>
          <p className="slidePopUp__text mt20">
            4. They create original and creative content, unlike an influencer
            which might lose his creative potential over time.
          </p>
          <p className="slidePopUp__text mt20">
            5. Because they generate trust and genuine feedback.
          </p>

          <h4 className="slidePopUp__title mt75">HOW REAL ARE THEY?</h4>
          <p className="slidePopUp__text mt40">
            We verify each user that signs up on the platform. But, first, they
            have to meet a minimum number of posts or friends (depending on each
            social platform) to link a social media account with us.
          </p>
          <p className="slidePopUp__text mt20">
            Second. Our AI systems verify if the user has a human behavior over
            their social media networks.
          </p>
          <p className="slidePopUp__text mt20">
            We know that our system might not be perfect. That is why you will
            get to see each user that posts your content and verify it. If you
            believe that is not a real user, you can report it to us for further
            investigation.
          </p>

          <h4 className="slidePopUp__title mt40">DEMOGRAPHICS</h4>
          <p className="slidePopUp__text mt20">
            Our user data base is formed from people with ages from 18 to 70
            years old, males, females and others (users that declared other
            gender). They have different backgrounds and occupations.
          </p>

          <h4 className="slidePopUp__title mt40">FRIENDS AUDIENCE</h4>
          <p className="slidePopUp__text mt20">
            Each users audience is formed in general from people that they know
            personally in real life. Relatives, work collegues, sport teammates,
            friends, school collegues, siblings, etc.
          </p>

          <div className="slidePopUp__finish">
            <Link
              to={loginPath}
              text="START A Campaign"
              white={true}
              size="lg"
              color="white-shadow"
              fontWeight="bold"
              stylesAttr={{ marginTop: "70px" }}
            />
          </div>
        </>
      );
    case SLIDE_OPTIONS.organic:
      return (
        <>
          <div className="slidePopUp__pretitle">TYPE OF CAMPAIGNS</div>
          <h3 className="slidePopUp__header">
            ORGANIC DISTRIBUTION ON SOCIAL MEDIA
          </h3>

          <div className="slidePopUp__video">
            <Video
              id="RpJ3uuq3yz4.yt"
              url="https://youtu.be/RpJ3uuq3yz4"
              showPlayIcon={true}
              height={true}
              width={true}
              controls={false}
            />
          </div>

          <p className="slidePopUp__text mt30">
            An Organic Distribution Campaign is a campaign where{" "}
            <span>
              you upload the creative and text, and ask users to post these on
              their social wall or stories
            </span>
            . You may also ask them to tag your page or account in their post.
            You can ad links and even emojis, to create an ad that will drive up
            conversions.
            <br />
          </p>

          <h4 className="slidePopUp__title mt40">
            SOCIAL NETWORKS & AD PLACEMENT
          </h4>
          <p className="slidePopUp__text mt15">
            You can promote your brand on all four major social media networks:
            <span>Facebook, Instagram, Twitter, and TikTok</span>. In addition,
            you can ask users to post your content on their{" "}
            <span>Walls or Stories</span>. This way, you will get the visibility
            that your brand deserves.
          </p>

          <h4 className="slidePopUp__title mt40">CREATIVES ACCEPTED</h4>
          <p className="slidePopUp__text mt15">
            When you create a campaign, you will be able to upload a video or
            photo to be distributed along with a message that you choose.
          </p>

          <h4 className="slidePopUp__title mt40">AUDIENCE</h4>
          <p className="slidePopUp__text mt15">
            You decide who will distribute your content. For example, you can
            set up the country, county, city, age, gender, number of
            followers/friends, etc.
          </p>

          <h4 className="slidePopUp__title mt40">PAY FOR EACH POST</h4>
          <p className="slidePopUp__text mt15">
            You pay for each post that users make. You decide how much you want
            to pay for a post and the number of posts users will make.
          </p>

          <h4 className="slidePopUp__title mt40">EASY TO LAUNCH CAMPAIGNS</h4>
          <p className="slidePopUp__text mt15">
            We have simplified the way you launch a campaign and made it easier
            to understand. Take a look.
          </p>

          <div className="slidePopUp__video">
            <Video
              id="iYX22mMf5BM.yt"
              url="https://www.youtube.com/embed/iYX22mMf5BM"
              showPlayIcon={true}
              height={true}
              width={true}
              controls={false}
            />
          </div>

          <div className="slidePopUp__finish">
            <Link
              to={loginPath}
              text="START A Campaign"
              white={true}
              size="lg"
              color="white-shadow"
              fontWeight="bold"
              stylesAttr={{ marginTop: "80px" }}
            />
          </div>

          <div className="slidePopUp__logos">
            <ImageRetina img1x={logo1} img2x={logo1_2x} alt="" />
            <ImageRetina img1x={logo2} img2x={logo2_2x} alt="" />
            <ImageRetina img1x={logo3} img2x={logo3_2x} alt="" />
            <ImageRetina img1x={logo4} img2x={logo4_2x} alt="" />
          </div>
        </>
      );
    case SLIDE_OPTIONS.scripted:
      return (
        <>
          <div className="slidePopUp__pretitle">TYPE OF CAMPAIGNS</div>
          <h3 className="slidePopUp__header">
            ORIGINAL & UNIQUE CONTENT CREATION & DISTRIBUTION
          </h3>

          <div className="slidePopUp__video">
            <Video
              id="rovT4IRim5o.yt"
              url="https://youtu.be/rovT4IRim5o"
              showPlayIcon={true}
              height={true}
              width={true}
              controls={false}
            />
          </div>

          <p className="slidePopUp__text mt30">
            Take advantage of the creativity of real people. You give the script
            and they will create a unique and engaging video to promote your
            product. After this, they will post on their social media profiles
            or stories, so their friends will find out about your brand.
          </p>

          <h4 className="slidePopUp__title mt40">SCRIPTED VIDEO</h4>
          <p className="slidePopUp__text mt15">
            We are the first marketing platform that allows advertisers to take
            full advantage of our users creativity. Users will record a video
            based on your script. Your script has to contain the key points that
            you want users to highlight in their videos, when endorsing your
            brand. It is a good ideea to make the script interactive. Challenge
            them to do something funny or ask them to review your product.
          </p>

          <h4 className="slidePopUp__title mt40">
            SOCIAL NETWORKS & AD PLACEMENT
          </h4>
          <p className="slidePopUp__text mt15">
            You can promote your brand on all four major social media networks:
            <span>Facebook, Instagram, Twitter, and TikTok</span>. In addition,
            you can ask users to post your content on their{" "}
            <span>Walls or Stories</span>. This way, you will get the visibility
            that your brand deserves.
          </p>

          <h4 className="slidePopUp__title mt40">AUDIENCE</h4>
          <p className="slidePopUp__text mt15">
            You decide who will distribute your content. For example, you can
            set up the country, county, city, age, gender, number of
            followers/friends, etc.
          </p>

          <h4 className="slidePopUp__title mt40">PAY FOR EACH POST</h4>
          <p className="slidePopUp__text mt15">
            You pay for each post that users make. You decide how much you want
            to pay for a post and the number of posts users will make.
          </p>

          <h4 className="slidePopUp__title mt40">EASY TO LAUNCH CAMPAIGNS</h4>
          <p className="slidePopUp__text mt15">
            We have simplified the way you launch a campaign and made it easier
            to understand. Take a look.
          </p>

          <div className="slidePopUp__video">
            <Video
              id="iYX22mMf5BM.yt"
              url="https://www.youtube.com/embed/iYX22mMf5BM"
              showPlayIcon={true}
              height={true}
              width={true}
              controls={false}
            />
          </div>

          <div className="slidePopUp__finish">
            <Link
              to={loginPath}
              text="START A Campaign"
              white={true}
              size="lg"
              color="white-shadow"
              fontWeight="bold"
              stylesAttr={{ marginTop: "80px" }}
            />
          </div>

          <div className="slidePopUp__logos">
            <ImageRetina img1x={logo1} img2x={logo1_2x} alt="" />
            <ImageRetina img1x={logo2} img2x={logo2_2x} alt="" />
            <ImageRetina img1x={logo3} img2x={logo3_2x} alt="" />
            <ImageRetina img1x={logo4} img2x={logo4_2x} alt="" />
          </div>
        </>
      );
    case SLIDE_OPTIONS.analytics:
      return (
        <>
          <div className="slidePopUp__pretitle">DATA ANALYTICS</div>
          <h3 className="slidePopUp__header">
            A POWERFULL AND EASY TO UNDERSTAND DASHBOARD
          </h3>

          <h4 className="slidePopUp__title mt75">SDEDICATED DASHBOARD</h4>
          <p className="slidePopUp__text mt20">
            We are the first marketing platform that allows advertisers to take
            full advantage of our users creativity. Users will record a video
            based on your script. Your script has to contain the key points that
            you want users to highlight in their videos, when endorsing your
            brand. It is a good ideea to make the script interactive. Challenge
            them to do something funny or ask them to review your product.
          </p>

          <div className="slidePopUp__image">
            <ImageRetina img1x={dashbordImg} img2x={dashbordImg_2x} alt="" />
          </div>

          <h4 className="slidePopUp__title mt50">CAMPAIGN CREATOR </h4>
          <p className="slidePopUp__text mt20">
            We have build a easy and comprehensive campaign creator. Unlike
            other marketing platforms, where you have to be an expert to launch
            a campaign, with Verbyo campaign creator everything is more easier
            and intuitive.
          </p>
          <p className="slidePopUp__text mt30">
            Just follow the steps and you will create your campaign in minutes.
          </p>

          <h4 className="slidePopUp__title mt50">AUDIENCE TARGETING</h4>
          <p className="slidePopUp__text mt20">
            When you create a campaign you have the option to decide who is
            going to post your message. You can select the country, the county,
            the city, gender, age, number of followers/friends. Based on your
            activity we can also recommend you certain users to promote your
            brand.
          </p>

          <h4 className="slidePopUp__title mt50">CREATIVES </h4>
          <p className="slidePopUp__text mt20">
            When you create an Organic Campaign you can upload videos or photos
            in order to be distributed with your text.
          </p>

          <h4 className="slidePopUp__title mt50">DATA REPORTING & ANALYTICS</h4>
          <p className="slidePopUp__text mt20">
            Once you have launched a campaign you can see in who posted your ad
            and where. We offer you informations about the people that posted
            your ad and people that viewed or interacted with your ad.
          </p>

          <h4 className="slidePopUp__text slidePopUp__text-bold mt30">
            Link tracking
          </h4>
          <p className="slidePopUp__text mt10">
            When you create your ad, you have the option to enter a link. You
            can enter your tracking link or use ours. This way, you will learn
            about the performance of your link.
          </p>

          <h4 className="slidePopUp__text slidePopUp__text-bold mt50">
            Interest Matching
          </h4>
          <p className="slidePopUp__text mt10">
            Once you start launching campaigns with us, our AI starts to learn
            about your business and can recommend you specific people whos
            friends might be interested in your product or brand.
          </p>

          <h4 className="slidePopUp__title mt50">WALLET FEATURE</h4>
          <p className="slidePopUp__text mt20">
            Top up your wallet and allocate budget for each campaign that you
            make. This way you keep track of your spendings easily and we can
            refund you very quickly.
          </p>

          <h4 className="slidePopUp__title mt50">LIVE SUPPORT</h4>
          <p className="slidePopUp__text mt20">
            We are here to help you anytime you need. Our sales team is
            avalaible in 6 countries.
          </p>

          <div className="slidePopUp__finish">
            <Link
              to={loginPath}
              text="START A Campaign"
              white={true}
              size="lg"
              color="white-shadow"
              fontWeight="bold"
              stylesAttr={{ marginTop: "80px" }}
            />
          </div>
        </>
      );
    case SLIDE_OPTIONS.privacyPolicy:
      return (
        <>
          <div className="slidePopUp__pretitle">OUR</div>
          <h3 className="slidePopUp__header">PRIVACY POLICY</h3>
          <p className="slidePopUp__text">
            This privacy policy applies to the business.verbyo.com site and
            platform (“Platform”) and the content management services, including
            web sites for uploading content (collectively and including the
            Platform, the “Services”) provided by VERBYO , a Romanian
            corporation, and its subsidiaries (collectively, “ VERBYO ”) to
            customers who have engaged BUSINESS.VERBYO.COM (“Customers”). This
            privacy policy describes how VERBYO BUSINESS collects and uses the
            Personal Information (described below) you or social media users
            provide through the Services, including through our mobile
            application. It also describes the choices available to you
            regarding our use of your Personal Information and how you can
            access and update this information.
          </p>
          <p className="slidePopUp__text mt20">
            If you have an unresolved privacy or personal information use
            concern that we have not addressed satisfactorily, please contact
            VERBYO BUSINESS ’s Data Protection Representative (contact
            information below).
          </p>
          <h5 className="slidePopUp__subtitle">1. Changes to this policy</h5>
          <p className="slidePopUp__text mt20">
            We may update this privacy policy to reflect changes to our
            information practices. If we make any material changes we will
            notify you by means of a prominent notice on the Services prior to
            the change becoming effective. If you are a VERBYO BUSINESS
            Customer, we may notify you of such changes by email (sent to the
            email address specified in the Customer’s account).
          </p>
          <p className="slidePopUp__text mt20">
            Any access or use of the Services by you after such notice shall be
            deemed to constitute acceptance by you of such changes,
            modifications or additions. We encourage you to periodically review
            this page for the latest information on our privacy practices. If
            you do not agree to abide by these practices or any future privacy
            policy, please do not use or access the Services.
          </p>
          <h5 className="slidePopUp__subtitle">
            2. Personal information we collect
          </h5>
          <p className="slidePopUp__text mt20">
            We collect information that may allow the identification of a
            natural person (“Personal Information”) (i) from individuals who
            upload user-generated content to Customer or other digital
            properties (such as a Customer’s website, Instagram or Facebook) on
            behalf of our Customers (“Social Media Users”), and (ii) from users
            which interact with the Customer galleries.
          </p>
          <p className="slidePopUp__text mt20">
            With regard to each of these groups we collect the following
            Personal Information: Social Media Users: We collect information
            from Social Media Users such as public uploads and posts, including
            publicly posted photographs. With such assets, we process name,
            social media username, physical address (if available),
            email-address (if available), IP address, and geo location (if
            available).
          </p>
          <p className="slidePopUp__text mt20">
            End Users: When End Users interact with our web sites or the user
            galleries we collect IP address and date/timestamp. If an end-user
            buys products directly from a Customer digital property, IUNYA
            additionally collects purchase information (including purchased
            product ID, transaction ID, transaction price, date of purchase,
            unique ID that our Customer may have assigned to such End User),
            email-address and username (if provided by End Users to Customers).
          </p>
          <h5 className="slidePopUp__subtitle">
            3. Use of personal information
          </h5>
          <p className="slidePopUp__text mt20">
            Social Media Users: We only process Personal Information we collect
            from you to provide the services for which our Customers have
            engaged us. Our Customers to whom you have submitted content through
            the IUNYA Services or whose brand hashtags, @brand mentions or
            branded location tags you have included in your social media posts,
            may use your username or email address to communicate with you, in
            addition to using your photo. IP and geo-location are used to filter
            content by region, country or other local area. Such data is used
            with your consent only.
          </p>
          <p className="slidePopUp__text mt20">
            End Users: We process the Personal Information described above to
            allow our Customers to analyze with which digital assets you
            interacted prior to purchasing a product from a Customer digital
            property after interacting with the user galleries on those digital
            properties.
          </p>
          <p className="slidePopUp__text mt20">
            Furthermore, we collect the information to improve the Services, and
            to improve marketing, analytics and the functionality of the
            Services. Finally, we log IP and date/timestamp to investigate
            and/or prevent security breaches.
          </p>
        </>
      );
    case SLIDE_OPTIONS.termsConditions:
      return (
        <>
          <div className="slidePopUp__pretitle">OUR</div>
          <h3 className="slidePopUp__header">TERMS and CONDITIONS</h3>
          <p className="slidePopUp__text">
            ACCOLADE MEDIA SRL. (“ VERBYO BUSINESS”) provides social content
            management services (the “Services”) for brands (“Brands”) subject
            to the following Terms of Use (“TOU”, “Terms of Use” or “Terms”).
            VERBYO BUSINESS reserves the right to alter these Terms of Use
            without advance notice by posting a revised Terms of Use.
            Accordingly, you should review the Terms of Use each time you access
            VERBYO BUSINESS website available at BUSINESS.VERBYO.COM (the
            “Site”) or use the Services, to stay informed of any changes to the
            Terms of Use.
          </p>
          <p className="slidePopUp__text mt20">
            Any access or use of the Site or Services by you after such notice
            shall be deemed to constitute acceptance by you of such changes,
            modifications or additions.
          </p>
          <h5 className="slidePopUp__subtitle">1. Use of Services</h5>
          <p className="slidePopUp__text mt20">
            These TOU apply to all of IUNYA Site and Services, unless otherwise
            provided. In addition, when using Services provided by IUNYA to a
            Brand, you may be subject to additional terms of use, agreements,
            guidelines or rules provided by the Brand applicable to such
            Services. Subject to these Terms, IUNYA grants you a limited license
            to use the IUNYA Services in accordance with these Terms and
            Services.
          </p>
          <p className="slidePopUp__text mt20">
            IUNYA reserves the right to terminate your license to use the Site
            and Services at any time and for any reason. IUNYA may change,
            suspend or discontinue your use of the Site and Services for any
            reason at any time, including discontinuing the availability of any
            feature, database or content.
          </p>
          <p className="slidePopUp__text mt20">
            You are solely responsible for your use of the Services. You certify
            to IUNYA that you are at least 18 years of age. You also certify
            that you are legally permitted to use the Services and access the
            Site.
          </p>
          <p className="slidePopUp__text mt20">
            IUNYA facilitates the direct upload of user photos, text, graphics,
            audio, video, location information and comments (“User Content” or
            “User Generated Content”) to websites and other digital properties
            operated by Brands, for use by the Brands in marketing, promotional,
            advertising and other customer engagement channels, as well as the
            collection of User Content from social media sites on behalf of
            Brands. Brand properties and the content residing thereon, and Brand
            business practices and privacy policies are not under IUNYA ’s
            control, and IUNYA is not responsible for and makes no claims
            regarding the accuracy or legality of any User Content, Brand
            Content or anything therein.
          </p>
          <p className="slidePopUp__text mt20">
            The inclusion of User Content on the Site or Services does not imply
            IUNYA ’s endorsement of or any affiliation with you, the User
            Content, or any Brand featured in such User Content.
          </p>
          <h5 className="slidePopUp__subtitle">
            2. Personal information we collect
          </h5>
          <p className="slidePopUp__text mt20">
            We collect information that may allow the identification of a
            natural person (“Personal Information”) (i) from individuals who
            upload user-generated content to Customer or other digital
            properties (such as a Customer’s website, Instagram or Facebook) on
            behalf of our Customers (“Social Media Users”), and (ii) from users
            which interact with the Customer galleries.
          </p>
          <p className="slidePopUp__text mt20">
            With regard to each of these groups we collect the following
            Personal Information: Social Media Users: We collect information
            from Social Media Users such as public uploads and posts, including
            publicly posted photographs. With such assets, we process name,
            social media username, physical address (if available),
            email-address (if available), IP address, and geo location (if
            available).
          </p>
          <p className="slidePopUp__text mt20">
            End Users: When End Users interact with our web sites or the user
            galleries we collect IP address and date/timestamp. If an end-user
            buys products directly from a Customer digital property, IUNYA
            additionally collects purchase information (including purchased
            product ID, transaction ID, transaction price, date of purchase,
            unique ID that our Customer may have assigned to such End User),
            email-address and username (if provided by End Users to Customers).
          </p>
          <h5 className="slidePopUp__subtitle">
            3. Use of personal information
          </h5>
          <p className="slidePopUp__text mt20">
            Social Media Users: We only process Personal Information we collect
            from you to provide the services for which our Customers have
            engaged us. Our Customers to whom you have submitted content through
            the IUNYA Services or whose brand hashtags, @brand mentions or
            branded location tags you have included in your social media posts,
            may use your username or email address to communicate with you, in
            addition to using your photo. IP and geo-location are used to filter
            content by region, country or other local area. Such data is used
            with your consent only.
          </p>
          <p className="slidePopUp__text mt20">
            End Users: We process the Personal Information described above to
            allow our Customers to analyze with which digital assets you
            interacted prior to purchasing a product from a Customer digital
            property after interacting with the user galleries on those digital
            properties.
          </p>
          <p className="slidePopUp__text mt20">
            Furthermore, we collect the information to improve the Services, and
            to improve marketing, analytics and the functionality of the
            Services. Finally, we log IP and date/timestamp to investigate
            and/or prevent security breaches.
          </p>
        </>
      );
    case SLIDE_OPTIONS.createAnAccount:
      return (
        <>
          <div className="slidePopUp__logo">
            <ImageRetina img1x={logo} img2x={logo_2x} alt="" />
          </div>
          <Form
            title="CREATE AN ACCOUNT"
            inputs={["email", "password", "rePassword"]}
            submit="Sign Up"
            checkboxes={["agree", "newsletter"]}
            error={error}
            // link={{
            //   text: "Log In",
            //   to: globals.LOGIN_PATH,
            // }}
            login={true}
            onSubmit={submit}
            popupMessage={message}
            popup={true}
          />
        </>
      );
    case SLIDE_OPTIONS.writeUs:
      return (
        <>
          <h3 className="slidePopUp__header">WRITE US</h3>
          <div className="slidePopUp__list">
            <h4 className="slidePopUp__list-title">VERBYO LLC</h4>
            <div className="slidePopUp__list-text">
              <p>1309 Coffeen Avenue STE 1200</p>
              <p>Sheridan, WY 82801</p>
              <p>Email: sales@verbyo.com</p>
            </div>
            <div className="slidePopUp__list-socials">
              <img src={iconSocials1} alt="Social Icon 1" />
              <img src={iconSocials2} alt="Social Icon 2" />
              <img src={iconSocials3} alt="Social Icon 3" />
            </div>
          </div>
          <div className="slidePopUp__buttons">
            <Link
              to={CONTACT_US_PATH}
              text="Email Us"
              white={true}
              size="l"
              color="white-shadow"
              fontWeight="bold"
              stylesAttr={{ marginTop: "75px" }}
            />
            <LinkOutside
              to={FACEBOOK_LINK}
              text="Live Support"
              white={true}
              size="l"
              color="white-shadow"
              fontWeight="bold"
              stylesAttr={{ marginTop: "17px" }}
            />
          </div>
        </>
      );
    case "default":
      return <>No information</>;
  }
};

export default SlideContent;
