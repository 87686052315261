// import NewButton from "../Button/NewButton";
// import AuthenticityAndReliability from "./AuthenticityAndReliability";

// function Advertising({
//   title,
//   advertising,
//   para,
//   para2,
//   extraClasses,
//   extraClassespara,
//   rightImage,
//   thepeople,
//   maindata,
//   verbyo,
//   Authenticity,
//   extraClassesRightImageDiv,
// }) {
//   const shadow = {
//     boxShadow:
//       "0px 4px 8px 0px rgba(9, 13, 20, 0.16), -2px -2px 4px 0px rgba(224, 224, 255, 0.02), 0px 1px 2px 0px rgba(9, 13, 20, 0.24)",
//   };

//   return (
//     <div
//       className={`${extraClasses} flex w-full mx-auto max-w-[1280px]  mt-[218px] justify-between`}
//     >
//       {/* left div */}
//       <div className="">
//         <div className="max-w-[561px] w-full font-bold text-[36px] text-[#1C1934] ">
//           {title}
//         </div>
//         {/* para */}
//         <div
//           className={`${extraClassespara} w-full text-[#5E5D6D] text-[18px] font-normal capitalize mt-[35px] leading-[28.5px]`}
//         >
//           {para}
//           {advertising && (
//             <span className="text-[black]">
//               They represent around 82% of social media users on platforms,
//             </span>
//           )}
//           {para2}
//           {thepeople && (
//             <div className="">
//               <br />
//               <br />
//               Friends recommendations are the number one sales tools:
//             </div>
//           )}
//         </div>
//         {/* Authenticity and reliability */}
//         {Authenticity && <AuthenticityAndReliability maindata={maindata} />}

//         {/* verbyo-community */}
//         {advertising && (
//           <div className="max-w-[651px] w-full text-[#5E5D6D] text-[18px] font-normal  mt-[50px] leading-[30px]">
//             Verbyo is a community of real people. We have implemented several
//             measures to verify that {""}
//             <span className="text-[#145CA3] font-medium">
//               individuals posting our campaigns are real,{" "}
//             </span>
//             thereby preventing bot registrations.
//           </div>
//         )}
//         {/* btn */}
//         {verbyo && (
//           <div className="max-w-[184px] w-full mt-[35px]">
//             <NewButton
//               text={"Learn More"}
//               extraClasses={` bg-[#057DF3] shrink-0`}
//               href={"/confirmation"}
//             />
//           </div>
//         )}
//       </div>
//       {/* right -div */}
//       <div className={`${extraClassesRightImageDiv} max-w-[555px] w-full`}>
//         <div className="absolute ">
//           <img
//             width={140}
//             height={138.39}
//             src={"/advertise/dots-video-back.svg"}
//           />
//         </div>
//         <div className="mt-[40px]   flex justify-center items-center ">
//           {" "}
//           <img
//             width={457}
//             height={502}
//             src={rightImage}
//             className="z-10 relative"
//           />
//           {advertising && (
//             <div className="absolute z-30 max-w-[76px] w-full ">
//               <img
//                 src="/advertise/women-video.svg"
//                 alt=""
//                 className="cursor-pointer"
//               />
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Advertising;
import NewButton from "../Button/NewButton";
import AuthenticityAndReliability from "./AuthenticityAndReliability";

function Advertising({
  title,
  advertising,
  para,
  para2,
  extraClasses,
  extraClassespara,
  rightImage,
  thepeople,
  maindata,
  verbyo,
  Authenticity,
  extraClassesRightImageDiv
}) {
  const shadow = {
    boxShadow:
      "0px 4px 8px 0px rgba(9, 13, 20, 0.16), -2px -2px 4px 0px rgba(224, 224, 255, 0.02), 0px 1px 2px 0px rgba(9, 13, 20, 0.24)",
  };

  return (
    <div
      className={`${extraClasses} flex xl:flex-row flex-col xl:items-start items-center gap-y-[42px] justify-center w-full mx-auto max-w-[1280px]  mt-[218px] xl:justify-between`}
    >
      {/* left div */}
      <div className="">
        <div className="max-w-[561px] sm:px-0 px-[23px] xl:text-start text-center w-full font-bold sm:text-[36px] text-[24px] sm:leading-[41.45px] leading-[30px] text-[#1C1934] ">
          {title}
        </div>
        {/* para */}
        <div
          className={`${extraClassespara} sm:px-0 px-[10px] xl:text-start text-center w-full text-[#5E5D6D] sm:text-[18px] text-[16px] sm:leading-[28.5px] leading-[26px] font-normal capitalize sm:mt-[35px] mt-[20px] `}
        >
          {para}
          {advertising && (
            <span className="text-[black] xl:text-start text-center  sm:text-[18px] text-[16px] sm:leading-[28.5px] leading-[26px]">
              They represent around 82% of social media users on platforms,
            </span>
          )}
          {para2}
          {thepeople && (
            <div className=" xl:text-start text-center  sm:text-[18px] text-[16px] sm:leading-[28.5px] leading-[26px]">
              <br />
              <br className="xl:block hidden" />
              Friends recommendations are the number one sales tools:
            </div>
          )}
        </div>
        {/* Authenticity and reliability */}
        <div className="sm:mt-[30px] mt-[40px] sm:px-0 px-[13px]">
          {Authenticity && <AuthenticityAndReliability maindata={maindata} />}
        </div>

        {/* verbyo-community */}
        {advertising && (
          <div className="max-w-[651px] w-full text-[#5E5D6D] text-[18px] font-normal  mt-[50px] leading-[30px]">
            Verbyo is a community of real people. We have implemented several
            measures to verify that {""}
            <span className="text-[#145CA3] font-medium">
              individuals posting our campaigns are real,{" "}
            </span>
            thereby preventing bot registrations.
          </div>
        )}
        {/* btn */}
        {verbyo && (
          <div className="max-w-[184px] w-full mt-[35px] xl:block hidden">
            <NewButton
              text={"Learn More"}
              extraClasses={` bg-[#057DF3] shrink-0`}
              href={"/confirmation"}
            />
          </div>
        )}
      </div>
      {/* right -div */}
      <div className={`${extraClassesRightImageDiv} max-w-[555px] w-full relative`}  >
        <div className="absolute sm:top-0 top-[10px]">
          <img
            width={140}
            height={138.39}
            src={"/advertise/dots-video-back.svg"}
          />
        </div>
        <div className="mt-[40px]   flex justify-center items-center ">
          {" "}
          <img
            width={457}
            height={502}
            src={rightImage}
            className="z-10 relative rounded-[20px] overflow-hidden"
          />
          {advertising && (
            <div className="absolute z-30 max-w-[76px] w-full ">
              <img
                src="/advertise/women-video.svg"
                alt=""
                className="cursor-pointer"
              />
            </div>
          )}
        </div>
        {verbyo && (
          <div className="max-w-[184px] w-full mt-[35px] xl:hidden block mx-auto">
            <NewButton
              text={"Learn More"}
              extraClasses={` bg-[#057DF3] shrink-0`}
              href={"/confirmation"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Advertising;
