import React, { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";

import Notice from "../components/Notice/Notice";
import { activateEmail } from "../services/userAuthService";

function ActivateEmail() {
	let { token } = useParams();

	const [message, setMessage] = useState("");
	const successText = "Your email has been saved!";
	const errorText = "Something went wrong. Please contact our administrator.";

	useEffect(() => {
		activateEmail(token)
			.then((data) => {
				console.log("data", data);
				if (!data || data.error) {
					setMessage(errorText);
				} else {
					setMessage(successText);
				}
			})
			.catch();
	}, [token]);

	return (
		<div className="container">
			<div className="wrapper">
				<Notice text={message} />
			</div>
		</div>
	);
};

export default withRouter(ActivateEmail);