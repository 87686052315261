import React from "react";

const Tabs = ({ active, setActive, status, raffle }) => {
  return (
    <div className="flex items-center gap-[23px]">
      <button
        disabled={
          status != "live" &&
          status != "finished" &&
          status != "paused" &&
          status != "stopped"
        }
        className={` ${active === 1 ? "bg-[#B3E2F0]" : "bg-[#fff]"
          } text-black disabled:text-gray-600 disabled:cursor-not-allowed px-[75px] rounded-[40px] py-[14px] text-sm font-semibold`}
        onClick={() => {
          setActive(1);
        }}
      >
        Statistics
      </button>
      {!raffle && (
        <button
          disabled={
            status != "live" &&
            status != "finished" &&
            status != "paused" &&
            status != "stopped"
          }
          className={` ${active === 2 ? " bg-[#B3E2F0]" : " bg-[#fff]"
            } text-black disabled:text-gray-600 disabled:cursor-not-allowed px-[75px] rounded-[40px] py-[14px] text-sm font-semibold`}
          onClick={() => {
            setActive(2);
          }}
        >
          Posts
        </button>
      )}
      <button
        className={` ${active === 0 ? " bg-[#B3E2F0]" : " bg-[#fff]"
          } text-black px-[75px] rounded-[40px] py-[14px] text-sm font-semibold`}
        onClick={() => {
          setActive(0);
        }}
      >
        Details
      </button>
    </div>
  );
};

export default Tabs;
