import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import TipPopUp from "../../PopUp/TipPopUp";
import styles from "./deletePopUp.module.scss";
import deleteIcon from "../../../assets/images/icons/icon-walking.svg";
import Button from "../../Button/Button";

function DeletePopUp({ open, onClose, deleteAcc }) {
  const [deleteValue, setDeleteValue] = useState("");
  const close = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (deleteValue === "DELETE") {
      deleteAcc();
      close();
    } else {
    }
  };

  return (
    <TipPopUp
      open={open}
      onClose={close}
      hideCloseButton={true}
      closeOnDocumentClick={true}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.headerIcon}>
            <img src={deleteIcon} alt="Delete" />
          </div>
          <h3 className="center">Account Deletion</h3>
          <p>
            We are sorry to see you go, but if there is no turning back, here is
            what we are going to do after you press delete.
          </p>
          <p>
            1. Your personal data will be deleted from our system.
            <br />
            2. We will delete all your campaigns that you have created.
            <br />
            3. We will delete all the transactions records.
          </p>
          <p className={styles.small}>Please type DELETE in the box below.</p>
          <input
            className={styles.input}
            type="text"
            placeholder="Company name"
            value={deleteValue}
            onChange={(e) => setDeleteValue(e.target.value)}
          />
          <Button
            text="Delete"
            color="blue-shadow"
            size="h"
            stylesAttr={{
              textTransform: "uppercase",
            }}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </TipPopUp>
  );
}

DeletePopUp.defaultProps = {};

DeletePopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteAcc: PropTypes.func.isRequired,
};
export default DeletePopUp;
