import { CampaignApi } from "../api/campaign";

export function createNewCampaign(data) {
  return CampaignApi.createOrUpdate(data);
}

export function EditCampaign(data) {
  return CampaignApi.createOrUpdate(data);
}

export function saveToDraftCampaign(data) {
  return CampaignApi.saveToDraft(data);
}

export function getCampaign(id) {
  return CampaignApi.getCampaign(id);
}

export function getCampaignStatistic(id) {
  return CampaignApi.getCampaignStatistic(id);
}

export function launchCampaign(id, edited) {
  return CampaignApi.launch(id, edited);
}

export function uploadCover(file) {
  const formData = new FormData();
  formData.append("cover-image", file);

  return CampaignApi.uploadCover(formData);
}

export function deleteCover(file) {
  return CampaignApi.deleteCover({ file });
}

export function uploadCreative(file) {
  const formData = new FormData();
  formData.append("creative-file", file);

  return CampaignApi.uploadCreative(formData);
}

export function deleteCreative(file) {
  return CampaignApi.deleteCreative({ file });
}

export function getCampaigns() {
  return CampaignApi.getCampaigns();
}

export function getPromoters(id) {
  return CampaignApi.getPromoters(id);
}

export function changeStatus(id, status) {
  return CampaignApi.changeCampaignStatus(id, status);
}
export function changePostFavourite(id, favourite) {
  return CampaignApi.changeFavouritePost(id, favourite);
}
export function cancelPending(id) {
  return CampaignApi.cancelPendingCampaign(id);
}
export function getPotentialStats(body) {
  return CampaignApi.getPotentialStats(body);
}
