import { API } from '../globals';
import { buildHeaders } from './utils';

export const adminDash = {
  getAdminDashStatsAndGoals: () => {
    return fetch(`${API}/admin/goals-statistics`, {
      method: 'get',
      headers: buildHeaders()
    })
    .then(response => response.json())
    .then(data => data)
    .catch((error) => {
      console.error('Error:', error);
    });
  }
};