import React from "react";
import Tooltip from "../../Tooltip-v2";

const ViewsBox = ({ color, heading, number, tooltip }) => {
  return (
    <div
      style={{
        borderTop: `3px solid ${color}`,
      }}
      className="w-1/3 bg-white min-h-[170px] p-6 pb-10"
    >
      <div className="relative w-fit">
        <p className="text-lg text-black font-semibold">{heading}</p>
        <Tooltip
          style={{
            position: "absolute",
            right: "-28px",
            top: "4px",
          }}
          content={[tooltip]}
        />
      </div>
      <p className="text-[50px] text-[#0D2174] font-bold">{number || 0}</p>
    </div>
  );
};

export default ViewsBox;
