import React from "react";

const Blog = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 11.625C4.98369 11.625 7.84517 12.9288 9.95495 15.2495C12.0647 17.5703 13.25 20.7179 13.25 24"
        stroke="currentColor"
        stroke-width="3.31038"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 2C7.30433 2 12.3914 4.31785 16.1421 8.44365C19.8929 12.5695 22 18.1652 22 24"
        stroke="currentColor"
        stroke-width="3.31038"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.25 24C3.94036 24 4.5 23.3844 4.5 22.625C4.5 21.8656 3.94036 21.25 3.25 21.25C2.55964 21.25 2 21.8656 2 22.625C2 23.3844 2.55964 24 3.25 24Z"
        stroke="currentColor"
        stroke-width="3.31038"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Blog;
