import React from 'react'

const SkeletonLoader = () => {
  return (
    <tr
    tabindex="0"
    className={`focus:outline-none   h-20 text-sm leading-none text-gray-800 bg-white  border-b border-t border-gray-100 `}
  >
 <td className="px-6 ">
 <div class="h-10 mt-4 bg-gray-200 mb-6 rounded w-full animate-pulse"></div>
 </td>
 <td className="px-3">
 <div class="h-10 mt-4 bg-gray-300 mb-6 rounded w-full animate-pulse"></div>
 </td>
 <td className="px-3">
 <div class="h-10 mt-4 bg-gray-200 mb-6 rounded w-full animate-pulse"></div>
 </td>
 <td className="px-3">
 <div class="h-10 mt-4 bg-gray-300 mb-6 rounded w-full animate-pulse"></div>
 </td>
 <td className="px-3">
 <div class="h-10 mt-4 bg-gray-200 mb-6 rounded w-full animate-pulse"></div>
 </td>
 <td className="px-3">
 <div class="h-10 mt-4 bg-gray-300 mb-6 rounded w-full animate-pulse"></div>
 </td>
 <td className="px-3">
 <div class="h-10 mt-4 bg-gray-200 mb-6 rounded w-full animate-pulse"></div>
 </td>
 <td className="px-3">
 <div class="h-10 mt-4 bg-gray-300 mb-6 rounded w-full animate-pulse"></div>
 </td>
 <td className="px-3">
 <div class="h-10 mt-4 bg-gray-200 mb-6 rounded w-full animate-pulse"></div>
 </td>
  </tr>
  )
}

export default SkeletonLoader