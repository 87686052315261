import io from "socket.io-client";
import { API } from "../globals";

const intializeSocket = (userId) => {
  // replace /api with nothing
  if (!userId) return console.log("No user id");
  const withoutSlashApi = API.replace(/\/api(?!.*\/api)/, "");
  let socket;
  console.log(userId, "userdata");
  socket = io(withoutSlashApi, {
    transports: ["websocket", "polling"],
    path: "/app-socket",
  }).on("connect_error", (err) => {
    console.log("connect_error", err);
  });

  socket.on("ping", () => {
    socket.emit("pong");
    console.log("ping pong");
  });

  socket.on("connect", () => {
    console.log("first connect");
    socket.emit("joinRoom", userId.toString());
  });

  // socket.on("disconnect", (reason) => {
  //   console.log(`Disconnected: ${reason}`);
  //   custom_toast(["<Socket Disconnected!"], "error");
  // });

  // socket.on("connectionStatus", (data) => {
  //   console.log(`Connection Status: ${data.message}`);
  //   custom_toast(["Connection Status:", `<${data.message}`], data.type);
  // });
  return socket;
};

export const setupSocketEvent = (socket, event, callback) => {
  socket.on(event, (data) => {
    callback(data);
  });
};

export default intializeSocket;
