import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ReactPlayer from "react-player";

import styles from "./video.module.scss";
import "./video.scss";

function Video({
  url,
  v2,
  v3,
  controls,
  paddingTop,
  playing,
  onPlayVideo,
  onStopVideo,
  light,
  height,
  width,
  onClick,
  id,
  heightSt,
  removeVideoExample,
}) {
  const [controlsNew, setControlsNew] = useState(controls);

  const [selfPlaying, setSelfPlaying] = useState(
    playing !== null ? playing : false
  );

  const subId = id ? id.substring(0, id.length - 3) : "";

  useEffect(() => {
    setSelfPlaying(playing);
  }, [playing]);

  if (!ReactPlayer.canPlay(url)) return <></>;

  const playVideo = () => {
    setSelfPlaying(true);
    onPlayVideo && onPlayVideo();
  };

  const stopVideo = () => {
    setSelfPlaying(false);
    onStopVideo && onStopVideo();
  };

  const videoAttrs = {
    className: styles.reactPlayer,
    controls: onClick ? controls : controlsNew,
    playing: selfPlaying,
    url: url,
    height: height ? "100%" : "220px",
    width: width ? "100%" : "auto",
    onStart: playVideo,
    onPlay: playVideo,
    onPause: stopVideo,
  };

  if (id) videoAttrs.light = `http://img.youtube.com/vi/${subId}/0.jpg`;
  if (heightSt) videoAttrs.height = "140px";

  return (
    <div
      className={cx(
        "relative ",
        "min-w-[300px]",
        "max-h-[400px]",
        "overflow-hidden"
      )}
      style={{ paddingTop: paddingTop && paddingTop }}
      onClick={onClick ? onClick : () => setControlsNew(true)}
    >
      {paddingTop && (
        <svg
          onClick={() => {
            removeVideoExample && removeVideoExample();
          }}
          className="cursor-pointer absolute top-1 bg-white rounded-full right-[-10px] z-20"
          xmlns="http://www.w3.org/2000/svg"
          width="29.25"
          height="29.25"
          viewBox="0 0 29.25 29.25"
        >
          <path
            id="Icon_ionic-ios-close-circle"
            data-name="Icon ionic-ios-close-circle"
            d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm3.705,19.92L18,19.589l-3.705,3.705a1.124,1.124,0,1,1-1.589-1.589L16.411,18l-3.705-3.705a1.124,1.124,0,0,1,1.589-1.589L18,16.411l3.705-3.705a1.124,1.124,0,1,1,1.589,1.589L19.589,18l3.705,3.705a1.129,1.129,0,0,1,0,1.589A1.116,1.116,0,0,1,21.705,23.295Z"
            transform="translate(-3.375 -3.375)"
            fill="#d90202"
          />
        </svg>
      )}

      <ReactPlayer
        {...videoAttrs}
        style={{
          borderRadius: paddingTop ? "8px" : "0px",
          overflow: "hidden",
        }}
        config={{
          youtube: {
            playerVars: {
              showinfo: 0,
              frameBorder: 0,
              allow:
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
              title: "Embedded youtube",
            },
          },
        }}
      />
    </div>
  );
}

Video.defaultProps = {
  controls: true,
  v2: false,
  v3: false,
  disable: false,
  showPlayIcon: false,
  paddingTop: null,
  playing: null,
  onPlayVideo: null,
  onStopVideo: null,
  light: false,
  width: true,
  height: true,
  heightSt: false,
};

Video.propTypes = {
  url: PropTypes.string.isRequired,
  playing: PropTypes.bool,
  paddingTop: PropTypes.string,
  controls: PropTypes.bool,
  disable: PropTypes.bool,
  onPlayVideo: PropTypes.func,
  onStopVideo: PropTypes.func,
  showPlayIcon: PropTypes.bool,
  v2: PropTypes.bool,
  v3: PropTypes.bool,
  light: PropTypes.bool,
  height: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
  heightSt: PropTypes.bool,
};

export default Video;
