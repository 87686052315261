import {
  FETCH_USER,
  SUCCESS,
  START,
  ERROR,
  LOGOUT,
  DELETE_USER,
  SET_ACCOUNT_TYPE,
  SET_USER_DATA,
} from "../actions/types";
import { accountTypes } from "../components/dashboard/SettingsTabs/Details";

const defaultUserData = {
  account: accountTypes[0],
  companyName: "",
  registration: "",
  firstName: "",
  lastName: "",
  address: "",
  country: "",
  state: "",
  city: "",
  contactName: "",
  email: "",
  industry: "",
  website: "",
  phone: "",
  otherContact: "",
  zip: "",
  verified: null,
  rejectionReason: "",
  warningText: "",
};

const correct = ({
  isCompany,
  companyName,
  regNo,
  firstName,
  lastName,
  address,
  country,
  state,
  city,
  contactName,
  contactEmail,
  industry,
  website,
  phone,
  otherContact,
  zipCode,
  verified,
  rejectionReason,
  accountStatus,
  warningText,
}) => ({
  account: isCompany ? accountTypes[1] : accountTypes[0],
  companyName,
  registration: regNo,
  firstName,
  lastName,
  address,
  country,
  state,
  city,
  contactName,
  email: contactEmail,
  industry,
  website,
  phone,
  otherContact,
  zip: zipCode,
  verified,
  rejectionReason,
  accountStatus,
  warningText,
});

const defaultsState = {
  loading: false,
  loaded: false,
  error: false,
  id: null,
  data: null,
};

export default function auth(state = defaultsState, action) {
  switch (action.type) {
    case FETCH_USER + START:
      return {
        loading: true,
        loaded: false,
        error: false,
        id: null,
        data: null,
      };
    case FETCH_USER + SUCCESS:
      console.log(action.payload, "asdasdewrwer");
      return {
        loading: false,
        loaded: true,
        error: false,
        id: action.payload.id || false,
        status: action.payload.status,
        accountEmail: action.payload.loginEmail,
        data: action.payload.data
          ? correct(action.payload.data)
          : defaultUserData,
        wallet: action.payload.wallet,
        type: action.payload.type,
        budgetMinimals: action.payload.budgetMinimals,
      };
    case FETCH_USER + ERROR:
      return {
        loading: false,
        loaded: false,
        error: true,
        id: null,
        data: null,
      };
    case SET_ACCOUNT_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case LOGOUT:
    case DELETE_USER:
      return {
        loading: false,
        loaded: false,
        error: false,
        id: null,
        data: null,
      };
    case SET_USER_DATA:
      return {
        ...state,
        data: action.payload ? correct(action.payload) : defaultUserData,
      };
    default:
      return state;
  }
}
