import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import cx from "classnames";

import globals from "../../globals";
import styles from "./footerMenu.module.scss";
import SlidePopUp from "../PopUp/SlidePopUp";
import SlideContent from "../../data/SlideContent";
import { authSelectorIsUserLogin } from "../../selectors";

function FooterMenu({ location, mobileMode, isLogin }) {
  const path = location.pathname;

  const mobileWidth = 640;
  const isMobile = () => window.innerWidth <= mobileWidth;

  const [selector, setSelector] = useState("default");
  const [modalShown, setModalShown] = useState(false);
  const [formState, setFormState] = useState(false);
  const [writeUsState, setWriteUsState] = useState(false);

  const show = (id) => {
    if (id === globals.SLIDE_OPTIONS.writeUs) {
      setFormState(true);
      setWriteUsState(true);
    }
    setSelector(id);
    setModalShown(true);
  };

  const close = () => {
    setModalShown(false);
    setFormState(false);
    setWriteUsState(false);
  };

  const getSlideContent = (id) => {
    const selector = id || "default";
    return <>{SlideContent(selector)}</>;
  };

  return (
    <>
      <nav className={cx("", mobileMode && styles.mobileMode)}>
        <ul className={styles.footerMenu}>
          <li
            className={
              path === globals.PRIVACY_POLICY_PATH
                ? styles.footerMenuActive
                : ""
            }
          >
            {isLogin ? (
              <a target="_blank" href={globals.PRIVACY_POLICY_PATH}>
                Privacy Policy
              </a>
            ) : (
              <div
                className={styles.link}
                onClick={() => show(globals.SLIDE_OPTIONS.privacyPolicy)}
              >
                Privacy Policy
              </div>
            )}
          </li>
          <li
            className={
              path === globals.TERMS_PATH ? styles.footerMenuActive : ""
            }
          >
            {isLogin ? (
              <a target="_blank" href={globals.TERMS_PATH}>
                Terms & Conditions
              </a>
            ) : (
              <div
                className={styles.link}
                onClick={() => show(globals.SLIDE_OPTIONS.termsConditions)}
              >
                Terms & Conditions
              </div>
            )}
          </li>

          <li
            className={cx(
              path === globals.CONTACT_US_PATH && styles.footerMenuActive
            )}
          >
            {isMobile() ? (
              <>
                <div
                  className={styles.link}
                  onClick={() => show(globals.SLIDE_OPTIONS.writeUs)}
                >
                  Contact Us
                </div>
              </>
            ) : (
              <>
                <Link to={globals.CONTACT_US_PATH}>Contact Us</Link>
              </>
            )}
          </li>
        </ul>
      </nav>
      <SlidePopUp
        onClose={close}
        open={modalShown}
        type="bottom"
        form={formState}
        writeUs={writeUsState}
      >
        <div className={styles.slidePopUp}>{getSlideContent(selector)}</div>
      </SlidePopUp>
    </>
  );
}

FooterMenu.defaultProps = {
  mobileMode: false,
  isLogin: false,
};

FooterMenu.propTypes = {
  mobileMode: PropTypes.bool,
  isLogin: PropTypes.bool,
};

const mapStateToProps = (store) => {
  return {
    isLogin: authSelectorIsUserLogin(store),
  };
};

// export default withRouter(FooterMenu);
export default connect(mapStateToProps, null)(withRouter(FooterMenu));
