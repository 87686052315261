import React from "react";

const Youtube = () => {
  return (
    <svg
      width="28"
      height="21"
      viewBox="0 0 28 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.9296 3.91358C26.625 2.76669 25.7278 1.86356 24.5882 1.55705C22.523 1 14.2415 1 14.2415 1C14.2415 1 5.96004 1 3.89476 1.55705C2.75534 1.86356 1.85792 2.76669 1.55339 3.91358C1 5.99224 1 10.3292 1 10.3292C1 10.3292 1 14.6662 1.55339 16.745C1.85792 17.8918 2.75534 18.7949 3.89476 19.1016C5.96004 19.6585 14.2415 19.6585 14.2415 19.6585C14.2415 19.6585 22.523 19.6585 24.5882 19.1016C25.7278 18.7949 26.625 17.8918 26.9296 16.745C27.483 14.6662 27.483 10.3292 27.483 10.3292C27.483 10.3292 27.483 5.99224 26.9296 3.91358Z"
        stroke="currentColor"
        stroke-width="0.910392"
        stroke-miterlimit="10"
      />
      <path
        d="M11.5331 14.2669L18.4548 10.3294L11.5331 6.39159V14.2669Z"
        stroke="currentColor"
        stroke-width="0.910392"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Youtube;
