import React, { useEffect, useState } from "react";
import validator from "validator";
import { userData } from "../../selectors";
import { connect } from "react-redux";

const ProfileInfo = ({ logo, form, user }) => {
  const images = [
    {
      src: "/images/website.svg",
      name: "website",
    },
    {
      src: "/images/facebook.svg",
      name: "facebookSocial",
    },
    {
      src: "/images/instagram.svg",
      name: "instaSocial",
    },
    {
      src: "/images/twitter.svg",
      name: "twitterSocial",
    },
  ];
  return (
    <div className="max-w-[241px] w-full mt-[30px] rounded-[5px] border border-[#EEE] bg-[#FEFEFE] h-fit">
      <div className="w-full pt-[7px] px-[8px] flex justify-between">
        <img src="/images/back-btn.svg" alt="" />
        <img src="/images/settings.svg" alt="" />
      </div>
      <div className="px-[14px] pb-[25px]">
        <div className="mx-auto bg-[#EEE] flex items-center justify-center rounded-full relative h-[50px] w-[50px] mt-[7px]">
          {form.logo && (
            <div className="w-[50px] h-[50px] overflow-hidden relative rounded-full">
              <img
                className="w-full h-full object-cover object-center"
                src={form.logo}
                alt="logo"
              />
            </div>
          )}
          <img
            src="/images/shield.svg"
            className="max-w-[30px] absolute right-[-7px] bottom-[-9px]"
            alt=""
          />
        </div>
        <h1 className="text-center mt-[7px] text-[14px] font-semibold leading-[17px]">
          {form["foundationName"] ? form["foundationName"] : "Foundation Name"}
        </h1>
        <p className="text-center mt-[3px] text-[12px] font-medium leading-[15px]">
          {user.country ? user.country : "Country"}
        </p>
        <div className="mt-[12px] pb-[21px] border-b border-[#E8E8E8]">
          <p className="text-[12px]  text-center leading-[15px]">
            {form["website"] && validator.isURL(form["website"]) ? (
              <a
                href={form["website"]}
                target="_blank"
                className="hover:underline"
                rel="noreferrer"
              >
                {form["website"]}
              </a>
            ) : (
              "https://website.com"
            )}
          </p>
        </div>
        <div className="mt-[14px] pb-[48px] ">
          <p className="text-[12px] text-center max-w-[194px] mx-auto ">
            {form["description"]
              ? form["description"]
              : `Enter a public description of your foundation. Mission, social
              causes, etc.`}
          </p>
        </div>
        {images.every((image) => !form[image.name]) ||
        images.every((image) => !validator.isURL(form[image.name])) ? null : (
          <div className="flex items-center border-t border-[#E8E8E8] justify-center gap-[10px] pt-[19px]  mx-auto max-w-[170px] w-full">
            {images.map((item, index) => {
              if (!form[item.name] || !validator.isURL(form[item.name]))
                return null;
              return (
                <a
                  href={form[item.name]}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:invert-[10%]"
                  key={index + "images"}
                >
                  <img src={item.src} alt="" />
                </a>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: userData(state),
  };
};

// export default ProfileInfo;
export default connect(mapStateToProps)(ProfileInfo);
