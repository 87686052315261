import React from "react";
import Button from "./Button";
import VfVid from "../../assets/images/verification-vid.png";

const MainArea = ({ setPlaying, onStart }) => {
  return (
    <div className="max-w-[600px] w-full py-[40px] px-[45px] bg-[#fff] ">
      <div className="flex gap-[4px] ml-[-3px]">
        <img src="/images/verification-images/shield.svg" alt="" />
        <p className="text-[18px] text-[#4E588D] font-semibold leading-[24px]">
          Foundation Verification
        </p>
      </div>
      <div className="mt-[20px]">
        <div
          onClick={() => setPlaying(true)}
          className="group cursor-pointer max-w-[325px] h-[203px] overflow-hidden rounded-md relative"
        >
          <div className="group-hover:opacity-50 opacity-20 transition-all duration-500 bg-black absolute top-0 left-0 w-full h-full"></div>
          <img
            src={VfVid}
            alt="vid"
            className="object-cover w-full h-full object-center"
          />
          <button className="absolute group-hover:animate-pulse flex items-center text-white gap-1 text-sm font-medium bottom-3 left-3">
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5 31C24.0603 31 31 24.0603 31 15.5C31 6.93958 24.0603 0 15.5 0C6.93958 0 0 6.93958 0 15.5C0 24.0603 6.93958 31 15.5 31ZM13.4749 21.461L20.7912 17.1414C22.0029 16.426 22.0029 14.574 20.7912 13.8585L13.4749 9.53899C12.2972 8.8437 10.85 9.74868 10.85 11.1804V19.8195C10.85 21.2513 12.2972 22.1563 13.4749 21.461Z"
                fill="white"
              />
            </svg>
            Play video
          </button>
        </div>
      </div>
      <p className="text-[14px] leading-[22px] mt-[21px]">
        Verbyo enables Foundations and NGOs to organically promote their
        initiatives through the genuine voices of millions of people, free of
        charge. However, in accordance with our policies, we request each entity
        to undergo verification to ensure authenticity.
      </p>
      <div className="mt-[28px]">
        <Button
          onClick={() => {
            onStart();
          }}
          btnText={"Start"}
        />
      </div>
    </div>
  );
};

export default MainArea;
