import React, { useState } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";

import Form from "../components/Form/Form";
import FormDesktopHeader from "../components/FormDesktopHeader/FormDesktopHeader";
import FormDesktopWrapper from "../components/FormDesktopWrapper/FormDesktopWrapper";
import { resetPassword } from "../services/userAuthService";

function NewPassword() {
  let { token } = useParams();

  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const messageText =
    "Your password have been changed. Please use it to login to your account.";

  const history = useHistory();

  const submit = (answers) => {
    resetPassword({
      password: answers.newPassword,
      repassword: answers.confirmPassword,
      token,
    })
      .then((data) => {
        if (data.error) {
          setError(data.error);
          setMessage("");
        } else {
          setError(null);
          setMessage(messageText);
          history.push("/login");
        }
      })
      .catch();
  };

  return (
    <div className="container container--flex container--login">
      <div className="wrapper wrapper--v3">
        {/* <FormDesktopHeader onRegister={false} />
        <FormDesktopWrapper
          // image={<ImageRetina img1x={Slide1} img2x={Slide1_2x} alt="" />}
          // imageMobile={
          //   <ImageRetina
          //     img1x={Slide1_mobile}
          //     img2x={Slide1_mobile_2x}
          //     alt=""
          //   />
          // }
          form={
          }
        /> */}
        <Form
          inputs={["newPassword", "confirmPassword"]}
          submit="Save"
          title="Change Password"
          onSubmit={submit}
          popupMessage={message}
          error={error}
          registration={true}
          aftertitle=" "
        />
      </div>
    </div>
  );
}

export default withRouter(NewPassword);
