import React from "react";
import PropTypes from "prop-types";
import { Popup } from "reactjs-popup";
import "./popup.scss";

function TipPopUp({ open, onClose, children, closeOnDocumentClick }) {
  return (
    <Popup
      closeOnDocumentClick={closeOnDocumentClick}
      onClose={onClose}
      open={open}
      lockScroll={true}
      modal
      offsetY={400}
    >
      {children}
    </Popup>
  );
}
TipPopUp.defaultProps = {
  closeOnDocumentClick: false,
};

TipPopUp.propTypes = {
  closeOnDocumentClick: PropTypes.bool,
};
export default TipPopUp;
