import { RaffleApi } from "../api/raffle";

export function createNewRaffle(data) {
  return RaffleApi.createOrUpdate(data);
}

export function EditRaffle(data) {
  return RaffleApi.createOrUpdate(data);
}

export function saveToDraftRaffle(data) {
  return RaffleApi.saveToDraft(data);
}

export function getRaffle(id) {
  return RaffleApi.getRaffle(id);
}

export function launchRaffle(id) {
  return RaffleApi.launch(id);
}

export function approveRaffle(id) {
  return RaffleApi.approveRaffle(id);
}

export function notifyUsersRaffle(id) {
  return RaffleApi.notifyRaffleUsers(id);
}

export function adminGetRaffleDetails(id) {
  return RaffleApi.adminRaffleDetails(id);
}

export function uploadCover(file) {
  const formData = new FormData();
  formData.append("media", file);

  return RaffleApi.uploadCover(formData);
}

export function deleteCover(file) {
  return RaffleApi.deleteCover({ file });
}

export function uploadCreative(file) {
  const formData = new FormData();
  formData.append("creative-file", file);

  return RaffleApi.uploadCreative(formData);
}

export function deleteCreative(file) {
  return RaffleApi.deleteCreative({ file });
}

export function getRaffles() {
  return RaffleApi.getRaffles();
}
export function cancelPendingRaffle(id) {
  return RaffleApi.cancelPending(id);
}
export function deleteRaffle(id) {
  return RaffleApi.deleteRaffle(id);
}
