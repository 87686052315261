import { API } from "../globals";
import { buildHeaders } from "./utils";

export const UserAuthApi = {
  register: (data) => {
    return fetch(`${API}/user/register-new-user`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  login: (data) => {
    return fetch(`${API}/user/login`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  forgotPassword: (data) => {
    return fetch(`${API}/user/forgot-password`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  resetPassword: (data) => {
    return fetch(`${API}/user/reset-password`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  changePassword: (data) => {
    return fetch(`${API}/user/change-password`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  activateAccount: (token) => {
    return fetch(`${API}/activate-new-user/${token}`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  activateEmail: (token) => {
    return fetch(`${API}/activate-new-email/${token}`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  changeEmail: (data) => {
    return fetch(`${API}/user/change-email`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.changeEmail) {
          throw new Error("Something went wrong. Please try again later.");
        } else {
          return data;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  self: () => {
    return fetch(`${API}/user/self`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  delete: () => {
    return fetch(`${API}/user/self-delete`, {
      method: "post",
      headers: buildHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  update: (data) => {
    return fetch(`${API}/user/update-info`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  setAccountType: (data) => {
    return fetch(`${API}/user/set-account-type`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  uploadDoc: (data) => {
    return fetch(`${API}/foundation/upload-docs`, {
      method: "post",
      headers: {
        ...buildHeaders(true),
      },
      body: data,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  deleteDoc: (data) => {
    return fetch(`${API}/foundation/delete-doc`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getVerified: (data) => {
    return fetch(`${API}/foundation/doc`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },

  removeWarning: () => {
    return fetch(`${API}/admin/warning-acknowledged`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error:", error);
      });
  },
  signUpWithGoogle: (data) => {
    return fetch(`${API}/user/register-user-google`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  signInWithGoogle: (data) => {
    return fetch(`${API}/user/login-google`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  updatePwlPass: (data) => {
    return fetch(`${API}/pwl-user-pass`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getIntercomHmac: (data) => {
    return fetch(`${API}/user/generate-intercom-hmac`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  }
};
