import React from "react";

const LoadingDashboard = () => {
  const [percentage, setPercentage] = React.useState(0);

  //   Increase the percentage with time

  React.useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((prevPercentage) => {
        if (prevPercentage >= 100) {
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.round(Math.min(prevPercentage + diff, 100));
      });
    }, 40);
  }, []);

  return (
    <>
      <div className="bg-gray-100 flex items-center justify-center fixed w-screen h-screen top-0 left-0">
        <div className=" py-20">
          <div>
            <p className="text-6xl font-semibold leading-10 text-center text-[#0d2174]">
              {percentage}%
            </p>
            <p className="text-lg leading-none text-center text-[#0d2174] mt-6">
              Loading ... Please Wait
            </p>
            <div className="mt-6">
              <svg
                className="mx-auto transition-all duration-100"
                width={305}
                height={5}
                viewBox="0 0 305 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width={305} height={5} rx="2.5" fill="#E5E7EB" />
                <rect
                  className="transition-all duration-100"
                  width={percentage === 100 ? 305 : (percentage / 100) * 305}
                  height={5}
                  rx="2.5"
                  fill="#0d2174"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingDashboard;
