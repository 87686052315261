import {
  LOAD_CAMPAIGNS,
  EDIT_CAMPAIGN,
  START,
  SUCCESS,
  ERROR,
  DELETE_USER,
  LOGOUT,
  UPDATE_CAMPAIGN,
} from "../actions/types";

const defaultsState = {
  loading: false,
  loaded: false,
  error: false,
  entities: null,
  editData: null,
};

const status = {
  live: 0,
  pending: 1,
  rejected: 2,
  finished: 3,
  draft: 4,
  paused: 5,
  stopped: 6,
  deleted: 7,
  scheduled: 8,
};
const correctCampaigns = (entities) =>
  entities.map((c) => {
    return {
      id: c.id,
      campaign: c.name,
      platform: c.platform,
      image: c.coverImg,
      product: c.productName,
      country: c.country,
      state: c.county,
      city: c.city,
      gender: c.gender,
      price: c.budget || "price",
      ageFrom: `${c.ageFrom}`,
      ageTo: `${c.ageTo}`,
      reason: c.rejectionReason,
      notified: c.notified,
      demographics1: "632",
      demographics2: "230",
      averageAge: "25",
      duration: c.durationOfPost ? c.durationOfPost.substring(0, 2) : "0",
      offers: c.numberOfPosts,
      postsNo: c.numberOfPosts,
      budget: c.budget,
      total: "0",
      clicks: {
        total: "n/a",
        unique: 24,
        countries: ["Romania"],
      },
      customAd: c.customAd,
      pricePerPost: c.pricePerPost,
      pricePerVideo: c.pricePerVideo,
      createdAt: c.createdAt,
      campaignType: c.campaignType,
      totalPosts: c.numberOfPosts || 0,
      usersPosted: c.usersPosted || 0,
      ...c,
      status: status[c.status],
      promoDbId: c.promoDbId,
    };
  });

export default function campaigns(state = defaultsState, action) {
  switch (action.type) {
    case UPDATE_CAMPAIGN:
      return {
        ...state,
        entities: state.entities.map((c) => {
          if (c.id === action.payload.id) {
            return {
              ...c,
              status: status[action.payload.status],
            };
          }
          return c;
        }),
      };
    case LOAD_CAMPAIGNS + START:
      return {
        loading: true,
        loaded: false,
        error: false,
      };
    case LOAD_CAMPAIGNS + SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: false,
        entities: action.payload
          ? correctCampaigns(action.payload).reverse()
          : false,
      };
    case LOAD_CAMPAIGNS + ERROR:
      return {
        loading: false,
        loaded: false,
        error: true,
      };
    case EDIT_CAMPAIGN + SUCCESS:
      return {
        loaded: true,
        editData: action.payload ? action.payload : false,
      };
    case EDIT_CAMPAIGN + ERROR:
      return {
        error: true,
      };
    case LOGOUT:
    case DELETE_USER:
      return {
        loading: false,
        loaded: false,
        error: false,
        entities: null,
      };
    default:
      return state;
  }
}
