import React from "react";
import IconCircle from "../../../assets/images/icons/icon-circle-blue.svg";
import Tooltip from "../../Tooltip-v2";

const NoData = () => {
  return (
    <div className=" flex items-center justify-center pt-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="122"
        height="60.845"
        viewBox="0 0 122 60.845"
      >
        <text
          id="Waiting_for_data"
          data-name="Waiting for data"
          transform="translate(0 56.845)"
          font-size="14"
          font-family="Montserrat-Regular, Montserrat"
          letter-spacing="0.04em"
        >
          <tspan x="0" y="0">
            Waiting for data
          </tspan>
        </text>
        <g
          id="Icon_ionic-md-stats"
          data-name="Icon ionic-md-stats"
          transform="translate(42.536 -4.5)"
        >
          <path
            id="Path_1802"
            data-name="Path 1802"
            d="M12.375,4.5h4.669V32.512H12.375Z"
            transform="translate(0.253)"
            fill="#113ca2"
          />
          <path
            id="Path_1803"
            data-name="Path 1803"
            d="M5.625,23.625h4.669V31.8H5.625Z"
            transform="translate(0 0.717)"
            fill="#113ca2"
          />
          <path
            id="Path_1804"
            data-name="Path 1804"
            d="M19.125,19.125h4.669V31.964H19.125Z"
            transform="translate(0.506 0.548)"
            fill="#113ca2"
          />
          <path
            id="Path_1805"
            data-name="Path 1805"
            d="M25.875,12.375h4.669V32.217H25.875Z"
            transform="translate(0.759 0.295)"
            fill="#113ca2"
          />
        </g>
      </svg>
    </div>
  );
};

const ByLocation = ({ locationData, tooltip }) => {
  const countries = Object.keys(locationData?.top2Countries || {}).map(
    (key, index) => {
      return {
        name: key,
        value: locationData?.top2Countries[key],
      };
    }
  );

  const cities = Object.keys(locationData?.top3Cities || {}).map(
    (key, index) => {
      return {
        name: key,
        value: locationData?.top3Cities[key],
      };
    }
  );

  //  Reduce countries length to 2 if it's more than 2 sorting the highest value first
  const top2CountriesSorted = countries.sort((a, b) => b.value - a.value);
  const top2Countries =
    top2CountriesSorted.length > 2
      ? top2CountriesSorted.slice(0, 2)
      : top2CountriesSorted;

  //  Reduce cities length to 3 if it's more than 2 sorting the highest value first
  const top3CitiesSorted = cities.sort((a, b) => b.value - a.value).slice(0, 3);
  const top3Cities =
    top3CitiesSorted.length > 3
      ? top3CitiesSorted.slice(0, 3)
      : top3CitiesSorted;

  return (
    <div className="max-w-[313px] min-h-[300px] max-h-[300px]  w-full bg-white p-[15px] rounded-[10px]">
      <div className="relative w-fit flex items-center gap-2">
        <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width={30} height={30} rx={5} fill="#F4F1F1" />
          <path fillRule="evenodd" clipRule="evenodd" d="M14.5 10.875C13.6025 10.875 12.875 11.6025 12.875 12.5C12.875 13.3975 13.6025 14.125 14.5 14.125C15.3975 14.125 16.125 13.3975 16.125 12.5C16.125 11.6025 15.3975 10.875 14.5 10.875ZM14.5 15.2083C13.0045 15.2083 11.7917 13.9961 11.7917 12.5C11.7917 11.0039 13.0045 9.79167 14.5 9.79167C15.9955 9.79167 17.2083 11.0039 17.2083 12.5C17.2083 13.9961 15.9955 15.2083 14.5 15.2083ZM14.5 6C10.9104 6 8 8.91038 8 12.5C8 15.2181 13.4194 23.3393 14.5 23.3333C15.5638 23.3393 21 15.1813 21 12.5C21 8.91038 18.0896 6 14.5 6Z" fill="black" />
        </svg>

        <div className="font-bold text-sm flex items-center gap-2">
          By Location{" "}
        </div>
        <Tooltip
          style={{ width: "14px", height: "14px" }} small={true} content={[tooltip]} />
      </div>
      <div className="flex justify-between flex-col pb-4  w-full mt-3 max-h-[230px] overflow-y-auto no-scroll">
        <div className="text-lg">Top Countries</div>
        {top2Countries.length == 0 && <NoData />}
        {top2Countries.map((country, index) => {
          if (!country) return null;
          return (
            <div className="flex justify-between items-center mt-2 font-semibold text-sm">
              <div>{country?.name}</div>
              <div>{country?.value}</div>
            </div>
          );
        })}
        <div className="text-lg mt-5">Top Cities</div>
        {top3Cities.length == 0 && <NoData />}
        {top3Cities.map((city, index) => {
          if (!city) return null;
          return (
            <div className="flex justify-between items-center mt-2 font-semibold text-sm">
              <div>{city.name}</div>
              <div>{city.value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ByLocation;
