import React, { useState } from "react";
import globals from "../../../globals";
import Blog from "./icons/Blog";
import Brand from "./icons/Brand";
import Camera from "./icons/Camera";
import Gaming from "./icons/Gaming";
import Insta from "./icons/Insta";
import Raffle from "./icons/Raffle";
import Siren from "./icons/Siren";
import Spotify from "./icons/Spotify";
import Tiktok from "./icons/Tiktok";
import Verbyo from "./icons/Verbyo";
import Volunteer from "./icons/Volunteer";
import Youtube from "./icons/Youtube";
import PlayIcon from "../../../assets/images/icons/play-blue.svg";

import { useHistory } from "react-router-dom";
import NotSelected from "./NotSelected";

import Article from "./Article";
import articles from "./articles";
import BellBlack from "../../../assets/images/icons/icon-bell-black.svg";
import Camera2 from "./icons/Camera2";
import BellComp from "../../BellBlack";
import MobileVerificationBadge from "../../MobileVerificationBadge";
import { connect } from "react-redux";
import {
  authSelectorType,
  campaignsSelector,
  userData,
} from "../../../selectors";

export const adversiterList = [
  {
    id: 1,
    intro: "Post",
    title: " my content",
    icon: Siren,
    redirect: globals.DASHBOARD_CAMPAIGN_1,
    sub: "People will create a post on their social account using your content.",
  },
  {
    id: 2,
    intro: "Create",
    title: " content for my brand",
    icon: Camera,
    redirect: globals.DASHBOARD_CAMPAIGN_2,
    sub: `People will create content, based on your
    script and post it on their social accounts.`,
  },
  {
    id: 3,
    intro: "Start a",
    title: "Raffle",
    icon: Raffle,
    redirect: globals.DASHBOARD_CAMPAIGN_5,
    sub: "Create awareness for your brand by sponsoring a raffle.",
  },
  {
    id: 4,
    intro: "Promote",
    title: " my Instagram",
    icon: Insta,
    redirect: globals.DASHBOARD_CAMPAIGN_IG,
    sub: "People will post your IG content on their accounts and tag you. ",
  },
  {
    id: 5,
    intro: "Promote",
    title: " my TikTok",
    icon: Tiktok,
    redirect: "tiktok_disabled",
    disabled: true,
    sub: `People will post your content on their 
    social media accounts.`,
  },
  {
    id: 6,
    intro: "Promote",
    title: " my YouTube Content",
    icon: Youtube,
    redirect: globals.DASHBOARD_CAMPAIGN_YT,
    sub: "Get more views and subscribers by asking people to post your content.",
  },
  {
    id: 7,
    intro: "Promote",
    title: " my Blog Article",
    icon: Blog,
    redirect: globals.DASHBOARD_CAMPAIGN_BL,
    sub: `Get more clicks and visitors by asking people to post your content.`,
  },
  {
    id: 8,
    intro: "Promote",
    title: " my Spotify playlist",
    icon: Spotify,
    redirect: "spotify_disabled",
    disabled: true,
    sub: "Get more listeners for your playlist by asking people to post it on their accounts.",
  },
  // {
  //   id: 9,
  //   intro: "To",
  //   title: "promote my Gaming channel",
  //   icon: Gaming,
  //   redirect: globals.DASHBOARD_CAMPAIGN_GM,
  //   sub: "Promote your Twitch or Facebook gaming channel. ",
  // },
];

export const NgoList = [
  {
    id: 11,
    intro: "Post",
    title: " my content",
    icon: Siren,
    redirect: globals.DASHBOARD_CAMPAIGN_4,
    sub: "People will create a post on their social account using your content.",
  },
  {
    id: 2,
    intro: "Create",
    title: " content",
    icon: Camera2,
    redirect: globals.DASHBOARD_CAMPAIGN_3,
    sub: `People will create content, based on your
    script and post it on their social accounts.`,
  },
  {
    id: 3,
    intro: "Start a",
    title: "Raffle",
    icon: Raffle,
    redirect: globals.DASHBOARD_CAMPAIGN_5,
    sub: "Create awareness for your foundation / ngo by sponsoring a raffle.",
  },
  {
    id: 44,
    intro: "Find",
    title: " Volunteers",
    icon: Volunteer,
    redirect: "volunteer_disabled",
    disabled: true,
    sub: "Get people to help you with your social endeavor.",
  },
  {
    id: 55,
    intro: "Find",
    title: " a Brand Ambassador",
    icon: Brand,
    redirect: "brand_ambassador_disabled",
    disabled: true,
    sub: "Name a brand ambassador for your foundation that will represent you.",
  },
  {
    id: 66,
    intro: "",
    title: "Verbyo Digital Foundation",
    icon: Verbyo,
    redirect: "/dashboard/foundation-campaign",
    disabled: false,
    sub: "Learn more about how you can get help from our foundation.",
  },
];

const Form = ({ type, user, userType, campaignsLength }) => {
  const [selected, setSelected] = useState("");
  const [hoveredItem, setHoveredItem] = useState(null);

  const history = useHistory();
  const timerRef = React.useRef(null);
  const timerRef2 = React.useRef(null);

  const hoveredArticle = (type == "advertiser" ? adversiterList : NgoList).find(
    (item1) => {
      if (!hoveredItem) return item1.redirect == selected;
      return item1.redirect == hoveredItem;
    }
  );

  const articleData = articles.find((item) => item.id == hoveredArticle?.id);

  return (
    <div className="max-w-[1270px] absolute sm:static top-0 mx-auto bg-[#EDEDED] sm:bg-white w-full sm:pt-16 pb-40   sm:p-[50px] sm:pr-[86px] ">
      <div className="px-5 py-4 bg-white flex lg:justify-between justify-between sm:justify-center items-center">
        <div>
          <h4 className="text-[#070707] block sm:hidden leading-5 text-lg font-bold">
            Start a campaign
          </h4>
          <h1 className="text-black  mt-[10px] !mb-0 sm:mt-0 sm:font-bold font-medium tracking-tight   text-sm  sm:text-[24px]">
            What would you like people to do?
          </h1>
        </div>
        <BellComp />
      </div>
      {/* <MobileVerificationBadge /> */}
      <div className="flex lg:flex-row flex-col lg:items-start items-center gap-4 bg-white mt-5 sm:mt-0 sm:bg-transparent w-full justify-center lg:justify-between">
        <div className="w-full sm:w-auto">
          {(type == "advertiser" ? adversiterList : NgoList).map(
            (item, index) => (
              <>
                <Item
                  {...item}
                  hoveredDisabled={hoveredArticle?.disabled}
                  key={item.id}
                  selected={selected === item.redirect}
                  trialOver={
                    user.verified !== "verified" &&
                    userType === "ngo" &&
                    campaignsLength == 3
                  }
                  hovered={hoveredItem === item.redirect}
                  onClick={() => setSelected(item.redirect)}
                  onHover={() => {
                    clearTimeout(timerRef.current);
                    timerRef2.current = setTimeout(() => {
                      setHoveredItem(item.redirect);
                    }, 200);
                  }}
                  onMouseLeave={() => {
                    clearTimeout(timerRef2.current);
                    timerRef.current = setTimeout(() => {
                      setHoveredItem(null);
                    }, 200);
                  }}
                />
                {index === 2 && (
                  <hr className="sm:my-8 my-0 py-[10px] sm:py-0 sm:bg-transparent bg-[#EDEDED] sm:max-w-[513px]" />
                )}
              </>
            )
          )}
        </div>
        <div className="min-h-[700px] lg:mt-0 mt-8 sm:block hidden">
          {!hoveredItem && !selected && <NotSelected type={type} />}
          {(hoveredItem || selected) && articleData && (
            <Article {...articleData} />
          )}
          <div className="mt-8 flex w-full gap-5">
            {hoveredArticle?.disabled &&
            hoveredArticle.redirect !== "verbyo_disabled" ? (
              <p className="text-sm text-[#373535] text-center w-full">
                Currently not avalaible in your country.
              </p>
            ) : (
              <>
                {/* <button
                  onClick={() => history.push("/dashboard")}
                  className="text-[#0D2174] border-[#0D2174] border rounded-md max-w-[175px] w-full py-2 hover:opacity-80"
                >
                  back
                </button>
                {selected && (
                  <button
                    onClick={() => {
                      history.push(selected);
                    }}
                    className="bg-[#0D2174] hover:opacity-90 max-w-[175px] w-full py-2 border border-[#0D2174] text-white text-sm rounded-md"
                  >
                    Start
                  </button>
                )} */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Item = ({
  intro,
  title,
  icon: Siren,
  selected,
  onClick,
  disabled,
  onHover,
  onMouseLeave,
  hoveredDisabled,
  hovered,
  redirect,
  sub,
  trialOver,
}) => {
  const history = useHistory();
  const dis = disabled && redirect !== "verbyo_disabled";

  return (
    <>
      <div className="sm:flex hidden items-center  gap-2">
        <div
          onClick={onClick}
          onMouseEnter={onHover}
          onMouseLeave={onMouseLeave}
          className={`flex items-center  ${
            disabled ? "cursor-pointer" : "cursor-pointer   "
          } mt-2 max-w-[532px] py-4 pl-3 pr-3 rounded-md ${
            (selected || hovered) && "bg-[#F6F6F6]"
          } duration-300 transition-all `}
        >
          <input
            type="radio"
            checked={selected}
            className="accent-[#1F8EF9] w-5 h-5"
            name="campaign-type"
          />
          <div
            className={`w-10  h-10 ml-3 p-2 flex transition-all duration-300 origin-top  items-center justify-center ${
              selected && !disabled
                ? "bg-[#1F8EF9] text-white"
                : selected && disabled
                ? "bg-[#1F8EF9] text-[#B5B3B3]"
                : disabled
                ? "bg-[#ECECEC] text-[#B5B3B3]"
                : "bg-[#ECECEC] text-black"
            }  rounded-md`}
          >
            <Siren />
          </div>
          <div className="flex gap-1 ml-2">
            <span
              className={`text-base whitespace-nowrap  ${
                disabled ? "text-[#9D9D9D]" : "text-black"
              }`}
            >
              {intro}
            </span>
            <span
              className={`text-base font-medium  ${
                disabled && redirect !== "verbyo_disabled"
                  ? "text-[#9D9D9D]"
                  : "text-[#0D2174]"
              }`}
            >
              {title}
            </span>
          </div>
        </div>

        <div
          onClick={() => {
            if (trialOver) {
              history.push("/foundation-verification");
              return;
            }
            history.push(redirect);
          }}
          className={` py-5 px-4 mt-2  cursor-pointer ${
            !hoveredDisabled && selected
              ? "opacity-100"
              : "opacity-0 pointer-events-none S"
          }  hover:scale-105 rounded-md
      ${(selected || hovered) && "bg-[#F6F6F6]"}
      `}
        >
          <img src={PlayIcon} />
        </div>
      </div>
      <>
        <div
          onClick={() => {
            if (!disabled) {
              if (trialOver) {
                history.push("/foundation-verification");
                return;
              }
              history.push(redirect);
            }
          }}
          tabIndex={0}
          className="p-5 pl-4 w-full cursor-pointer  border-b border-[#DFDFDF] hover:bg-gray-100 focus:bg-gray-100 sm:hidden flex py-5 "
        >
          <div className="flex items-center justify-evenly gap-4 w-full">
            <div
              className={`w-8 ${
                disabled ? "text-[#B5B3B3]" : "text-black"
              }   flex-shrink-0 min-w-10 mb-2 min-h-10 h-10  `}
            >
              <Siren />
            </div>
            <div className="w-full max-w-[250px]">
              <p
                className={`${
                  dis ? "text-[#B5B3B3]" : "text-black"
                } font-semibold text-sm mont`}
              >
                {intro} {title}
              </p>
              <p
                className={`text-xs 
              ${dis ? "text-[#B5B3B3]" : "text-black"}
              leading-4 mt-2`}
              >
                {sub}
              </p>
            </div>
            <svg
              className="shrink-0"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.395 23.24L18.0017 15.6333C18.9 14.735 18.9 13.265 18.0017 12.3667L10.395 4.75999"
                stroke={dis ? "#A5A5A5" : `black`}
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: userData(state),
  userType: authSelectorType(state),
  campaignsLength: campaignsSelector(state)?.length || 0,
});

export default connect(mapStateToProps)(Form);
