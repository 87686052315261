import { LOAD_STATISTICS, START, SUCCESS, ERROR, LOGOUT, DELETE_USER } from '../actions/types';

const defaultsState = {
  loading: false,
  loaded: false,
  error: false,
  data: null
};
export default function statistics(state = defaultsState, action) {
  switch (action.type) {
    case LOAD_STATISTICS + START:
      return {
        loading: true,
        loaded: false,
        error: false
      };
    case LOAD_STATISTICS + SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: false,
        data: action.payload || false
      };
    case LOAD_STATISTICS + ERROR:
      return {
        loading: false,
        loaded: false,
        error: true,
        data: null,
      };
    case LOGOUT:
    case DELETE_USER:
      return {
        loading: false,
        loaded: false,
        error: false,
        data: null,
      };
    default:
      return state;
  }
}

