import { START, SUCCESS, ERROR, LOAD_IG_REQUESTS } from "../actions/types";

const defaultsState = {
  loading: false,
  loaded: false,
  error: false,
  data: null,
};

export default function adminStats(state = defaultsState, action) {
  switch (action.type) {
    case LOAD_IG_REQUESTS + START:
      return {
        loading: true,
        loaded: false,
        error: false,
      };
    case LOAD_IG_REQUESTS + SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: false,
        data: action.payload || false,
      };
    case LOAD_IG_REQUESTS + ERROR:
      return {
        loading: false,
        loaded: false,
        error: true,
      };

    default:
      return state;
  }
}
