import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { campaignColors } from "../../data/campaigns";

const fonts = () => ({
  fontSize: "14px",
  letterSpacing: 0,
});

const styles = (color, isError) => {
  const isWhite = color === "#fff";

  return {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: isError ? "red" : isWhite ? campaignColors[1] : color,
      borderWidth: "2px",
      minHeight: "42px",
      ":hover": {
        ...styles[":hover"],
        borderColor: isWhite ? campaignColors[1] : color,
        boxShadow: `0 0 1px ${color}`,
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected, isA }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? "#ccc"
          : isSelected && !isWhite
          ? "white"
          : isSelected && isWhite
          ? "#EBE7E7"
          : isFocused && !isWhite
          ? "white"
          : isFocused && isWhite
          ? "#EBE7E7"
          : color,
        color: isDisabled
          ? "#000"
          : isSelected
          ? "black"
          : isFocused
          ? "black"
          : isWhite
          ? "black"
          : "white",
        cursor: isDisabled ? "not-allowed" : "pointer",
        ...fonts(),

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "white" : "white"),
        },
      };
    },
    input: (styles) => ({
      ...styles,
      ...fonts(),
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#7A7A7A",

      ...fonts(),
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: "0 8px 0 20px",
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: 0,
      padding: 0,
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "5px",
      marginTop: 0,
      padding: 0,
    }),
    singleValue: (styles) => ({
      ...styles,
      ...fonts(),
      fontFamily: "MontserratSemiBold, sans-serif",
    }),
    indicatorSeparator: (styles) => ({ display: "none" }),
  };
};

function MySelect({
  options,
  placeholder,
  color,
  value,
  onChange,
  onBlur,
  className,
  isError,
  isDisabled,
}) {
  const handleChange = (option) => {
    onChange(option);
  };

  return (
    <Select
      options={options}
      placeholder={placeholder}
      styles={styles(color, isError)}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      className={className}
      isDisabled={isDisabled}
    />
  );
}
MySelect.defaultProps = {
  color: campaignColors[1],
  placeholder: null,
  value: null,
  isDisabled: false,
};

MySelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default MySelect;
