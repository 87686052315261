import MC1BG from "../assets/images/advertisers-dashboard@2x.png";
import MC1 from "../assets/images/mc-img-5.png";
import MC1_2x from "../assets/images/mc-img-5@2x.png";
import MC1NEW from "../assets/images/organic-new.jpg";

import MC2BG from "../assets/images/content-dashboard@2x.png";
import MC2 from "../assets/images/mc-img-1.png";
import MC2_2x from "../assets/images/mc-img-1@2x.png";
import MC2NEW from "../assets/images/scripted-new.jpg";

// import MC3 from '../assets/images/mc-img-3.png';
// import MC3_2x from '../assets/images/mc-img-3@2x.png';

import MC4 from "../assets/images/mc-img-4.png";
import MC4_2x from "../assets/images/mc-img-4@2x.png";
import MC4BG from "../assets/images/ngo-dashboard@2x.png";
import MC4NEW from "../assets/images/organic-new.jpg";

import MCIGBG from "../assets/images/ig-dashboard@2x.png";
import MCIGNEW from "../assets/images/instagram-new.jpg";
import MCIG from "../assets/images/mc-img-ig.png";
import MCIG_2x from "../assets/images/mc-img-ig@2x.png";

import MCYT from "../assets/images/mc-img-yt.png";
import MCYT_2x from "../assets/images/mc-img-yt@2x.png";
import MCYTNEW from "../assets/images/youtube-new.jpg";
import MCYTBG from "../assets/images/yt-dashboard@2x.png";

import MCBLNEW from "../assets/images/blog-new.jpg";
import MCBLBG from "../assets/images/fb-dashboard@2x.png";
import MCBL from "../assets/images/mc-img-fb.png";
import MCBL_2x from "../assets/images/mc-img-fb@2x.png";

import MCGMNEW from "../assets/images/gaming-new.jpg";
import MCGMBG from "../assets/images/gm-dashboard@2x.png";
import MCGM from "../assets/images/mc-img-gm.png";
import MCGM_2x from "../assets/images/mc-img-gm@2x.png";

import GIFTNEW from "../assets/images/raffle-new.jpg";
import GIFT from "../assets/images/rafflegift.png";
import GIFT_2x from "../assets/images/rafflegift@2x.png";

import VOLUNTEERSNEW from "../assets/images/volunteers-new.jpg";

import AMBASSADORNEW from "../assets/images/ambassador-new.jpg";


import globals, { CAMPAIGN_CREATE_IDS } from "../globals";

export const campaignColors = [
  "#BC2A8D",
  "#646C9A",
  "#367872",
  "#61975B",
  "#7b649a",
  "#F2F2F2",
  "linear-gradient(150deg, #405de6 0%, #5851db 14%, #833ab4 32%, #c13584 49%, #e1306c 65%, #f56040 77%, #e29325 88%, #dea81c 100%)",
  "linear-gradient(150deg, #FF0000 0%, #5E1547 100%)",
  "linear-gradient(150deg, #198CD9 20%, #310925 100%)",
  "linear-gradient(150deg, #69c9d0 10%, #ee1d52 54%, #000000 100%)",
];

export const campaigns = [
  {
    pretitle: "Start a",
    title: "ORGANIC CAMPAIGN",
    title_small: "Organic Distribution Campaign",
    description:
      "You give the creative and text. People will post this on their social wall or stories.",
    image: {
      img1x: MC1,
      img2x: MC1_2x,
      alt: "Organic Distribution Campaign",
    },
    imageBg: MC1BG,
    imageCard: MC1NEW,
    tag: "#usergeneratedcontent",
    color: campaignColors[0],
    to: globals.DASHBOARD_CAMPAIGN_1,
    toLogout: globals.ORGANIC_DISTRIBUTION_PATH,
    social: ["tiktok", "instagram", "twitter", "facebook"],
    video: "https://youtu.be/RpJ3uuq3yz4",
  },
  {
    pretitle: "Start a",
    title: "SOCIAL CAMPAIGN",
    title_small: "Humanitarian Campaign",
    description:
      "You give the creative and text. People will post this on their social wall or stories.",
    image: {
      img1x: MC4,
      img2x: MC4_2x,
      alt: "SOCIAL CAMPAIGN",
    },
    imageBg: MC4BG,
    imageCard: MC4NEW,
    tag: "#wecare",
    color: campaignColors[3],
    to: globals.DASHBOARD_CAMPAIGN_4,
    toLogout: globals.WE_CARE_PATH,
    social: ["tiktok", "instagram", "twitter", "facebook"],
    video: "https://www.youtube.com/embed/iYX22mMf5BM",
  },
  {
    pretitle: "Start a",
    title: "SCRIPTED CAMPAIGN",
    title_small: "Content Creation & Distribution",
    description:
      "You give the script, and people will record a video based on it and post on their social media wall or stories.",
    image: {
      img1x: MC2,
      img2x: MC2_2x,
      alt: "Content Creation & Distribution Campaign",
    },
    imageBg: MC2BG,
    imageCard: MC2NEW,
    tag: "#usergeneratedcontent",
    color: campaignColors[1],
    to: globals.DASHBOARD_CAMPAIGN_2,
    toLogout: globals.CONTENT_CREATION_PATH,
    social: ["tiktok", "instagram", "twitter", "facebook"],
    video: "https://youtu.be/rovT4IRim5o",
    coming: true,
  },
  /*   {
    title: 'Create Social Media Content',
    title_small: 'Create Social Media Content',
    description: 'Upload your script and let our users create unique and original content for your brand. You can use it on your social media campaigns.',
    image: {
      img1x: MC3,
      img2x: MC3_2x,
      alt: 'Create Social Media Content',
    },
    tag: '#becreative',
    color: campaignColors[2],
    to: globals.DASHBOARD_CAMPAIGN_3,
    social: false,
  }, */
  {
    pretitle: "Promote your",
    title: `INSTAGRAM ACCOUNT`,
    title_small: "Promote your Instagram Account",
    description:
      "Get more visibility for your Instagram account. Use real people to distribute your profile.",
    image: {
      img1x: MCIG,
      img2x: MCIG_2x,
      alt: "Promote your Instagram Account",
    },
    imageBg: MCIGBG,
    imageCard: MCIGNEW,
    color: campaignColors[6],
    to: globals.DASHBOARD_CAMPAIGN_IG,
    toLogout: globals.WE_CARE_PATH,
    social: ["instagram"],
    type: "content",
    video: "https://youtu.be/DHoRfN6FATs",
  },
  {
    pretitle: "Promote your",
    title: "YouTube CONTENT",
    title_small: "Promote your YouTube Content",
    description:
      "If you are a YT content creator or a music artist, you can distribute your creation organically through millions of people.",
    image: {
      img1x: MCYT,
      img2x: MCYT_2x,
      alt: "Promote your YouTube Content",
    },
    imageBg: MCYTBG,
    imageCard: MCYTNEW,
    color: campaignColors[7],
    to: globals.DASHBOARD_CAMPAIGN_YT,
    toLogout: globals.WE_CARE_PATH,
    social: ["twitter", "facebook"],
    type: "content",
    video: "https://youtu.be/FDFGry2bkJU",
  },
  {
    pretitle: "Promote your",
    title: `BLOG ARTICLE`,
    title_small: "Promote your Blog Article",
    description: "Promote your blog or news article",
    image: {
      img1x: MCBL,
      img2x: MCBL_2x,
      alt: "Promote your Blog Article",
    },
    imageBg: MCBLBG,
    imageCard: MCBLNEW,
    color: campaignColors[8],
    to: globals.DASHBOARD_CAMPAIGN_BL,
    toLogout: globals.WE_CARE_PATH,
    social: ["twitter", "facebook"],
    type: "content",
    video: "https://youtu.be/URgKWAPFo6Y",
    coming: true,
  },
  {
    pretitle: "Promote your",
    title: "GAMING CHANNEL",
    title_small: "Promote your Gaming Channel",
    description:
      "You play? Let the whole world know about your game. Promote your gaming channel all around the world.",
    image: {
      img1x: MCGM,
      img2x: MCGM_2x,
      alt: "Promote your Gaming Channel",
    },
    imageBg: MCGMBG,
    imageCard: MCGMNEW,
    color: campaignColors[9],
    to: globals.DASHBOARD_CAMPAIGN_GM,
    toLogout: globals.WE_CARE_PATH,
    social: ["twitter", "facebook"],
    type: "content",
    // comingDate: "1 December 2021",
    video: "",
  },
/*   {
    pretitle: "About",
    title: "VERBYO FOUNDATION",
    title_small: "About Verbyo Foundation",
    description: "We give a voice to those in need.",
    imageCard: VERBYONEW,
    video: "",
    social: false,
    link: globals.ABOUT_PATH,
  }, */
];

export const ngoCampaigns = [
  ...campaigns.filter((c) =>
    [
      globals.DASHBOARD_CAMPAIGN_4,
      globals.DASHBOARD_CAMPAIGN_2,
      globals.DASHBOARD_CAMPAIGN_BL,
      globals.DASHBOARD_CAMPAIGN_5,
    ].includes(c.to)
  ),
  {
    pretitle: "Start a",
    title: "SOCIAL RAFFLE",
    title_small: "Start a Social Raffle",
    description:
      "Get more visibility for your cause by sponsoring one of our raffles. Every user that posts social offers can enter a raffle contest.",
    image: {
      img1x: GIFT,
      img2x: GIFT_2x,
      alt: "Start A Raffle Contest",
    },
    imageCard: GIFTNEW,
    color: campaignColors[4],
    to: globals.DASHBOARD_CAMPAIGN_5,
    toLogout: globals.WE_CARE_PATH,
    video: "",
    social: false,
    coming: true,
  },
  {
    pretitle: "Find",
    title: "VOLUNTEERS",
    title_small: "Find Volunteers",
    description:
      "If you need an extra hand, we are ready to help you. Find volunteers for your project, among our users.",
    imageCard: VOLUNTEERSNEW,
    video: "",
    social: false,
    coming: true,
  },
  {
    pretitle: "Find a",
    title: "GOODWILL AMBASSADOR",
    title_small: "Find a Goodwill Ambassador",
    description:
      "Choose from our users, who will represent your cause in their communities.",
    imageCard: AMBASSADORNEW,
    video: "",
    social: false,
    coming: true,
  },
  ...campaigns.filter((c) => [globals.ABOUT_PATH].includes(c.link)),
];

export const adminCampaigns = [
  {
    title: "Organic Distribution Campaign",
    title_small: "Organic Distribution Campaign",
    description:
      "Distribute your brand and people will post your ad on their social media wall.",
    image: {
      img1x: MC1,
      img2x: MC1_2x,
      alt: "Organic Distribution Campaign",
    },
    color: campaignColors[0],
    to: globals.DASHBOARD_CAMPAIGN_1,
    toLogout: globals.ORGANIC_DISTRIBUTION_PATH,
    video: "",
  },
  {
    title: "Content Creation & Distribution Campaign",
    title_small: "Content Creation & Distribution",
    description:
      "Create and distribute original user generated content for your brand, based on your script.",
    image: {
      img1x: MC2,
      img2x: MC2_2x,
      alt: "Content Creation & Distribution Campaign",
    },
    color: campaignColors[1],
    to: globals.DASHBOARD_CAMPAIGN_2,
    toLogout: globals.CONTENT_CREATION_PATH,
    video: "",
  },
  {
    title: "FREE SOCIAL Campaign",
    title_small: "Humanitarian Campaign",
    description:
      "We stand by you. Promote your cause. We give a voice for those in need or we can create awareness for things that really matter.",
    image: {
      img1x: MC4,
      img2x: MC4_2x,
      alt: "FREE SOCIAL Campaign",
    },
    tag: "#wecare",
    color: campaignColors[3],
    to: globals.DASHBOARD_CAMPAIGN_4,
    toLogout: globals.WE_CARE_PATH,
    video: "https://www.youtube.com/embed/iYX22mMf5BM",
  },
  {
    title: "Start A Raffle Contest",
    title_small: "Start A Raffle Contest",
    description: "Create a raffle for users",
    image: {
      img1x: GIFT,
      img2x: GIFT_2x,
      alt: "Start A Raffle Contest",
    },
    color: campaignColors[4],
    to: globals.DASHBOARD_CAMPAIGN_5,
    toLogout: globals.WE_CARE_PATH,
    video: "",
  },
];

export const campaignTitles = {
  [CAMPAIGN_CREATE_IDS.choosePlatform]: {
    title: "Choose platform",
    subtitle: "Choose the distribution platform",
  },
  [CAMPAIGN_CREATE_IDS.campaignDetails]: {
    title: "Campaign & Product Details",
    subtitle: "Setup your campaign details.",
  },
  [CAMPAIGN_CREATE_IDS.defineAudience]: {
    title: "Audience Targeting & Distribution",
    subtitle: "Where and who will distribute your cause.",
  },
  [CAMPAIGN_CREATE_IDS.designAdd]: {
    title: "",
    subtitle: "",
  },
  [CAMPAIGN_CREATE_IDS.adRequirements]: {
    title: "",
    subtitle: "",
  },
  [CAMPAIGN_CREATE_IDS.budgetPayment]: {
    title: "",
    subtitle: "",
  },
};
