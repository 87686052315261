import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./errorItem.module.scss";
import { deleteError } from "../../../actions";

import iconWarning from "../../../assets/images/icons/icon-notifications-warning.svg";
import iconError from "../../../assets/images/icons/icon-notifications-error.svg";
import iconSuccess from "../../../assets/images/icons/icon-notifications-success.svg";
import iconClose from "../../../assets/images/icons/icon-modal-close.svg";

const imageType = {
  warning: iconWarning,
  error: iconError,
  success: iconSuccess,
};

function ErrorItem({
  type,
  title,
  message,
  deleteError,
  items,
  link,
  position,
}) {
  if (link) {
    return (
      <Link
        to={link}
        className={cx(
          styles.errorItem,
          styles[`errorItem__${type}`],
          position && styles[`errorItem__${position}`]
        )}
      >
        <h3 className={styles.title}>
          <img src={imageType[type]} alt={type} />
          {title}
        </h3>
        <div className={styles.message}>
          {message && message}
          {items && (
            <ul className={styles.list}>
              {items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          )}
        </div>
        <div className={styles.close} onClick={() => deleteError()}>
          <img src={iconClose} alt="Close" />
        </div>
      </Link>
    );
  }
  return (
    <div className={cx(styles.errorItem, styles[`errorItem__${type}`])}>
      <h3 className={styles.title}>
        <img src={imageType[type]} alt={type} />
        {title}
      </h3>
      <div className={styles.message}>
        {message && message}
        {items && (
          <ul className={styles.list}>
            {items.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        )}
      </div>
      <div className={styles.close} onClick={() => deleteError()}>
        <img src={iconClose} alt="Close" />
      </div>
    </div>
  );
}

ErrorItem.propTypes = {
  type: PropTypes.string,
};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = {
  deleteError: deleteError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorItem);
