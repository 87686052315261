const TitleAndParagraph = ({
  title,
  para,
  stylesForMain,
  stylesForTitle,
  stylesForPara,
}) => {
  return (
    <div
      className={`${stylesForMain ? stylesForMain : "items-center"
        }  w-full mx-auto  flex flex-col  `}
    >
      <div
        className={`${stylesForTitle
          ? stylesForTitle
          : "sm:text-[36px] text-[24px] leading-[31.12px]  font-bold sm:leading-[46.69px]"
          }  w-full  text-[#1C1934] text-center capitalize `}
      >
        {title}{" "}
      </div>
      <div className={` ${stylesForPara} w-full text-[#5E5D6D] text-center `}>
        {para}
      </div>
    </div>
  );
};

export default TitleAndParagraph;
