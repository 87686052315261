import { API } from "../globals";
import { buildHeaders } from "./utils";

export const WalletApi = {
  topUp: (amount) => {
    return fetch(`${API}/wallet/topup`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ amount: amount }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  wallet: () => {
    return fetch(`${API}/wallet`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getTopupOptions: () => {
    return fetch(`${API}/wallet/topup-options`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  createIntent: (amount, currency) => {
    return fetch(`${API}/wallet/create-intent`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ amount: amount, currency: currency }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  addFunds: (paymentIntent) => {
    return fetch(`${API}/wallet/add-funds`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(paymentIntent),
    })
      .then((response) => {
        console.log(response);
        // setTimeout(() => window.location.reload(), 2000);

        return response.json();
      })
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
};
