import { createStore, applyMiddleware, compose } from "redux";
import reducers from "../reducers";
import reduxThunk from "redux-thunk";
import logger from "redux-logger";

const composeEnhancers =
	typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		  })
		: compose;

const middlewares = [];

middlewares.push(reduxThunk);

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
	// dev code
	middlewares.push(logger);
}

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// const store = createStore(reducer(history), enhancer);

const store = createStore(reducers, enhancer);

export default store;
