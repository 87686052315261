import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import ImageRetina from "../ImageRetina";
import Logo1x from "../../assets/images/logo-hex.png";
import Logo2x from "../../assets/images/logo-hex@2x.png";
import styles from "./logo.module.scss";

function Logo({ center, black, header, leftPanel }) {
  return (
    <div
      className={cx(
        styles.logo,
        center && styles.logo__center,
        header && styles.logo__header,
        leftPanel && styles.logo__leftPanel
      )}
    >
      {/* {center && (
        <div
          className={cx(
            styles.text,
            styles.text__first,
            black ? styles.text__black : ""
          )}
        >
          <div className={styles.title}>Verbyo</div>
        </div>
      )} */}

      <ImageRetina
        width={window.innerWidth > 450 ? 90 : undefined}
        img1x={Logo1x}
        img2x={Logo2x}
        alt="Verbyo Business Logo"
      />
      <div className={cx(styles.text, black ? styles.text__black : "")}>
        {!center && <div className={styles.title}>Verbyo</div>}
        <div className={`md:text-[26px] md:text-[#262F61] font-bold text-lg`}>
          BUSINESS
        </div>
      </div>
    </div>
  );
}

Logo.defaultProps = {
  center: false,
  black: false,
  header: false,
  leftPanel: false,
};

Logo.propTypes = {
  center: PropTypes.bool,
  black: PropTypes.bool,
  header: PropTypes.bool,
  leftPanel: PropTypes.bool,
};

export default Logo;
