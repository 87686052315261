import React from "react";

const Button = ({ btnText, onClick, type, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className="max-w-[120px] hover:opacity-90 disabled:bg-gray-400 disabled:cursor-not-allowed w-full h-[40px] bg-[#0D2174] text-[#fff] text-[16px] font-semibold"
    >
      {btnText}
    </button>
  );
};

export default Button;
