import {
  LOAD_VIDEOS,
  START,
  SUCCESS,
  ERROR,
  LOGOUT,
  DELETE_USER,
} from "../actions/types";

const defaultsState = {
  loading: false,
  loaded: false,
  error: false,
  entities: null,
};
export default function videos(state = defaultsState, action) {
  switch (action.type) {
    case LOAD_VIDEOS + START:
      return {
        loading: true,
        loaded: false,
        error: false,
      };
    case LOAD_VIDEOS + SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: false,
        entities: action.payload || false,
      };
    case LOAD_VIDEOS + ERROR:
      return {
        loading: false,
        loaded: false,
        error: true,
        entities: null,
      };
    case LOGOUT:
    case DELETE_USER:
      return {
        loading: false,
        loaded: false,
        error: false,
        entities: null,
      };
    default:
      return state;
  }
}
