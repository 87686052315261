import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { getDetailedRaffleStats } from "../../actions";
import {
  raffleDetailsLoading,
  raffleDetailsSelector,
  raffleDetailsSelectorLoaded,
} from "../../selectors";
import SkeletonLoader from "../CampaignDetailsPage/SkeletonLoader";
import Header from "../CampaignDetailsPage/sections/Header";
import Tabs from "../CampaignDetailsPage/sections/Tabs";
import DetailsTab from "./sections/DetailsTab";
import StatisticsTab from "./sections/StatisticsTab";
import { DASHBOARD_CAMPAIGN_5 } from "../../globals";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Index = ({ fetchDetails, raffleDetails, loaded, _loading }) => {
  const [active, setActive] = React.useState(0);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const history = useHistory();

  const colors = {
    pending: "#c9c300",
    active: "#23AE19",
    live: "#23AE19",
    paused: "#4B5563",
    stopped: "#B91C1C",
    finished: "#2563eb",
    rejected: "#AE1919",
    completed: "bg-gray-500",
    draft: "#8719AE",
  };
  const color = colors[raffleDetails?.status];

  useEffect(() => {
    fetchDetails(id);
  }, []);

  if (_loading || !raffleDetails) {
    return (
      <div className="">
        <SkeletonLoader />
      </div>
    );
  }
  return (
    <div>
      <Header
        isRaffle={true}
        fetchDetails={() => fetchDetails(id)}
        status={raffleDetails.status}
        color={color}
        campaignDetails={raffleDetails}
        raffle={true}
        onRaffleEdit={() => {
          history.push({
            pathname: DASHBOARD_CAMPAIGN_5,
            state: { raffle: raffleDetails },
          });
        }}
      />
      <Tabs
        status={raffleDetails.status}
        active={active}
        setActive={setActive}
        raffle={true}
      />
      {active === 0 && <DetailsTab color={color} data={raffleDetails} />}
      {active === 1 && <StatisticsTab data={raffleDetails} color={color} />}
    </div>
  );
};

const mapStateToProps = (store) => ({
  raffleDetails: raffleDetailsSelector(store),
  _loading: raffleDetailsLoading(store),
  loaded: raffleDetailsSelectorLoaded(store),
});

const mapDispatchToProps = {
  fetchDetails: getDetailedRaffleStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
