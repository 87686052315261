import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./cardContent.scss";

function CardContent({
  children,
  noPadding,
  transparent,
  column,
  padding,
  noTopPadding,
  full,
}) {
  return (
    <div
      className={cx(
        "card-content",
        noPadding ? " card-content--no-padding" : "",
        transparent ? "card-content--transparent" : "",
        column ? "card-content--column" : "",
        padding ? "card-content--padding" : "",
        noTopPadding ? "card-content--noTopPadding" : "",
        full ? "card-content--full" : ""
      )}
    >
      {children}
    </div>
  );
}

CardContent.defaultProps = {
  noPadding: false,
  transparent: false,
  column: false,
  padding: false,
  noTopPadding: false,
  full: false,
};

CardContent.propTypes = {
  noPadding: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  transparent: PropTypes.bool,
  column: PropTypes.bool,
  padding: PropTypes.bool,
  noTopPadding: PropTypes.bool,
  full: PropTypes.bool,
};

export default CardContent;
