import React from 'react'
import Switch from "react-switch";

const NewSwitchBtn = ({ campaignDetails, checked, handleChange, loading }) => {
    return (
        <div className='h-fit'>
            <Switch
                handleDiameter={13}
                width={35}
                height={19}
                className=''
                disabled={
                    (campaignDetails.status != "live" &&
                        campaignDetails.status != "paused") ||
                    loading
                }
                checkedIcon={false}
                uncheckedIcon={false}
                // checkedHandleIcon={
                //     <svg
                //         xmlns="http://www.w3.org/2000/svg"
                //         fill="none"
                //         viewBox="0 0 24 24"
                //         stroke-width="4"
                //         stroke="currentColor"
                //         class="w-5 h-5 mx-auto align-middle mt-[2.5px]"
                //     >
                //         <path
                //             stroke-linecap="round"
                //             stroke-linejoin="round"
                //             d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                //         />
                //     </svg>
                // }
                // uncheckedHandleIcon={
                //     <svg
                //         xmlns="http://www.w3.org/2000/svg"
                //         viewBox="0 0 24 24"
                //         stroke-width="1.5"
                //         stroke="currentColor"
                //         class="w-5 h-5 mx-auto align-middle mt-[2.5px]"
                //         fill="currentColor"
                //     >
                //         <path
                //             stroke-linecap="round"
                //             stroke-linejoin="round"
                //             d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                //         />
                //     </svg>
                // }
                onChange={handleChange}
                checked={checked}
            />
        </div>
    )
}

export default NewSwitchBtn