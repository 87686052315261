import React from "react";
import ProgressBar from "./ProgressBar";
import Tooltip from "../../Tooltip-v2";
import IconCircle from "../../../assets/images/icons/icon-circle-blue.svg";

const ByAge = ({ ageData, total, tooltip }) => {
  return (
    <div className="max-w-[390px] w-full bg-white p-[15px] rounded-[10px] max-h-[300px] min-h-[300px] ">
      <div className="relative w-fit flex items-center gap-2">
        <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width={30} height={30} rx={5} fill="#F4F1F1" />
          <g clipPath="url(#clip0_0_29)">
            <path d="M21 6.79167C21 6.35444 21.3358 6 21.75 6C22.1642 6 22.5 6.35444 22.5 6.79167V7.58333H23.25C23.6642 7.58333 24 7.93778 24 8.375C24 8.81222 23.6642 9.16667 23.25 9.16667H22.5V9.95833C22.5 10.3956 22.1642 10.75 21.75 10.75C21.3358 10.75 21 10.3956 21 9.95833V9.16667H20.25C19.8358 9.16667 19.5 8.81222 19.5 8.375C19.5 7.93778 19.8358 7.58333 20.25 7.58333H21V6.79167Z" fill="#0F0F0F" />
            <path d="M21.8952 12.3924C22.2762 12.2207 22.7228 12.4057 22.8501 12.8217C23.3588 14.4825 23.3844 16.2731 22.9142 17.9592C22.3731 19.8994 21.2091 21.5803 19.622 22.7134C18.0348 23.8465 16.1235 24.3611 14.2161 24.1689C12.3088 23.9768 10.5242 23.0898 9.16879 21.6603C7.81337 20.2308 6.97161 18.3478 6.78799 16.3346C6.60436 14.3214 7.09032 12.3035 8.16246 10.6272C9.2346 8.95098 10.8261 7.72092 12.6637 7.14815C14.2607 6.65041 15.9569 6.67605 17.5308 7.21154C17.9251 7.34567 18.1007 7.81688 17.9384 8.21913C17.776 8.62138 17.3352 8.80643 16.9385 8.68077C15.6888 8.28496 14.3515 8.27865 13.0899 8.67186C11.5875 9.14012 10.2864 10.1458 9.4099 11.5162C8.53336 12.8866 8.13605 14.5364 8.28618 16.1823C8.43631 17.8282 9.12449 19.3677 10.2326 20.5364C11.3408 21.7052 12.7998 22.4302 14.3591 22.5874C15.9185 22.7445 17.4812 22.3238 18.7787 21.3974C20.0763 20.471 21.028 19.0968 21.4703 17.5106C21.8418 16.1786 21.8348 14.7669 21.4587 13.4482C21.3394 13.0295 21.5143 12.5641 21.8952 12.3924Z" fill="#0F0F0F" />
            <path d="M11.7829 13.2396L11.4708 13.4043C11.0248 13.6397 10.5 13.2973 10.5 12.771C10.5 12.5027 10.6436 12.2576 10.8708 12.1376L11.8416 11.6252C11.9458 11.5703 12.0606 11.5417 12.177 11.5417H12.75C13.1642 11.5417 13.5 11.8961 13.5 12.3333V18.6667C13.5 19.1039 13.1642 19.4583 12.75 19.4583C12.3358 19.4583 12 19.1039 12 18.6667V13.3812C12 13.2635 11.8826 13.1869 11.7829 13.2396Z" fill="#0F0F0F" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.5 13.125C19.5 12.2505 18.8284 11.5417 18 11.5417H16.5C15.6716 11.5417 15 12.2505 15 13.125V14.3125C15 14.9683 15.5037 15.5 16.125 15.5C15.5037 15.5 15 16.0317 15 16.6875V17.875C15 18.7495 15.6716 19.4583 16.5 19.4583H18C18.8284 19.4583 19.5 18.7495 19.5 17.875V16.6875C19.5 16.0317 18.9963 15.5 18.375 15.5C18.9963 15.5 19.5 14.9683 19.5 14.3125V13.125ZM18 13.9167C18 13.4794 17.6642 13.125 17.25 13.125C16.8358 13.125 16.5 13.4794 16.5 13.9167C16.5 14.3539 16.8358 14.7083 17.25 14.7083C17.6642 14.7083 18 14.3539 18 13.9167ZM18 17.0833C18 17.5206 17.6642 17.875 17.25 17.875C16.8358 17.875 16.5 17.5206 16.5 17.0833C16.5 16.6461 16.8358 16.2917 17.25 16.2917C17.6642 16.2917 18 16.6461 18 17.0833Z" fill="#0F0F0F" />
          </g>
          <defs>
            <clipPath id="clip0_0_29">
              <rect width={18} height={19} fill="white" transform="translate(6 6)" />
            </clipPath>
          </defs>
        </svg>
        <div className="font-bold text-sm flex items-center gap-2">
          By Age{" "}
        </div>
        <Tooltip
          style={{ width: "14px", height: "14px" }} small={true} content={[tooltip]} />
      </div>

      <div className="flex justify-between flex-col  gap-4 w-full mt-6">
        <div className="flex items-end">
          <ProgressBar
            barColor={"#0D2174"}
            label="18 - 30 Years Old"
            trackColor={"#D6DFEC"}
            progress={ageData?.age18_30 ? (ageData?.age18_30 / total) * 100 : 0}
          />
          <p className="text-xs font-bold relative left-2 top-[4px]">
            {ageData?.age18_30}
          </p>
        </div>
        <div className="flex items-end">
          <ProgressBar
            barColor={"#0D2174"}
            label="31 - 50 Years Old"
            trackColor={"#D6DFEC"}
            progress={ageData?.age31_50 ? (ageData?.age31_50 / total) * 100 : 0}
          />
          <p className="text-xs font-bold relative left-2 top-[4px]">
            {ageData?.age31_50}
          </p>
        </div>

        <div className="flex items-end">
          <ProgressBar
            barColor={"#0D2174"}
            label="50+ Years Old"
            trackColor={"#D6DFEC"}
            progress={
              ageData?.age50Above ? (ageData?.age50Above / total) * 100 : 0
            }
          />
          <p className="text-xs font-bold relative left-2 top-[4px]">
            {ageData?.age50Above}
          </p>
        </div>
      </div>
      <div className="font-semibold text-base mt-6">Average Age</div>
      <div className="text-[#7C828A] text-sm">
        <span className="text-black">
          {ageData?.averageAge ? ageData?.averageAge : 0}
        </span>{" "}
        Years Old
      </div>
    </div>
  );
};

export default ByAge;
