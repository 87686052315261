export const authSelectorLoaded = (store) => store.auth.loaded;
export const authSelectorLoading = (store) => store.auth.loading;
export const authSelectorUserData = (store) =>
  store.auth ? store.auth.data : null;
export const authSelectorIsUserLogin = (store) => store.auth && !!store.auth.id;
export const authenticatedWithEmail = (store) =>
  store.auth ? store.auth.accountEmail : null;
export const authUserType = (store) => (store.auth ? store.auth.status : null);
export const authSelectorType = (store) => store.auth.type;
export const authSelectorStatus = (store) => store.auth.status;
export const authSelectorId = (store) => store.auth.id;

export const userData = (store) => (store.auth ? store.auth.data : null);
export const userWallet = (store) =>
  store.auth.wallet ? store.auth.wallet : null;

export const budgetMinimals = (store) => store.auth.budgetMinimals ? store.auth.budgetMinimals : null;

export const campaignsSelectorLoaded = (store) => store.campaigns.loaded;
export const campaignsSelectorLoading = (store) => store.campaigns.loading;
export const campaignsSelector = (store) =>
  store.campaigns ? store.campaigns.entities : null;
export const campaignEdit = (store) =>
  store.campaigns.editData ? store.campaigns.editData : null;

export const rafflesSelectorLoaded = (store) => store.raffles.loaded;
export const rafflesSelectorLoading = (store) => store.raffles.loading;
export const rafflesSelector = (store) =>
  store.raffles ? store.raffles.entities : null;
export const raffleEdit = (store) =>
  store.raffles.editData ? store.raffles.editData : null;

export const statisticsSelectorLoaded = (store) => store.statistics.loaded;
export const statisticsSelectorLoading = (store) => store.statistics.loading;
export const statisticsSelector = (store) =>
  store.statistics ? store.statistics.data : null;

export const transactionsSelectorLoaded = (store) => store.transactions.loaded;
export const transactionsSelectorLoading = (store) =>
  store.transactions.loading;
export const transactionsSelector = (store) =>
  store.transactions ? store.transactions.entities : null;

export const usersSelectorLoaded = (store) => store.users.loaded;
export const usersSelectorLoading = (store) => store.users.loading;
export const usersSelector = (store) =>
  store.users ? store.users.entities : null;

export const offersSelectorLoaded = (store) => store.offers.loaded;
export const offersSelectorLoading = (store) => store.offers.loading;
export const offersSelector = (store) =>
  store.offers ? store.offers.entities : null;

export const clientsSelectorLoaded = (store) => store.clients.loaded;
export const clientsSelectorLoading = (store) => store.clients.loading;
export const clientsSelector = (store) =>
  store.clients ? store.clients.entities : null;

export const campaignsAdminSelectorLoaded = (store) =>
  store.campaignsAdmin.loaded;
export const campaignsAdminSelectorLoading = (store) =>
  store.campaignsAdmin.loading;
export const campaignsAdminSelector = (store) =>
  store.campaignsAdmin ? store.campaignsAdmin.entities : null;

export const adminStatisticsSelector = (store) =>
  store.adminStats ? store.adminStats.data : null;

export const adminSearch = (store) =>
  store.search ? store.search.search : null;

export const adminFilter = (store) =>
  store.search ? store.search.filter : null;

export const errors = (store) => store.errors;

export const videosSelectorLoaded = (store) => store.videos.loaded;
export const videosSelectorLoading = (store) => store.videos.loading;
export const videosSelector = (store) =>
  store.videos ? store.videos.entities : null;

export const notificationsSelectorLoaded = (store) =>
  store.notifications.loaded;
export const notificationsSelectorLoading = (store) =>
  store.notifications.loading;
export const notificationsSelector = (store) => {
  return store.notifications ? store.notifications.entities : null;
};

export const interestSelectorLoaded = (store) => store.interestStats.loaded;
export const interestSelectorLoading = (store) => store.interestStats.loading;
export const interestSelector = (store) => {
  return store.interestStats ? store.interestStats.entities : null;
};

export const campaignDetailsSelectorLoaded = (store) =>
  store.campaignStats.loaded;
export const campaignDetailsLoading = (store) => store.campaignStats.loading;
export const campaignDetailsSelector = (store) => {
  return store.campaignStats ? store.campaignStats.entities : null;
};
export const raffleDetailsSelectorLoaded = (store) => store.raffleStats.loaded;
export const raffleDetailsLoading = (store) => store.raffleStats.loading;
export const raffleDetailsSelector = (store) => {
  return store.raffleStats ? store.raffleStats.entities : null;
};
export const postsDetailsSelectorLoaded = (store) => store.postStats.loaded;
export const postsDetailsLoading = (store) => store.postStats.loading;
export const postsDetailsSelector = (store) => {
  return store.raffleStats ? store.postStats.entities : null;
};
