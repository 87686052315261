import { LOAD_ADMIN_STATS, START, SUCCESS, ERROR } from '../actions/types';

const defaultsState = {
  loading: false,
  loaded: false,
  error: false,
  data: null,
};

export default function adminStats(state = defaultsState, action) {
  switch (action.type) {
    case LOAD_ADMIN_STATS + START:
      return {
        loading: true,
        loaded: false,
        error: false
      };
    case LOAD_ADMIN_STATS + SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: false,
        data: action.payload || false
      };
    case LOAD_ADMIN_STATS + ERROR:
      return {
        loading: false,
        loaded: false,
        error: true
      };
      
    default:
      return state;
  }
}