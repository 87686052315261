import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { LOGIN_PATH, SIGN_UP_PATH } from "../../globals";
import FormLogo from "../FormLogo/FormLogo";
import styles from "./formDesktopHeader.module.scss";

import iconArrow from "../../assets/images/icons/icon-arrow-login.svg";

function FormDesktopHeader({ onRegister }) {
  return (
    <div className={styles.formDesktopHeader}>
      <div className="wrapper wrapper--v3">
        <div className={styles.formDesktopHeader__wrapper}>
          <div className={styles.formLogo}>
            <RouterLink to="/">
              <FormLogo color="purple" />
            </RouterLink>
          </div>
          {onRegister ? (
            <RouterLink className={styles.link} to={LOGIN_PATH}>
              Log In <img src={iconArrow} alt="Arrow" />
            </RouterLink>
          ) : (
            <RouterLink className={styles.link} to={SIGN_UP_PATH}>
              Open Account <img src={iconArrow} alt="Arrow" />
            </RouterLink>
          )}
        </div>
      </div>
    </div>
  );
}

FormDesktopHeader.defaultProps = {
  onRegister: true,
};

FormDesktopHeader.propTypes = {
  onRegister: PropTypes.bool,
};

export default FormDesktopHeader;
