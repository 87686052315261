import React, { useMemo } from "react";
import StarIcon from "../../assets/images/icons/star-badge.svg";
import { useState } from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  DeleteDoc,
  getVerified,
  uploadDoc,
} from "../../services/userAuthService";
import { fetchUser } from "../../actions";
import {
  authSelectorId,
  authSelectorType,
  campaignsSelector,
  userData,
} from "../../selectors";
import { connect } from "react-redux";
import { useEffect } from "react";
import PendingIcon from "../../assets/images/icons/pending-icon.svg";
import { getVerificationDocs } from "../../services/adminService";
import validator from "validator";
import CloudIcon from "../../assets/images/icons/cloud-icon.svg";
import EclamIcon from "../../assets/images/icons/exclamation.svg";
import VfVid from "../../assets/images//verification-vid.png";
import ReactPlayer from "react-player/youtube";
import FoundationVerification from "../VerificationComponents/FoundationVerification";
import ProfileCreation from "../VerificationComponents/ProfileCreation";
import SideBar from "../VerificationComponents/SideBar";
import CompanyDocs from "../VerificationComponents/CompanyDocs";

const Index = ({ user, fetchUser, type, id: userId, campaigns }) => {
  const [ein, setEin] = useState(null);
  const [aoi, setAoi] = useState(null);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    ein: null,
    aoi: null,
    website: "",
    description: "",
    instaSocial: "",
    facebookSocial: "",
    twitterSocial: "",
    logo: "",
    foundationName: "",
  });
  const [pending, setPending] = useState(false);
  const [update, setUpdate] = useState(false);

  const history = useHistory();
  const [playing, setPlaying] = useState(false);
  const [step, setStep] = useState(1);
  useEffect(() => {
    if (type !== "ngo") {
      history.push("/dashboard");
    } else if (user.verified && user.verified !== "rejected") {
      history.push("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (user.verified == "rejected") {
      getVerificationDocs(userId).then((res) => {
        setUpdate(res.id);
        setForm({
          ...form,
          ein: res.ein,
          aoi: res.aoi,
          website: res.website,
          description: res.description,
          instaSocial: res.instaSocial,
          facebookSocial: res.facebookSocial,
          twitterSocial: res.twitterSocial,
          logo: res.logo,
          foundationName: res.foundationName,
        });

        setEin({
          name: res.ein.split("/")[res.ein.split("/").length - 1],
        });
        setAoi({
          name: res.aoi.split("/")[res.aoi.split("/").length - 1],
        });
        setLogo({
          name: res.logo.split("/")[res.logo.split("/").length - 1],
        });
      });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (update) {
      getVerified({
        ...form,
        id: update,
      }).then(() => {
        setLoading(false);
        setPending(true);
      });
      return;
    }
    getVerified(form).then(() => {
      setLoading(false);
      setPending(true);
    });
  };

  const disabled = !form.ein || !form.aoi || !form.website || !form.description;
  const launchedOnTrial = useMemo(
    () =>
      campaigns?.filter((item) => {
        return item.status !== 4;
      })?.length,
    [campaigns]
  );

  return (
    <>
      {playing && (
        <div
          onClick={() => setPlaying(false)}
          className="fixed top-0 left-0 p-10 md:px-20 w-screen h-screen bg-black bg-opacity-40 z-[1000]"
        >
          <ReactPlayer
            url="https://www.youtube.com/watch?v=CNyYcBAjV-w"
            playing
            controls
            width={"100%"}
            height={"100%"}
          />
          <style>
            {`iframe {
              display: block;
            }`}
          </style>
        </div>
      )}

      <div className="sm:pt-[55px] min-h-[80vh] pb-14">
        <div className="max-w-[914px]  w-full flex gap-[14px] mx-auto">
          <SideBar form={form} step={step} setStep={setStep} />
          {step === 1 && (
            <FoundationVerification
              onStart={() => {
                setStep(2);
              }}
              setPlaying={setPlaying}
            />
          )}
          {step === 2 && (
            <ProfileCreation
              setLogo={setLogo}
              logo={logo}
              form={form}
              setForm={setForm}
              setStep={setStep}
            />
          )}
          {step === 3 && (
            <CompanyDocs form={form} onSubmit={handleSubmit}>
              <Button
                name={"Articles of Incorporation"}
                onChange={(e, location) => {
                  setAoi(e.target.files[0]);
                  setForm({ ...form, aoi: location });
                }}
                onRemove={() => {
                  DeleteDoc({ file: form.aoi }).then((res) => {
                    setForm({ ...form, aoi: null });
                    setAoi(null);
                  });
                }}
                link={form.aoi}
                uploaded={aoi}
                update={update}
              />
              <Button
                name={"EIN"}
                onChange={(e, location) => {
                  setEin(e.target.files[0]);
                  setForm({ ...form, ein: location });
                }}
                onRemove={(e) => {
                  DeleteDoc({ file: form.ein }).then((res) => {
                    setForm({ ...form, ein: null });
                    setEin(null);
                  });
                }}
                link={form.ein}
                uploaded={ein}
                update={update}
              />
            </CompanyDocs>
          )}
        </div>
        {pending && (
          <PendingModal
            onClose={() => {
              fetchUser();
            }}
          />
        )}
      </div>
    </>
  );
};
const Button = ({ name, onChange, uploaded, onRemove, link, update }) => {
  const [loading, setLoading] = useState(false);
  const inref = useRef(null);
  return (
    <div className="flex items-center sm:flex-row flex-col mt-12 sm:mt-0 sm:gap-8 gap-3 ">
      <input
        ref={inref}
        className="opacity-0 pointer-events-none absolute"
        type="file"
        onChange={(e) => {
          const form = new FormData();
          setLoading(true);
          form.append("media", e.target.files[0]);
          uploadDoc(form).then((res) => {
            onChange(e, res.location);
            setLoading(false);
          });
        }}
        accept=".pdf,.jpg,.jpeg,.png,.svg+xml"
      />
      <h4 className="text-[#202020] max-w-[150px] whitespace-nowrap w-full text-sm font-semibold">
        {name}
      </h4>

      <button
        type="button"
        onClick={() => {
          if (uploaded) return;
          inref.current.click();
        }}
        className={`text-xs  ${uploaded
          ? "bg-[#D4D4D4] text-black hidden sm:block"
          : "bg-[#0D2174] text-white"
          }  font-medium text-base whitespace-nowrap px-8 max-w-[280px] w-full sm:w-auto sm:py-[6px] py-3  ml-4 hover:opacity-80`}
      >
        {loading ? (
          <>
            {" "}
            <svg
              aria-hidden="true"
              role="status"
              class="inline w-4 h-4 mr-3 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Loading...
          </>
        ) : uploaded ? (
          "Uploaded"
        ) : (
          "Upload"
        )}
      </button>

      {uploaded && (
        <div className="flex items-center gap-1">
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="text-[10px] hover:text-blue-500 font-medium leading-4"
          >
            {uploaded.name}
          </a>
          <button
            type="button"
            onClick={() => {
              inref.current.value = null;
              onRemove();
            }}
            className="hover:opacity-80"
          >
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.27295 0C2.80765 0 0 2.80765 0 6.27295C0 9.73825 2.80765 12.5459 6.27295 12.5459C9.73825 12.5459 12.5459 9.73825 12.5459 6.27295C12.5459 2.80765 9.73825 0 6.27295 0ZM9.34872 7.9196C9.4676 8.03848 9.4676 8.23072 9.34872 8.3496L8.34707 9.34872C8.22819 9.4676 8.03595 9.4676 7.91707 9.34872L6.27295 7.68942L4.6263 9.34872C4.50742 9.4676 4.31518 9.4676 4.1963 9.34872L3.19718 8.34707C3.0783 8.22819 3.0783 8.03595 3.19718 7.91707L4.85648 6.27295L3.19718 4.6263C3.0783 4.50742 3.0783 4.31518 3.19718 4.1963L4.19883 3.19465C4.31771 3.07577 4.50995 3.07577 4.62883 3.19465L6.27295 4.85648L7.9196 3.19718C8.03848 3.0783 8.23072 3.0783 8.3496 3.19718L9.35125 4.19883C9.47013 4.31771 9.47013 4.50995 9.35125 4.62883L7.68942 6.27295L9.34872 7.9196Z"
                fill="#FB1111"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export const PendingModal = ({ onClose }) => {
  return (
    <div
      onClick={() => {
        onClose();
      }}
      className="bg-black  z-[1000] flex items-center justify-center bg-opacity-80 w-screen h-screen fixed top-0 left-0"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="text-center rounded-xl sm:rounded-md flex items-center justify-center flex-col bg-white shadow-xl py-11 px-12 sm:max-w-[700px] max-w-[calc(100vw_-_40px)] w-full"
      >
        <img src={PendingIcon} alt="pending" />
        <h4 className="sm:text-[22px] text-[20px] mt-9 font-semibold text-[#4E588D] ">
          Pending Verification
        </h4>

        <p className="font-normal text-sm mt-5 text-black">
          Your documents are currently under review, and this procedure
          typically takes between 1 and 3 business days. Once the application is
          approved, we will notify you via email. In the meantime, feel free to
          utilize your account.
        </p>
        <div className="mx-auto w-full mt-10 flex items-center justify-center">
          <button
            onClick={onClose}
            className="py-3 font-semibold hover:opacity-80 bg-[#0D2174] text-white text-[20px]  max-w-[300px] w-full mx-auto"
          >
            Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

// export default Index;

const mapStateToProps = (state) => {
  return {
    user: userData(state),
    type: authSelectorType(state),
    id: authSelectorId(state),
    campaigns: campaignsSelector(state),
  };
};

const mapDispatchToProps = {
  fetchUser: fetchUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
