import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const MobileSelect = ({ value, onChange }) => {
  const [opened, setOpened] = useState(false);
  const options = [
    { value: "campaigns", label: "Campaigns" },
    { value: "raffles", label: "Raffles" },
  ];
  const close = () => setOpened(false);
  useEffect(() => {
    window.addEventListener("click", close);
    return () => window.removeEventListener("click", close);
  }, []);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="relative"
    >
      <div
        onClick={() => setOpened(!opened)}
        className="flex  items-center gap-3 text-base font-semibold capitalize leading-5 px-4 bg-[#F3F3F3] py-[9px] cursor-pointer hover:opacity-90 rounded-md"
      >
        {value}
        <svg
          width="15"
          height="8"
          viewBox="0 0 15 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0.25L7.5 7.75L15 0.25H0Z" fill="black" />
        </svg>
      </div>
      {opened && (
        <div className="w-full absolute rounded-md bg-white shadow">
          <div className="flex flex-col ">
            {options.map((option) => (
              <div
                onClick={() => {
                  setOpened(false);
                  onChange(option);
                }}
                key={option.value}
                className={`flex
                  ${option.value === value && "bg-gray-100"}
                  items-center gap-3 text-xs font-medium whitespace-nowrap leading-5 px-4  py-[9px] cursor-pointer hover:opacity-90 rounded-md`}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileSelect;
