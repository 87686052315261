import React from "react";
import StarIcon from "../assets/images/icons/welcome-basge.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const FoundationVerificationModal = ({ onVerified, onSkip, close }) => {
  const history = useHistory();
  return (
    <div
      onClick={() => {
        close();
        history.push(history.location.pathname);
      }}
      className="w-screen h-screen px-4 fixed z-[10000] flex items-center justify-center top-0 left-0 bg-black bg-opacity-70"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="max-w-[700px] rounded-lg sm:rounded-none flex-col text-center py-11 sm:px-12 px-6 flex items-center justify-center min-h-[514px] w-full bg-white shadow-xl"
      >
        <img src={StarIcon} alt="star" className="" />
        <h4 className="font-semibold sm:text-[22px] text-[20px] mt-5 text-[#0D2174]">
          Business Verification Needed
        </h4>
        <p className="text-sm leading-[22px] mt-4 text-black font-normal">
          Verbyo enables Foundations and NGOs to organically promote their
          initiatives through the genuine voices of millions of people,{" "}
          <span className="font-semibold">free of charge.</span>
          <br />
          <br />
          You are allowed to launch{" "}
          <span className="font-semibold">3 campaigns</span> before you need to
          undergo verification to ensure authenticity.
        </p>
        <div className="flex flex-col justify-center items-center   gap-5 w-full mt-8">
          <button
            onClick={onVerified}
            className="sm:text-[20px] !m-0 rounded-lg sm:rounded-none hover:opacity-90 bg-[#0D2174] text-white font-bold shadow sm:max-w-[300px] w-full py-[14px]"
          >
            Get Verified
          </button>
          {/* <button
            onClick={() => {
              close();
              sessionStorage.setItem("skip", true);
              history.push(history.location.pathname);
            }}
            className="sm:text-[20px] !m-0 rounded-lg sm:rounded-none hover:opacity-90  text-black border border-black font-bold shadow sm:max-w-[300px] w-full py-[14px]"
          >
            Later
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default FoundationVerificationModal;
