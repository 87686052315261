import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./checkbox.module.scss";

function RadioButton({
  id,
  items,
  onChange,
  onClickChange,
  value,
  className,
  itemClassName,
  round,
  color,
  isError,
  disabled,
}) {
  return (
    <div className={cx(styles.radio, className, styles[color])}>
      {items.map((item, i) => (
        <div className={cx(styles.checkbox, itemClassName)} key={i}>
          <input
            name={id}
            type="radio"
            id={`${id}${i}`}
            onChange={onChange}
            value={item.value}
            checked={onChange && value === item.value}
            disabled={disabled || item.disabled}
          />
          <label
            htmlFor={`${id}${i}`}
            onClick={value !== item.value ? onClickChange : () => {}}
          >
            <span
              className={cx(
                styles.main,
                round && styles.round,
                isError && styles.error
              )}
            />
            {item.label}
          </label>
          {item.below && item.below}
        </div>
      ))}
    </div>
  );
}

RadioButton.defaultProps = {
  onChange: null,
  className: null,
  itemClassName: null,
  round: false,
  color: "violent",
};

RadioButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
      below: PropTypes.element,
    })
  ).isRequired,
  onChange: PropTypes.func,
  onClickChange: PropTypes.func,
  round: PropTypes.bool,
  color: PropTypes.oneOf(["violent", "blue", "black"]),
};

export default RadioButton;
