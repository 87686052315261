import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Button from "../../Button/Button";
import CardHeader from "../CardHeader/CardHeader";
import CardContent from "../CardContent/CardContent";
import RadioButton from "../../Checkbox/RadioButton";

import styles from "./settingsTabs.module.scss";
import Select from "../../Select/Select";
import { COUNTRY_OPTIONS } from "../../../data/locationDropdowns";
import { getStatesData, getCityData } from "../../../utils/select";
import DeletePopUp from "../DeletePopUp/DeletePopUp";

export const accountTypes = ["individual", "company"];

function Details({
  account,
  onAccountChange,
  firstName,
  onFirstNameChange,
  lastName,
  onLastNameChange,
  address,
  onAddressChange,
  country,
  onCountryChange,
  state,
  onStateChange,
  city,
  onCityChange,
  contactName,
  onContactNameChange,
  email,
  onEmailChange,
  phone,
  onPhoneChange,
  otherContact,
  onOtherContactChange,
  industry,
  onIndustryChange,
  website,
  onWebsiteChange,
  companyName,
  onCompanyNameChange,
  registration,
  onRegistrationChange,
  zip,
  onZipChange,
  onSubmit,
  saving,
  saved,
  emailError,
  onDelete,
}) {
  const [modalShown, setModalShown] = useState(false);
  const show = () => {
    setModalShown(true);
  };

  const close = () => {
    setModalShown(false);
  };

  const [disable, setDisable] = useState({});
  const [disabledSave, setDisabledSave] = useState(false);

  useEffect(() => {
    if (account === accountTypes[0]) {
      if (
        !firstName ||
        !lastName ||
        !address ||
        !country ||
        !state ||
        !city ||
        !contactName ||
        !email
      ) {
        setDisabledSave(true);
      } else {
        setDisabledSave(false);
      }
    } else if (account === accountTypes[1]) {
      if (
        !companyName ||
        !registration ||
        !address ||
        !country ||
        !state ||
        !city ||
        !contactName ||
        !email
      ) {
        setDisabledSave(true);
      } else {
        setDisabledSave(false);
      }
    }
  }, [
    account,
    firstName,
    companyName,
    registration,
    lastName,
    address,
    country,
    state,
    city,
    contactName,
    email,
  ]);

  console.log("country", country);

  useEffect(() => {
    setDisable({
      firstName: firstName !== "",
      lastName: lastName !== "",
      companyName: companyName !== "",
      registration: registration !== "",
      address: address !== "",
      country: country !== "" && country !== null,
      state: state !== "" && state !== null,
      city: city !== "" && city !== null,
      zip: zip !== "",
      contactName: contactName !== "",
      email: email !== "",
      phone: phone !== "",
      otherContact: otherContact !== "",
      industry: industry !== "",
      website: website !== "",
    });
  }, [saved]);

  return (
    <div className={styles.content}>
      <div className={styles.card}>
        <CardHeader
          title="Type of account"
          subtitle="Choose if you are a company or an individual."
          color="transparent"
        />
        <CardContent transparent>
          <RadioButton
            items={[
              {
                label: (
                  <span className="fz16 text-bold va-middle">
                    Individual Account
                  </span>
                ),
                value: accountTypes[0],
              },
              {
                label: (
                  <span className="fz16 text-bold va-middle">
                    Company Account
                  </span>
                ),
                value: accountTypes[1],
              },
            ]}
            id="account"
            color="black"
            round={true}
            value={account}
            onChange={onAccountChange}
            className="card-content__row card-content__row--f"
            itemClassName="card-content__col ml0"
          />
        </CardContent>
      </div>

      <div className={styles.card}>
        {account === accountTypes[0] ? (
          <CardHeader
            title="Personal Details"
            subtitle="Please enter your personal details."
            color="transparent"
          />
        ) : (
          <CardHeader
            title="Company Details"
            subtitle="Please enter your company details."
            color="transparent"
          />
        )}
        <CardContent transparent>
          <div className="card-content__row">
            {account === accountTypes[0] ? (
              <>
                <div className="card-content__col--md">
                  <input
                    type="text"
                    id="first-name"
                    name="first-name"
                    value={firstName}
                    placeholder="First Name"
                    onChange={onFirstNameChange}
                    disabled={disable.firstName}
                  />
                </div>
                <div className="card-content__col--md">
                  <input
                    type="text"
                    id="last-name"
                    name="last-name"
                    value={lastName}
                    placeholder="Last Name"
                    onChange={onLastNameChange}
                    disabled={disable.lastName}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="card-content__col--md">
                  <input
                    type="text"
                    id="company-name"
                    name="company-name"
                    value={companyName}
                    placeholder="Company name"
                    onChange={onCompanyNameChange}
                    disabled={disable.companyName}
                  />
                </div>

                <div className="card-content__col--md">
                  <input
                    type="text"
                    id="registration"
                    name="registration"
                    value={registration}
                    placeholder="Registration number"
                    onChange={onRegistrationChange}
                    disabled={disable.registration}
                  />
                </div>
              </>
            )}
            <div className="card-content__col card-content__col--full">
              <input
                type="text"
                id="address"
                name="address"
                value={address}
                placeholder="Address"
                onChange={onAddressChange}
                disabled={disable.address}
              />
            </div>

            <div className="card-content__col--md">
              <Select
                options={COUNTRY_OPTIONS}
                placeholder="Choose country"
                value={country}
                onChange={onCountryChange}
                color="#fff"
                isDisabled={disable.country}
              />
            </div>
            <div className="card-content__col--md">
              <Select
                options={country ? getStatesData(country.value) : []}
                placeholder="Choose state"
                value={state}
                onChange={onStateChange}
                color="#fff"
                isDisabled={disable.state}
              />
            </div>

            <div className="card-content__col--md">
              <Select
                options={state ? getCityData(state.value) : []}
                placeholder="Choose city"
                value={city}
                onChange={onCityChange}
                color="#fff"
                isDisabled={disable.city}
              />
            </div>

            <div className="card-content__col--md">
              <input
                type="text"
                id="zip-code"
                name="zip-code"
                value={zip}
                placeholder="Choose Zip Code (optional)"
                onChange={onZipChange}
                disabled={disable.zip}
              />
            </div>
          </div>
        </CardContent>
      </div>

      <div className={styles.card}>
        <CardHeader
          title="Contact Details"
          subtitle="Please enter your contact details."
          color="transparent"
        />
        <CardContent transparent>
          <div className="card-content__row">
            <div className="card-content__col--md">
              <input
                type="text"
                id="contact-name"
                name="contact-name"
                value={contactName}
                placeholder="Your name"
                onChange={onContactNameChange}
                disabled={disable.contactName}
              />
            </div>

            <div className="card-content__col--md">
              <input
                type="text"
                id="email"
                name="email"
                value={email}
                placeholder="Email Address"
                onChange={onEmailChange}
                disabled={disable.email}
              />
              {emailError && (
                <span className={styles.error}>Invalid email address</span>
              )}
            </div>

            <div className="card-content__col--md">
              <input
                type="text"
                id="phone"
                name="phone"
                value={phone}
                placeholder="Phone Number"
                onChange={onPhoneChange}
                disabled={disable.phone}
              />
            </div>

            <div className="card-content__col--md">
              <input
                type="text"
                id="other"
                name="other"
                value={otherContact}
                placeholder="Other contact (Telegram, Skype)"
                onChange={onOtherContactChange}
                disabled={disable.otherContact}
              />
            </div>

            <div className="card-content__col--md">
              <input
                type="text"
                id="industry"
                name="industry"
                value={industry}
                placeholder="Industry"
                onChange={onIndustryChange}
                disabled={disable.industry}
              />
            </div>

            <div className="card-content__col--md">
              <input
                type="text"
                id="website"
                name="website"
                value={website}
                placeholder="Website"
                onChange={onWebsiteChange}
                disabled={disable.website}
              />
            </div>
          </div>
        </CardContent>
      </div>

      <div className={styles.card}>
        <CardHeader
          title="Delete your account"
          subtitle="You can delete your account."
          color="transparent"
        />
        <CardContent transparent>
          <p className="card-content__description">
            We're are sorry to see you leaving. If you want to delete your
            account press the button below and enter your password for security
            reasons. However, according to our Terms & Conditions, your data
            we'll remain in our archive for another 6 months before completely
            erase.
          </p>
          <br />
          <br />
          <p className="card-content__description">
            <Button
              text="Delete Account"
              onClick={show}
              color="white-shadow-small"
              size="h"
            />
          </p>
        </CardContent>
      </div>
      <div className={cx(styles.card, styles.mt)}>
        <Button
          text={saved ? "Saved!" : "Save"}
          fullWidth
          disabled={disabledSave}
          onClick={onSubmit}
          loading={saving}
          size="h"
        />
      </div>

      <DeletePopUp onClose={close} open={modalShown} deleteAcc={onDelete} />
    </div>
  );
}

Details.defaultProps = {
  saving: false,
  saved: false,
  emailError: false,
};
Details.propTypes = {
  account: PropTypes.oneOf(accountTypes).isRequired,
  onAccountChange: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  onFirstNameChange: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  onLastNameChange: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  country: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  onCountryChange: PropTypes.func.isRequired,
  state: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  onStateChange: PropTypes.func.isRequired,
  city: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  onCityChange: PropTypes.func.isRequired,
  zip: PropTypes.string.isRequired,
  onZipChange: PropTypes.func.isRequired,
  contactName: PropTypes.string.isRequired,
  onContactNameChange: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  onPhoneChange: PropTypes.func.isRequired,
  otherContact: PropTypes.string.isRequired,
  onOtherContactChange: PropTypes.func.isRequired,
  industry: PropTypes.string.isRequired,
  onIndustryChange: PropTypes.func.isRequired,
  website: PropTypes.string.isRequired,
  onWebsiteChange: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  onCompanyNameChange: PropTypes.func.isRequired,
  registration: PropTypes.string.isRequired,
  onRegistrationChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  saved: PropTypes.bool,
  emailError: PropTypes.bool,
};
export default Details;
