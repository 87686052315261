import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './closeButton.module.scss';

function CloseButton({onClick, desktopHide, light, small, revert}) {
  return (
    <button className={cx(styles.closeButton, desktopHide && styles.desktopHide, light && styles.light, small && styles.small, revert && styles.revert)} onClick={onClick}>
      <span/>
    </button>
  );
}

CloseButton.defaultProps = {
  desktopHide: false,
  light: false,
  small: false,
  revert: false
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  desktopHide: PropTypes.bool,
  light: PropTypes.bool,
  small: PropTypes.bool,
  revert: PropTypes.bool
};

export default CloseButton;
