import { API } from "../globals";
import { buildHeaders } from "./utils";

export const StatisticsApi = {
  get: () => {
    return fetch(`${API}/statistics/dash-stats`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
};

export const getDetailedCampaignStats = {
  get: (id) => {
    return fetch(`${API}/campaigns/get-complete-stats/${id}`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
};
export const getDetailedRaffleStats = {
  get: (id) => {
    return fetch(`${API}/campaigns/get-raffle-stats/${id}`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
};
export const getDetailedPostsStats = {
  get: (id) => {
    return fetch(`${API}/campaigns/get-user-posts/${id}`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
};
