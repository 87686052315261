import { API } from "../globals";
import { buildHeaders } from "./utils";

export const VideoApi = {
  createOrUpdateVideo: (data) => {
    console.log("create or update video", data);
    return fetch(`${API}/videoresources/create-or-update`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ ...data }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("video create", data);
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  deleteVideo: (id) => {
    return fetch(`${API}/videoresources/delete-resource`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ ...id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("video delete", data);
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getVideos: () => {
    return fetch(`${API}/videoresources/list-videoresources`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        console.log("video data", data);
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
};
