import React from "react";
import Tooltip from "../../Tooltip-v2";

const LinkBox = ({ color, heading, number, tooltip }) => {
  return (
    <div
      style={{
        borderTop: `3px solid ${color}`,
      }}
      className="w-1/3 bg-white p-6 pb-10 min-h-[170px]"
    >
      <div className="relative w-fit">
        <p className="text-lg text-black font-semibold">Link Clicks</p>
        <Tooltip
          style={{
            position: "absolute",
            right: "-28px",
            top: "4px",
          }}
          content={[
            `How many people clicked on the link you have inserted in raffle description.
          `,
          ]}
        />
      </div>
      <div className="flex items-center gap-2 pb-2 mt-8 justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24.75"
          className="pb-1"
          height="27"
          viewBox="0 0 24.75 27"
        >
          <g
            id="Icon_ionic-md-stats"
            data-name="Icon ionic-md-stats"
            transform="translate(-5.625 -4.5)"
          >
            <path
              id="Path_1802"
              data-name="Path 1802"
              d="M12.375,4.5h4.5v27h-4.5Z"
              fill="#0d2174"
            />
            <path
              id="Path_1803"
              data-name="Path 1803"
              d="M5.625,23.625h4.5V31.5h-4.5Z"
              fill="#0d2174"
            />
            <path
              id="Path_1804"
              data-name="Path 1804"
              d="M19.125,19.125h4.5V31.5h-4.5Z"
              fill="#0d2174"
            />
            <path
              id="Path_1805"
              data-name="Path 1805"
              d="M25.875,12.375h4.5V31.5h-4.5Z"
              fill="#0d2174"
            />
          </g>
        </svg>
        Waiting for data
      </div>
    </div>
  );
};

export default LinkBox;
