import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./label-v2.module.scss";
import { STATUSES } from "../../../globals";

function Label({ type }) {
  const title =
    typeof type == "string"
      ? type.slice(0, 1).toUpperCase() + type.slice(1)
      : type;
  return <div className={cx(styles.label, styles[type])}>{title}</div>;
}

Label.defaultProps = {
  content: null,
  headerVariant: "v1",
  title: "Labels & Tricks",
};

Label.propTypes = {
  type: PropTypes.oneOf(STATUSES).isRequired,
};

export default Label;
