import React from "react";
import { getCurrentAccountType } from "../api/utils";
import { authUserType, userData } from "../selectors";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { PendingModal } from "./Verification";

const MobileVerificationBadge = ({ data, userType }) => {
  const [pendingModal, setPendingModal] = React.useState(false);
  const type = getCurrentAccountType();
  const user = data;
  let ngo = type === "ngo";

  const history = useHistory();

  if (!user || userType == "ADMIN") return null;
  if (user.verified === "verified" && ngo) {
    return true;
  } else if (user.verified === null && ngo) {
    return (
      <div
        onClick={() => history.push("/foundation-verification")}
        className="px-5 mt-5 sm:hidden"
      >
        <div
          tabIndex={0}
          className="w-full cursor-pointer focus:opacity-90 p-6 flex gap-4 items-center  rounded-[10px]  bg-[#690707] "
        >
          <svg
            className="shrink-0"
            width="33"
            height="34"
            viewBox="0 0 33 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32.2678 14.426L30.0236 11.8188C29.5946 11.3238 29.2481 10.3997 29.2481 9.73971V6.93454C29.2481 5.18544 27.8125 3.74985 26.0634 3.74985H23.2582C22.6147 3.74985 21.6741 3.40333 21.1791 2.9743L18.5719 0.730169C17.4334 -0.24339 15.5688 -0.24339 14.4137 0.730169L11.823 2.9908C11.3279 3.40333 10.3874 3.74985 9.74384 3.74985H6.88916C5.14006 3.74985 3.70447 5.18544 3.70447 6.93454V9.75621C3.70447 10.3997 3.35795 11.3238 2.94543 11.8188L0.717793 14.4425C-0.239264 15.5811 -0.239264 17.4292 0.717793 18.5678L2.94543 21.1914C3.35795 21.6864 3.70447 22.6105 3.70447 23.254V26.0757C3.70447 27.8248 5.14006 29.2604 6.88916 29.2604H9.74384C10.3874 29.2604 11.3279 29.6069 11.823 30.0359L14.4302 32.2801C15.5688 33.2536 17.4334 33.2536 18.5884 32.2801L21.1956 30.0359C21.6906 29.6069 22.6147 29.2604 23.2747 29.2604H26.0799C27.829 29.2604 29.2646 27.8248 29.2646 26.0757V23.2705C29.2646 22.627 29.6111 21.6864 30.0401 21.1914L32.2843 18.5843C33.2413 17.4457 33.2413 15.5646 32.2678 14.426ZM23.3572 13.3864L15.3872 21.3564C15.1562 21.5874 14.8427 21.7194 14.5127 21.7194C14.1827 21.7194 13.8691 21.5874 13.6381 21.3564L9.64483 17.3632C9.1663 16.8847 9.1663 16.0926 9.64483 15.6141C10.1234 15.1356 10.9154 15.1356 11.3939 15.6141L14.5127 18.7328L21.6081 11.6373C22.0866 11.1588 22.8787 11.1588 23.3572 11.6373C23.8357 12.1159 23.8357 12.9079 23.3572 13.3864Z"
              fill="white"
            />
          </svg>
          <div>
            <p className="font-bold text-lg text-white">
              BUSINESS VERIFICATION
            </p>
            <p className="text-sm text-white leading-6">
              To initiate a campaign, ensure your business is verified with us.
            </p>
          </div>
        </div>
      </div>
    );
  } else if (user.verified === "pending" && ngo) {
    return (
      <>
        <div
          onClick={() => {
            setPendingModal(true);
          }}
          className="px-5 sm:hidden mt-5"
        >
          {pendingModal && (
            <PendingModal
              onClose={() => {
                setPendingModal(false);
              }}
            />
          )}
          <div
            tabIndex={0}
            className="w-full cursor-pointer focus:opacity-90 p-6 flex gap-4 items-center  rounded-[10px]  bg-[#8F6700] "
          >
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5 0C7.4085 0 0 7.4085 0 16.5C0 25.5915 7.4085 33 16.5 33C25.5915 33 33 25.5915 33 16.5C33 7.4085 25.5915 0 16.5 0ZM23.6775 22.3905C23.4465 22.7865 23.034 23.001 22.605 23.001C22.3905 23.001 22.176 22.9515 21.978 22.8195L16.863 19.767C15.5925 19.008 14.652 17.3415 14.652 15.873V9.108C14.652 8.4315 15.213 7.8705 15.8895 7.8705C16.566 7.8705 17.127 8.4315 17.127 9.108V15.873C17.127 16.467 17.622 17.3415 18.1335 17.6385L23.2485 20.691C23.8425 21.0375 24.0405 21.7965 23.6775 22.3905Z"
                fill="white"
              />
            </svg>

            <div>
              <p className="font-bold text-lg text-white">
                PENDING VERIFICATION
              </p>
              <p className="text-sm text-white leading-6">
                Your identity is currently undergoing verification.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  } else if (user.verified === "rejected" && ngo) {
    return (
      <div
        onClick={() => history.push("/foundation-verification")}
        className="px-5 mt-5 sm:hidden"
      >
        <div
          tabIndex={0}
          className="w-full cursor-pointer focus:opacity-90 p-6 flex gap-4 items-center  rounded-[10px]  bg-[#690707] "
        >
          <svg
            className="shrink-0"
            width="33"
            height="34"
            viewBox="0 0 33 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32.2678 14.426L30.0236 11.8188C29.5946 11.3238 29.2481 10.3997 29.2481 9.73971V6.93454C29.2481 5.18544 27.8125 3.74985 26.0634 3.74985H23.2582C22.6147 3.74985 21.6741 3.40333 21.1791 2.9743L18.5719 0.730169C17.4334 -0.24339 15.5688 -0.24339 14.4137 0.730169L11.823 2.9908C11.3279 3.40333 10.3874 3.74985 9.74384 3.74985H6.88916C5.14006 3.74985 3.70447 5.18544 3.70447 6.93454V9.75621C3.70447 10.3997 3.35795 11.3238 2.94543 11.8188L0.717793 14.4425C-0.239264 15.5811 -0.239264 17.4292 0.717793 18.5678L2.94543 21.1914C3.35795 21.6864 3.70447 22.6105 3.70447 23.254V26.0757C3.70447 27.8248 5.14006 29.2604 6.88916 29.2604H9.74384C10.3874 29.2604 11.3279 29.6069 11.823 30.0359L14.4302 32.2801C15.5688 33.2536 17.4334 33.2536 18.5884 32.2801L21.1956 30.0359C21.6906 29.6069 22.6147 29.2604 23.2747 29.2604H26.0799C27.829 29.2604 29.2646 27.8248 29.2646 26.0757V23.2705C29.2646 22.627 29.6111 21.6864 30.0401 21.1914L32.2843 18.5843C33.2413 17.4457 33.2413 15.5646 32.2678 14.426ZM23.3572 13.3864L15.3872 21.3564C15.1562 21.5874 14.8427 21.7194 14.5127 21.7194C14.1827 21.7194 13.8691 21.5874 13.6381 21.3564L9.64483 17.3632C9.1663 16.8847 9.1663 16.0926 9.64483 15.6141C10.1234 15.1356 10.9154 15.1356 11.3939 15.6141L14.5127 18.7328L21.6081 11.6373C22.0866 11.1588 22.8787 11.1588 23.3572 11.6373C23.8357 12.1159 23.8357 12.9079 23.3572 13.3864Z"
              fill="white"
            />
          </svg>
          <div>
            <p className="font-bold text-lg text-white">
              BUSINESS VERIFICATION
            </p>
            <p className="text-sm text-white leading-6">
              To initiate a campaign, ensure your business is verified with us.
            </p>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (store) => ({
  userType: authUserType(store),
  data: userData(store),
});
export default connect(mapStateToProps, null)(MobileVerificationBadge);
