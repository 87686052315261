import React from "react";
import ByAge from "../../CampaignDetailsPage/sections/ByAge";
import ByLocation from "../../CampaignDetailsPage/sections/ByLocation";
import GenderCard from "../../CampaignDetailsPage/sections/GenderCard";
import LinkBox from "./LinkBox";
import ViewsBox from "./ViewsBox";

const StatisticsTab = ({ data, color }) => {
  return (
    <>
      <div className="flex mt-6 items-center gap-5">
        <ViewsBox
          heading={" Number of total views"}
          number={data.userViews}
          tooltip="The number of people that viewed your raffle."
          color={color}
        />
        <ViewsBox
          heading={"Number of total SignUps"}
          number={data.usersJoined}
          tooltip="The number of people that signed up for your raffle."
          color={color}
        />
        <LinkBox color={color} />
      </div>
      <div className="flex items-center gap-5 mt-5 justify-between">
        <ByAge
          tooltip={`Age of people that signed up for your raffle.`}
          ageData={data.ageData}
          total={data.usersJoined}
        />
        <GenderCard
          tooltip={`The gender of people that signed up for your raffle.`}
          genderData={data.genderData}
          usersPosted={data.usersJoined}
        />
        <ByLocation
          tooltip={`Location of people that signed up for your raffle.`}
          locationData={data.locationData}
        />
      </div>
    </>
  );
};

export default StatisticsTab;
