import React from "react";

const Volunteer = () => {
  return (
    <svg
      width="28"
      height="23"
      viewBox="0 0 28 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.64909 8.39902H13.2991V10.849C13.2991 12.5859 14.7122 13.999 16.449 13.999C18.1859 13.999 19.599 12.5859 19.599 10.849V5.52903L22.4384 7.23527C23.2828 7.7384 23.799 8.65277 23.799 9.63277V11.7021L27.299 13.7234C27.9684 14.1084 28.2003 14.9659 27.8109 15.6352L24.3109 21.699C23.9259 22.3683 23.0684 22.5958 22.399 22.2108L17.8753 19.599H11.8991C10.3547 19.599 9.09908 18.3434 9.09908 16.799H8.39908C7.62471 16.799 6.99908 16.1734 6.99908 15.399V12.599H6.64909C6.06721 12.599 5.59909 12.1309 5.59909 11.549V9.44902C5.59909 8.86714 6.06721 8.39902 6.64909 8.39902ZM0.187235 6.76278L3.68722 0.699051C4.07222 0.0296785 4.92972 -0.197821 5.59909 0.187178L10.1228 2.79904H14.5941C15.1191 2.79904 15.6309 2.94779 16.0772 3.22342L17.5428 4.13779C17.954 4.39154 18.199 4.84216 18.199 5.32341V10.849C18.199 11.8159 17.4159 12.599 16.449 12.599C15.4822 12.599 14.6991 11.8159 14.6991 10.849V6.99903H6.64909C5.29722 6.99903 4.19909 8.09715 4.19909 9.44902V10.6959L0.699108 8.67464C0.0297356 8.28527 -0.197763 7.43215 0.187235 6.76278Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Volunteer;
