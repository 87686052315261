import { API } from "../globals";
import { buildHeaders } from "./utils";

export const CampaignApi = {
  createOrUpdate: (data) => {
    return fetch(`${API}/campaign/create-or-update`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ ...data, status: "draft" }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  saveToDraft: (data) => {
    return fetch(`${API}/campaign/create-or-update`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ ...data, status: "draft" }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  launch: (id, edited) => {
    return fetch(`${API}/campaign/send-approve`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ campaignId: id, edited }),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  uploadCover: (data) => {
    return fetch(`${API}/campaigns/uploadcover`, {
      method: "post",
      headers: buildHeaders(true),
      body: data,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  deleteCover: (data) => {
    return fetch(`${API}/campaigns/deletecover`, {
      method: "post",
      headers: buildHeaders(true),
      body: data,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  uploadCreative: (data) => {
    return fetch(`${API}/campaigns/uploadcreative`, {
      method: "post",
      headers: buildHeaders(true),
      body: data,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  deleteCreative: (data) => {
    return fetch(`${API}/campaigns/deletecreative`, {
      method: "post",
      headers: buildHeaders(true),
      body: data,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getCampaigns: () => {
    return fetch(`${API}/campaigns/get-campaigns`, {
      method: "get",
      headers: buildHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getCampaign: (id) => {
    return fetch(`${API}/camapigns/get-campaign`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ id: id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getCampaignStatistic: (id) => {
    return fetch(`${API}/statistics/get-campaigns-statistics`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ campaignId: id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getPromoters: (id) => {
    return fetch(`${API}/statistics/getpromoters-whocompleted`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ campaignId: id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  changeCampaignStatus: (campaignId, status) => {
    return fetch(`${API}/campaigns/change-status`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ campaignId, status }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  changeFavouritePost: (id, favourite) => {
    return fetch(`${API}/campaigns/updatefavouritepost`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ postId: id, favourite }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  cancelPendingCampaign: (id) => {
    return fetch(`${API}/campaigns/cancel-pending`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify({ id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  getPotentialStats: (body) => {
    return fetch(`${API}/campaigns/potential-stats`, {
      method: "post",
      headers: buildHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
};
