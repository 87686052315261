import moment from "moment/moment";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Switch from "react-switch";
import { changeStatus } from "../../../services/campaignService";

import InstagramIcon from "../../../assets/images/icons/insta-icon-blue.svg";
import FacebookIcon from "../../../assets/images/icons/fb-icon-blue.svg";
import TwitterIcon from "../../../assets/images/icons/twitter-icon-blue.svg";
import TiktokIcon from "../../../assets/images/icons/tiktok-icon-blue.svg";
import RaffleIcon from "../../../assets/images/icons/icon-awesome-gift.svg";
import IconTag from "../../../assets/images/icons/icon-tag-blue.svg";

import {
  DASHBOARD_CAMPAIGN_1,
  DASHBOARD_CAMPAIGN_2,
  DASHBOARD_CAMPAIGN_4,
  DASHBOARD_CAMPAIGN_BL,
  DASHBOARD_CAMPAIGN_GM,
  DASHBOARD_CAMPAIGN_IG,
  DASHBOARD_CAMPAIGN_YT,
} from "../../../globals";
import Tooltip from "../../Tooltip-v2";
import ProgressBar from "./ProgressBar";
import NewSwitchBtn from "../NewSwitchBtn";

const icons = {
  instagram: InstagramIcon,
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  tiktok: TiktokIcon,
  raffle: RaffleIcon,
};

const Header = ({
  campaignDetails,
  color,
  status,
  fetchDetails,
  raffle,
  currencySymbol,
  type,
  onRaffleEdit,
  data,
  isCampaign,
  isRaffle
}) => {
  const { id } = useParams();
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (status === "active" || status === "live") {
      setChecked(true);
    } else if (status == "paused") {
      setChecked(false);
    }
  }, [campaignDetails]);

  const handleChange = () => {
    setLoading(true);
    setChecked(!checked);
    changeStatus(id, checked ? "paused" : "live").finally((res) => {
      setLoading(false);
      fetchDetails();
    });
  };

  const history = useHistory();

  const {
    adPlacementData,
    locationData,
    ageData,
    performanceData,
    genderData,
    postData,
    usersPosted,
    platform,
    copyText,
    campaignType
  } = campaignDetails;
  console.log(campaignDetails, "sadasdasdsad",);

  const onEdit = () => {
    let data_path;
    const data = campaignDetails;
    switch (data?.campaignType) {
      case "instagram":
        data_path = `${DASHBOARD_CAMPAIGN_IG}/${id}`;
        break;
      case "social":
        data_path = `${DASHBOARD_CAMPAIGN_4}/${id}`;
        break;
      case "youtube":
        data_path = `${DASHBOARD_CAMPAIGN_YT}/${id}`;
        break;
      case "blog":
        data_path = `${DASHBOARD_CAMPAIGN_BL}/${id}`;
      case "organic":
        data_path = `${DASHBOARD_CAMPAIGN_1}/${id}`;
        break;
      case "scripted":
        data_path = `${DASHBOARD_CAMPAIGN_2}/${id}`;
        break;
      case "gaming":
        data_path = `${DASHBOARD_CAMPAIGN_GM}/${id}`;
        break;
      default:
        data_path = `${DASHBOARD_CAMPAIGN_1}/${id}`;
        break;
    }

    history.push({
      pathname: data_path,
      state: { campaign: data },
    });
  };
  console.log(postData, "post", campaignDetails + "%")
  return (
    <>
      {isRaffle && <><div className={`p-8 bg-white w-full   flex items-center`}>
        <div className="flex items-center gap-2">
          <img
            alt="asdasdasd"
            src={icons[campaignDetails?.platform || "raffle"]}
            className="w-6 h-6"
          />
          <div className="text-[26px] text-black font-semibold">
            {campaignDetails.name}
          </div>
        </div>
        <div
          style={{ backgroundColor: color }}
          className={`px-10 py-2  capitalize text-white ml-6 text-xs font-bold rounded-md`}
        >
          {campaignDetails.status}
        </div>
        {(campaignDetails?.status == "draft" ||
          campaignDetails?.status == "paused" ||
          campaignDetails?.status == "rejected") && (
            <button
              onClick={raffle ? onRaffleEdit : onEdit}
              className="text-base hover:underline ml-3 text-[#0D2174] font-medium"
            >
              Edit
            </button>
          )}
        <div className="font-semibold text-black text-sm ml-6">
          <span className="font-medium text-gray-700">Start on: </span>
          {!raffle
            ? campaignDetails.status !== "scheduled"
              ? moment(campaignDetails?.createdAt).format("DD MMMM YYYY")
              : moment(campaignDetails?.launchDate).format("DD MMMM YYYY")
            : moment(campaignDetails?.startDate).format("DD MMMM YYYY")}{" "}
          {raffle && (
            <span className="font-medium text-gray-700">Ends on: </span>
          )}
          {raffle && moment(campaignDetails?.endDate).format("DD MMMM YYYY")}
          {raffle &&
            ` ( ${moment(campaignDetails?.endDate).diff(
              moment(),
              "days"
            )} days )`}
        </div>
        <div
          style={{
            display: raffle ? "none" : "flex",
          }}
          className="ml-auto flex gap-3"
        >
          <button
            onClick={() => {
              fetchDetails();
            }}
            className="hover:opacity-80"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 9.291 9.297"
            >
              <path
                id="Icon_material-refresh"
                data-name="Icon material-refresh"
                d="M13.941,7.366a4.649,4.649,0,1,0,1.209,4.445H13.941a3.485,3.485,0,1,1-3.283-4.649A3.437,3.437,0,0,1,13.11,8.2l-1.871,1.871h4.067V6Z"
                transform="translate(-6.015 -6)"
                fill="#7d7b7b"
              />
            </svg>
          </button>
          <label className="flex  items-center gap-2">
            <span>
              {status === "active" || status === "live" ? "Pause" : "Go Live"}
            </span>
            <Switch
              disabled={
                (campaignDetails.status != "live" &&
                  campaignDetails.status != "paused") ||
                loading
              }
              checkedIcon={false}
              uncheckedIcon={false}
              checkedHandleIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="4"
                  stroke="currentColor"
                  class="w-5 h-5 mx-auto align-middle mt-[2.5px]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                  />
                </svg>
              }
              uncheckedHandleIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 mx-auto align-middle mt-[2.5px]"
                  fill="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                  />
                </svg>
              }
              onChange={handleChange}
              checked={checked}
            />
          </label>
        </div>
      </div>
        {!raffle && (
          <div className="w-full grid grid-cols-3 mt-5 gap-5">
            <div className="bg-white p-4 pb-6">
              <Head
                title="Posts"
                content={[
                  "The number of posts that have been executed and verified with our system.",
                ]}
              />
              <div className="font-semibold text-sm text-black mt-2  ">
                <span className=" text-[#1A5E15]">
                  Executed {postData?.executedPosts || 0}
                </span>{" "}
                of Total {postData?.totalPosts || data?.numberOfPosts || 0}
              </div>
              <div className="mt-4">
                <ProgressBar
                  showProgress={true}
                  label={"Campaign Timeline"}
                  barColor={"#0D2174"}
                  trackColor={"#9FD9FF"}
                  progress={
                    (postData?.campaignProgress
                      ? postData?.campaignProgress
                      : 0) + "%"
                  }
                />
              </div>
            </div>
            <div className="bg-white p-4 pb-6">
              <Head
                title="Campaign Budget"
                content={[
                  "The number of posts that have been executed and verified with our system.",
                ]}
              />
              {(type === "social" || type == "media") && (
                <div className="text-sm font-medium mt-4 ">
                  Social campaigns are free of charge.
                </div>
              )}
              {type !== "social" && type !== "media" && (
                <>
                  <div className="font-semibold text-sm text-black mt-2  ">
                    <span className=" text-[#1A5E15]">
                      Paid {currencySymbol}{" "}
                      {postData?.paid?.toLocaleString() || 0}{" "}
                    </span>
                    of Total {currencySymbol}{" "}
                    {postData?.totalBudget?.toLocaleString() ||
                      data?.pricePerVideo?.toLocaleString()}
                  </div>
                  <div className="mt-4">
                    <ProgressBar
                      label={"Budget Timeline"}
                      barColor={"#970C97"}
                      trackColor={"#E69EE6"}
                      progress={
                        (postData?.paid / postData?.totalBudget) * 100 + "%"
                      }
                    />
                  </div>
                </>
              )}
            </div>
            <div className="bg-white p-4 pb-6">
              <Head
                title="Unique Hashtag"
                content={[
                  "This unique hashtag helps you to track your campaign performance over social networks.",
                ]}
              />
              <br />
              <a
                rel="noreferrer"
                href={
                  platform === "facebook"
                    ? `https://www.facebook.com/hashtag/${postData?.hashTag}`
                    : platform === "instagram"
                      ? `https://www.instagram.com/explore/tags/${postData?.hashTag}/`
                      : platform === "twitter"
                        ? `https://twitter.com/search?q=%23${postData?.hashTag} &src=typed_query`
                        : `https://www.tiktok.com/search?q=%23${postData?.hashTag}&t=1669307167391`
                }
                className="font-medium text-xl  hover:text-blue-500 ml-[18px]"
                target="_blank"
              >
                #{postData?.hashTag || data?.searchTag}
              </a>
              <div className="text-black font-medium mt-3">
                Price per Post:{" "}
                <span className="font-bold">
                  {currencySymbol}&nbsp;
                  {postData?.pricePerPost?.toLocaleString() ||
                    data?.pricePerVideo?.toLocaleString() ||
                    0}
                </span>
              </div>
            </div>
          </div>
        )}</>}
      {isCampaign && <div className="sticky top-[90px] flex flex-col px-[15px] py-6 bg-white rounded-xl max-w-[400px] min-w-[400px] w-full">
        <div className="flex gap-5  justify-between items-center">
          <div className="flex gap-3 items-center ">
            <div className="w-[40px] h-[40px] rounded-[5px] p-[8px] bg-[#F4F1F1] flex justify-center items-center">
              <img
                alt="asdasdasd"
                src={icons[campaignDetails?.platform || "raffle"]}
                className="w-full h-full"
              />
            </div>
            <div className="text-[18px] text-black font-bold">
              {campaignDetails.name}
            </div>
          </div>
          <NewSwitchBtn loading={loading} campaignDetails={campaignDetails} handleChange={handleChange} checked={checked} />
        </div>
        <div className="flex flex-col">
          <div className="flex gap-3 jus mt-[10px]">
            <div
              style={{ backgroundColor: color }}
              className={`px-[12px] h-fit py-[2px]  capitalize text-white  text-xs font-bold rounded-[10px]`}
            >
              {campaignDetails.status}
            </div>
            <div className="font-semibold text-black text-sm ">
              <div>
                <span className="font-medium text-black">Start: </span>
                {!raffle
                  ? campaignDetails.status !== "scheduled"
                    ? moment(campaignDetails?.createdAt).format("DD MMMM YYYY")
                    : moment(campaignDetails?.launchDate).format("DD MMMM YYYY")
                  : moment(campaignDetails?.startDate).format("DD MMMM YYYY")}{" "}
              </div>
              {raffle && <div className="mt-[10px]">
                {raffle && (
                  <span className="font-medium text-black">End: </span>
                )}

                {raffle && moment(campaignDetails?.endDate).format("DD MMMM YYYY")}
                {raffle &&
                  ` ( ${moment(campaignDetails?.endDate).diff(
                    moment(),
                    "days"
                  )} days )`}
              </div>}
            </div>
          </div>

        </div>


        {!raffle && (
          <div className="w-full flex flex-col  mt-12 gap-[50px]">
            <div className="bg-white ">
              <Head
                svg={<svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width={30} height={30} rx={5} fill="#F4F1F1" />
                  <path d="M15.5 11.3333V15.5H18M23 15.5C23 19.6422 19.6422 23 15.5 23C11.3579 23 8 19.6422 8 15.5C8 11.3579 11.3579 8 15.5 8C19.6422 8 23 11.3579 23 15.5Z" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
                title="Posts"
                content={[
                  "Your campaign budget and timeline",
                ]}
              />

              <div className="mt-3 pl-10">
                <ProgressBar
                  showProgress={true}
                  label={"Campaign Timeline"}
                  barColor={"#0D2174"}
                  trackColor={"#9FD9FF"}
                  progress={
                    (postData?.campaignProgress
                      ? postData?.campaignProgress
                      : 0) + "%"
                  }
                />
                <div className="font-semibold text-sm text-black mt-[15px]  ">
                  <span className=" text-[#1A5E15]">
                    Executed {postData?.executedPosts || 0}
                  </span>{" "}
                  of Total {postData?.totalPosts || data?.numberOfPosts || 0}
                </div>
              </div>
            </div>
            {(campaignType !== "social" && campaignType !== "media") && <div className="bg-white ">
              <Head
                svg={<svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width={30} height={30} rx={5} fill="#F4F1F1" />
                  <path d="M13.0716 20.3571C17.0954 20.3571 20.3574 17.0953 20.3574 13.0714C20.3574 12.8269 20.3453 12.5852 20.3218 12.3469C22.4851 13.2756 24.0002 15.4251 24.0002 17.9285C24.0002 21.2818 21.2818 24 17.9287 24C15.4253 24 13.2758 22.4849 12.347 20.3217C12.5854 20.3451 12.8271 20.3571 13.0716 20.3571Z" fill="black" />
                  <path d="M13.0715 19.1429C16.4247 19.1429 19.1429 16.4247 19.1429 13.0715C19.1429 9.71829 16.4247 7 13.0715 7C9.71829 7 7 9.71829 7 13.0715C7 16.4247 9.71829 19.1429 13.0715 19.1429Z" fill="black" />
                </svg>
                }
                title="Campaign Budget"
                content={[
                  "The number of posts that have been executed and verified with our system.",
                ]}
              />
              <div className="pl-10">

                {(type === "social" || type == "media") && (
                  <div className="text-sm font-medium mt-4 ">
                    Social campaigns are free of charge.
                  </div>
                )}
                {type !== "social" && type !== "media" && (
                  <>
                    <div className="mt-3">
                      <ProgressBar
                        label={"Budget Timeline"}
                        barColor={"#970C97"}
                        trackColor={"#E69EE6"}
                        progress={postData ?
                          Math.ceil(postData?.paid / postData?.totalBudget * 100) + "%"
                          : "0%"}
                        showProgress={true}
                      />
                    </div>
                    <div className="font-semibold text-sm text-black mt-[15px]  ">
                      <span className=" text-[#1A5E15]">
                        Paid {currencySymbol}{" "}
                        {postData?.paid?.toLocaleString() || 0}{" "}
                      </span>
                      of Total {currencySymbol}{" "}
                      {postData?.totalBudget?.toLocaleString() ||
                        data?.pricePerVideo?.toLocaleString()}
                    </div>

                    <div className="text-black font-medium mt-[15px]">
                      Price per Post:{" "}
                      <span className="font-bold">
                        {currencySymbol}&nbsp;
                        {postData?.pricePerPost?.toLocaleString() ||
                          data?.pricePerVideo?.toLocaleString() ||
                          0}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>}
            <div className="bg-white ">
              <Head
                svg={<svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width={30} height={30} rx={5} fill="#F4F1F1" />
                  <path d="M13.875 9L11.4375 22M19.5625 9L17.125 22M9.8125 12.25H22M9 18.75H21.1875" stroke="black" strokeWidth={2} strokeLinecap="round" />
                </svg>
                }
                title="Unique Hashtag"
                content={[
                  "This unique hashtag helps you to track your campaign performance over social networks.",
                ]}
              />
              <div className="mt-[8px] pl-[38px]">
                <a
                  rel="noreferrer"
                  href={
                    platform === "facebook"
                      ? `https://www.facebook.com/hashtag/${postData?.hashTag}`
                      : platform === "instagram"
                        ? `https://www.instagram.com/explore/tags/${postData?.hashTag}/`
                        : platform === "twitter"
                          ? `https://twitter.com/search?q=%23${postData?.hashTag} &src=typed_query`
                          : `https://www.tiktok.com/search?q=%23${postData?.hashTag}&t=1669307167391`
                  }
                  className="font-bold   hover:text-blue-500 "
                  target="_blank"
                >
                  #{postData?.hashTag || data?.searchTag}

                </a>

              </div>

            </div>
          </div>
        )}
      </div>}
    </>
  );
};

const Head = ({ title, content, svg }) => {
  return (
    <div className="font-bold text-sm relative text-black flex gap-2 items-center ">

      {svg}

      <span className="block mt-[2px]">
        {title}
      </span>
      <Tooltip style={{ width: "14px", height: "14px" }} small={true} content={content} />
    </div>
  );
};

export default Header;
