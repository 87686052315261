import { VideoApi } from "../api/video";

export function createNewVideo(data) {
  return VideoApi.createOrUpdateVideo(data);
}

export function EditVideo(data) {
  return VideoApi.createOrUpdateVideo(data);
}

export function deleteVideo(id) {
  return VideoApi.deleteVideo({ id });
}

export function getVideos() {
  return VideoApi.getVideos();
}
