import React from "react";

const Spotify = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 28C22.1796 28 28 22.1796 28 15C28 7.82029 22.1796 2 15 2C7.82029 2 2 7.82029 2 15C2 22.1796 7.82029 28 15 28Z"
        stroke="currentColor"
        stroke-width="2.6666"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.98013 11.4902C12.6601 9.67016 17.9901 10.3202 22.1501 13.1802"
        stroke="currentColor"
        stroke-width="2.6666"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.66977 15.52C13.1798 14.22 17.0798 14.61 20.0698 16.82"
        stroke="currentColor"
        stroke-width="2.6666"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8402 19.4201C13.4401 18.3801 16.3001 18.7701 18.6401 20.3301"
        stroke="currentColor"
        stroke-width="2.6666"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Spotify;
