import { UserAuthApi } from "../api/userAuth";
import { NotificationApi } from "../api/notifications";

export function registerNewUser({ email, password, newsletter }) {
  return UserAuthApi.register({ email, password, newsletter });
}

export function login({ email, password }) {
  return UserAuthApi.login({ email, password });
}

export function getNotifications() {
  return NotificationApi.getNotifications();
}

export function forgotPassword({ email }) {
  return UserAuthApi.forgotPassword({ email });
}

export function resetPassword({ password, repassword, token }) {
  return UserAuthApi.resetPassword({ password, repassword, token });
}

export function changePassword({ newpass, repeatnewpass }) {
  return UserAuthApi.changePassword({ newpass, repeatnewpass });
}

export function changeEmail({ newEmail }) {
  return UserAuthApi.changeEmail({ newEmail });
}

export function activateEmail(token) {
  return UserAuthApi.activateEmail(token);
}

export function activateAccount(token) {
  return UserAuthApi.activateAccount(token);
}

export function getUser() {
  return UserAuthApi.self();
}

export function updateUser(data) {
  return UserAuthApi.update(data);
}

export function deleteUser(data) {
  return UserAuthApi.delete(data);
}

export function setAccountType({ accountType }) {
  return UserAuthApi.setAccountType({ accountType });
}
export function uploadDoc(data) {
  return UserAuthApi.uploadDoc(data);
}
export function DeleteDoc(data) {
  return UserAuthApi.deleteDoc(data);
}

export function getVerified(data) {
  return UserAuthApi.getVerified(data);
}
export function removeWarning(data) {
  return UserAuthApi.removeWarning(data);
}
export function signUpWithGoogle(data) {
  return UserAuthApi.signUpWithGoogle(data);
}
export function signInWithGoogle(data) {
  return UserAuthApi.signInWithGoogle(data);
}
export function updatePwlPass(data) {
  return UserAuthApi.updatePwlPass(data);
}
export function getIntercomHmac(data) {
  return UserAuthApi.getIntercomHmac(data);
}
