import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useHistory } from "react-router-dom";
import cx from "classnames";

import styles from "./formDesktopWrapper.module.scss";
import FormLogo from "../FormLogo/FormLogo";

import iconSoc1 from "../../assets/images/icons/icon-instagram-black.svg";
import iconSoc2 from "../../assets/images/icons/icon-facebook-black.svg";
import iconSoc3 from "../../assets/images/icons/icon-twitter-black.svg";
import Background from "../../assets/images/AuthPagesBackground/bg.png";
import { TERMS_PATH } from "../../globals";

function FormDesktopWrapper({ form }) {
  const history = useHistory();
  return (
    <div className="absolute top-0 left-0 w-full xl:justify-start h-fit  justify-center bg-white xl:h-auto 2xl:h-full  flex  ">
      <div className="xl:w-[50vw] w-full xl:flex hidden  relative  items-center  justify-center min-h-screen bg-black">
        <img className="object-cover  w-full h-full" src={Background} />
        <div className="absolute font-medium text-white leading-[59px]  z-10 text-[35px]">
          We do,
          <br />
          <span className="font-extrabold">SOCIAL MEDIA</span>
          <br /> <span className="font-extrabold">ORGANIC DISTRIBUTION</span>
          <br /> through the authentic voices
          <br /> of millions of people
        </div>
      </div>
      <div className="flex flex-col relative items-center w-full h-full min-h-screen justify-center xl:w-[50vw] ">
        <div className="w-full">{form}</div>
        <div
          onClick={() => {
            history.push(TERMS_PATH);
          }}
          className="lg:absolute bottom-4 pb-2 lg:pb-0 hover:underline text-xs text-[#43425D] cursor-pointer"
        >
          Term of use. Privacy policy
        </div>
      </div>
    </div>
  );
}

FormDesktopWrapper.defaultProps = {};

FormDesktopWrapper.propTypes = {
  form: PropTypes.element.isRequired,
};

export default FormDesktopWrapper;
